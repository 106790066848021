.details-banner.buyer-aspect .project-title {
  display: flex;
  align-items: center;
}

.details-banner.buyer-aspect .project-title .sm-icon {
  width: 36px;
  height: 36px;
  margin-right: 0.5em;
  background-color: var(---2170ff-p-);
  border-radius: 8px;
}

.details-banner.buyer-aspect .project-title .main-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.details-banner.buyer-aspect .project-title .badge.badge-secondary {
  background: #cfcdd0;
  border-color: var(---f1f0f2);
  color: #958f91;
}

.details-banner.buyer-aspect .project-title .sm-icon.cp-icon {
  color: #fff;
  transform: rotatey(180deg);
  padding: 7px;
  opacity: 0.66;
}

.details-banner.buyer-aspect .banner-container .project-title .status {
  width: 6.5em;
  height: 1.6em;
  font-size: 16px !important;
  font-weight: 300 !important;
  min-height: 36px;
}

.details-banner.buyer-aspect .banner-container .project-title .table-container {
  margin-top: 0px;
}

.details-banner.buyer-aspect .datetime-picker-cotainer {
  position: relative;
}

.details-banner.buyer-aspect .datetime-picker-cotainer .MuiFormControl-root {
  width: 15em;
  margin: 0px;
}

.details-banner.buyer-aspect .datetime-picker-cotainer .MuiButtonBase-root {
  position: absolute;
  left: 0;
  padding: 0;
  color: var(---2170ff-p-);
  transform: rotateY(180deg);
}

.details-banner.buyer-aspect .datetime-picker-cotainer .MuiInputBase-root {
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 32px;
}

.details-banner.buyer-aspect
  .datetime-picker-cotainer
  .MuiInputBase-root
  input {
  margin-left: 1em;
  border-bottom: 2px solid transparent;
  padding: 1px 6px;
}
.details-banner.buyer-aspect
  .datetime-picker-cotainer.project-date
  .MuiInputBase-root
  input {
  width: 90px;
}

.details-banner.buyer-aspect
  .datetime-picker-cotainer.__view
  .MuiInputBase-root
  input {
  border-bottom-color: transparent;
  background-color: transparent;
}

.details-banner.buyer-aspect
  .datetime-picker-cotainer.__edit
  .MuiInputBase-root
  input {
  border-bottom-color: var(---2170ff-p-);
  background-color: #badeff;
}

.details-banner.buyer-aspect
  .datetime-picker-cotainer
  .MuiInputBase-root
  .MuiOutlinedInput-notchedOutline {
  border: none;
  margin: 0;
  padding: 0;
}

.details-banner.buyer-aspect
  .banner-container
  .editable-board-container
  .image {
  width: 93px;
}
