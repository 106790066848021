#project-table-list-buyer-aspect .filter-box-container .filter-box .btn-mui {
  min-height: 45px;
  margin-top: -42px;
  border-radius: 8px !important; 
}

@media screen and (max-width: 899px) {
  #project-table-list-buyer-aspect .filter-box-container .filter-box .btn-mui {
    margin-top: 0px;
  }
}

#project-table-list-buyer-aspect .filter-box-container .filter-box .mui-input {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

#auction-list-filter-box-supplier-aspect .filter-field-container,
#auction-list-filter-box-supplier-aspect
  .filter-box-container
  .filter-field-container {
  align-items: normal;
}
