.supp-auction-details-container [aria-label="general-info"] > div {
  height: 100%;
}
@media (min-width: 1536px) {
  .supp-auction-details-container [aria-label="general-info"] {
    max-width: 22%;
    flex-basis: 22%;
  }

  .supp-auction-details-container [aria-label="item-bid-list"] {
    max-width: 50%;
    flex-basis: 50%;
  }

  .supp-auction-details-container [aria-label="live-bid"] {
    max-width: 28%;
    flex-basis: 28%;
  }
}

@media (max-width: 1535px) {
  .supp-auction-details-container [aria-label="general-info"] {
    order: 0;
  }

  .supp-auction-details-container [aria-label="item-bid-list"] {
    order: 2;
  }

  .supp-auction-details-container [aria-label="live-bid"] {
    order: 1;
  }
}
