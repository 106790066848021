.item-list-details-info-container *,
#item-list-info-details-status,
#item-list-info-details-option-list,
#item-list-info-details-attach-option-list,
#item-slider-details-buyer-aspect-notification-box,
[aria-label="item-list-info-details-attach-docs-popup"],
[aria-label="item-list-info-details-attach-link-popup"] {
  z-index: 1600 !important;
}

.item-list-details-info-container .image-container {
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
}

.item-list-details-info-container .image-container .empty-bck {
  content: "there is no image";
  position: absolute;
  top: 3vh;
  left: 1em;
  color: #b1b0b0;
  font-size: 18px;
  z-index: 0;
  text-decoration: underline;
}

.item-list-details-info-container .table-container.MuiBox-root {
  margin-right: 0px;
}

.item-list-details-info-container .image-container .image {
  display: block;
  width: 100%;
  object-fit: contain;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 38vh;
  background: #ffffff;
  z-index: 2;
}

.item-list-details-info-container .image-container .middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  bottom: 24px;
  transform: translateX(1vw);
  text-align: center;
}

.item-list-details-info-container .image-container:hover .middle {
  opacity: 1;
}

.item-list-details-info-container .image-container .middle .hover-box {
  background-color: #24242487;
  padding: 16px 16px;
  display: flex;
  color: #fff;
  border-radius: 32px;
  align-items: center;
}

.item-list-details-info-container .image-container .middle .hover-box .btn-mui {
  padding: 0 12px !important;
  border-radius: 26px !important;
  white-space: break-spaces;
  font-size: 13px !important;
}

.item-list-details-info-container .table-container tbody td,
.item-list-details-info-container .table-container tbody th {
  font-size: 13px;
}

.item-list-details-info-container
  .table-container.MuiTableContainer-root::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.item-list-details-info-container
  .table-container.MuiTableContainer-root::-moz-scrollbar {
  height: 8px;
  width: 8px;
}

.item-list-details-info-container
  .table-container.MuiTableContainer-root::-ms-scrollbar {
  height: 8px;
  width: 8px;
}

.item-list-details-info-container
  .table-container.MuiTableContainer-root::-o-scrollbar {
  height: 8px;
  width: 8px;
}

.item-list-details-info-container .MuiFormControl-root,
.MuiInputBase-root {
  height: 44px;
}

.item-list-details-info-container .btn-mui-table-option.MuiButton-root {
  background: #fff;
  color: var(---2170ff-p-);
  height: 44px;
}
