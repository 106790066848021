.filter-table-header {
  margin-top: 2em;
  align-items: center;
  padding: 15px 0px;
}

.filter-table-header .title {
  font-size: 2em;
  font-weight: 600;
}

.filter-table-header .buttons-box {
  text-align: end;
}

/* small screen  */
@media screen and (max-width: 600px) {
  .filter-table-header .buttons-box {
    text-align: center;
  }
  .filter-table-header .title {
    text-align: center;
  }
}

.filter-box-container {
  position: relative;
}

.filter-box-container .filter-box {
  position: absolute;
  /* margin-top: 1em; */
  z-index: 2;
  border-radius: 15px !important;
  width: 100%;
  top: 0;
  right: 0;
  padding: 1.5em 2em;
  box-shadow: 0px 1px 4px 2px #dddbdb !important;
}

.filter-box-container .filter-action-container,
.filter-box-container .filter-field-container {
  display: flex;
  align-items: flex-end;
}

.filter-box-container .filter-action-container button {
  margin: 0 !important;
  white-space: nowrap;
  padding: 0.9em 2em;
  min-width: 14em;
  text-transform: capitalize;
  border-radius: 8px;
  min-height: 48px;
}

.filter-box-container .filter-action-container .select-field:nth-child(1) {
  padding-right: 20px !important;
}

.filter-box-container .filter-action-container {
  margin-left: 0 !important;
}

.filter-box-container .select-field label {
  line-height: 30px;
  font-size: 14px !important;
  color: #a19b9d;
}

.filter-box-container .select-field input {
  height: 48px;
  font-weight: 700;
}

.filter-box-container .filter-field-container .select-field {
  /* padding-right: 20px !important; */
  flex-grow: 1;
}

.filter-box-container
  .filter-field-container
  .select-field
  .MuiOutlinedInput-root {
  width: 100%;
}
