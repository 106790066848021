.dashboard-statistics-container .dashboard-statistics-box {
  padding: 25px;
}

.dashboard-statistics-container .dashboard-statistics-box .icon {
  /* width: 55px;
  height: 55px; */
  width: 55px;
  max-width: 100%;
  height: auto;
}

.dashboard-statistics-container .dashboard-statistics-box .icon.small {
  width: 75px;
  height: 75px;
}

.dashboard-statistics-container .dashboard-statistics-box .details {
  font-weight: 700;
  margin-left: 0.8em;
  text-align: center;
}

.dashboard-statistics-container .dashboard-statistics-box .details .label {
  font-size: 1.2em;
  color: var(---2170ff-p-);
}

.dashboard-statistics-container .dashboard-statistics-box .details .label a {
  text-decoration: none;
  color: inherit;
}

.dashboard-statistics-container .dashboard-statistics-box .details .value {
  font-size: 2.2em;
}
