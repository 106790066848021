.auth-box-container .success-page-logo {
  text-align: -webkit-center;
  text-align: center;
  margin-bottom: 6em;
}

.auth-box-container .success-page-logo img {
  width: 60%;
}

@media screen and (min-width: 1475px) {
  .auth-box-container .success-page-logo {
    width: 175px;
    height: 30px;
  }
}

@media screen and (min-width: 960px) {
  .auth-box-container .success-page-logo {
    width: 150px;
    height: 30px;
  }

  .auth-box-container .success-page-logo {
    margin-bottom: 5em;
  }

  .auth-box-container .form-box-container {
    background-color: #fff;
    padding: 33px 16px;
    border-radius: 30px;
    margin-top: 25px;
  }

  .MuiTypography-subtitle2 {
    font-size: 0.75rem !important;
    font-family: Noto Sans, sans-serif;
    font-weight: 500;
    line-height: 1.57;
  }

  .auth-box-container .text-logo-box-container-success {
    position: relative;
    /* display: list-item !important; */
    text-align: -webkit-center;
  }
}

.auth-box-container .text-logo-box-container-success {
  position: relative;
  display: none;
  text-align: -webkit-center;
  text-align: center;
}

@media screen and (min-width: 1280px) {
  .auth-box-container .text-logo-box-container-success {
    display: block;
  }

  .auth-box-container .success-page-logo {
    width: 150px;
    height: 30px;
  }
}

.auth-box-container .text-logo-box-success {
  /* background: #94c8f157; */
  padding: 15px 0px;
  border-radius: 100px 0px;
  /* width: 100px; */
  text-transform: lowercase;
}

.auth-box-container .text-logo-box-success span {
  font-family: "Raleway", sans-serif !important;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 3px;
  color: #fff;
  opacity: 1;
  text-transform: lowercase;
}

@media screen and (min-width: 1475px) {
  .auth-box-container .text-logo-box-success {
    /* width: 120px; */
    height: 52px !important;
  }
}

.form-box-container span {
  font-weight: 700;
  /* font-size: 20px; */
}

.email-content-text-email {
  text-align: -webkit-center;
}

.text-contact-support {
  margin-top: 17px;
  font-size: 10px;
  color: #bbbbbb;
}

.contact-support-color {
  color: #72a3fe;
}

.footer-alredy-account-text {
  margin-top: 3em;
}
