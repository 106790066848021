.auction-comment-container {
}

.new-comment-container {
}
.auction-comment-container .new-comment-img-container {
  margin-right: 2em;
}

.auction-comment-container img {
  width: 2.7em;
  border-radius: 50%;
}

.auction-comment-container .title-divider {
  color: #f2f2f2;
  font-size: 1em;
  opacity: 0.4;
  height: 2px;
}

.auction-comment-container .new-comment-form-container .comment-text-box {
  height: 5em;
  width: calc(100% - 2px) !important;
}

.auction-comment-container
  .new-comment-form-container
  .comment-text-box.repaly {
  height: 8em;
  width: calc(100% - 2px) !important;
}

.auction-comment-container
  .new-comment-form-container
  .comment-text-box
  .MuiInputBase-root {
  height: 5em;
}

.auction-comment-container
  .new-comment-form-container
  .comment-text-box.repaly
  .MuiInputBase-root {
  height: 8em;
}

.auction-comment-container
  .new-comment-form-container
  .comment-text-box
  textarea:first-of-type {
  max-height: 3em;
}

.auction-comment-container
  .new-comment-form-container
  .comment-text-box.repaly
  textarea:first-of-type {
  max-height: 6em;
}

.auction-comment-container .new-comment-text {
  font-weight: 600;
  border: 2px #f2f2f2 solid;
  width: -webkit-fill-available;
  width: -moz-fill-available;
  width: -ms-fill-available;
  width: -o-fill-available;
  font-family: "Noto Sans", sans-serif;
  resize: none;
  outline: none;
  border-radius: 15px;
  overflow: hidden;
  padding: 1em;
  font-size: 1em;
  margin-right: -3em;
}

.auction-comment-container .new-comment-text:visited,
.auction-comment-container .new-comment-text:focus-visible,
.auction-comment-container .new-comment-text:focus {
  border-color: var(---2170ff-p-);
}

.auction-comment-container .comment-container {
  display: flex;
}

.auction-comment-container .comment-container img {
  margin-right: 0.6em;
}

.auction-comment-container .comment-container .comment-container-title {
  font-size: 16px;
  font-weight: 600;
  color: var(---2170ff-p-);
  text-transform: capitalize;
}

.auction-comment-container .comment-container .comment-container-time {
  display: block;
  font-size: 0.7em;
  font-weight: 500;
  color: var(---a19b9d);
  line-height: 2em;
}

.auction-comment-container .replay-container {
  margin-left: 3.6em;
}

.auction-comment-container .replay-container .replay-container-label {
  color: var(---2170ff-p-);
  font-size: 0.8em;
  font-weight: 600;
  margin-bottom: 2em;
}

.auction-comment-container .action-btn {
  padding: 0.6em 2em;
  min-width: 10em;
  text-transform: capitalize;
  border-radius: 12px;
}

.auction-comment-container .action-btn.cancel {
  margin-right: 0.5em;
  background-color: var(---e2feff);
  color: #1976d2;
  font-weight: 600;
}
