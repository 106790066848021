.checkbox {
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  cursor: pointer;
}

.checkbox-primary-outline [aria-label="outer-box"],
.checkbox-primary [aria-label="outer-box"] {
  color: var(---2170ff-p-);
}

.checkbox [aria-label="check-mark"] {
  display: none;
  position: absolute;
  left: 3px;
  top: 3px;
  width: 18px;
  height: 17px;
}

.checkbox-primary-outline [aria-label="check-mark"] {
  color: var(---2170ff-p-);
}

.checkbox-primary [aria-label="check-mark"] {
  color: #ffffff;
  background-color: var(---2170ff-p-);
}

.checkbox.checked [aria-label="check-mark"] {
  display: block;
}
