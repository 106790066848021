#item-table-list-buyer-aspect .filter-box-container .filter-box .btn-mui {
  min-height: 45px;
  margin-top: -50px;
  border-radius: 8px !important;
}

@media screen and (max-width: 960px) {
  #item-table-list-buyer-aspect .filter-box-container .filter-box .btn-mui {
    margin-top: 0px;
  }
}
