/* normal  */
.mui-input {
  height: 45px !important;
}

.mui-input .MuiInputBase-root {
  height: 45px !important;
}

.mui-input textarea:first-of-type {
  max-height: 45px !important;
}
/* normal  */

/* 5em height  */
.mui-input.fix-h-5em {
  min-height: 5rem !important;
}

.mui-input.fix-h-5em .MuiInputBase-root {
  height: 5em !important;
}

.mui-input.fix-h-5em textarea:first-of-type {
  max-height: 3em !important;
  height: 3em !important;
}
/* 5em height  */

/* 8em height  */
.mui-input.fix-h-8em {
  min-height: 8rem !important;
}

.mui-input.fix-h-8em .MuiInputBase-root {
  height: 8em !important;
}

.mui-input.fix-h-8em textarea:first-of-type {
  max-height: 6em !important;
  height: 6em !important;
}
/* 8em height  */

/* 12em height  */
.mui-input.fix-h-12em {
  min-height: 12em !important;
}

.mui-input.fix-h-12em .MuiInputBase-root {
  height: 12em !important;
}

.mui-input.fix-h-12em textarea:first-of-type {
  max-height: 8em !important;
  height: 8em !important;
}
/* 12em height  */

/* 20em height  */
.mui-input.fix-h-20em {
  min-height: 20em !important;
}

.mui-input.fix-h-20em .MuiInputBase-root {
  height: 20em !important;
}

.mui-input.fix-h-20em textarea:first-of-type {
  max-height: calc(100% - 10px) !important;
  height: calc(100% - 10px) !important;
}
/* 20em height  */

.mui-input .MuiOutlinedInput-notchedOutline,
.mui-input .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: var(---e0e0e0);
}

.MuiOutlinedInput-root.Mui-disabled
  .MuiOutlinedInput-notchedOutline
  .mui-input
  .MuiFormHelperText-contained {
  margin-left: 0px;
}

/* date time picker design  */

.mui-input.mui-date-picker-reverse .MuiIconButton-root {
  padding: 0 !important;
}

.mui-input.mui-date-picker-reverse .MuiButtonBase-root {
  position: absolute;
  left: 10px;
  color: var(---2170ff-p-);
  transform: rotateY(180deg);
}

.mui-input.mui-date-picker-reverse .MuiInputBase-root {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 26px;
}

.mui-input .MuiInputBase-root.Mui-disabled {
  cursor: no-drop;
  color: rgba(0, 0, 0, 0.87);
  background: var(---f1f0f2);
}

/* date time picker design  */

.mui-label {
  font-size: 14px !important;
  color: var(---a19b9d) !important;
  margin-bottom: 4px;
}

.mui-input.helper-text-nowrap .MuiFormHelperText-contained {
  white-space: nowrap;
}

.mui-input .MuiFormHelperText-contained {
  margin-left: 0px;
}
.mui-select .MuiFormHelperText-contained {
  margin-left: 0px;
}
