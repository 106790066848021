.tab-header-container {
  margin-top: 12em;
  margin-bottom: 2em;
  justify-content: space-between;
}

.tab-header {
  background-color: rgb(255, 255, 255);
  border-radius: 25px !important;
}

.tab-header .MuiTab-root {
  font-weight: 600;
  color: #000;
  text-transform: capitalize;
  padding: 0px 15px;
  min-height: 2.7em;
}

.tab-header .MuiTab-root.Mui-selected {
  color: #fff;
  background-color: var(---2170ff-p-);
  padding: 0px;
  margin: 7px;
  border-radius: 25px;
  text-transform: capitalize;
}

.tab-body-container.__clear-page.MuiPaper-rounded {
  box-shadow: none !important ;
  background-color: transparent;
  border: none !important;
}

.tab-body-container.__clear-page > .MuiBox-root {
  padding: 0px !important;
}

.tab-body-container.__clear-page [labelAria="no-padding"] > .MuiBox-root {
  padding: 0px !important;
}
