.image-uploader-container {
  margin: 0 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #dbe8ff;
  border: 2px dashed #2170ff;
  border-radius: 8px;
  margin-bottom: 1rem;
  color: #000000;
  padding: 1.5em;
}

.image-uploader-container .uploader-header p {
  margin: 2px 0;
}

.image-uploader-container .uploader-header .upload-btn {
  background-color: #fff;
  color: var(---2170ff-p-);
  margin: 20px 0;
  font-size: 16px;
  border-radius: 5em;
  padding: 0.5rem 2rem;
  font-weight: 700;
  height: 40px;
  text-transform: capitalize;
}

.image-uploader-container .uploader-header .upload-icon {
  margin-top: 1em;
  height: 43px;
  width: 46px;
}

.image-uploader-container .uploader-header .upload-title {
  font-size: 20px;
  color: var(---1e1d1d);
  font-weight: 600;
}

.image-uploader-container .uploader-header .upload-description {
  font-size: 14px;
  color: var(---a19b9d);
  margin-top: 8px;
}

.image-uploader-container input[type="file"] {
  display: none;
}

.image-uploader-container .label {
  display: none;
}

.uploaded-files {
  padding: 11px 20px 10px 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin: 10px 0px 3px 0;
}


.uploaded-files .icon {
  height: 37px;
  width: 37px;
}

.uploaded-files .details {
  overflow: hidden;
}

.uploaded-files .details .name {
  font-size: 16px;
  color: var(---1e1d1d);
  font-weight: 600;
}

.uploaded-files .details .size {
  font-size: 14px;
  color: var(---a19b9d);
}
