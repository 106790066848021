.create-form-container .MuiFormLabel-root {
  font-size: 0.9rem;
  color: var(---a19b9d);
  margin-bottom: 0.5rem;
}

.create-form-container .MuiFormControl-root {
  width: 100% !important;
  margin: 0 !important;
}

.create-form-container .MuiFormControl-root .MuiFormHelperText-contained {
  margin-left: 0px;
  margin-bottom: 7px;
}

.create-form-container .MuiInputBase-root {
  font-weight: 600;
}

/* Popup Design  */

/* first-step popup form */
[aria-label="create-item-second-step-buyer-aspect"] .popup-form-container {
  width: 80vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="create-item-second-step-buyer-aspect"] .popup-form-container {
    width: 60vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="create-item-second-step-buyer-aspect"] .popup-form-container {
    width: 80vw;
  }
}

[aria-label="create-item-second-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiButtonBase-root {
  position: absolute;
  left: 9px;
  color: var(---2170ff-p-);
  transform: rotateY(180deg);
}

[aria-label="create-item-second-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiInputBase-root {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 26px;
}
[aria-label="create-item-second-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiInputBase-input {
  margin-left: 10px;
}

[aria-label="create-item-second-step-buyer-aspect"] .btn-mui {
  font-size: 16px !important;
}

/* bottom buttons  */
[aria-label="create-item-second-step-buyer-aspect"]
  #bottom-button-box-container {
  display: flex;
  justify-content: space-between;
  margin-top: 5em;
}

@media screen and (max-width: 960px) {
  [aria-label="create-item-second-step-buyer-aspect"]
    #bottom-button-box-container {
    display: block;
  }
}

[aria-label="create-item-second-step-buyer-aspect"]
  #bottom-button-box-container
  #left-side {
  min-width: 197px;
}

[aria-label="create-item-second-step-buyer-aspect"]
  #bottom-button-box-container
  #right-side {
  display: flex;
}

@media screen and (max-width: 960px) {
  [aria-label="create-item-second-step-buyer-aspect"]
    #bottom-button-box-container
    #right-side {
    display: block;
  }
}

[aria-label="create-item-second-step-buyer-aspect"]
  #bottom-button-box-container
  #right-side
  > div:first-of-type {
  min-width: 197px;
}

@media screen and (max-width: 960px) {
  [aria-label="create-item-second-step-buyer-aspect"]
    #bottom-button-box-container
    #right-side
    > div:first-of-type {
    margin-top: 10px;
  }
}

[aria-label="create-item-second-step-buyer-aspect"]
  #bottom-button-box-container
  #right-side
  > div:last-of-type {
  min-width: 197px;
  margin-left: 1.25em;
}

@media screen and (max-width: 960px) {
  [aria-label="create-item-second-step-buyer-aspect"]
    #bottom-button-box-container
    #right-side
    > div:last-of-type {
    margin-left: 0;
    margin-top: 10px;
  }
}
/* bottom buttons  */

/* first-step popup form */

/* Upload Link Popup  */

[aria-label="create-item-second-step-buyer-aspect-upload-link-file"]
  .popup-form-container {
  width: 30vw;
}

/* for meduim screen  */
@media screen and (max-width: 1400px) {
  [aria-label="create-item-second-step-buyer-aspect-upload-link-file"]
    .popup-form-container {
    width: 50vw;
  }
}

/* for small screen  */
@media screen and (max-width: 960px) {
  [aria-label="create-item-second-step-buyer-aspect-upload-link-file"]
    .popup-form-container {
    width: 70vw;
  }
}

[aria-label="create-item-second-step-buyer-aspect-upload-link-file"]
  .popup-form-container
  .bottom-button-box-container
  .left-side {
  min-width: 165px;
}

/* Upload Link Popup  */

/* Upload Document Popup  */

[aria-label="create-item-second-step-buyer-aspect-upload-docs-file"]
  .popup-form-container {
  width: 30vw;
}

/* for meduim screen  */
@media screen and (max-width: 1400px) {
  [aria-label="create-item-second-step-buyer-aspect-upload-docs-file"]
    .popup-form-container {
    width: 50vw;
  }
}

/* for small screen  */
@media screen and (max-width: 960px) {
  [aria-label="create-item-second-step-buyer-aspect-upload-docs-file"]
    .popup-form-container {
    width: 70vw;
  }
}

[aria-label="create-item-second-step-buyer-aspect-upload-docs-file"]
  .popup-form-container
  .bottom-button-box-container
  .left-side {
  min-width: 165px;
}

/* Upload Document Popup  */
