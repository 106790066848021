.rfx-checkbox {
  border: none !important;
}
.rfx-question-step .question-box {
  /* padding: 11px 20px 10px 10px; */
  border: 1px solid #e0e0e0;
  border-radius: 12px !important;
}
.rfx-question-step .question-box .question-title {
  padding: 11px 20px 10px 10px;
  border-bottom: 1px solid #e0e0e0;
}
.rfx-question-step .question-box .answer-area {
  padding: 10px;
}
.rfx-question-step .lower-box {
  padding: 10px;
}
.rfx-question-step .common-rfx-icon,
.edit-question-answer .common-rfx-icon {
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
}
.rfx-question-step .add-rfx-icon,
.edit-question-answer .add-rfx-icon {
  background-color: #83c9f4;
  color: #2170ff;
}
.rfx-question-step .remove-rfx-icon,
.edit-question-answer .remove-rfx-icon {
  background-color: #f3949e;
  color: #c82333;
}
.edit-question-answer .add-rfx-icon,
.edit-question-answer .remove-rfx-icon {
  margin-top: -10px;
}
.rfx-general-info .inner-info-first {
  min-height: 6.5em !important;
}
.rfx-general-info .question-num-text {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 5px;
}
.rfx-general-info .question-num {
  font-size: 31px;
  line-height: 42px;
}
.rfx-general-info .time-num {
  font-size: 24px;
  line-height: 35px;
}
.rfx-cover-image {
  width: 29px;
  height: 38px;
}
.icon-rfx-general {
  width: 52px;
  height: auto;
  display: block;
  margin: auto;
}
.question-tab .question-detail {
  display: flex;
  justify-content: space-between;
}
.question-tab .action-btn,
.question-seller-tab .action-btn {
  background-color: var(---e2f2ff) !important;
  color: var(---2170ff-p-) !important;
  border: px solid var(---e2f2ff) !important;
  font-size: 7px !important;
  box-shadow: none !important;
  border-radius: 50% !important;
  padding: 0px 0px 0px 0px !important;
  min-width: 1em !important;
  transform: rotate(90deg);
}

.mui-custom-drawer {
  background: unset !important;
}
.rfx-question-badge {
  border: 1px solid #2170ff;
  background-color: #e2f2ff;
  color: #2170ff;
  padding: 6px 22px;
  border-radius: 16px;
}
.each-option-card .inner-option-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 100%;
  padding: 12px 6px;
  word-break: break-all;
  max-height: 150px;
  overflow-y: auto;
}
.question-tab .quetion-action {
  margin-top: -6px;
  justify-content: end;
}

@media screen and (max-width: 900px) {
  .question-tab .question-order {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 900px) {
  .question-tab .quetion-action {
    margin-top: 15px;
  }
}
.edit-question-drawer .MuiPaper-root {
  width: calc(100% - 130px);
  max-width: 640px;
}
.question-tab .inner-edit-question {
  padding: 32px;
}
.question-tab .edit-title,
.messages-tab .common-title {
  color: #2170ff;
  font-size: 24px;
  line-height: 33px;
  font-weight: 700;
}
.question-tab .edit-title {
  padding: 30px 32px 10px;
}
.messages-tab .chat-head-body {
  padding: 30px 32px 10px;
}
.question-tab .edit-question-answer {
  padding: 32px;
}
.messages-tab .messages-list {
  padding: 0 32px;
}
.question-tab .divider-title,
.messages-tab .divider-title {
  width: 100%;
  height: 5px;
  border-top: 1px solid #eeeeee;
}
.edit-question-textarea .MuiFormControl-root {
  margin: 0 !important;
  width: 100% !important;
}
.common-chat .buyer-chat {
  /* width: calc(100% - 49px); */
  width: 70%;
}
.common-chat .buyer-chat .chat-time {
  color: #888888;
  font-size: 14px;
  line-height: 19px;
}
.common-chat .buyer-chat-profile {
  margin-right: 17px;
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.common-chat .seller-chat-profile {
  margin-left: 17px;
  order: 1;
}
.chat-box {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.chat-box .chat-header {
  flex: 0;
}

.chat-box .chat-body {
  flex: 1%;
  overflow-y: auto;
}
.chat-box .chat-footer {
  gap: 10px;
  flex: 0;
  padding: 32px;
}
.chat-box .chat-input {
  min-height: 48px;
}
.chat-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.chat-footer .attachment-icon {
  margin-right: 5px;
  transform: rotate(40deg);
  color: #afafaf;
}
.chat-footer .icon-chat-attach-list {
  margin-left: -7px;
}
.chat-footer .document-list {
  max-height: 255px;
  overflow-y: auto;
}
.rfx-step-2-checkbox {
  margin-top: -2px;
}
.uploaded-files.chat-file {
  width: 40%;
  cursor: pointer;
}
.question-seller-tab .question-tab-title {
  color: #2170ff;
  font-weight: 700;
  margin-right: 8px;
  font-size: 22px;
  line-height: 43px;
  margin-top: -4px;
}
.question-seller-tab .btn-reply {
  padding: 0 25px !important;
  min-height: 27px !important;
}
.question-seller-tab .btn-replied {
  background: var(---def8f3) 0% 0% no-repeat padding-box !important;
  border-color: var(---def8f3) !important;
  color: var(---00d4b5-s-) !important;
}
.question-seller-tab .inner-option-card {
  border: 0 !important;
  padding: 0 !important;
}
.item-seller-table .item-add-action {
  height: 30px;
  border-radius: 15px;
}
.item-seller-table .item-edit-action {
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid var(---2170ff-p-);
  color: var(---2170ff-p-);
}
.item-seller-table .item-edit-action:hover {
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid var(---2170ff-p-);
  color: var(---2170ff-p-);
}
.rfx-seller-bid-card .add-lumpsum-btn {
  background-color: #e2f2ff;
  color: #2170ff;
}
.rfx-seller-bid-card .add-lumpsum-btn:hover {
  background-color: #e2f2ff;
  color: #2170ff;
}

.rfx-seller-bid-card .attach-name-display {
  height: 45px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  width: 100%;
  padding: 8px 12px;
}

.rfx-seller-bid-card .add-bid-attach {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid;
  height: 45px !important;
  min-height: 45px !important;
}

.rfx-seller-bid-card .bid-doc-display,
.rfx-compare-tab .bid-doc-display {
  /* width: 48%; */
  border-radius: 16px;
  background-color: #e2f2ff;
  padding: 4px 12px;
  color: #2170ff;
  justify-content: space-between;
  align-items: center;
}
.rfx-compare-tab .compare-doc {
  width: 130px !important;
  /* width: 42% !important; */
}
.rfx-seller-bid-card .bid-doc-display .delete-icon-body {
  background-color: #fff;
  border-radius: 50%;
  padding: 4px;
}
.rfx-seller-bid-card .bid-doc-display .delete-icon-body .delete-icon {
  color: #c82333;
}
.rfx-seller-bid-card .bid-doc-display .attach-icon,
.rfx-compare-tab .bid-doc-display .attach-icon {
  transform: rotate(40deg);
}
.rfx-seller-bid-card .price-body {
  position: relative;
  width: fit-content;
  margin: 0 auto;
}
.rfx-seller-bid-card .bid-edit-container {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  border-radius: 50%;
  padding: 4px;
}
.rfx-seller-bid-card .bid-number {
  padding: 0 40px 0 0;
}
.rfx-seller-bid-card .rfx-bid-body {
  width: fit-content !important;
  max-width: 100%;
  margin: 0 auto;
}
.rfx-seller-bid-card .bid-edit-container.border-primary {
  border: 1px solid #2170ff;
}
.rfx-seller-bid-card .bid-edit-container.border-warning {
  border: 1px solid #ffc107;
}

.rfx-seller-bid-card .btn-mui.btn-primary,
.rfx-seller-bid-card .btn-mui.btn-primary,
.rfx-seller-bid-card .btn-mui.btn-primary {
  color: #fff;
  background-color: var(---2170ff-p-);
  border-color: var(---2170ff-p-);
}
.rfx-supplier .file-name {
  color: #2170ff !important;
}
.details-banner.rfx-compare-banner {
  height: 9em !important;
}
/* .rfx-compare-tab tr:hover,
.rfx-compare-price-tab tr:hover {
  background-color: inherit !important;
} */
.rfx-compare-tab tr:nth-child(odd):hover,
.rfx-compare-price-tab tr:nth-child(even):hover,
.rfx-compare-price-tab th:hover,
.rfx-lumpsum-tab th:hover {
  background-color: #fff !important;
}
.rfx-compare-tab tr:nth-child(even):hover,
.rfx-compare-price-tab tr:nth-child(odd):hover {
  background-color: #f0f5ff !important;
}
.rfx-compare-price-tab tr:first-child:hover,
.rfx-lumpsum-tab tr:hover {
  background-color: #fff !important;
}
.rfx-compare-tab th,
.rfx-compare-price-tab th,
.rfx-lumpsum-tab th {
  color: #1e1d1d !important;
  font-weight: 600 !important;
}
.rfx-compare-tab th:first-child,
.rfx-compare-tab td:first-child {
  border-right: 1px solid rgba(224, 224, 224, 1) !important;
}
.rfx-compare-tab td {
  color: #a19b9d !important;
  font-weight: 400 !important;
}
.rfx-compare-tab .inner-td {
  padding: 8px 16px 8px 0;
  border-right: 1px solid rgba(224, 224, 224, 1);
}
.rfx-compare-tab .inner-th {
  padding: 8px 15px 8px 0px;
  border-right: 1px solid rgba(224, 224, 224, 1);
}
.rfx-compare-tab .inner-th:last-child,
.rfx-compare-tab .inner-td:last-child {
  border: none !important;
}
@media screen and (min-width: 1536px) {
  .rfx-invite-supplier {
    width: 40vw !important;
  }
}
@media screen and (max-width: 1148px) {
  .rfx-invite-supplier .rfx-supplier-detail {
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
  .rfx-invite-supplier .dot {
    display: none;
  }
  .rfx-invite-supplier .supplier-name {
    padding-top: 10px;
  }
}

.rfx-invite-supplier .dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #a19b9d;
}
.rfx-invite-supplier .supplier-data {
  padding: 16px 0;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.rfx-compare-tab .close-icon-body,
.rfx-compare-price-tab .close-icon-body,
.rfx-lumpsum-tab .close-icon-body {
  width: 24px;
  height: 24px;
  background-color: #fcdee1;
  border-radius: 4px;
}
.rfx-compare-tab .close-icon-body .close-icon,
.rfx-compare-price-tab .close-icon-body .close-icon,
.rfx-lumpsum-tab .close-icon-body .close-icon {
  color: #c82333;
  width: 16px;
  height: 16px;
}
.rfx-compare-tab .hr-line,
.rfx-compare-price-tab .hr-line,
.rfx-lumpsum-tab .hr-line {
  width: 1px;
  height: auto;
  background-color: rgba(224, 224, 224, 1);
  margin: 0 6px;
}
.rfx-compare-tab .response-value {
  width: calc(100% - 140px);
}

.rfx-compare-price-tab th,
.rfx-lumpsum-tab th {
  border: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.rfx-compare-price-tab .sub-header,
.rfx-lumpsum-tab .sub-header {
  background-color: #f0f5ff;
  color: #2170ff;
  height: 40px !important;
}
.rfx-compare-price-tab th,
.rfx-lumpsum-tab th,
.rfx-compare-tab th {
  /* width: auto !important; */
  width: 350px !important;
  max-width: 350px !important;
  min-width: 350px !important;
}
.rfx-compare-tab td,
.rfx-compare-price-tab td,
.rfx-lumpsum-tab td {
  width: auto !important;
  max-width: 150px !important;
  min-width: 150px !important;
}
.rfx-compare-tab td:first-child {
  max-width: 300px !important;
  min-width: 300px !important;
}
.rfx-compare-price-tab th:nth-child(1),
.rfx-compare-price-tab td:nth-child(2) {
  border-right: 1px solid rgba(224, 224, 224, 1);
}
.rfx-compare-price-tab td:nth-child(2n),
.rfx-compare-price-tab th,
.rfx-compare-tab td,
.rfx-compare-tab th,
.rfx-lumpsum-tab td,
.rfx-lumpsum-tab th {
  position: relative;
}
.rfx-compare-price-tab th::before,
.rfx-compare-price-tab td:nth-child(2n)::before,
.rfx-compare-tab th::before,
.rfx-compare-tab td::before,
.rfx-lumpsum-tab th::before,
.rfx-lumpsum-tab td::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 80%;
  top: 10%;
  right: 0px;
  background-color: rgba(224, 224, 224, 1);
}
.rfx-compare-price-tab td:nth-child(2)::before,
.rfx-compare-price-tab td:last-child::before,
.rfx-compare-price-tab th:nth-child(1)::before,
.rfx-compare-price-tab th:last-child::before,
.rfx-compare-tab th:first-child::before,
.rfx-compare-tab td:first-child::before,
.rfx-compare-tab th:last-child::before,
.rfx-compare-tab td:last-child::before,
.rfx-lumpsum-tab th:last-child::before,
.rfx-lumpsum-tab td:last-child::before {
  display: none !important;
}
@media screen and (max-width: 730px) {
  .rfx-tab-header {
    padding-top: 60px !important;
    padding-bottom: 30px !important;
    gap: 20px;
  }
}
.rfx-submit-bid-modal {
  max-width: 540px !important;
}
.rfx-submit-bid-modal .submit-bid-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-right: 60px;
  padding-left: 60px;
}
@media screen and (max-width: 830px) {
  .rfx-submit-bid-modal .submit-bid-body {
    padding-left: 0;
    padding-right: 0;
  }
}
.rfx-submit-bid-modal .rfx-submit-icon {
  width: 60px !important;
  height: 60px !important;
}
.rfx-submit-bid-modal .submit-main-text {
  font-size: 20px;
}

.rfx-submit-bid-modal .submit-cancel-btn {
  background-color: #e2f2ff;
  color: #2170ff;
}
.rfx-submit-bid-modal .submit-cancel-btn:hover {
  background-color: #e2f2ff;
  color: #2170ff;
}
.rfx-tab-list .message-tab-count {
  background-color: #e2f2ff;
  color: #2170ff;
  border-radius: 50%;
  padding: 4px;
}
.rfx-tab {
  display: inline-flex;
  align-items: center;
}
.rfx-tab .message-count {
  border-radius: 50%;
  background-color: #2170ff;
  color: #fff;
  margin-left: 8px !important;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Mui-selected .rfx-tab .message-count {
  background-color: #fff;
  color: #2170ff;
}
.rfx-tab .tab-title {
  font-weight: 600 !important;
  font-size: 14px !important;
}
.messages-tab .collapsed-overlayed-page-contaier {
  z-index: 1050 !important;
}
.rfx-seller-detail-card .file-name-container {
  flex-wrap: wrap;
  gap: 10px;
}
.rfx-seller-table .rfx-seller-status {
  max-width: 300px !important;
}
.rfx-read-more-modal {
  outline: none;
}
.rfx-read-more-modal .read-question {
  font-size: 18px;
  line-height: 1.1;
}
@media screen and (max-width: 1414px) {
  .rfx-tab-body .cancel-rfx {
    margin-top: 16px !important;
  }
}
