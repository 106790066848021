.projet-card-buyer-aspect {
  height: 24em;
}

.projet-card-buyer-aspect .card-container {
  height: 24em;
  position: relative;
  border: 1px solid transparent;
}

.projet-card-buyer-aspect .card-container.__active {
  border-color: var(---2170ff-p-);
}

.projet-card-buyer-aspect .card-container .card-image {
  height: 12em;
}

.projet-card-buyer-aspect .card-container .card-image {
  position: absolute;
  top: 0;
  /* object-fit: none; */
}

.projet-card-buyer-aspect .card-container .card-image.__hide {
  opacity: 0.1;
}

.projet-card-buyer-aspect .card-container .option-crud-list {
  position: absolute;
  top: 22px;
  right: 15px;
}

.projet-card-buyer-aspect .card-container .status-crud-list {
  position: absolute;
  top: 15px;
  left: 15px;
}

.projet-card-buyer-aspect .card-container .MuiCardActionArea-root {
  height: 24em;
}

.projet-card-buyer-aspect .card-container .card-content-container {
  /* z-index: 1; */
  position: absolute;
  bottom: 0;
  width: 100%;
}

.projet-card-buyer-aspect .card-container .card-content-container .title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 7px;
}

.projet-card-buyer-aspect .card-container .card-content-container .description {
  font-size: 13px;
}

.projet-card-buyer-aspect .card-container .card-content-container .details {
  margin-top: 10px;
}

.projet-card-buyer-aspect
  .card-container
  .card-content-container
  .details
  .mui-label {
  font-size: 12px !important;
}

.projet-card-buyer-aspect
  .card-container
  .card-content-container
  .details
  .field {
  font-size: 14px;
  font-weight: 600;
}
