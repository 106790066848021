.supp-auction-details-general-info-container .file-name-container {
  display: flex;
  overflow: hidden;
}

.supp-auction-details-general-info-container .file-name-container .icon {
  width: 20px;
  height: 20px;
  color: var(---a19b9d);
  vertical-align: middle;
}

.supp-auction-details-general-info-container .file-name-container .name {
  margin-left: 15px;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}
