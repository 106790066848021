.invite-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0 40px 0;
}
.common-tab {
  font-size: 16px;
  background-color: #fff;
  color: #000;
  padding: 0px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  text-transform: capitalize;
  padding: 12px 50px;
  font-weight: 600;
  border-bottom: 1px solid #2170ff;
  cursor: pointer;
}
.activeTab {
  background-color: #2170ff;
  color: #fff;
}

