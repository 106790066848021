.popup-form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  background-color: #fff;
  padding: 3em;
  flex-grow: 1;
  max-height: 90vh;
  overflow: auto;
  scrollbar-color: #dbdbdb rgba(0, 0, 0, 0.03);
  scrollbar-width: thin;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  .popup-form-container {
    width: 30vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 960px) {
  .popup-form-container {
    width: 70vw;
  }
}

.popup-form-container .title {
  margin-bottom: 1.4em;
  font-size: 1.3rem;
  font-weight: 600;
}

.popup-form-container .MuiFormLabel-root {
  font-size: 0.875rem;
  color: var(---a19b9d);
  margin-bottom: 0.25rem;
}

.popup-form-container .MuiFormControl-root {
  width: 100% !important;
  margin: 0 !important;
}

.popup-form-container .MuiInputBase-root {
  font-weight: 600;
}

/* scroll bar design  */

/* scrollbar */
.popup-form-container::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.popup-form-container::-moz-scrollbar {
  width: 7px;
  height: 7px;
}

.popup-form-container::-ms-scrollbar {
  width: 7px;
  height: 7px;
}

.popup-form-container::-o-scrollbar {
  width: 7px;
  height: 7px;
}
/* scrollbar */

/* scrollbar-track */
.popup-form-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}

.popup-form-container::-moz-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}

.popup-form-container::-ms-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}

.popup-form-container::-o-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}
/* scrollbar-track */

/* scrollbar-thumb */
.popup-form-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -moz-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.popup-form-container::-moz-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -moz-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.popup-form-container::-ms-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -moz-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.popup-form-container::-o-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -moz-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}
/* scrollbar-thumb */
/* scroll bar design  */

/* bottom box (left-rigt)  */

.popup-form-container .bottom-button-box-container {
  display: flex;
  justify-content: space-between;
  margin-top: 5em;
}

@media screen and (max-width: 960px) {
  .popup-form-container .bottom-button-box-container {
    display: block;
  }
}

.popup-form-container .bottom-button-box-container .left-side {
  min-width: 197px;
}

.popup-form-container .bottom-button-box-container .right-side {
  display: flex;
}

@media screen and (max-width: 960px) {
  .popup-form-container .bottom-button-box-container .right-side {
    display: block;
  }
}

.popup-form-container
  .bottom-button-box-container
  .right-side
  > div:first-of-type {
  min-width: 197px;
}

@media screen and (max-width: 960px) {
  .popup-form-container
    .bottom-button-box-container
    .right-side
    > div:first-of-type {
    margin-top: 10px;
  }
}

.popup-form-container
  .bottom-button-box-container
  .right-side
  > div:last-of-type {
  min-width: 197px;
  margin-left: 1.25em;
}

@media screen and (max-width: 960px) {
  .popup-form-container
    .bottom-button-box-container
    .right-side
    > div:last-of-type {
    margin-left: 0;
    margin-top: 10px;
  }
}

/* bottom box (left-rigt)  */

[araia-label="table-option-list-of-menu-item"] .MuiList-root .MuiMenuItem-root {
  display: block;
}
