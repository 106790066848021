.side-bar-container {
  overflow: hidden !important;
  z-index: 1450;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
}

.side-bar-container.MuiDrawer-root .MuiDrawer-paper {
  scrollbar-width: thin;
  scrollbar-color: #206cf9 rgba(0, 0, 0, 0.03);
}
.side-bar-container.MuiDrawer-root .MuiDrawer-paper::-webkit-scrollbar {
  width: 0px;
  height: 0;
  background-color: #206cf9;
}

.side-bar-container.MuiDrawer-root .MuiDrawer-paper::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #206cf9;
}

.side-bar-container .logo-container {
  display: block;
  min-height: 5em;
  margin-top: 1em;
}

.side-bar-container .logo-text {
  display: flex;
  flex-direction: column;
  margin-top: 0.5em;
}

.side-bar-container .logo-text div:nth-of-type(1) {
  color: #fff;
  font-size: 0.7em;
  padding-bottom: 1px;
}

.side-bar-container .logo-text img {
  width: 10em;
}

.side-bar-container .logo-image img {
  width: 3em;
  margin-left: 2em;
  margin-right: 1.8em;
}

.side-bar-container .logo-image img.toggle-width-show {
  width: 4em !important;
}

.side-bar-container .logo-image img.toggle-width-hide {
  width: 3em !important;
}

.side-bar-container .MuiListItemText-root p {
  font-weight: 600;
  font-size: 1.1em;
  vertical-align: middle;
  margin-bottom: 0;
}

.side-bar-container .MuiListItemIcon-root {
  height: 3em;
  border: 2px transparent solid;
  border-radius: 10px;
  margin-right: 2em;
  padding: 0 !important;
  min-width: 3em !important;
  max-width: 3em !important;
}

.side-bar-container .MuiListItemIcon-root[arialable="selected"] {
  border-color: #fff;
}

.side-bar-container .MuiListItemIcon-root .MuiSvgIcon-root {
  margin: auto;
}

.side-bar-container .MuiListItem-gutters {
  padding-left: 1.8em;
}

.side-bar-container .footer {
  padding-top: 20px;
  border-top: 1px rgba(0, 0, 0, 0.12) solid;
  margin-bottom: 0.2em;
}

.side-bar-container .side-bar-toggle-switch span {
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  cursor: pointer;
  opacity: 1;
}

.side-bar-container .side-bar-toggle-switch.animation-toggle-show {
  width: 40px !important;
  height: 20px !important;
}

.side-bar-container .side-bar-toggle-switch span .MuiSwitch-track {
  background: transparent;
  border-radius: 10px;
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  border: 1px #fff solid;
}

.side-bar-container .side-bar-toggle-switch span .MuiSwitch-thumb {
  display: block;
  width: 14px;
  height: 14px;
  top: 3px;
  left: 3px;
  border-radius: 16px;
  background-color: #fff;
  position: relative;
  transition: all 500ms ease;
}

.side-bar-container
  .side-bar-toggle-switch
  span:focus-visible
  .MuiSwitch-thumb {
  background-color: #aab4be;
  box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
}

.side-bar-container .side-bar-toggle-switch span.Mui-checked .MuiSwitch-thumb {
  left: 22px;
  top: 3px;
  background-color: #fff;
}

.side-bar-container .side-bar-toggle-switch span input {
  cursor: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
}

.side-bar-container .side-bar-toggle-btn {
  display: block;
  min-width: 180%;
  text-align: center;
}

.side-bar-container .side-bar-toggle-btn .MuiToggleButtonGroup-root {
  min-width: 100%;
}

.side-bar-container .side-bar-toggle-btn .MuiToggleButton-root.Mui-selected {
  color: var(---2170ff-p-) !important;
  background-color: #fff !important;
}

.side-bar-container .side-bar-toggle-btn .MuiToggleButton-root {
  flex-grow: 1;
  color: #fff;
  background-color: transparent;
  border: 1px #fff solid;
  font-weight: 700;
  padding: 0.5em;
  border-radius: 8px;
  text-transform: capitalize;
}

.side-bar-container
  .side-bar-toggle-btn
  .MuiToggleButtonGroup-groupedHorizontal:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.side-bar-container
  .side-bar-toggle-btn
  .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.app-bar-container {
  border-bottom: 1px solid #afafaf6e;
}

.app-bar-container.MuiAppBar-colorPrimary {
  background-color: #fff;
}

.app-bar-container.MuiAppBar-positionFixed {
  z-index: 1000;
}

/* scroll bar  */

/* scrollbar */

.MuiDrawer-paper::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.MuiDrawer-paper::-moz-scrollbar {
  width: 12px;
  height: 12px;
}

.MuiDrawer-paper::-ms-scrollbar {
  width: 12px;
  height: 12px;
}

.MuiDrawer-paper::-o-scrollbar {
  width: 12px;
  height: 12px;
}

/* scrollbar */

/* scrollbar-track */
.MuiDrawer-paper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  background: linear-gradient(
    0deg,
    rgba(51, 54, 204, 1) 0%,
    rgba(33, 112, 255, 1) 100%
  );
  border-radius: 10px;
}

.MuiDrawer-paper::-moz-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  background: linear-gradient(
    0deg,
    rgba(51, 54, 204, 1) 0%,
    rgba(33, 112, 255, 1) 100%
  );
  border-radius: 10px;
}

.MuiDrawer-paper::-ms-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  background: linear-gradient(
    0deg,
    rgba(51, 54, 204, 1) 0%,
    rgba(33, 112, 255, 1) 100%
  );
  border-radius: 10px;
}

.MuiDrawer-paper::-o-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  background: linear-gradient(
    0deg,
    rgba(51, 54, 204, 1) 0%,
    rgba(33, 112, 255, 1) 100%
  );
  border-radius: 10px;
}

/* scrollbar-track */

/* scrollbar-thumb */
.MuiDrawer-paper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  background: linear-gradient(
    0deg,
    rgba(51, 54, 204, 1) 0%,
    rgba(33, 112, 255, 1) 100%
  );
}

.MuiDrawer-paper::-moz-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  background: linear-gradient(
    0deg,
    rgba(51, 54, 204, 1) 0%,
    rgba(33, 112, 255, 1) 100%
  );
}

.MuiDrawer-paper::-ms-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  background: linear-gradient(
    0deg,
    rgba(51, 54, 204, 1) 0%,
    rgba(33, 112, 255, 1) 100%
  );
}

.MuiDrawer-paper::-o-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  background: linear-gradient(
    0deg,
    rgba(51, 54, 204, 1) 0%,
    rgba(33, 112, 255, 1) 100%
  );
}
/* scrollbar-thumb */

/* scroll bar  */

/* signature block   */

.side-bar-container .signature {
  color: #fff;
  flex-direction: column;
  margin-bottom: 1em;
}

.side-bar-container .signature img {
  margin-bottom: -2px;
}

.side-bar-container .signature img.__opened {
  width: 6em;
  margin-left: 0.3em;
}

.side-bar-container .signature img.__closed {
  width: 4.5em;
}

.side-bar-container .signature span:nth-of-type(1) {
  font-size: 0.7em;
  text-transform: uppercase;
}

.side-bar-container .signature span:nth-of-type(1).__opened {
  display: inline;
}

.side-bar-container .signature span:nth-of-type(1).__closed {
  display: block;
  margin-top: 2em;
}

/* signature block   */

.side-bar-container .btn-success {
  background-color: var(---00d4b5-s-);
  border-radius: 12px;
  border-color: var(---00d4b5-s-);
  width: 16em;
  /* margin-top: 1em; */
  margin-bottom: 1em;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  margin-left: 1.5em;
}

.side-bar-container .btn-success svg {
  vertical-align: middle;
  margin-right: 0.5em;
}

.side-bar-container .btn-success span {
  vertical-align: bottom;
  font-size: 1em;
  letter-spacing: 1px;
  font-weight: 600;
}

/* animation  */

.animation-toggle-show {
  width: 100% !important;
  height: 100% !important;
  opacity: 1 !important;
  margin-left: 0px;
  transition: all 0.5s ease;
}

.animation-toggle-hide {
  width: 0% !important;
  height: 0% !important;
  opacity: 0 !important;
  margin-left: -2em;
  transition: all 0.5s ease;
}

.add-auction-animation-show {
  width: 16em !important;
  transition: all 0.4s ease !important;
}

.add-auction-animation-show span {
  opacity: 1 !important;
}

.add-auction-animation-hide {
  width: 3em !important;
  transition: all 0.4s ease !important;
}

.add-auction-animation-hide span {
  opacity: 0 !important;
}

/* animation  */
