.details-form-container .title-container {
  display: flex;
  justify-content: space-between;
}

.details-form-container .title-container .edit-btn {
  height: 30px;
  width: 52px;
  height: 30px;
  border-radius: 8px;
  font-size: 16px;
}

.body-container .MuiFormControl-root {
  width: 100%;
}

.details-form-container .disclose-option {
  display: inline-flex;
  padding: 14px !important;
  margin-top: 0.5em;
  border-radius: 8px;
  border: 1px rgba(0, 0, 0, 0.2) solid;
  width: 100%;
}

.details-form-container .disclose-option .icon {
  height: 16px;
  width: 16px;
  margin: auto 10px;
  color: #1e65e6;
}

.details-form-container .awarding-check-alert {
  background-color: var(---e2f2ff);
  padding: 20px;
  margin-top: 1em;
  border-radius: 12px;
}

.details-form-container .awarding-check-alert .text {
  font-size: 14px;
  margin-top: 8px;
  color: var(---707070);
}

.details-form-container .awarding-check-alert .label {
  font-weight: 600;
}

.details-form-container .awarding-check-alert .MuiCheckbox-root {
  padding-top: 0;
}

.details-form-container .animate-max-height.__feed {
  max-height: 60em;
}

/* small screen  */
@media (min-width: 600px) {
  .mui-tooltip .MuiTooltip-tooltipPlacementTop {
    margin: 5px 0;
  }
}

#statistics-box-first-container {
  padding: 1.5em 1em;
  min-height: 9.5em;
}

#statistics-box-first-container .label {
  color: var(---a19b9d);
  font-size: 14px;
}

#statistics-box-first-container .line.MuiGrid-item {
  padding-bottom: 0px;
}

#statistics-box-first-container .icon {
  width: 18px;
  height: 18px;
  color: var(---424143);
  vertical-align: text-bottom;
}

#statistics-box-first-container .field {
  font-weight: 600;
  border: none;
  border-bottom: 1px transparent solid;
  width: 100%;
  font-family: "Noto Sans", sans-serif;
  resize: none;
  outline: none;
  font-size: 14px !important;
}

#statistics-box-first-container .field.__edit {
  font-weight: 600;
  border: none;
  border-bottom: 2px solid #0e86f5;
  background-color: #badeff;
}

#statistics-box-first-container .go-back-button {
  font-size: 16px;
  font-weight: 700;
  color: var(---2170ff-p-);
  background-color: #fff;
  border-color: #fff;
  white-space: break-spaces;
  height: 45px;
  border-radius: 12px;
  padding: 0px 12px;
}

#statistics-box-first-container .go-back-button.__edit {
  border: 1px solid var(---2170ff-p-);
  font-size: 0.9em;
  border-radius: 8px;
  text-transform: capitalize;
}

#statistics-box-first-container .go-back-button.__save {
  border: 1px solid var(---2170ff-p-);
  font-size: 0.9em;
  color: #fff;
  padding: 4px 10px;
  border-radius: 8px;
  text-transform: capitalize;
  background-color: var(---2170ff-p-);
}

#statistics-box-first-container .field.__edit:focus-visible {
  outline: none;
}

#statistics-box-second-container {
  padding: 1em;
  background-color: var(---2170ff-p-);
  color: #fff;
  min-height: 9.5em;
}

#statistics-box-second-container .icon {
  width: 50px;
  height: 50px;
  margin: auto;
  display: block;
}

#statistics-box-third-container {
  padding: 1em;
  padding-left: calc(1em + 8px);
  min-height: 9.5em;
}

#statistics-box-third-container .label {
  color: var(---a19b9d);
  font-size: 18px;
  font-weight: 600;
}

#statistics-box-third-container .number {
  color: var(---424143);
  font-size: 31px;
  font-weight: 600;
}

#statistics-box-third-container .time {
  color: var(---a19b9d);
  font-size: 15px;
}
