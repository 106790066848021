.page-chart-container__hide.MuiPaper-rounded {
  box-shadow: none !important ;
  background-color: transparent;
  border: none !important;
}

.page-chart-container__view.MuiPaper-rounded {
  box-shadow: inherit;
  background-color: #fff;
  border: inherit;
}

/* <> tab labels  */
.settings-index-section-contaier .settings-index-section-tab-labels {
  max-width: 23%;
  flex-basis: 23%;
}

@media screen and (max-width: 1280px) {
  .settings-index-section-contaier .settings-index-section-tab-labels {
    max-width: 32%;
    flex-basis: 32%;
  }
}

@media screen and (max-width: 960px) {
  .settings-index-section-contaier .settings-index-section-tab-labels {
    max-width: 75%;
    flex-basis: 75%;
  }
}

@media screen and (max-width: 600px) {
  .settings-index-section-contaier .settings-index-section-tab-labels {
    max-width: 90%;
    flex-basis: 90%;
  }
}

[aria-label="settings-index-section-tab-labels-block"] {
  align-items: center;
}
/* </> tab labels  */

/* <> tab Content  */
.settings-index-section-contaier .settings-index-section-tab-content {
  max-width: 75%;
  flex-basis: 75%;
}

@media screen and (max-width: 1280px) {
  .settings-index-section-contaier .settings-index-section-tab-content {
    max-width: 65%;
    flex-basis: 65%;
  }
}

@media screen and (max-width: 960px) {
  .settings-index-section-contaier .settings-index-section-tab-content {
    max-width: 90%;
    flex-basis: 90%;
  }
}
/* </> tab Content  */

/* className={mdScreen ? "w90-percent-box mb-4" : "w23-percent-box"} */
/* 
.settings-profile-section-contaier .preferences-container .row-label {
  max-width: 45%;
  flex-basis: 45%;
} */

/* 
   MuiTab-textColorPrimary Mui-selected  
     MuiTab-textColorPrimary TabMenu css-aym9vq-MuiButtonBase-root-MuiTab-root" */
