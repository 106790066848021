.table-container {
  margin-top: 1em;
}

.table-container.MuiPaper-rounded {
  border-radius: 0px !important;
}

.table-container.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.table-container tbody td,
.table-container tbody th {
  white-space: nowrap;
  font-weight: 600;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-container tbody td .icon,
.table-container tbody th .icon {
  font-size: 1.5em;
  margin-bottom: -3px;
  color: var(---e0a800);
  margin-left: 0.2em;
}

.table-container tbody td .action-btn {
  background-color: var(---e2f2ff) !important;
  color: var(---2170ff-p-) !important;
  border: px solid var(---e2f2ff) !important;
  font-size: 7px !important;
  box-shadow: none !important;
  border-radius: 50% !important;
  padding: 0px 0px 0px 0px !important;
  min-width: 1em !important;
  transform: rotate(90deg);
}

.table-container tbody td .action-btn .MuiSvgIcon-root {
  width: 30px;
  height: 30px;
  background: var(---f7fafc);
  border-radius: 50%;
  border: 2px var(---f7fafc) solid;
}

.table-container tbody td .action-btn:hover .MuiSvgIcon-root {
  background: var(---e2f2ff);
  border-color: var(---e2f2ff);
}

.table-container tbody td .action-btn[aria-controls] .MuiSvgIcon-root {
  border-color: var(---2170ff-p-);
}

/* scroll bar design  */
.table-container.MuiTableContainer-root {
  scrollbar-color: #dbdbdb rgba(0, 0, 0, 0.03);
  scrollbar-width: thin;
}

/* scrollbar */
.table-container.MuiTableContainer-root::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.table-container.MuiTableContainer-root::-moz-scrollbar {
  height: 8px;
  width: 8px;
}

.table-container.MuiTableContainer-root::-ms-scrollbar {
  height: 8px;
  width: 8px;
}

.table-container.MuiTableContainer-root::-o-scrollbar {
  height: 8px;
  width: 8px;
}
/* scrollbar */

/* scrollbar-track */
.table-container.MuiTableContainer-root::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}

.table-container.MuiTableContainer-root::-moz-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}

.table-container.MuiTableContainer-root::-ms-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}

.table-container.MuiTableContainer-root::-o-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}
/* scrollbar-track */

/* scrollbar-thumb */
.table-container.MuiTableContainer-root::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -moz-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.table-container.MuiTableContainer-root::-moz-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -moz-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.table-container.MuiTableContainer-root::-ms-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -moz-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.table-container.MuiTableContainer-root::-o-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -moz-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}
/* scrollbar-thumb */

/* scroll bar design  */

.select-colored-option {
  border: none !important;
  margin: 0.3em 0;
  border-radius: 8px;
  letter-spacing: 1px;
  cursor: pointer;
}

.select-colored-option {
  border: none !important;
  margin: 0.3em 0;
  border-radius: 8px;
  letter-spacing: 1px;
  cursor: pointer;
  text-transform: capitalize !important;
}

.table-container tbody td .badge.select-colored-btn:hover {
  background-color: inherit !important;
}

.table-container tbody td .badge.select-colored-btn {
  line-height: 1em;
  border-radius: 10px;
  text-transform: capitalize;
}

.table-container tbody td .badge.select-colored-btn > span {
  margin-left: -3px !important;
}

.table-container .status {
  text-transform: capitalize !important;
  font-weight: 600 !important;
  font-size: 1em !important;
  border-radius: 10px !important;
  letter-spacing: 1px !important;
  height: 30px;
}

.table-container .status .MuiButton-endIcon {
  margin-left: 0px !important;
}

.table-container .status.badge-success.MuiButton-root:hover {
  background: var(---def8f3) 0% 0% no-repeat padding-box !important;
}

.table-container .status.badge-warning.MuiButton-root:hover {
  background: var(---ffc107) 0% 0% no-repeat padding-box !important;
}

.table-container .status.badge-secondary.MuiButton-root:hover {
  background: var(---f1f0f2) 0% 0% no-repeat padding-box !important;
}

.table-container .status.badge-purple.MuiButton-root:hover {
  background: var(---dee2ff) 0% 0% no-repeat padding-box !important;
}

.table-container .status.badge-danger.MuiButton-root:hover {
  background: var(---c82333) 0% 0% no-repeat padding-box !important;
}

.table-container .status.badge-danger-outline.MuiButton-root:hover {
  background: var(---fbeaec) 0% 0% no-repeat padding-box !important;
}

.table-container .status.badge-warning-outline.MuiButton-root:hover {
  background: var(---fcf6e5) 0% 0% no-repeat padding-box !important;
}
.table-container .status.badge-primary-outline.MuiButton-root:hover {
  background: var(---e2f2ff) 0% 0% no-repeat padding-box !important;
}

.table-container .status.badge-primary.MuiButton-root:hover {
  background: var(---2170ff-p-) 0% 0% no-repeat padding-box !important;
}

.table-container .pagination-box-container {
  flex-grow: 1;
  padding-top: 1.3em;
  padding-bottom: 0.9em;
}

.table-container .pagination-box-container .pagination-slider-container {
  margin: auto;
}

.table-container .pagination-box-container .pagination-slider-container ul {
  justify-content: end;
  /* margin-right: 3em; */
}

.table-container .pagination-box-container .pagination-slider-container ul li {
  margin-right: 5px;
}

.table-container
  .pagination-box-container
  .pagination-slider-container
  .MuiButtonBase-root {
  width: 35px;
  height: 35px;
  border-radius: 8px;
  background-color: var(---e2f2ff);
  color: var(---2170ff-p-);
}

.table-container
  .pagination-box-container
  .pagination-slider-container
  .MuiButtonBase-root.Mui-selected {
  background-color: var(---2170ff-p-);
  color: #fff;
}

.table-container
  .pagination-box-container
  .pagination-slider-container
  .MuiPaginationItem-previousNext {
  background-color: #fff;
}

.table-container .pagination-box-container .pagination-total-container h3 {
  color: var(---a19b9d);
  font-weight: 500;
  font-size: 1em;
}

.table-header-container .sm-right-box {
  text-align: end;
}

@media screen and (max-width: 960px) {
  .table-header-container .sm-left-box {
    text-align: center;
  }

  .table-header-container .sm-right-box {
    text-align: center;
  }
}

.table-container .no-record-msg {
  color: var(---2170ff-p-);
  text-align: center;
  width: 100%;
  margin: 20px 0px;
  font-size: 16px;
}

/* editable row  */
.table-container.editable-table .editable-row .option-cell {
  display: none;
  align-items: center;
}

.table-container.editable-table .editable-row .option-cell.__active {
  display: flex;
}

.table-container.editable-table .editable-row .option-cell .icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.table-container.editable-table .editable-row .option-cell .icon.__edit {
  color: var(---424143);
  margin-right: 14px;
}

.table-container.editable-table .editable-row .option-cell .icon.__check {
  color: var(---2170ff-p-);
  margin-right: 14px;
}

.table-container.editable-table .editable-row .option-cell .icon.__close {
  color: #f00;
}

.table-container.editable-table .editable-row.__select {
  background-color: var(---e2f2ff);
}

.table-container.editable-table .editable-row .editable-field {
  display: none !important;
}

.table-container.editable-table .editable-row .editable-field.__active {
  display: flex !important;
}
.table-container.editable-table .editable-row .editable-field.text-show {
  display: none !important;
}

.table-container.editable-table
  .editable-row
  .editable-field.__active
  .MuiInputBase-root {
  min-width: 100px;
}
/* editable row  */
