.supp-auction-details-bid-list-container .header-title {
  font-size: 1.3em;
  font-weight: 700;
}

.supp-auction-details-bid-list-container .options-contaier {
  text-align: end;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.supp-auction-details-bid-list-container .table-container.mybid-table {
  opacity: 0;
  height: 0;
}

.supp-auction-details-bid-list-container .table-container.allbid-table {
  opacity: 0;
  height: 0;
}

.supp-auction-details-bid-list-container .table-container.__active {
  opacity: 1;
  height: 100%;
  max-height: 50vh;
  animation: 1s opacity-animate;
}

.supp-auction-details-bid-list-container
  .options-contaier
  .MuiFormControlLabel-root {
  margin-right: 0px;
}

.supp-auction-details-bid-list-container .options-contaier span.my-bid {
  font-weight: 600;
  margin-right: 10px;
}

.supp-auction-details-bid-list-container .options-contaier span.all-bid {
  font-weight: 600;
  margin-right: 15px;
}

.supp-auction-details-bid-list-container .header-button {
  padding: 0px;
  font-weight: 700 !important;
  color: var(---2170ff-p-);
}

.supp-auction-details-bid-list-container .header-button .btn {
  cursor: pointer;
  font-weight: 600;
  font-size: 1.1em;
}

.table-item-img {
  height: 48px;
}

.supp-auction-details-bid-list-container .btn-primary {
  color: #fff !important;
  background-color: var(---2170ff-p-) !important;
  border-color: var(---2170ff-p-) !important;
}

.supp-auction-details-bid-list-container
  .switchable-toggle.Mui-checked
  .MuiSwitch-track {
  background: var(---00d4b5-s-);
}

.supp-auction-details-bid-list-container .switchable-toggle .MuiSwitch-track {
  background: var(---2170ff-p-);
}

[aria-label="auction-details-bid-popup"] .popup-form-container {
  width: 40vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="auction-details-bid-popup"] .popup-form-container {
    width: 45vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="auction-details-bid-popup"] .popup-form-container {
    width: 50vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 960px) {
  [aria-label="auction-details-bid-popup"] .popup-form-container {
    width: 70vw;
  }
}
