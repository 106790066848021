.details-banner
  .banner-container
  .project-details-buyer-aspect.editable-board-container
  .container
  .imgPreview.attach_box {
  width: 100%;
  height: 100px;
}

.details-banner
  .banner-container
  .project-details-buyer-aspect.editable-board-container
  .imgPreview
  svg {
  width: 29px;
  height: 29px;
  margin-top: 0px;
  margin-bottom: -5px;
}
