/* Popup Design  */

/* first-step popup form */
[aria-label="create-project-first-step-buyer-aspect"] .popup-form-container {
  width: 60vw;
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="create-project-first-step-buyer-aspect"] .popup-form-container {
    width: 80vw;
  }
}

[aria-label="create-project-first-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiButtonBase-root {
  position: absolute;
  left: 9px;
  color: var(---2170ff-p-);
  transform: rotateY(180deg);
}

[aria-label="create-project-first-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiInputBase-root {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 26px;
}

[aria-label="create-project-first-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiInputBase-input {
  margin-left: 10px;
}

[aria-label="create-project-first-step-buyer-aspect"] .btn-mui {
  font-size: 16px !important;
}

[aria-label="create-project-first-step-buyer-aspect"]
  #bottom-button-box-container {
  display: flex;
  justify-content: space-between;
  margin-top: 5em;
}

@media screen and (max-width: 960px) {
  [aria-label="create-project-first-step-buyer-aspect"]
    #bottom-button-box-container {
    display: block;
  }
}

[aria-label="create-project-first-step-buyer-aspect"]
  #bottom-button-box-container
  #right-side {
  min-width: 197px;
}

[aria-label="create-project-first-step-buyer-aspect"]
  #bottom-button-box-container
  #left-side {
  display: flex;
}

@media screen and (max-width: 960px) {
  [aria-label="create-project-first-step-buyer-aspect"]
    #bottom-button-box-container
    #left-side {
    display: block;
  }
}

[aria-label="create-project-first-step-buyer-aspect"]
  #bottom-button-box-container
  #left-side
  > div:first-of-type {
  min-width: 197px;
}

@media screen and (max-width: 960px) {
  [aria-label="create-project-first-step-buyer-aspect"]
    #bottom-button-box-container
    #left-side
    > div:first-of-type {
    margin-top: 10px;
  }
}

[aria-label="create-project-first-step-buyer-aspect"]
  #bottom-button-box-container
  #left-side
  > div:last-of-type {
  min-width: 197px;
  margin-left: 1.25em;
}

@media screen and (max-width: 960px) {
  [aria-label="create-project-first-step-buyer-aspect"]
    #bottom-button-box-container
    #left-side
    > div:last-of-type {
    margin-left: 0;
    margin-top: 10px;
  }
}
/* first-step popup form */
