.item-list-details-container .scroll-page {
  overflow-y: scroll;
  height: 100vh;
}

.item-list-details-container .scroll-page {
  scrollbar-color: #dbdbdb rgba(0, 0, 0, 0.03);
  scrollbar-width: thin;
}

.item-list-details-container .scroll-page::-webkit-scrollbar {
  height: 1px;
  width: 1px;
}

.item-list-details-container .scroll-page::-moz-scrollbar {
  height: 1px;
  width: 1px;
}

.item-list-details-container .scroll-page::-ms-scrollbar {
  height: 1px;
  width: 1px;
}

.item-list-details-container .scroll-page::-o-scrollbar {
  height: 1px;
  width: 1px;
}
