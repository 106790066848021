.read-more-paragraph {
    overflow: hidden;
    position: relative;
  }
  
  .paragraph-text {
    max-height: 360px;
    overflow: hidden;
    transition: max-height 0.3s ease;
    margin-bottom: 8px;
  }
  
  .paragraph-text.expanded {
    max-height: none;
  }
  
  .read-more-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    color: #2170FF;
    font-weight: 600;
  }