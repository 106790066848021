.budget-statistics-card .MuiCardHeader-root {
  border-radius: 12px;
  box-shadow: 0px 0px 1px 2px #eee;
  padding-bottom: 3em;
}

.budget-statistics-card .MuiCardHeader-action {
  margin-top: 0px;
  margin-right: 0px;
}

/* MuiIconButton-label */
.budget-statistics-card .chart-icon-label,
.budget-statistics-card .chart-icon-label svg {
  width: 2em;
  height: 2em;
  padding: 1.5em !important;
  border-radius: 15px;
}

.buget-chart-container .budget-statistics-card.first .chart-icon-label {
  background-color: var(---e2f2ff);
}

.buget-chart-container .budget-statistics-card.second .chart-icon-label {
  background-color: var(---def8f3);
}

.buget-chart-container .budget-statistics-card.third .chart-icon-label {
  background-color: var(---ffe8ea);
}

.buget-chart-container .budget-statistics-card #bottom-container .MuiList-root {
  flex-wrap: wrap;
}

.project-buget-container > .MuiBox-root {
  padding: 0px !important;
}

.page-chart-container__hide.MuiPaper-rounded {
  box-shadow: none !important ;
  background-color: transparent;
  border: none !important;
}

.page-chart-container__show.MuiPaper-rounded {
  box-shadow: inherit;
  background-color: #fff;
  border: inherit;
}

.tab-chart-container__hide > .MuiBox-root {
  padding: 0px !important;
}

.tab-chart-container__show > .MuiBox-root {
  padding: inherit;
}

.buget-chart-container .buget-chart-title {
  margin-left: 0.5em;
  font-weight: 700;
}

.buget-chart-container .buget-chart-title span:nth-of-type(1) {
  color: var(---a19b9d);
  display: block;
  font-size: 0.9em;
}

.buget-chart-container .buget-chart-title span:nth-of-type(2) {
  font-size: 1.2em;
}

/* 
.buget-chart-container [arialabel="chart-number-1"] g:nth-of-type(1) path {
  fill: #00ad84;
}

.buget-chart-container [arialabel="chart-number-1"] g:nth-of-type(2) path {
  fill: #00d4b5;
}

.buget-chart-container [arialabel="chart-number-2"] g:nth-of-type(1) path {
  fill: #00ad84;
}

.buget-chart-container [arialabel="chart-number-2"] g:nth-of-type(2) path {
  fill: #00d4b5;
}

.buget-chart-container [arialabel="chart-number-3"] g:nth-of-type(1) path {
  fill: #00ad84;
}

.buget-chart-container [arialabel="chart-number-3"] g:nth-of-type(2) path {
  fill: #00d4b5;
} */

.buget-chart-container .budget-statistics-card #bottom-container .MuiList-root {
  display: flex;
  margin: auto;
  flex-direction: row;
}

.buget-chart-container .budget-statistics-card .MuiListItemText-root {
  white-space: nowrap;
}

.prodject-comment-container .action-btn {
  padding: 0.6em 2em;
  min-width: 10em;
  text-transform: capitalize;
  border-radius: 12px;
}

.prodject-comment-container .action-btn.add {
  background-color: var(---2170ff-p-);
}

.prodject-comment-container .action-btn.cancel {
  margin-right: 0.5em;
  background-color: #e2f2ff;
  color: #1976d2;
  font-weight: 600;
}

/* #center-container {
  max-height: 25em;
} */

.budget-form-container .action-btn {
  padding: 0.6em 2em !important;
  min-width: 10em !important;
  text-transform: capitalize !important;
  border-radius: 12px !important;
}

.budget-form-container .action-btn.cancel {
  margin-right: 0.5em !important;
  background-color: var(---e2f2ff) !important;
  color: #1976d2 !important;
  font-weight: 600 !important;
}

.budget-form-container .MuiFormHelperText-root.Mui-error {
  margin-left: 0px;
}

#project-tab-list .tab-header-container {
  flex-wrap: wrap;
}
/* 
#project-tab-list .tab-header-container {
  margin-top: 2em;
}

@media screen and (max-width: 1279px) {
  #project-tab-list .tab-header-container {
    margin-top: 3em;
  }
} */
#project-tab-list .MuiTabs-flexContainer {
  flex-wrap: wrap;
}

#project-tab-list .comment-number {
  margin-left: 6px;
  padding: 6px;
  border-radius: 100%;
  font-size: 14px;
  width: 2em;
  height: 2em;
  background-color: var(---2170ff-p-);
  color: #fff;
}

#project-tab-list .MuiTab-root.Mui-selected .comment-number {
  background-color: #fff;
  color: var(---2170ff-p-);
}

/* .tab-header-container */

#buyer-side-project-attachment-dropdown-list .MuiPaper-root {
  width: 200px;
}
.project-attachment-tab {
  background-color: #fff !important;
  border-radius: 12px !important;
}
