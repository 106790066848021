.shimmer-thumbnail.h24 {
  height: 24px !important;
}

.custom-table {
  width: 100%;
}

.custom-table .shimmer-table-row {
  padding: 18px 0;
  margin-bottom: 0px;
  box-shadow: none;
  border-radius: 5px !important;
  min-height: 48px;
}

.custom-table .shimmer-table-row > .shimmer {
  margin: 0 20px;
}

@media (max-width: 700px) {
  .custom-table .shimmer-table-row > .shimmer {
    margin: 0 10px;
  }
}

.custom-table .shimmer-table-row.shimmer-table-row--header {
  background: #f2f4f6;
  min-height: 57px;
  padding: 22px 0;
}

.custom-table .shimmer-table-row.shimmer-table-row--header .shimmer-table-col {
  background: rgba(0, 0, 0, 0.15) !important;
}

.dynamicLink-table {
  width: 100%;
}

.dynamicLink-table .shimmer-table-row {
  padding: 18px 0;
  margin-bottom: 0px;
  box-shadow: none;
  border-radius: 5px !important;
  min-height: 48px;
}

.dynamicLink-table .shimmer-table-row > .shimmer {
  margin: 0 20px;
}

@media (max-width: 700px) {
  .dynamicLink-table .shimmer-table-row > .shimmer {
    margin: 0 10px;
  }
}

.dynamicLink-table .shimmer-table-row.shimmer-table-row--header {
  background: #fff;
  min-height: 48px;
  padding: 18px 0;
}

.dynamicLink-table
  .shimmer-table-row.shimmer-table-row--header
  .shimmer-table-col {
  background: rgba(0, 0, 0, 0.1) !important;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.cu-shimmer {
  background-color: #f2f4f6 !important;
  background-image: linear-gradient(
    to right,
    #f6f6f6 8%,
    #f0f0f0 18%,
    #f6f6f6 33%
  ) !important;
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  position: relative;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}

.cu-shimmer.br20 {
  border-radius: 20px;
}

.cu-shimmer.br10 {
  border-radius: 10px;
}

.cu-shimmer.h20 {
  height: 20px;
}

.cu-shimmer.mh400 {
  min-height: 400px;
}
.cu-shimmer.mh170 {
  min-height: 170px;
}

@media (max-width: 767px) {
  .cu-shimmer.mh400 {
    min-height: 300px;
  }
}

.cu-shimmer-white {
  background-color: #f2f4f6 !important;
  background-image: linear-gradient(
    to right,
    #fff 8%,
    #f0f0f0 18%,
    #fff 33%
  ) !important;
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  position: relative;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}

.shimmer-thumbnail.h150 {
  height: 150px !important;
}

.shimmer-thumbnail.h120 {
  height: 120px !important;
}

.shimmer-thumbnail.h150 {
  height: 150px !important;
}

.shimmer-thumbnail.h100 {
  height: 100px !important;
}

.shimmer-thumbnail.h40 {
  height: 40px !important;
}

.shimmer-thumbnail.h100p {
  height: 100px !important;
}

.shimmer-thumbnail.br20 {
  border-radius: 20px;
}

.shimmer-thumbnail.mb20 {
  margin-bottom: 20px;
}
.cu-shimmer.mb5 {
  margin-bottom: 5px;
}
.cu-shimmer.mb10 {
  margin-bottom: 10px;
}

.cu-shimmer.h15 {
  height: 15px !important;
}
.cu-shimmer.h50 {
  height: 50px !important;
}
.cu-shimmer.w50 {
  width: 50px !important;
}
.cu-shimmer.w75 {
  width: 75% !important;
}
.cu-shimmer.w100 {
  width: 100px !important;
}
.cu-shimmer.w160 {
  width: 160px !important;
}
.cu-shimmer.fixWidth260 {
  width: 260px !important;
}
.cu-shimmer.w260 {
  max-width: 260px !important;
}
.cu-shimmer.w360 {
  max-width: 360px !important;
}
.border-round {
  border-radius: 50%;
}
.thumbnail-center {
  margin: 0 auto;
}
.item-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
