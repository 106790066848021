[aria-label="attachment-from-lib-popup-buyer-aspect"] .popup-form-container {
  width: 80vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="attachment-from-lib-popup-buyer-aspect"] .popup-form-container {
    width: 55vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="attachment-from-lib-popup-buyer-aspect"] .popup-form-container {
    width: 80vw;
  }
}

/* Supplier table  */

[aria-label="attachment-from-lib-popup-buyer-aspect"] table tbody,
[aria-label="attachment-from-lib-popup-buyer-aspect"] table thead,
[aria-label="attachment-from-lib-popup-buyer-aspect"] table th,
[aria-label="attachment-from-lib-popup-buyer-aspect"] table tr,
[aria-label="attachment-from-lib-popup-buyer-aspect"] table td {
  display: block;
  max-width: none !important;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"] table tr:after {
  content: " ";
  display: block;
  visibility: hidden;
  clear: both;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"] table tbody {
  height: 41vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #dbdbdb rgba(0, 0, 0, 0.03);
  scrollbar-width: thin;
  margin-top: 14px;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"] table tr:first-of-type {
  margin-bottom: 0px;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"] table tr {
  min-width: 800px;
  margin-bottom: -2px;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"] table td {
  padding-top: 24px !important;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"] table tbody tr:hover {
  background-color: #fff !important;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"] .MuiTableCell-root {
  border-bottom: 1px solid #e0e0e0 !important;
  float: left;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .MuiTableHead-root
  .MuiTableRow-head
  .MuiTableCell-root {
  /* border-top: 1px solid #e0e0e0 !important; */
  min-height: 58px;
  white-space: nowrap;
}

/* devide grid pixels  */
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(1) {
  width: 50%;
  min-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(2) {
  width: 25%;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(3) {
  width: 25%;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: flex-end;
}
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(3)
  .checkbox {
  margin-top: -30px;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(3)
  .checkbox.header {
  margin-top: -9px;
  margin-right: 15px;
}

/* devide grid pixels  */

/* scroll bar design  */

/* scrollbar */
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-webkit-scrollbar,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-moz-scrollbar,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-moz-scrollbar {
  width: 7px;
  height: 7px;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-ms-scrollbar,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-ms-scrollbar {
  width: 7px;
  height: 7px;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-o-scrollbar,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-o-scrollbar {
  width: 7px;
  height: 7px;
}
/* scrollbar */

/* scrollbar-track */
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-webkit-scrollbar-track,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-moz-scrollbar-track,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-moz-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-ms-scrollbar-track,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-ms-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-o-scrollbar-track,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-o-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}
/* scrollbar-track */

/* scrollbar-thumb */
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-webkit-scrollbar-thumb,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-moz-scrollbar-thumb,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-moz-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-ms-scrollbar-thumb,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-ms-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-o-scrollbar-thumb,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-o-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}
/* scrollbar-thumb */

/* scroll bar design  */
