.sellers-link-container {
  display: flex;
  color: var(---2a5cec);
  cursor: pointer;
}

.sellers-link-container .label {
  text-decoration: underline;
  font-size: 15px;
}

.sellers-link-container .icon {
  color: var(---2a5cec) !important;
  width: 20px;
  height: 20px;
}

.invite-seller-box .label {
  font-size: 12px;
  color: #a19b9d;
}

.invite-seller-box .title {
  display: flex;
}

.invite-seller-box .title .icon-cotainer {
  border-radius: 50%;
  margin: auto 0px;
}

.invite-seller-box .title .icon-cotainer .icon {
  width: 24px;
  height: 24px;
  background-color: var(---2170ff-p-);
  border-radius: 50%;
  padding: 5px;
  vertical-align: sub;
}

.invite-seller-box .title .text {
  color: var(---2170ff-p-);
  margin: auto;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 400;
}

.invite-seller-box .seller-check-box-container.MuiGrid-item {
  padding-top: 0px;
  padding-bottom: 0px;
}

.invite-seller-box .seller-check-box {
  display: inline-flex;
  padding: 14px !important;
  border: 1px rgba(0, 0, 0, 0.2) solid;
  width: 100%;
  justify-content: space-between;
  border-bottom: none;
}

.invite-seller-box
  div.seller-check-box-container:first-of-type
  .seller-check-box {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.invite-seller-box
  div.seller-check-box-container:last-of-type
  .seller-check-box {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom: 1px rgba(0, 0, 0, 0.2) solid;
}

.invite-seller-box .seller-check-box .name-container {
  display: flex;
}

.invite-seller-box .seller-check-box .name-container .icon {
  width: 24px;
  height: 24px;
  background-color: var(---a19b9d);
  display: block;
  border-radius: 50%;
}

.invite-seller-box .seller-check-box .name-container .name {
  font-size: 16px;
  color: var(---424143);
  margin-left: 8px;
}
