.loading-progress-spinner-container .overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1400;
}

.loading-progress-spinner-container .absolute {
  position: fixed;
  background: #fff;
  border-radius: 12px;
  z-index: 1400;
  top: 46vh;
  left: 46vw;
}

@keyframes spin-animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.spinner div {
  left: 65.075px;
  top: 32.195px;
  position: absolute;
  animation: spin-animation linear 1.8518518518518516s infinite;
  background: #3336cc;
  width: 6.8500000000000005px;
  height: 15.07px;
  border-radius: 3.4250000000000003px / 3.6168000000000005px;
  transform-origin: 3.4250000000000003px 36.305px;
}

.spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.6975308641975309s;
  background: #3336cc;
}

.spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1.5432098765432098s;
  background: #2a5cec;
}

.spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -1.3888888888888888s;
  background: #216fff;
}

.spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -1.2345679012345678s;
  background: #0882ff;
}

.spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -1.0802469135802468s;
  background: #0092ff;
}

.spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.9259259259259258s;
  background: #1ca3ff;
}

.spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.7716049382716049s;
  background: #53b4ff;
}

.spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.6172839506172839s;
  background: #8bcaff;
}

.spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.4629629629629629s;
  background: #badeff;
}

.spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.30864197530864196s;
  background: #3336cc;
}

.spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.15432098765432098s;
  background: #2a5cec;
}

.spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
  background: #216fff;
}

.loadingio-spinner-spinner-box {
  width: 137px;
  height: 137px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.spinner {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}

.spinner div {
  box-sizing: content-box;
}
