.import-item-btn {
  background-color: #00d4b5 !important;
  text-decoration: none;
  border-color: #00d4b5 !important;
}

.import-item-btn :hover {
  background-color: #00d4b5 !important;
  border-color: #00d4b5 !important;
}
.supplier-import .browse-file-supplier {
  background-color: #2170ff;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 12px 0 !important;
  border-radius: 12px !important;
  cursor: pointer;
}
.browse-file-button {
  width: 25vw;
}

@media screen and (max-width: 1800px) {
  .browse-file-button {
    width: 25vw;
  }
}

.import-item-footer-description {
  text-align: center;
  color: #bbbbbb;
}

.mt-10 {
  padding-top: 14px;
}

.import-download-icn {
  margin-bottom: -5px;
}

hr.solid {
  border-top: 1px solid #bbb;
}

.hr-devider-import-file {
  width: inherit;
  padding: 20px 0px 10px 0px;
}

.tac {
  text-align: center;
}

.devider-center-text {
  color: #bbbbbb;
}

.pt-15 {
  padding-top: 15px;
}
