.craete-edit-form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  background-color: #fff;
  padding: 3em;
}

/* is admin switch toggle  */
/* .craete-edit-form-container #is-admin-switch {
  width: 38px;
  height: 22px;
  margin-left: 1.5rem;
}

.craete-edit-form-container #is-admin-switch + span {
  color: var(---a19b9d);
}

.craete-edit-form-container #is-admin-switch.Mui-checked .MuiSwitch-track {
  background: var(---2170ff-p-);
}

.craete-edit-form-container #is-admin-switch .MuiSwitch-track {
  background-color: var(---a19b9d);
}

.craete-edit-form-container #is-admin-switch.Mui-checked .MuiSwitch-thumb {
  left: 18px;
  top: 3px;
}

.craete-edit-form-container #is-admin-switch .MuiSwitch-thumb {
  width: 16px;
  height: 16px;
  top: 3px;
  left: 3px;
} */
/* is admin switch toggle  */
