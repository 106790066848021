.select-box {
  padding: 0 2px 0 18px !important;
  border: 1px solid #f1f0f2 !important;
  border-radius: 8px !important;
}

.select-box.search-box {
  background: no-repeat;
  background-size: 18px;
  background-position: 10px center;
  width: 300px;
  background-image: url("../assets/icons/search-gray.png");
}

.select-box-primary:hover,
.select-box-primary:focus,
.select-box-primary:active {
  border: 1px solid var(---2170ff-p-) !important;
}

.select-box-primary svg {
  display: none;
}

.mui-select .MuiInputBase-root {
  height: 45px;
}

.mui-select .MuiSelect-select:focus {
  background-color: transparent !important;
}

.mui-select .MuiOutlinedInput-notchedOutline {
  border-color: var(---e0e0e0);
}

.mui-select.outline .MuiOutlinedInput-notchedOutline {
  border: none;
}

.mui-select-placeholder {
  color: #acacac;
  padding: 4px 14px;
}
