#user-role-container .ratio-btn .MuiFormControlLabel-root {
  background-color: var(---e2f2ff);
  border-radius: 12px;
  margin: 5px 0px;
  padding: 10px;
}

.BottomModal-right .MuiGrid-item {
  text-align: -webkit-center;
  text-align: -moz-center;
  text-align: -ms-center;
  text-align: -o-center;
}

#user-role-container .MuiRadio-colorSecondary.Mui-checked {
  color: var(---2170ff-p-);
}

#user-role-container .MuiRadio-root {
  color: var(---2170ff-p-);
}

[aria-label="project-details-user-popup"] .popup-form-container {
  width: 40vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="project-details-user-popup"] .popup-form-container {
    width: 30vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="project-details-user-popup"] .popup-form-container {
    width: 50vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 600px) {
  [aria-label="project-details-user-popup"] .popup-form-container {
    width: 70vw;
  }
}

.imge-user-list {
  width: 26px;
  border-radius: 50%;
  margin-right: 12px;
}

.letter-user-list {
  padding: 6px;
  background-color: var(---def8f3);
  border-radius: 50%;
  margin-right: 15px;
  color: var(---2170ff-p-);
  font-weight: 600;
  font-size: 12px;
}

/* .MuiPopover-paper {
  width: 100%;
  max-width: 416px !important;
  max-height: 200px !important;
} */
.user-dropdown-data {
  max-width: 416px !important;
  display: flex;
  align-items: center;
}
.user-dropdown-data .user-title {
  white-space: pre;
  overflow: hidden;
  word-break: break-all;
  max-width: 370px !important;
  text-overflow: ellipsis;
}
.inner-li {
  display: block !important;
  width: 100% !important;
  padding: 4px 6px !important;
}
