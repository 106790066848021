.supp-auction-details-live-bid-container .number-bis span:first-child {
  color: var(---a19b9d);
  margin-right: 10px;
}

.supp-auction-details-live-bid-container .number-bis span:last-child {
  color: var(---13c2c2);
  font-weight: 600;
}

.supp-auction-details-live-bid-container .reserve-price-container {
  cursor: pointer;
}

.supp-auction-details-live-bid-container .reserve-price-container .price {
  font-size: 50px;
  font-weight: 600;
  position: relative;
}

.supp-auction-details-live-bid-container .reserve-price-container .price.hazel {
  color: var(---ffc107);
}

.supp-auction-details-live-bid-container .reserve-price-container .price.pass {
  color: #000;
}

.supp-auction-details-live-bid-container
  .reserve-price-container
  .price
  .sm-sup-rank {
  font-size: 20px;
  padding-top: -50 !important;
  position: absolute;
  font-weight: 700;
  top: 6px;
}

.supp-auction-details-live-bid-container
  .reserve-price-container
  .price.cancel {
  color: var(---dc3545);
}

.supp-auction-details-live-bid-container .reserve-price-container .label {
  font-size: 13px;
}

.supp-auction-details-live-bid-container .reserve-price-container .label.hazel {
  color: var(---ffc107);
}

.supp-auction-details-live-bid-container .reserve-price-container .label.pass {
  color: var(---009b73);
}

.supp-auction-details-live-bid-container .minimum-step {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.supp-auction-details-live-bid-container .minimum-step div:first-child {
  color: var(---a19b9d);
  margin-right: 20px;
  font-size: 22px;
  /* font-weight: 600; */
}

.supp-auction-details-live-bid-container .minimum-step div:last-child {
  font-size: 22px;
  font-weight: 600;
}

.supp-auction-details-live-bid-container
  .place-bid-container
  .decrease
  .MuiButton-startIcon,
.supp-auction-details-live-bid-container
  .place-bid-container
  .increase
  .MuiButton-startIcon {
  margin-left: 0px;
  margin-right: 0px;
}

.supp-auction-details-live-bid-container
  .place-bid-container
  .btn-mui.increase {
  padding: 0.4rem 1.5rem !important;
}

.supp-auction-details-live-bid-container
  .place-bid-container
  .btn-mui.decrease {
  padding: 0.4rem 1.5rem !important;
}

.supp-auction-details-live-bid-container
  .place-bid-container
  .btn-mui.btn-primary,
.supp-auction-details-live-bid-container
  .place-bid-container
  .btn-mui.btn-primary,
.supp-auction-details-live-bid-container
  .place-bid-container
  .btn-mui.btn-primary {
  color: #fff !important;
  background-color: var(---2170ff-p-) !important;
  border-color: var(---2170ff-p-) !important;
}

.supp-auction-details-live-bid-container
  .place-bid-container
  .btn-mui.place-btn
  .MuiButton-label {
  font-size: 16px;
}
.supp-auction-details-live-bid-container
  .place-bid-container
  .btn-mui.place-btn {
  margin-top: 12px !important;
}

.supp-auction-details-live-bid-container
  .place-bid-container
  .decrease.MuiButton-root {
  min-width: 100%;
}

.supp-auction-details-live-bid-container
  .place-bid-container
  .increase.MuiButton-root {
  min-width: 100%;
  margin-left: 0px;
}

.supp-auction-details-live-bid-container
  .place-bid-container
  textarea[name="price"] {
  font-weight: 700;
}

/* //////////////////////////////// */
.supp-auction-details-live-bid-container .item-stc-container {
  text-align: -webkit-center;
  text-align: -moz-center;
  text-align: -ms-center;
  text-align: -o-center;
}

.supp-auction-details-live-bid-container .icon-container {
  margin: auto 0px;
}

.supp-auction-details-live-bid-container .icon {
  color: var(---2170ff-p-);
  width: 50px;
  height: 50px;
  vertical-align: bottom;
}

.supp-auction-details-live-bid-container .content-container .text {
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
}

.supp-auction-details-live-bid-container .content-container.timer .text {
  font-size: 34px;
  color: #fff;
  margin-top: 0px;
}

.supp-auction-details-live-bid-container .content-container .mui-label {
  white-space: nowrap;
}

.supp-auction-details-live-bid-container .content-container.timer .mui-label {
  line-height: 13px;
  color: #fff !important;
}

.supp-auction-details-live-bid-container .content-container .hint-icon {
  width: 16px;
  height: 16px;
  color: var(---2170ff-p-);
  margin-left: 4px;
}
