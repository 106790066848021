/* .live-graph-container .live-graph-range-bar-container .MuiSlider-rail {
  height: 16px;
  border-radius: 0px;
  background-color: var(---e0e0e0);
}

.live-graph-container .live-graph-range-bar-container .MuiSlider-track {
  height: 16px;
  border-radius: 0px;
  background-color: var(---2170ff-p-);
}

.live-graph-container .live-graph-range-bar-container .MuiSlider-thumb {
  border-radius: 5px;
  background-color: #fff;
  height: 24px;
} */

.live-graph-container
  .live-graph-range-bar-container
  .MuiSlider-thumb
  .bot-bar {
  height: 12px;
  width: 2px;
  background-color: #9e9e9e;
}

.live-graph-container
  .live-graph-range-bar-container
  .MuiSlider-thumb
  .bot-bar[aria-label="first"] {
  margin-right: 2px;
}

.live-graph-container
  .live-graph-range-bar-container
  .MuiSlider-thumb
  .bot-bar[aria-label="second"] {
  margin-left: 2px;
}

.live-graph-container .live-graph-container #bottom-container .MuiList-root {
  display: flex;
  margin: auto;
  flex-direction: row;
}

@media screen and (max-width: 960px) {
  .live-graph-container .live-graph-container #bottom-container .MuiList-root {
    flex-wrap: wrap;
  }
}

.live-graph-container #bottom-container .MuiListItemText-root {
  white-space: nowrap;
}

/* 
.live-graph-container [aria-label="bot-vertex"] {
  stroke-width: 2px;
  fill: #fff;
} */
