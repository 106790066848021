/* Popup Design  */

/* first-step popup form */
[aria-label="create-auction-first-step-buyer-aspect"] .popup-form-container {
  width: 80vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="create-auction-first-step-buyer-aspect"] .popup-form-container {
    width: 60vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="create-auction-first-step-buyer-aspect"] .popup-form-container {
    width: 80vw;
  }
}

[aria-label="create-auction-first-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiButtonBase-root {
  position: absolute;
  left: 9px;
  color: var(---2170ff-p-);
  transform: rotateY(180deg);
}

[aria-label="create-auction-first-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiInputBase-root {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 26px;
}
[aria-label="create-auction-first-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiInputBase-input {
  margin-left: 10px;
}

[aria-label="create-auction-first-step-buyer-aspect"] .btn-mui {
  font-size: 16px !important;
}
/* first-step popup form */
