.switchable-toggle {
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 38px;
  height: 22px;
  cursor: pointer;
  opacity: 1;
  margin: 10px;
}

/* refere to label  */
.switchable-toggle + span {
  color: var(---a19b9d);
}

.switchable-toggle.Mui-checked + span {
  color: var(---2170ff-p-);
}

.switchable-toggle .MuiSwitch-track {
  background: var(---a19b9d);
  border-radius: 10px;
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
}

.switchable-toggle.Mui-checked .MuiSwitch-track {
  background: var(---2170ff-p-);
}

.switchable-toggle .MuiSwitch-thumb {
  display: block;
  width: 16px;
  height: 16px;
  top: 3px;
  left: 3px;
  border-radius: 16px;
  background-color: #fff;
  position: relative;
  transition: all 300ms ease;
}

.switchable-toggle.Mui-checked .MuiSwitch-thumb {
  left: 18px;
  top: 3px;
}

.switchable-toggle input {
  cursor: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
}
