/* Popup Design  */

/* second-step popup form */
[aria-label="create-auction-second-step-buyer-aspect"] .popup-form-container {
  width: 80vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="create-auction-second-step-buyer-aspect"] .popup-form-container {
    width: 60vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="create-auction-second-step-buyer-aspect"] .popup-form-container {
    width: 80vw;
  }
}

[aria-label="create-auction-second-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiButtonBase-root {
  position: absolute;
  left: 0;
  color: var(---2170ff-p-);
  transform: rotateY(180deg);
}

[aria-label="create-auction-second-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiInputBase-root {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 26px;
}

[aria-label="create-auction-second-step-buyer-aspect"] .btn-mui {
  font-size: 16px !important;
}

[aria-label="create-auction-second-step-buyer-aspect"] .short-field {
  font-size: 16px !important;
}

@media (min-width: 960px) {
  [aria-label="create-auction-second-step-buyer-aspect"]
    .short-field.MuiGrid-grid-sm-6 {
    max-width: 49%;
    flex-basis: 49%;
  }
}

@media ((min-width: 600px) and (max-width: 899px)) {
  [aria-label="create-auction-second-step-buyer-aspect"]
    .short-field.MuiGrid-grid-sm-6 {
    max-width: 50%;
    flex-basis: 50%;
  }
}

@media (max-width: 599px) {
  [aria-label="create-auction-second-step-buyer-aspect"]
    .short-field.MuiGrid-grid-sm-6 {
    max-width: 100%;
    flex-basis: 100%;
  }
}

[aria-label="create-auction-second-step-buyer-aspect"] .awarding-check-alert {
  background-color: var(---e2f2ff);
  padding: 20px !important;
  border-radius: 12px;
  margin: 1em 7px 1em 7px;
  margin-top: 22px;
}

[aria-label="create-auction-second-step-buyer-aspect"]
  .awarding-check-alert
  .checkbox {
  margin-right: 14px;
}

[aria-label="create-auction-second-step-buyer-aspect"]
  .awarding-check-alert
  .text {
  font-size: 14px;
  color: var(---707070);
}

[aria-label="create-auction-second-step-buyer-aspect"]
  .awarding-check-alert
  .label {
  font-weight: 600;
}

[aria-label="create-auction-second-step-buyer-aspect"]
  .awarding-check-alert
  .MuiCheckbox-root {
  padding-top: 0;
}

[aria-label="create-auction-second-step-buyer-aspect"] .section-title {
  font-size: 18px;
  color: var(---424143);
  font-weight: 600;
  margin-top: 10px;
}

[aria-label="create-auction-second-step-buyer-aspect"] .collabse-btn {
  font-size: 18px;
  color: var(---424143);
  font-weight: 600;
  margin-top: 10px;
}

[aria-label="create-auction-second-step-buyer-aspect"] .checkbox-container {
  min-height: 45px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px;
}

[aria-label="create-auction-second-step-buyer-aspect"]
  .checkbox-container
  .MuiCheckbox-root {
  margin-left: 12px;
  color: var(---2170ff-p-);
}

[aria-label="create-auction-second-step-buyer-aspect"]
  .checkbox-container
  .MuiTypography-root {
  color: var(---424143);
  font-weight: 600;
  /* white-space: nowrap; */
}

[aria-label="create-auction-second-step-buyer-aspect"]
  .checkbox-container
  .hint-icon {
  width: 16px;
  height: 16px;
  color: var(---2170ff-p-);
  margin-left: 10px;
  display: block;
}
.reserve-price-part .hint-icon {
  width: 16px;
  height: 16px;
  color: var(---2170ff-p-);
  margin-left: 10px;
  display: block;
  margin-top: 2px;
}
[aria-label="create-auction-second-step-buyer-aspect"]
  .checkbox-container
  .MuiFormControl-root {
  height: 45px;
}

[aria-label="create-auction-second-step-buyer-aspect"]
  .checkbox-container
  .MuiFormControlLabel-root {
  min-height: 45px;
}

/*  height animation */

[aria-label="create-auction-second-step-buyer-aspect"] .animate-height.__hide {
  -webkit-transition: height 0.5s;
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  transition: height 0.5s;
}

[aria-label="create-auction-second-step-buyer-aspect"] .animate-height.__feed {
  -webkit-transition: height 0.5s;
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  transition: height 0.5s;
}

[aria-label="create-auction-second-step-buyer-aspect"] .animate-opacity.__hide {
  width: 0;
}

[aria-label="create-auction-second-step-buyer-aspect"] .animate-opacity.__feed {
  width: 100%;
}
/* second-step popup form */
.suplier-bid-checkbox.disable-supplier-bid {
  pointer-events: none;
  opacity: 0.4;
}

.delivery-date .Mui-error.MuiFormHelperText-sizeMedium {
  color: #f44336 !important;
  margin-left: 0 !important;
}
