.form-stepper-label-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-stepper-label-container .stepitem {
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-wrap: wrap;
  justify-content: center;
}
.form-stepper-label-container .step-num {
  width: 36px;
  height: 36px;
  background-color: var(---a19b9d);
  border-radius: 50% !important;
  color: #fff;
  display: flex;
}

.form-stepper-label-container .step-num.__active {
  background-color: var(---2170ff-p-);
}

.form-stepper-label-container .step-num div {
  margin: auto;
}

.form-stepper-label-container .step-text {
  font-size: 14px;
  margin-left: 8px;
  color: var(---a19b9d);
  font-weight: 600;
  letter-spacing: 0.02rem;
}

.form-stepper-label-container .step-text.__active {
  color: #231f20;
}

.form-stepper-label-container .divider-container {
  flex-grow: 1;
  margin-left: 10px;
  margin-right: 10px;
}

.form-stepper-label-container .divider-container .line {
  min-width: 15px;
  width: 100%;
  height: 2px;
  background-color: #d7d5d6;
}

@media screen and (max-width: 600px) {
  .form-stepper-label-container .step-text {
    display: none;
  }
}
