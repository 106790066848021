@import url("https://fonts.googleapis.com/css?family=Noto+Sans:400,700,500,600|Roboto:400|Noto+Sans+Tamil:400");
.table {
  border-radius: 20px;
}

.item-container {
  height: 45px;
  flex: 1;
  font-weight: 700;
  color: var(--x424143);
  line-height: 24px;
  white-space: nowrap;
  font-family: "Noto Sans", sans-serif;
  margin-top: 30px;
  color: #424143;
}

.active-btn-item {
  background-color: #def8f3 !important;
  color: #0ad6b8 !important;
  font-size: 10px !important;
  box-shadow: none !important;
  border-radius: 7px !important;
  padding: 2px 2px 2px 5px !important;
  cursor: pointer;
}

.inactive-btn-item {
  background-color: #f8dede !important;
  color: #d60a0a !important;
  font-size: 10px !important;
  box-shadow: none !important;
  border-radius: 7px !important;
  padding: 2px 3px 2px 10px !important;
  cursor: pointer;
}
.active-btn-item :hover {
  border: none;
}

.edit-delete-btn-item {
  background-color: white !important;
  color: #2170ff !important;
  border: 1px solid #2170ff !important;
  font-size: 7px !important;
  box-shadow: none !important;
  border-radius: 100px !important;
  padding: 0px 0px 0px 0px !important;
  cursor: pointer;
  margin-top: 15px !important;
}

.active-btn-popup {
  background-color: #def8f3 !important;
  color: #0ad6b8;
}

.inactive-btn-popup,
.active-btn-popup {
  font-size: 13px !important;
  /* margin-bottom: 5px !important; */
  border: none !important;
  margin: 4px !important;
  cursor: pointer;
  border-radius: 4px;
  line-height: 27px;
  font-weight: 700;
  padding: 0 25px;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  margin: 4px !important;
  border-radius: 4px !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  margin: 4px !important;
}

.inactive-btn-popup {
  background-color: #f1f0f2 !important;
  color: #a19b9d !important;
}

.MuiList-root {
  padding: 7px !important;
}

.icn-active-btn {
  margin-left: -10px !important;
  color: #b5b0b0;
}

.MuiPaper-rounded {
  border-radius: 12px !important;
}

.MuiDialogTitle-root h2 {
  font-weight: 700 !important;
}

.MuiPaper-elevation1 {
  box-shadow: 0px 3px 6px #00000029 !important;
}

.makeStyles-root-64 {
  background-color: white !important;
}

.makeStyles-root-64 svg {
  color: black !important;
}

.MuiMenuItem-root {
  font-size: 13px !important;
}
.MuiSelect-select {
  font-style: normal !important;
}
.table-item-img {
  flex: 1;
  width: 48px;
  border-radius: 5px;
  object-fit: cover;
}

/*Claude*/

.MuiInput-underline:before {
  border: none !important;
}

.MuiToolbar-regular {
  height: 80px;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}
.MuiPaper-elevation0 {
  border-radius: 12px;
}
.makeStyles-Button-34 {
  font-size: 16px;
  height: 48px;
}
.MuiButton-contained {
  box-shadow: none !important;
}
/*.makeStyles-Button-34{
    padding: 0.6rem 1.6rem !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    border-radius: 10px !important;
    
}
.MuiBox-root-52{
    padding: 8px 0 8px 8px !important;
}*/

/*.makeStyles-pageHeader-38{
    padding: 4px 0 4px 0px !important;
    z-index: 2;
    width: -webkit-fill-available;
    width: -moz-fill-available;
}*/
.MuiIconButton-colorInherit {
  margin: 0 15px !important;
  height: 40px !important;
  width: 40px !important;
}

.MuiInput-underline:after {
  border-bottom: none !important;
}

.makeStyles-searchInput-9 {
  border: 1px solid #f1f0f2 !important;
  border-radius: 8px !important;
}
.makeStyles-searchInput-9:focus,
.makeStyles-searchInput-9:hover {
  border: 1px solid #000 !important;
}

input {
  border: 1px solid #f1f0f2;
}

.MuiFormControl-root,
.MuiInputBase-root {
  min-height: 48px;
  height: auto;
  /*border: 1px solid #F1F0F2;
    border-radius: 8px;*/
  padding: 7px 10px;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select,
.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  min-height: auto !important;
}

.BottomModal-right button,
.BottomModal-left button {
  display: flex;
  margin: 0 !important;
  height: 48px !important;
  border-radius: 10px !important;
  min-width: 150px;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none !important;
}
.BottomModal-right button {
  margin-left: 20px !important;
}

.BottomModal-right {
  display: flex;
  justify-content: flex-end;
}
.FilterInSelect label {
  line-height: 30px;
  font-size: 14px !important;
  color: #a19b9d;
}

.FilterInSelect input {
  height: 48px;
}

.BlocFieldsInput .FilterInSelect {
  padding-right: 20px !important;
}

/* FilterInSelect{
    width: 25% !important;
} */

.BottomModal {
  padding: 30px 0 10px 0 !important;
}

.BottomModal button {
  border-radius: 10px;
  height: 48px;
  margin: 0;
}

.BottomModal button:nth-child(3) {
  margin-left: 20px !important;
}

.makeStyles-filterDiv-46 {
  width: 100% !important;
  padding: 1rem !important;
  margin: 0.3rem 0 0 0 !important;
}

/*.MuiPaper-root{
    margin: 10px 0 0 0  !important;
    border-radius: 10px !important;
}*/

.inactive-btn-item,
.active-btn-item {
  font-size: 13px !important;
  text-transform: none !important;
  font-weight: 700 !important;
}

.MuiTableRow-root {
  height: 75px;
}

table thead th {
  color: #a19b9d !important;
  font-weight: 400 !important;
}

table tbody tr:hover {
  background-color: #e2f2ff !important;
}

/* Table*/
/* nth-child(1) = the first td in each tr */
td:nth-child(1) {
  width: 7%;
}
.supplier-detail-contact-name {
  width: 30%;
}

/* column 2 */
td:nth-child(2) {
  width: 5%;
}

/* column 3 */
td:nth-child(3) {
  width: 30%;
}
/* column 4 */
td:nth-child(4) {
  width: 10%;
}

/* column 5 */
td:nth-child(5) {
  width: 12%;
}

/* column 6 */
td:nth-child(6) {
  width: 10%;
}

/* column 7 */
td:nth-child(7) {
  width: 10%;
}

/* column 8 */
td:nth-child(8) {
  width: 10%;
  min-width: 60px;
}
.best-bid-col {
  min-width: 200px !important;
}
/* column 8 */
td:nth-child(9) {
  width: 7%;
  /* min-width: 60px !important; */
}
.recent-auction-auction-id {
  width: 12% !important;
}
.recent-auction-auction-name {
  width: 20% !important;
}
.recent-auction-auction-type {
  width: 25% !important;
}
.recent-auction-auction-date {
  width: 15% !important;
}
.auction-action-tab {
  width: 5% !important;
  min-width: 60px !important;
}
.company-user-action-tab {
  width: 5% !important;
  min-width: 60px !important;
}

.css-9tj150-MuiButton-endIcon {
  display: inherit;
  margin-right: -11px !important;
  margin-left: 16px !important;
}

td:nth-child(8) .MuiBox-root {
  min-width: 0 !important;
  /* width: 0 !important; */
  margin: 0;
  padding: 0;
  display: inline;
  align-items: center;
}

.edit-delete-btn-item {
  border: none !important;
}

/* td:nth-child(8) .MuiGrid-root .MuiBox-root.css-0 {
  float: right;
  width: 30px !important;
  margin-right: 20px;
}

td:nth-child(8) .MuiBox-root button {
  line-height: 27px;
  padding: 0 15px !important;
}

td:nth-child(8) .MuiBox-root button svg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transform: rotate(90deg);
  background-color: transparent !important;
}

td:nth-child(8) .MuiBox-root button svg:active {
  border: 1px solid #1976d2;
}

td:nth-child(8) .MuiBox-root button span svg {
  border: none !important;
  transform: none;
  width: 21px;
} */
.edit-delete-btn-item {
  margin-top: 0 !important;
}
/*.css-1q1u3t4-MuiTableRow-root{
    color: inherit;
    display: flex !important;
    vertical-align: middle !important;
    outline: 0;
    justify-content: space-between !important;
}*/
.css-130xbax-MuiTable-root {
  display: inline-table !important;
  border-collapse: unset !important;
}

/*Modal*/

.MuiDialogContent-dividers {
  border: 0 !important;
}

.MuiDialogContent-dividers {
  position: relative;
}

.makeStyles-control-76 {
  position: absolute;
}
.MuiDialogContent-root {
  padding: 30px 0 0 0 !important;
  overflow: hidden;
}
.MuiDialog-paperWidthMd .MuiGrid-spacing-xs-1 > .MuiGrid-item {
  margin-bottom: 10px !important;
}

.MuiDialog-paperWidthMd,
.makeStyles-dialogWrapper-31 {
  padding: 40px !important;
  top: inherit !important;
}

.MuiTypography-h6 {
  font-weight: 700 !important;
}
.ModalHeader {
  padding: 0 !important;
}

.ModalHeader button {
  background: none;
  height: 35px;
  margin-right: -11px !important;
}

.css-w4z10b-MuiStack-root {
  flex-direction: row;
  justify-content: space-between;
}

.ModalHeader button svg {
  color: black !important;
}

.MuiDialog-paperWidthMd .StepControlInput,
.MuiDialog-paperWidthMd .MuiFormControl-root {
  width: 100% !important;
  margin: 0 !important;
  margin-bottom: 10px !important;
}
.ModalHeader h2 {
  color: #1e1d1d;
  font-size: 20px !important;
  font-weight: 700px !important;
}

/*.makeStyles-active-74, .makeStyles-inactive-75{
    width: 36px;
    height: 36px;
    line-height: 35px;
    border-radius: 50%;
    font-size: 14px;
    color: white !important;
    font-weight: 700;
    background-color: #2170FF;
}*/

.makeStyles-secondary-65 {
  background-color: none !important;
}
.makeStyles-root-64 {
  margin: 0 !important;
}

.makeStyles-root-69.MuiFormControl-root {
  width: 100% !important;
}

.MuiBox-root.MuiBox-root-252 {
  display: flex;
}

.MuiPaper-root.MuiBox-root {
  width: 100% !important;
}

.css-1ex1afd-MuiTableCell-root {
  border-bottom: 1px solid rgba(224, 224, 224, 0.4) !important;
  padding: 12px 16px !important;
}

/*Spaces*/
.padding-right-10 {
  padding-right: 10px !important;
}
.padding-right-20 {
  padding-right: 20px !important;
}
.padding-right-30 {
  padding-right: 30px !important;
}
.padding-right-40 {
  padding-right: 40px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}
.margin-right-20 {
  margin-right: 20px !important;
}
.margin-right-30 {
  margin-right: 30px !important;
}
.margin-right-40 {
  margin-right: 40px !important;
}
/*---*/

.padding-left-10 {
  padding-left: 10px !important;
}
.padding-left-20 {
  padding-left: 20px !important;
}
.padding-left-30 {
  padding-left: 30px !important;
}
.padding-left-40 {
  padding-left: 40px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}
.margin-left-20 {
  margin-left: 20px !important;
}
.margin-left-30 {
  margin-left: 30px !important;
}
.margin-left-40 {
  margin-left: 40px !important;
}

.marginleft15 {
  margin-left: 15px !important;
}

.StepControl .gridCol,
.StepControl .gridColdisabled,
.StepControl .gridColactive,
.StepControl .gridColchecked {
  line-height: 26px !important;
  width: 36px !important;
  height: 36px !important;
  font-size: 14px;
  font-weight: 500;
  border-radius: 50% !important;
  color: #fff !important;
  margin: 2px;
}

.StepControlIcon {
  height: 36px;
  width: 36px;
  background-color: #fff !important;
}

.uploadControlIcon {
  height: 40px;
  width: 40px;
  margin: 2px;
}

.attachControlIcon {
  height: 34px;
  margin: 4px 4px 0px 2px;
}
.uploadheader p {
  margin: 2px 0;
}

.uploadheader .customeFileUpload {
  display: none;
}

.StepControl .gridColactive {
  margin: 0;
}
.StepControl .gridColdisabled {
  background-color: #a19b9d !important;
}

.StepControl .gridColchecked {
  background-color: #00d4b5 !important;
}

.StepControl {
  padding: 2rem 0 2rem 0 !important;
  position: relative;
}

.StepControl .wrapper {
  width: 50%;
  margin: auto;
  align-items: center;
}

.StepControl .wrapper .MuiGrid-item {
  padding: 0px;
}

.StepControl svg {
  width: 36px;
  height: 36px;
}

.StepControl .gridCol {
  background: var(---2170ff-p-);
  text-align: center;
}

.StepControl .gridCol.__inactive {
  background-color: var(---a19b9d) !important;
}

.StepControl .gridCol.__active {
  background-color: var(---2170ff-p-) !important;
}

.StepControl .gridCol > div {
  margin-top: 5px;
}

/* .StepControl div {
  width: auto !important;
} */

.StepControl .gridColTextActive,
.StepControl .gridColTextInactive {
  font-size: 14px !important;
  line-height: 29px !important;
  width: auto !important;
  margin: 1px 5px !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  font-weight: 700;
}

.StepControl .gridColTextActive {
  color: #000 !important;
}
.StepControl .gridColTextInactive {
  color: #a19b9d !important;
}

.filterBox {
  position: absolute;
  right: 0;
  z-index: 999;
  left: 0;
}

.MuiOutlinedInput-root {
  border-radius: 8px !important;
}

.FilterControl button {
  height: 48px;
  margin: 8px 0 8px 16px;
  border-radius: 10px;
}

/* .FilterControl button span:nth-child(2){
    display: none !important;
} */

.FilterBoxView {
  z-index: 99;
}

.blockInFilter {
  /* margin: 12px 0 0 0; */
  width: 100%;
  padding: 20px 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.BlocFieldsButtun,
.BlocFieldsInput {
  display: flex;
  align-items: flex-end;
}

.BlocFieldsButtun button {
  width: 176px;
  margin: 0 !important;
}

.BlocFieldsButtun .FilterInSelect:nth-child(1) {
  padding-right: 20px !important;
}

.BlocFieldsButtun {
  margin-left: 0 !important;
}
/* .blockInFilter .MuiOutlinedInput-root, .blockInFilter button{
    width: 180px;
    border-radius: 8px;
} */

.blockInFilter .MuiInputBase-root {
  width: 100%;
}
.blockInFilter button {
  height: 48px;
}

.blockInFilter div button:nth-child(1) {
  border: 1px solid #dddbdb !important;
}

.blockInFilter div button:nth-child(1) {
  border: 1px solid #dddbdb !important;
}
.ContainerAttachment hr {
  border-color: #e0e0e0 !important;
}
.AttachedFiles {
  padding: 11px 20px 10px 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin: 10px 10px 3px 0;
  width: 220px;
}

.blockInFilter button {
  font-size: 16px;
  height: 48px;
  margin: 8px;
}
.CloseControlIcon {
  height: 18px;
}

.blockInFilter .MuiOutlinedInput-root,
.blockInFilter button {
  font-family: "Noto Sans" !important;
  font-size: 14px !important;
  border-radius: 8px;
}

.BlueButtun,
.WhiteButton,
.WhiteButton,
.WhiteButtonFilter,
.GreyButton,
.GreenButton {
  height: 48px;
  border-radius: 8px;
  padding: 0 5px !important;
  min-width: 175px !important;
  width: 100% !important;
}

.BlueButtun {
  background-color: #2170ff;
  color: #fff !important;
}

.WhiteButton {
  background-color: #fff;
  color: #2170ff !important;
}
.WhiteButtonFilter {
  background-color: #fff;
  border: 1px solid #dddbdb;
}

.GreyButton {
  background-color: #f1f0f2;
  color: #424143 !important;
}

.GreenButton {
  background-color: #e2f2ff;
  color: #2170ff !important;
}

.container .imgPreview {
  margin: 0 !important;
  width: 100%;
}

.container .imgPreview.attach_box {
  margin: 0 !important;
  width: 150px;
  height: 141px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #e2f2ff;
  border-radius: 8px;
  margin: 10px 0 0 10px;
  color: #000000;
}

.container .imgPreview.doc_box {
  margin: 0 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #dbe8ff;
  border: 2px dashed #2170ff;
  border-radius: 8px;
  margin-bottom: 1rem;
  color: #000000;
}

.container .imgPreview.doc_box .upload-btn {
  background-color: #fff;
  color: #2170ff;
  margin: 20px 0;
  font-size: 13px;
  border-radius: 5em;
  padding: 0.5rem 2rem;
  font-weight: 700;
}

.imgPreview input {
  display: none;
}

.DividerContainer {
  min-width: 700px;
}
.DividerContainer label {
  margin-left: -8px;
}

.imgPreview svg {
  width: 29px;
  height: 29px;
  margin-top: 8px;
}

.imgPreview label {
  width: 150px !important;
  height: 150px !important;
}

.ContainerAttachment {
  margin: 32px 0 10px 0;
}
.ContainerAttachment span {
  line-height: 34px;
}

.ContainerAttachment hr {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.SearchInputList {
  padding: 0 2px 0 18px !important;
  border: 1px solid #f1f0f2 !important;
  border-radius: 8px !important;
  background: no-repeat;
  background-size: 18px;
  background-position: 10px center;
  width: 278px;
}

.SearchInputList:hover,
.SearchInputList:focus,
.SearchInputList:active {
  border: 1px solid var(---2170ff-p-) !important;
}

.SearchInputList svg {
  display: none;
}

.library-doc {
  cursor: pointer;
  text-decoration: underline;
}
