.details-banner {
  background-color: rgb(255, 255, 255);
  display: block;
  margin-top: 1rem;
  margin-left: -2em;
  margin-right: -1.7em;
  border: none;
  background: transparent
    linear-gradient(90deg, #ffffff 0%, #d5e4ffe7 49%, #2170ff82 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
  position: relative;
  height: 14em;
}

.details-banner .banner-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.2;
}

.details-banner .banner-container {
  margin: 1em 2em;
  z-index: 2;
  position: relative;
}

.details-banner .project-title {
  margin-bottom: 0em;
}

.details-banner .project-title__smal {
  margin-bottom: 1em;
  font-size: 0.9em;
  margin-top: 0.5em;
}

.details-banner .project-title__smal span:nth-of-type(1) {
  color: var(---2170ff-p-);
}

.details-banner .project-title__smal span:nth-of-type(2) {
  color: var(---a19b9d);
}

.details-banner .banner-container .go-back-button {
  font-size: 16px;
  font-weight: 700;
  color: var(---2170ff-p-);
  background-color: #fff;
  border-color: #fff;
  white-space: break-spaces;
  height: 45px;
  border-radius: 12px;
  padding: 0px 12px;
}

.details-banner .banner-container .go-back-button svg {
  font-size: 1.1em;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
  margin-right: 0.4em;
  vertical-align: middle;
}

.details-banner .banner-container .go-back-button .live-icon {
  color: var(---2170ff-p-);
  width: 24px;
  height: 24px;
  vertical-align: bottom;
}

.details-banner .banner-container .go-back-button.__edit {
  border: 1px solid var(---2170ff-p-);
  font-size: 0.9em;
  padding: 4px 10px;
  border-radius: 8px;
  text-transform: capitalize;
}

.details-banner .banner-container .go-back-button.__save {
  border: 1px solid var(---2170ff-p-);
  font-size: 0.9em;
  color: #fff;
  padding: 4px 10px;
  border-radius: 8px;
  text-transform: capitalize;
  background-color: var(---2170ff-p-);
}

.details-banner .banner-container .editable-board-container {
  margin-top: 1em;
  position: relative;
  overflow: hidden;
  padding: 1.2em;
  /* min-height: 10em; */
}

.details-banner .banner-container .editable-board-container .image {
  width: 100%;
  max-height: 100px;
  border-radius: 8px;
  background: #f2f2f270;
}

.details-banner .banner-container .editable-board-container .img-back-grd {
  background-image: linear-gradient(
    to top left,
    transparent 50%,
    var(---2170ff-p-) 50%
  );
  position: absolute;
  width: 6em;
  height: 157%;
  top: 0;
  left: 0;
  z-index: 0;
}

.details-banner .banner-container .editable-board-container .lable {
  font-size: 0.8em;
  color: var(---a19b9d);
  padding: 2px 0px;
}

.details-banner .banner-container .editable-board-container .field {
  margin-bottom: 1em;
  font-weight: 600;
  border: none;
  border-bottom: 1px transparent solid;
  width: 100%;
  font-family: "Noto Sans", sans-serif;
  resize: none;
  outline: none;
}

.details-banner .banner-container .editable-board-container .status {
  padding: 0px 10px !important;
  margin-right: 4px;
  font-size: 1em !important;
}

.details-banner .banner-container .editable-board-container .edit-project {
  border: 1px solid var(---2170ff-p-);
  font-size: 0.7em;
  padding: 4px;
  border-radius: 8px;
  text-transform: capitalize;
}

.details-banner .banner-container .editable-board-container .field.__edit {
  font-weight: 600;
  border: none;
  border-bottom: 2px solid #0e86f5;
  background-color: #badeff;
}

.details-banner .banner-container .editable-board-container select:disabled {
  color: #000;
  opacity: 1;
  appearance: none;
}

.details-banner .banner-container .editable-board-container select option {
  background-color: #fff;
}

.details-banner
  .banner-container
  .editable-board-container
  .field.__edit:focus-visible {
  outline: none;
}

.show__less__text {
  display: inline;
}

.show-wrap-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-width: 300px;
  word-break: break-word;
}
.show-wrap-table-text {
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.show-wrap-text.mw550 {
  max-width: 550px !important;
}
.show-wrap-text.mw600 {
  max-width: 600px !important;
}
.show-wrap-text.mw200 {
  max-width: 200px !important;
}
.show-wrap-text.eight-line {
  -webkit-line-clamp: 8 !important;
}
.show-wrap-text.two-line {
  -webkit-line-clamp: 2 !important;
}
.show-wrap-text.one-line {
  -webkit-line-clamp: 1 !important;
}
.show__complete__text {
  display: none;
}

.show__more__link {
  color: var(---2170ff-p-);
  padding: 3px;
  cursor: pointer;
}

