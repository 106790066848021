.auction-confirm-dailog-title {
    text-align: center;
}

.auction-confirm-dailog-main-div {
    padding: 30px !important;
}

.auctio-dailog-content {
    padding: 0px !important;
}

.color-red {
    background-color: #DC3545 !important;
    width: 200px;
}

.custom-color {
    background-color: #E2F2FF !important;
    color: #2170ff !important;
    width: 200px;
}

.mt-20 {
    padding-top: 30px;
}