[aria-label="add-edit-item-cost-center-settings-modal"] .popup-form-container {
  width: 40vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="add-edit-item-cost-center-settings-modal"]
    .popup-form-container {
    width: 25vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 600px) {
  [aria-label="add-edit-item-cost-center-settings-modal"]
    .popup-form-container {
    width: 70vw;
  }
}
