.live-statistics-container {
  background-color: #fff;
  padding: 24px;
}

.live-statistics-container > div {
  /* justify-content: space-between; */
  justify-content: space-between;
}

.live-statistics-container .MuiGrid-container {
  align-items: center;
  justify-content: unset !important;
}

.live-statistics-container .mui-card.MuiGrid-item {
  padding: 24px;
}

.live-statistics-container .mui-card {
  border: 1px solid var(---e0e0e0);
  /* max-width: 238px; */
  text-align: -webkit-auto;
  text-align: -moz-auto;
  text-align: -ms-auto;
  text-align: -o-auto;
}

@media screen and (max-width: 1200px) {
  .live-statistics-container .mui-card {
    max-width: 270px;
  }
}

.live-statistics-container .mui-card.best-bid {
  /* max-width: 260px; */
  padding-left: 16px;
}
.live-statistics-container .item-stc-container {
  text-align: -webkit-center;
  text-align: -moz-center;
  text-align: -ms-center;
  text-align: -o-center;
}

.live-statistics-container .icon-container {
  margin: auto 0px;
}

.live-statistics-container .icon {
  color: var(---2170ff-p-);
  width: 50px;
  height: 50px;
  vertical-align: bottom;
}

.live-statistics-container .content-container .text {
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
}

.live-statistics-container .content-container.supplier-name .text {
  overflow: overlay;
}

.live-statistics-container
  .content-container.supplier-name
  .text::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.live-statistics-container
  .content-container.supplier-name
  .text::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  background: linear-gradient(
    0deg,
    rgb(239 239 239) 0%,
    rgb(255, 255, 255) 100%
  );
  border-radius: 10px;
}

.live-statistics-container
  .content-container.supplier-name
  .text::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -moz-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.live-statistics-container .content-container.timer .text {
  font-size: 34px;
  color: #fff;
  margin-top: 0px;
}

.live-statistics-container .content-container .mui-label {
  white-space: nowrap;
}

.live-statistics-container .content-container.timer .mui-label {
  line-height: 13px;
  color: #fff !important;
}

.live-statistics-container .content-container .hint-icon {
  width: 16px;
  height: 16px;
  color: var(---2170ff-p-);
  margin-left: 4px;
}

.mui-tooltip.small-box .MuiTooltip-tooltip {
  padding: 6px 6px 6px 6px;
}
