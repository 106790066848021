.item-edit-board-details-container *,
#menu-category {
  z-index: 1600 !important;
  font-size: 13px !important;
}
.item-edit-board-details-container *,
#menu-group {
  z-index: 1600 !important;
  font-size: 13px !important;
}

.item-edit-board-details-container .edit-btn {
  height: 26px;
  width: 52px;
  border-radius: 8px;
  font-size: 16px;
  background-color: #fff !important;
  border-color: var(---2170ff-p-) !important;
  border-width: 1px !important;
  color: var(---2170ff-p-) !important;
}

.item-edit-board-details-container .edit-btn:hover {
  background-color: var(---2170ff-p-) !important;
  color: #fff !important;
}

.item-edit-board-details-container .title-header {
  font-size: 14px !important;
  margin-top: 0px;
}

.item-edit-board-details-container .tag {
  border-radius: 18px;
  margin-left: 5px;
  margin-top: 5px;
}

.item-edit-board-details-container .tag.badge {
  padding: 0.2em 0.5em;
  display: flex;
  align-items: center;
}

.item-edit-board-details-container .mui-input {
  height: 43px !important;
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  background-color: #fff;
}

.item-edit-board-details-container .mui-select .MuiInputBase-root {
  height: 43px;
  background-color: #fff;
}

.item-edit-board-details-container .mui-select.MuiFormControl-root {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.item-edit-board-details-container .mui-select .MuiSelect-iconOutlined {
  margin-top: 3px;
  font-size: 18px !important;
}
