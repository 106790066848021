.details-banner.supplier-aspect .project-title .sm-icon {
  width: 36px;
  height: 36px;
  margin-right: 0.5em;
  background-color: var(---2170ff-p-);
  border-radius: 8px;
}
.details-banner.rfx-seller-banner {
  height: 11em !important;
}

.details-banner.supplier-aspect .project-title .sm-icon.cp-icon {
  color: #fff;
  transform: rotatey(180deg);
  padding: 7px;
  opacity: 0.66;
}

.details-banner.supplier-aspect .banner-container .project-title .status {
  /* width: inherit; */
  height: 1.6em;
  vertical-align: super;
  font-size: 16px !important;
  font-weight: 300 !important;
  padding: 4px 10px !important;
  border-radius: 6px !important;
}

.badge.badge-danger {
  background: var(---fd5656) 0% 0% no-repeat padding-box;
  border-color: var(---fd5656);
  color: #fff;
}

.details-banner.supplier-aspect .datetime-picker-cotainer {
  position: relative;
}

.details-banner.supplier-aspect .datetime-picker-cotainer .MuiFormControl-root {
  width: 15em;
  margin: 0px;
}

.details-banner.supplier-aspect .datetime-picker-cotainer .MuiButtonBase-root {
  position: absolute;
  left: 0;
  padding: 0;
  color: var(---2170ff-p-);
  transform: rotateY(180deg);
}

.details-banner.supplier-aspect .datetime-picker-cotainer .MuiInputBase-root {
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 32px;
}

.details-banner.supplier-aspect
  .datetime-picker-cotainer
  .MuiInputBase-root
  input {
  margin-left: 1em;
  border-bottom: 2px solid transparent;
  padding: 1px 6px;
}

.details-banner.supplier-aspect
  .datetime-picker-cotainer.__view
  .MuiInputBase-root
  input {
  border-bottom-color: transparent;
  background-color: transparent;
}

.details-banner.supplier-aspect
  .datetime-picker-cotainer.__edit
  .MuiInputBase-root
  input {
  border-bottom-color: var(---2170ff-p-);
  background-color: #badeff;
}

.details-banner.supplier-aspect
  .datetime-picker-cotainer
  .MuiInputBase-root
  .MuiOutlinedInput-notchedOutline {
  border: none;
  margin: 0;
  padding: 0;
}

.details-banner.supplier-aspect
  .banner-container
  .editable-board-container
  .image {
  width: 93px;
}

.details-banner.supplier-aspect .project-title__smal span:nth-of-type(2) {
  color: var(---2170ff-p-);
}

.details-banner.supplier-aspect .btn-danger {
  background-color: var(---dc3545) !important;
  border-color: var(---dc3545) !important;
  font-size: 16px !important;
  letter-spacing: 0.02em;
}

.details-banner.supplier-aspect .btn-success {
  background-color: var(---13c2c2) !important;
  border-color: var(---13c2c2) !important;
  font-size: 16px !important;
  letter-spacing: 0.02em;
  margin-right: 20px;
}
