.supp-auction-details-items-list-container .header-title {
  font-size: 1.3em;
  font-weight: 700;
}

.supp-auction-details-items-list-container .header-button {
  padding: 0px;
  font-weight: 700 !important;
  color: var(---2170ff-p-);
}

.supp-auction-details-items-list-container .header-button .btn {
  float: right;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.1em;
}

.table-item-img {
  height: 48px;
}

.supp-auction-details-items-list-container .btn-primary {
  color: #fff !important;
  background-color: var(---2170ff-p-) !important;
  border-color: var(---2170ff-p-) !important;
}

[aria-label="auction-details-items-popup"] .popup-form-container {
  width: 40vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="auction-details-items-popup"] .popup-form-container {
    width: 45vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="auction-details-items-popup"] .popup-form-container {
    width: 50vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 960px) {
  [aria-label="auction-details-items-popup"] .popup-form-container {
    width: 70vw;
  }
}
