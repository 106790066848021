@import url("https://fonts.googleapis.com/css?family=Noto+Sans:400,700,500,600|Roboto:400|Noto+Sans+Tamil:400");
.table {
  border-radius: 20px;
}

.item-container {
  height: 45px;
  flex: 1;
  font-weight: 700;
  color: var(--x424143);
  line-height: 24px;
  white-space: nowrap;
  font-family: "Noto Sans", sans-serif;
  margin-top: 30px;
  color: #424143;
}

.active-btn-item {
  background-color: #def8f3 !important;
  color: #0ad6b8 !important;
  font-size: 10px !important;
  box-shadow: none !important;
  border-radius: 7px !important;
  padding: 2px 2px 2px 5px !important;
  cursor: pointer;
}
.inactive-btn-item {
  background-color: #f8dede !important;
  color: #d60a0a !important;
  font-size: 10px !important;
  box-shadow: none !important;
  border-radius: 7px !important;
  padding: 2px 3px 2px 7px !important;
  cursor: pointer;
}
.active-btn-item :hover {
  border: none;
}
/* .edit-delete-btn-item{
      background-color: white !important;
      color : #2170FF !important;
      border : 1px solid #2170FF !important;
      font-size: 7px !important;
      box-shadow: none !important;
      border-radius: 100px !important;
      padding: 0px 0px 0px 0px !important;
      cursor: pointer;
      margin-top: 15px !important;
  } */

.active-btn-popup {
  background-color: #def8f3 !important;
  color: #0ad6b8;
}

.inactive-btn-popup,
.active-btn-popup {
  font-size: 13px !important;
  /* margin-bottom: 5px !important; */
  border: none !important;
  margin: 4px !important;
  cursor: pointer;
  border-radius: 4px;
  line-height: 27px;
  font-weight: 700;
  padding: 0 25px;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  margin: 4px !important;
  border-radius: 4px !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  margin: 4px !important;
}

.inactive-btn-popup {
  background-color: #f1f0f2 !important;
  color: #a19b9d !important;
}

.MuiList-root {
  padding: 7px !important;
}

.icn-active-btn {
  margin-left: -20px !important;
  color: #b5b0b0;
}

.MuiPaper-rounded {
  border-radius: 12px !important;
}

.MuiDialogTitle-root h2 {
  font-weight: 700 !important;
}

.MuiPaper-elevation1 {
  box-shadow: 0px 3px 6px #00000029 !important;
}

.makeStyles-root-64 {
  background-color: white !important;
}

.makeStyles-root-64 svg {
  color: black !important;
}

.MuiMenuItem-root {
  font-size: 13px !important;
}
.MuiSelect-select {
  font-style: normal !important;
}
.table-item-img {
  flex: 1;
  width: 48px;
  border-radius: 5px;
  object-fit: cover;
}
