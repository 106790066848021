@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,700,500,600|Roboto:400|Noto+Sans+Tamil:400);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,700,500,600|Roboto:400|Noto+Sans+Tamil:400);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,700,500,600|Roboto:400|Noto+Sans+Tamil:400);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,700,500,600|Roboto:400|Noto+Sans+Tamil:400);
:root {
  ---2170ff-p-: #2170ff;
  ---2a5cec: #2a5cec;
  ---424143: #424143;
  ---1e1d1d: #1e1d1d;
  ---707070: #707070;
  ---a19b9d: #a19b9d;
  ---ecdfe4: #ecdfe4;
  ---e0e0e0: #e0e0e0;
  ---afafaf: #afafaf;
  ---e2feff: #e2feff;
  ---13c2c2: #13c2c2;
  ---009b73: #009b73;
  ---00d4b5-s-: #00d4b5;
  ---def8f3: #def8f3;
  ---83c9f4-p-: #83c9f4;
  ---e2f2ff: #e2f2ff;
  ---dee2ff: #dee2ff;
  ---e0a800: #e0a800;
  ---f1f0f2: #f1f0f2;
  ---f7fafc: #f7fafc;
  ---c82333: #c82333;
  ---dc3545: #dc3545;
  ---fd5656: #fd5656;
  ---ffe8ea: #ffe8ea;
  ---fbeaec: #fbeaec;
  ---ffc107: #ffc107;
  ---fcf6e5: #fcf6e5;
  ---575ba7: #575ba7;
  /* break points  */
  ---bkp-xs: 0px;
  ---bkp-sm: 600px;
  ---bkp-md: 960px;
  ---bkp-lg: 1280px;
  ---bkp-xl: 1536px;
}

* {
  margin: 0;
  /* font-family: "Noto Sans Avestan", sans-serif; */
  font-family: "Noto Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.toggle-display-hide {
  display: none !important;
}

.toggle-display-show {
  display: block !important;
}

.toggle-width-show {
  width: 100% !important;
  transition: width 0.5s ease;
}

.toggle-width-hide {
  width: 0 !important;
  transition: width 0.5s ease;
}

.toggle-height-show {
  height: 100% !important;
  transition: height 0.5s ease;
}

.toggle-height-hide {
  height: 0 !important;
  transition: height 0.5s ease;
}

.toggle-opacity-show {
  opacity: 1 !important;
  transition: opacity 0.5s ease;
}

.toggle-opacity-hide {
  opacity: 0 !important;
  transition: opacity 0.5s ease;
}

.flexible-container {
  margin-left: 6em !important;
}

@media screen {
  @media screen and (min-width: 1400px) {
    .flexible-container {
      margin-left: 0;
    }
  }
}

/* fix width and height  */
.fix-w-12em {
  min-width: 12.5rem !important;
}

fix-w-8em {
  min-width: 8rem !important;
}

.fix-w-6em {
  min-width: 6rem !important;
}

@media screen and (max-width: 960px) {
  .fix-w-12em {
    min-width: 6.5rem !important;
  }

  .fix-w-8em {
    min-width: 4.5rem !important;
  }

  .fix-w-6em {
    min-width: 3.5rem !important;
  }
}

/* mui link (anchor) */
.mui-link {
  text-decoration: none;
  color: -p-;
  color: var(---2170ff-p-);
}

.mui-link:link {
  color: -p-;
  color: var(---2170ff-p-);
}

/* visited link */
.mui-link:visited {
  color: var(---575ba7);
}

/* mouse over link */
.mui-link:hover {
  color: var(---575ba7);
}

/* selected link */
.mui-link:active {
  color: var(---575ba7);
}
/* mui link (anchor) */

/* bold  */
.bold-line-600 {
  font-size: 1em !important;
  font-weight: 600 !important;
}

.b-600 {
  font-weight: 600 !important;
}
/* bold  */

/* color  */
.brc-f1f0f2 {
  border-color: var(---f1f0f2) !important;
}

.clr-2170ff {
  color: -p- !important;
  color: var(---2170ff-p-) !important;
}
.clr-ff0000 {
  color: #f00 !important;
}

.clr-ffffff {
  color: #fff !important;
}
/* color  */

/* display  */
.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.d-inline {
  display: inline !important;
}

.d-none {
  display: none !important;
}
/* display  */

/* position  */
.position-rel {
  position: relative !important;
}

.position-abs {
  position: absolute !important;
}

/* fonts  */

.fz-16 {
  font-size: 16px !important;
}

.fz-18 {
  font-size: 18px !important;
}

.fz-30 {
  font-size: 30px !important;
}

.fz-32 {
  font-size: 32px !important;
}

.fz-34 {
  font-size: 34px !important;
}
/* font size  */

/* width  */
.w-100 {
  width: 100% !important;
}
.w-50 {
  width: 50% !important;
}
.w-auto {
  width: auto !important;
}
/* width  */

/* margin  */
.m-0 {
  margin: 0px !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.25rem !important;
}

.m-5 {
  margin: 1.75rem !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.25rem !important;
}

.mt-5 {
  margin-top: 1.75rem !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.25rem !important;
}

.mb-5 {
  margin-bottom: 1.75rem !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.25rem !important;
}

.mr-5 {
  margin-right: 1.75rem !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.25rem !important;
}

.ml-5 {
  margin-left: 1.75rem !important;
}
/* margin  */

/* padding  */
.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.25rem !important;
}

.p-5 {
  padding: 1.75rem !important;
}

.p-0 {
  padding: 0px !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.25rem !important;
}

.pt-5 {
  padding-top: 1.75rem !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.25rem !important;
}

.pb-5 {
  padding-bottom: 1.75rem !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.px-5 {
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
}
.py-1 {
  padding: 0.25rem !important;
}

.py-2 {
  padding: 0.5rem !important;
}

.py-3 {
  padding: 1rem !important;
}

.py-4 {
  padding: 1.25rem !important;
}

.py-5 {
  padding: 1.75rem !important;
}
.pt-0 {
  padding-top: 0px !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.25rem !important;
}

.pt-5 {
  padding-top: 1.75rem !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.25rem !important;
}

.pb-5 {
  padding-bottom: 1.75rem !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-4 {
  padding-right: 1.25rem !important;
}

.pr-5 {
  padding-right: 1.75rem !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.25rem !important;
}

.pl-5 {
  padding-left: 1.75rem !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.25rem !important;
}

.mt-5 {
  margin-top: 1.75rem !important;
}
.ml-auto {
  margin-left: auto !important;
}
.float-right {
  float: right;
}
/* padding  */

/* text alignment  */
.text-end {
  text-align: end !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}
/* text alignment  */

.text-transform-cap {
  text-transform: capitalize !important;
}

/* item alignment  */
.items-center {
  align-items: center;
}
/* item alignment */

/* cursor shape  */

.cursor-p {
  cursor: pointer;
}
/* cursor shape */

/* overflow: hidden; */
.overflow-h {
  overflow: hidden;
}
/* overflow: hidden; */

.bck-transparent {
  background-color: transparent !important;
}
.MuiFormLabel-root {
  line-height: 1.4 !important;
}

.lineBreak {
  word-break: break-all;
}
.Toastify__close-button {
  align-self: center !important;
  width: 14px;
  height: 16px;
  padding: 0 10px 0 0 !important;
}
.Toastify__toast--success {
  box-shadow: 0 6px 15px -7px #edf7ed;
  background-color: #edf7ed !important;
}

.Toastify__toast--success .Toastify__toast-body div {
  color: #1e4620;
}
.Toastify__toast--succcess .Toastify__close-button {
  background-color: #edf7ed !important;
  padding: 0 10px 0 0;
}
.Toastify__close-button svg {
  fill: #1e4620;
}
.Toastify__toast--error {
  box-shadow: 0 6px 15px -7px #fdecea;
  background-color: #fdecea !important;
}

.Toastify__toast--error .Toastify__toast-body div {
  color: #611a15;
}
.Toastify__toast--error .Toastify__close-button {
  background-color: #fdecea !important;
}
.Toastify__toast--error .Toastify__close-button svg {
  fill: #611a15;
}
.lineheight28 {
  line-height: 28px;
}
.breakLine {
  white-space: break-spaces;
}
.simple-word-break {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-word-break {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-word;
}
.minW175 {
  width: 175px !important;
  max-width: 175px;
}
.minW350 {
  min-width: 350px;
}
.mw100 {
  max-width: 100px;
}
.mw200 {
  max-width: 200px;
}
.minW200 {
  min-width: 200px !important;
}
.max-width100 {
  max-width: 100%;
}
.global-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.global-flex-center.item-start {
  justify-content: start !important;
}
.supplier-import .import-done-icon {
  width: 60px;
  height: 60px;
}
.cusrsor-pointer {
  cursor: pointer;
}
.text-warning {
  color: #ffc107 !important;
}
.g-10 {
  grid-gap: 10px;
  gap: 10px;
}
.error-msg {
  color: #e64646 !important;
  margin-bottom: 8px;
  font-size: 12px !important;
  line-height: 1.1;
}

/* COLOR-CLASS */
.text-black {
  color: #1e1d1d;
}
.text-gray {
  color: #a19b9d;
}
.text-primary {
  color: #1e65e6;
}
.text-dark {
  color: #424143;
}

/* FONT-WEIGHT */
.text-bold {
  font-weight: 700;
}

.item-disabeled {
  cursor: not-allowed !important;
  -webkit-filter: grayscale(1) !important;
          filter: grayscale(1) !important;
}
.badge.badge-primary.button-disabeled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  cursor: not-allowed;
}
.supp-auction-details-live-bid-container
  .place-bid-container
  .btn-mui.btn-primary.button-disabeled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  cursor: not-allowed;
}
.button-disabeled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  cursor: not-allowed;
}
.h-30 {
  height: 30px;
}
.d-flex {
  display: flex;
}
.align-item-center {
  align-items: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-end {
  justify-content: end;
}
.common-slider {
  background-color: #fff;
  min-height: 100vh;
}
.common-slider.MuiBox-root {
  border-radius: 0 !important;
}
.common-textarea-input .MuiFormControl-root,
.common-textarea-input .MuiInputBase-root {
  min-height: 44px;
  height: 100%;
  padding: 0;
}
.question-seller-tab .common-textarea-input .MuiFormControl-root,
.question-seller-tab .common-textarea-input .MuiInputBase-root {
  width: 100%;
}

.common-textarea-input .MuiInputBase-root textarea {
  padding: 0 20px !important;
  min-height: 28px;
  height: 100%;
  line-height: 1.6;
}
.font1622 {
  font-size: 16px;
  line-height: 22px;
}

.common-light-button {
  background-color: #e2f2ff;
  color: #2170ff;
}
.common-border-button {
  background-color: #ffffff;
  color: #2170ff;
  border: 1px solid #2170ff;
}
.text-white {
  color: #ffffff;
}

.bg-grey {
  background-color: #f8fcff;
}
.bg-primary {
  background-color: #2170ff;
}
.side-bar-container .MuiListItemIcon-root img {
  object-fit: contain !important;
  max-width: 38px;
}
.d-block {
  display: block;
}
.common-attachment-btn {
  text-transform: capitalize;
  border: 1px #2170ff solid;
  background-color: #2170ff;
  color: #fff;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 0.6em 1.4em;
  border-radius: 10px;
  margin-left: 12px;
  height: 48px;
  cursor: pointer;
}
.common-attachment-btn.outline {
  color: #2170ff !important;
  background-color: #fff !important;
}

.button-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.bg-skyBlue {
  background-color: #f0f5ff !important;
}

.select-label-margin {
  margin-top: -9px !important;
}

.common-title {
  color: #2170ff;
  font-size: 24px;
  line-height: 33px;
  font-weight: 700;
}
.divider-title {
  width: 100%;
  height: 5px;
  border-top: 1px solid #eeeeee;
}
.MuiSelect-select:focus {
  background-color: transparent !important;
}
.link-outline-none {
  text-decoration: none !important;
  color: #000000de !important;
}

.loading-progress-spinner-container .overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1400;
}

.loading-progress-spinner-container .absolute {
  position: fixed;
  background: #fff;
  border-radius: 12px;
  z-index: 1400;
  top: 46vh;
  left: 46vw;
}

@-webkit-keyframes spin-animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes spin-animation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.spinner div {
  left: 65.075px;
  top: 32.195px;
  position: absolute;
  -webkit-animation: spin-animation linear 1.8518518518518516s infinite;
          animation: spin-animation linear 1.8518518518518516s infinite;
  background: #3336cc;
  width: 6.8500000000000005px;
  height: 15.07px;
  border-radius: 3.4250000000000003px / 3.6168000000000005px;
  -webkit-transform-origin: 3.4250000000000003px 36.305px;
          transform-origin: 3.4250000000000003px 36.305px;
}

.spinner div:nth-child(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-animation-delay: -1.6975308641975309s;
          animation-delay: -1.6975308641975309s;
  background: #3336cc;
}

.spinner div:nth-child(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  -webkit-animation-delay: -1.5432098765432098s;
          animation-delay: -1.5432098765432098s;
  background: #2a5cec;
}

.spinner div:nth-child(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
  -webkit-animation-delay: -1.3888888888888888s;
          animation-delay: -1.3888888888888888s;
  background: #216fff;
}

.spinner div:nth-child(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation-delay: -1.2345679012345678s;
          animation-delay: -1.2345679012345678s;
  background: #0882ff;
}

.spinner div:nth-child(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
  -webkit-animation-delay: -1.0802469135802468s;
          animation-delay: -1.0802469135802468s;
  background: #0092ff;
}

.spinner div:nth-child(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
  -webkit-animation-delay: -0.9259259259259258s;
          animation-delay: -0.9259259259259258s;
  background: #1ca3ff;
}

.spinner div:nth-child(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-animation-delay: -0.7716049382716049s;
          animation-delay: -0.7716049382716049s;
  background: #53b4ff;
}

.spinner div:nth-child(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
  -webkit-animation-delay: -0.6172839506172839s;
          animation-delay: -0.6172839506172839s;
  background: #8bcaff;
}

.spinner div:nth-child(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
  -webkit-animation-delay: -0.4629629629629629s;
          animation-delay: -0.4629629629629629s;
  background: #badeff;
}

.spinner div:nth-child(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  -webkit-animation-delay: -0.30864197530864196s;
          animation-delay: -0.30864197530864196s;
  background: #3336cc;
}

.spinner div:nth-child(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
  -webkit-animation-delay: -0.15432098765432098s;
          animation-delay: -0.15432098765432098s;
  background: #2a5cec;
}

.spinner div:nth-child(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  background: #216fff;
}

.loadingio-spinner-spinner-box {
  width: 137px;
  height: 137px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.spinner {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform: translateZ(0) scale(1);
          transform: translateZ(0) scale(1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0; /* see note above */
}

.spinner div {
  box-sizing: content-box;
}

/* @import url("https://fonts.googleapis.com/css2?family=Raleway:wght@1,600&display=swap"); */

.auth-box-container {
  background: transparent
    linear-gradient(180deg, -p- 0%, #3336cc 100%) 0% 0% no-repeat
    padding-box;
  background: transparent
    linear-gradient(180deg, var(---2170ff-p-) 0%, #3336cc 100%) 0% 0% no-repeat
    padding-box;
  display: flex;
  min-height: 100vh;
  align-items: center;
}

.auth-box-container .text-logo-box-container {
  position: relative;
  display: none;
  text-align: -webkit-center;
  text-align: center;
}

@media screen and (min-width: 1280px) {
  .auth-box-container .text-logo-box-container {
    display: block;
    text-align: center;
  }
}

.auth-box-container .text-logo-box {
  /* background: #94c8f157; */
  padding: 33px 0px;
  border-radius: 100px 0px;
  /* width: 330px; */
  text-transform: lowercase;
}

.auth-box-container .text-logo-box span {
  font-family: "Raleway", sans-serif !important;
  font-weight: 700;
  font-size: 72px;
  letter-spacing: 4px;
  color: #fff;
  opacity: 1;
  text-transform: lowercase;
  padding-left: 12px;
}

@media screen and (min-width: 1475px) {
  .auth-box-container .text-logo-box {
    /* width: 410px; */
    height: 165px;
  }
}

.auth-box-container .text-logo-img {
  position: absolute;
  top: 71px;
  -webkit-transform: translate(-50%, -25%);
          transform: translate(-50%, -25%);
  height: 40px;
}

@media screen and (min-width: 1475px) {
  .auth-box-container .text-logo-img {
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
    height: 42px;
  }
}

.auth-box-container .face-logo {
  text-align: center;
  margin-bottom: 5em;
}

.auth-box-container .face-logo img {
  /* width: 480px;
  height: 380px;
  text-align: -webkit-right; */
  width: 60%;
}

@media screen and (min-width: 1475px) {
  .auth-box-container .face-logo {
    /* width: 525px; */
    height: 380px;
  }
}

.auth-box-container .form-box-container {
  background-color: #fff;
  padding: 50px 70px;
  border-radius: 30px;
  margin-top: 25px;
}

.auth-box-container .form-box-container .footer {
  font-size: 12px;
}

@media screen and (min-width: 1475px) {
  .auth-box-container .form-box-container .footer {
    font-size: 16px;
  }
}

.auth-box-container .form-box-container .form-banner-container .main-title {
  text-align: center;
  font-size: 22px;
  color: var(---424143);
  font-weight: 600;
}

@media screen and (min-width: 1475px) {
  .auth-box-container .form-box-container .form-banner-container .main-title {
    font-size: 32px;
  }
}

.auth-box-container .form-box-container .form-banner-container .sub-title {
  font-size: 14px;
  color: var(---424143);
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  border-left: 1px solid var(---424143);
  padding: 14px 0px;
}
.auth-box-container
  .form-box-container
  .form-banner-container
  .singup-sub-title {
  font-size: 14px;
  color: -p-;
  color: var(---2170ff-p-);
  /* text-transform: uppercase; */
  text-align: center;

  padding: 14px 0px 0px;
}

@media screen and (min-width: 1475px) {
  .auth-box-container
    .form-box-container
    .form-banner-container
    .singup-sub-title {
    font-size: 16px;
  }
}
@media screen and (min-width: 1475px) {
  .auth-box-container .form-box-container .form-banner-container .sub-title {
    font-size: 16px;
  }
}

.auth-box-container a {
  text-decoration: none;
  color: -p-;
  color: var(---2170ff-p-);
}

.auth-box-container .form-box-container form {
  margin-top: 2em;
}

@media screen and (min-width: 1475px) {
  .auth-box-container .form-box-container form {
    margin-top: 4em;
  }
}

.auth-box-container
  .form-box-container
  .mui-input
  .MuiOutlinedInput-notchedOutline {
  border-radius: 30px;
}

.auth-box-container .form-box-container .mui-input .MuiSvgIcon-root {
  width: 16px;
  height: 16px;
  margin: 0px 14px 0px 12px;
}

.auth-box-container .form-box-container .remember-me-box-container {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: var(---a19b9d);
}

.auth-box-container .form-box-container .mui-input {
  min-height: 55px !important;
}
.auth-box-container .form-box-container .mui-select {
  min-height: 55px !important;
}

.auth-box-container .form-box-container .mui-input .MuiInputBase-root {
  height: 55px !important;
}

.auth-box-container .form-box-container .mui-input textarea:first-of-type {
  max-height: 20px !important;
  height: 20px !important;
}

.auth-box-container .form-box-container .mui-select {
  width: 100%;
}

.auth-box-container .form-box-container .mui-select .MuiInputBase-root {
  border-radius: 30px !important;
  height: 55px !important;
}

.auth-box-container .form-box-container .error-msg {
  color: red;
  font-size: 11px;
  padding-left: 5px;
}

.auth-box-container .form-box-container .submit-btn {
  background-color: -p-;
  background-color: var(---2170ff-p-);
  border-radius: 30px;
  color: #fff;
  height: 55px !important;
}

.auth-box-container .form-box-container .submit-btn.MuiButton-root {
  text-transform: none;
  font-size: 19px;
}

.auth-box-container .form-box-container .checkbox-container {
  min-height: 45px;
  display: flex;
  align-items: center;
  padding: 8px;
}

.auth-box-container .form-box-container .checkbox-container .MuiCheckbox-root {
  margin-left: 12px;
  color: -p-;
  color: var(---2170ff-p-);
}

.auth-box-container
  .form-box-container
  .checkbox-container
  .MuiTypography-root {
  color: var(---424143);
  font-weight: 600;
  /* white-space: nowrap; */
}

.auth-box-container .form-box-container .checkbox-container .hint-icon {
  width: 16px;
  height: 16px;
  color: -p-;
  color: var(---2170ff-p-);
  margin-left: 10px;
  display: block;
}

.auth-box-container
  .form-box-container
  .checkbox-container
  .MuiFormControl-root {
  height: 45px;
}

.auth-box-container
  .form-box-container
  .checkbox-container
  .MuiFormControlLabel-root {
  min-height: 45px;
}

.auth-box-container .form-box-container .MuiGrid-container .user-tab {
  margin: 14px 0;
  justify-content: center;
}
.auth-box-container .form-box-container .MuiGrid-container .inner-all-tab {
  border-bottom: 1px solid -p-;
  border-bottom: 1px solid var(---2170ff-p-);
}
.signup-tab-body > div {
  padding: 0 !important;
}
.signup-tab-body > div {
  padding: 0 !important;
}
.sub-text-none {
  opacity: 0;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}

.auth-box-container.login
  .form-box-container
  .form-banner-container
  .main-title {
  text-align: center;
}

.auth-box-container.login
  .form-box-container
  .form-banner-container
  .sub-title {
  color: var(---a19b9d);
  border-left: none;
  text-transform: none;
  font-weight: 500;
}

.invite-icon-status {
  display: flex;
  justify-content: center;
}
.invite-icon-status .invited-icon {
  width: 60px;
  height: 60px;
}

@media screen and (min-width: 1071px) and (max-width: 1279px) {
  .auth-box-container.login .face-logo {
    width: 400px;
    height: 380px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1070px) {
  .auth-box-container.login .face-logo {
    width: 360px;
    height: 380px;
  }
}
.form-banner-container .invitation-data {
  display: flex;
  justify-content: center;
}

.auth-box-container.reset-forgot
  .form-box-container
  .form-banner-container
  .main-title {
  text-align: center;
}

.auth-box-container.reset-forgot
  .form-box-container
  .form-banner-container
  .sub-title {
  color: var(---a19b9d);
  border-left: none;
  text-transform: none;
  font-weight: 500;
}

@media screen and (min-width: 1071px) and (max-width: 1279px) {
  .auth-box-container.reset-forgot .face-logo {
    width: 400px;
    height: 380px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1070px) {
  .auth-box-container.reset-forgot .face-logo {
    width: 360px;
    height: 380px;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:focus,
.btn:hover {
  text-decoration: none;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

/* Primary  */
.btn-primary {
  color: #fff;
  background-color: -p-;
  background-color: var(---2170ff-p-);
  border-color: -p-;
  border-color: var(---2170ff-p-);
}

.btn-primary:hover {
  /* need to be more bold  */
  background-color: -p-;
  background-color: var(---2170ff-p-);
  /* need to be more bold  */
  border-color: -p-;
  border-color: var(---2170ff-p-);
}

.btn-outline-primary {
  color: -p-;
  color: var(---2170ff-p-);
  background-color: transparent;
  background-image: none;
  border-color: -p-;
  border-color: var(---2170ff-p-);
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 50%);
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: -p-;
  background-color: var(---2170ff-p-);
  border-color: -p-;
  border-color: var(---2170ff-p-);
}

.btn-primary-lite {
  color: -p- !important;
  color: var(---2170ff-p-) !important;
  background-color: var(---e2f2ff) !important;
  border: 2px solid var(---e2f2ff) !important;
}

/* success  */
.btn-success {
  color: #fff;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}

.btn-light-blue {
  background-color: var(---e2feff) !important;
  border-color: var(---e2feff) !important;
  color: #1976d2 !important;
}

/* white  */
.btn-white {
  color: -p- !important;
  color: var(---2170ff-p-) !important;
  background-color: #fff !important;
  border: 2px solid #fff !important;
}

.btn-white.active {
  border-color: -p- !important;
  border-color: var(---2170ff-p-) !important;
}

.btn-danger {
  color: #fff !important;
  background-color: var(---c82333) !important;
  border: 2px solid var(---c82333) !important;
}

.btn.draft {
  color: var(---424143);
  background-color: var(---f1f0f2);
  border-color: var(---f1f0f2);
}

/* .btn-primary:hover {
  background-color: var(---2170ff-p-);
  border-color: var(---2170ff-p-);
} */

/* MUI Button Custome Style  */
.btn-mui {
  padding: 0.4rem 2rem !important;
  border-radius: 12px !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  min-height: 48px;

  text-transform: capitalize;
}

.setting-button {
  color: #fff !important;
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
  padding: 0.6em 1.4em !important;
  border-radius: 10px !important;
  margin-left: 12px !important;
  height: 48px !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
}

.btn-mui-table-option.MuiButton-root {
  text-transform: capitalize;
  border: 1px #2170ff solid;
  padding: 0.6em 1.4em;
  border-radius: 10px;
  margin-left: 12px;
  height: 48px;
}

.btn-mui-table-option.outline.MuiButton-root {
  text-transform: capitalize;
  border: none;
  padding: 0px;
  margin-left: 12px;
  color: var(---424143);
  background-color: transparent;
  margin-right: 40px;
  font-weight: 600;
}

.btn-mui-table-option.outline .MuiButton-endIcon {
  margin-left: 5px !important;
}

/* MUI Button Custome Style  */

.badge {
  border-radius: 3px;
  font-size: 0.9em;
  padding: 0.5em 0.7em;
}

.badge.badge-info {
  background: -p- 0% 0% no-repeat padding-box;
  background: var(---83c9f4-p-) 0% 0% no-repeat padding-box;
  border-color: -p-;
  border-color: var(---83c9f4-p-);
  color: #fff;
}

.badge.badge-success {
  background: var(---def8f3) 0% 0% no-repeat padding-box !important;
  border-color: var(---def8f3) !important;
  color: -s- !important;
  color: var(---00d4b5-s-) !important;
}

.badge.badge-secondary {
  background: var(---f1f0f2) 0% 0% no-repeat padding-box !important;
  border-color: var(---f1f0f2) !important;
  color: var(---a19b9d) !important;
}

.badge.badge-primary {
  background: -p- 0% 0% no-repeat padding-box !important;
  background: var(---2170ff-p-) 0% 0% no-repeat padding-box !important;
  border-color: -p- !important;
  border-color: var(---2170ff-p-) !important;
  color: #fff !important;
}

.badge.badge-primary-outline {
  background: var(---e2f2ff) 0% 0% no-repeat padding-box !important;
  border-color: var(---e2f2ff) !important;
  color: -p- !important;
  color: var(---2170ff-p-) !important;
}

.badge.badge-warning {
  background: var(---ffc107) 0% 0% no-repeat padding-box;
  border-color: var(---ffc107);
  color: #fff;
}

.badge.badge-warning-outline {
  background: var(---fcf6e5) 0% 0% no-repeat padding-box;
  border-color: var(---ffc107);
  color: var(---ffc107);
}

.badge.badge-purple {
  background: var(---dee2ff) 0% 0% no-repeat padding-box !important;
  border-color: var(---dee2ff) !important;
  color: var(---575ba7) !important;
}

.badge.badge-danger {
  background: var(---c82333) 0% 0% no-repeat padding-box;
  border-color: var(---c82333);
  color: #fff;
}

.badge.badge-danger-outline {
  background: var(---fbeaec) 0% 0% no-repeat padding-box;
  border-color: var(---fbeaec);
  color: var(---c82333);
}

/* Popup Design  */

/* first-step popup form */
[aria-label="create-auction-first-step-buyer-aspect"] .popup-form-container {
  width: 80vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="create-auction-first-step-buyer-aspect"] .popup-form-container {
    width: 60vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="create-auction-first-step-buyer-aspect"] .popup-form-container {
    width: 80vw;
  }
}

[aria-label="create-auction-first-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiButtonBase-root {
  position: absolute;
  left: 9px;
  color: -p-;
  color: var(---2170ff-p-);
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

[aria-label="create-auction-first-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiInputBase-root {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 26px;
}
[aria-label="create-auction-first-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiInputBase-input {
  margin-left: 10px;
}

[aria-label="create-auction-first-step-buyer-aspect"] .btn-mui {
  font-size: 16px !important;
}
/* first-step popup form */

.auction-confirm-dailog-title {
    text-align: center;
}

.auction-confirm-dailog-main-div {
    padding: 30px !important;
}

.auctio-dailog-content {
    padding: 0px !important;
}

.color-red {
    background-color: #DC3545 !important;
    width: 200px;
}

.custom-color {
    background-color: #E2F2FF !important;
    color: #2170ff !important;
    width: 200px;
}

.mt-20 {
    padding-top: 30px;
}
/* Popup Design  */

/* second-step popup form */
[aria-label="create-auction-second-step-buyer-aspect"] .popup-form-container {
  width: 80vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="create-auction-second-step-buyer-aspect"] .popup-form-container {
    width: 60vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="create-auction-second-step-buyer-aspect"] .popup-form-container {
    width: 80vw;
  }
}

[aria-label="create-auction-second-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiButtonBase-root {
  position: absolute;
  left: 0;
  color: -p-;
  color: var(---2170ff-p-);
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

[aria-label="create-auction-second-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiInputBase-root {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 26px;
}

[aria-label="create-auction-second-step-buyer-aspect"] .btn-mui {
  font-size: 16px !important;
}

[aria-label="create-auction-second-step-buyer-aspect"] .short-field {
  font-size: 16px !important;
}

@media (min-width: 960px) {
  [aria-label="create-auction-second-step-buyer-aspect"]
    .short-field.MuiGrid-grid-sm-6 {
    max-width: 49%;
    flex-basis: 49%;
  }
}

@media ((min-width: 600px) and (max-width: 899px)) {
  [aria-label="create-auction-second-step-buyer-aspect"]
    .short-field.MuiGrid-grid-sm-6 {
    max-width: 50%;
    flex-basis: 50%;
  }
}

@media (max-width: 599px) {
  [aria-label="create-auction-second-step-buyer-aspect"]
    .short-field.MuiGrid-grid-sm-6 {
    max-width: 100%;
    flex-basis: 100%;
  }
}

[aria-label="create-auction-second-step-buyer-aspect"] .awarding-check-alert {
  background-color: var(---e2f2ff);
  padding: 20px !important;
  border-radius: 12px;
  margin: 1em 7px 1em 7px;
  margin-top: 22px;
}

[aria-label="create-auction-second-step-buyer-aspect"]
  .awarding-check-alert
  .checkbox {
  margin-right: 14px;
}

[aria-label="create-auction-second-step-buyer-aspect"]
  .awarding-check-alert
  .text {
  font-size: 14px;
  color: var(---707070);
}

[aria-label="create-auction-second-step-buyer-aspect"]
  .awarding-check-alert
  .label {
  font-weight: 600;
}

[aria-label="create-auction-second-step-buyer-aspect"]
  .awarding-check-alert
  .MuiCheckbox-root {
  padding-top: 0;
}

[aria-label="create-auction-second-step-buyer-aspect"] .section-title {
  font-size: 18px;
  color: var(---424143);
  font-weight: 600;
  margin-top: 10px;
}

[aria-label="create-auction-second-step-buyer-aspect"] .collabse-btn {
  font-size: 18px;
  color: var(---424143);
  font-weight: 600;
  margin-top: 10px;
}

[aria-label="create-auction-second-step-buyer-aspect"] .checkbox-container {
  min-height: 45px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px;
}

[aria-label="create-auction-second-step-buyer-aspect"]
  .checkbox-container
  .MuiCheckbox-root {
  margin-left: 12px;
  color: -p-;
  color: var(---2170ff-p-);
}

[aria-label="create-auction-second-step-buyer-aspect"]
  .checkbox-container
  .MuiTypography-root {
  color: var(---424143);
  font-weight: 600;
  /* white-space: nowrap; */
}

[aria-label="create-auction-second-step-buyer-aspect"]
  .checkbox-container
  .hint-icon {
  width: 16px;
  height: 16px;
  color: -p-;
  color: var(---2170ff-p-);
  margin-left: 10px;
  display: block;
}
.reserve-price-part .hint-icon {
  width: 16px;
  height: 16px;
  color: -p-;
  color: var(---2170ff-p-);
  margin-left: 10px;
  display: block;
  margin-top: 2px;
}
[aria-label="create-auction-second-step-buyer-aspect"]
  .checkbox-container
  .MuiFormControl-root {
  height: 45px;
}

[aria-label="create-auction-second-step-buyer-aspect"]
  .checkbox-container
  .MuiFormControlLabel-root {
  min-height: 45px;
}

/*  height animation */

[aria-label="create-auction-second-step-buyer-aspect"] .animate-height.__hide {
  transition: height 0.5s;
}

[aria-label="create-auction-second-step-buyer-aspect"] .animate-height.__feed {
  transition: height 0.5s;
}

[aria-label="create-auction-second-step-buyer-aspect"] .animate-opacity.__hide {
  width: 0;
}

[aria-label="create-auction-second-step-buyer-aspect"] .animate-opacity.__feed {
  width: 100%;
}
/* second-step popup form */
.suplier-bid-checkbox.disable-supplier-bid {
  pointer-events: none;
  opacity: 0.4;
}

.delivery-date .Mui-error.MuiFormHelperText-sizeMedium {
  color: #f44336 !important;
  margin-left: 0 !important;
}

.sellers-link-container {
  display: flex;
  color: var(---2a5cec);
  cursor: pointer;
}

.sellers-link-container .label {
  text-decoration: underline;
  font-size: 15px;
}

.sellers-link-container .icon {
  color: var(---2a5cec) !important;
  width: 20px;
  height: 20px;
}

.invite-seller-box .label {
  font-size: 12px;
  color: #a19b9d;
}

.invite-seller-box .title {
  display: flex;
}

.invite-seller-box .title .icon-cotainer {
  border-radius: 50%;
  margin: auto 0px;
}

.invite-seller-box .title .icon-cotainer .icon {
  width: 24px;
  height: 24px;
  background-color: -p-;
  background-color: var(---2170ff-p-);
  border-radius: 50%;
  padding: 5px;
  vertical-align: sub;
}

.invite-seller-box .title .text {
  color: -p-;
  color: var(---2170ff-p-);
  margin: auto;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 400;
}

.invite-seller-box .seller-check-box-container.MuiGrid-item {
  padding-top: 0px;
  padding-bottom: 0px;
}

.invite-seller-box .seller-check-box {
  display: inline-flex;
  padding: 14px !important;
  border: 1px rgba(0, 0, 0, 0.2) solid;
  width: 100%;
  justify-content: space-between;
  border-bottom: none;
}

.invite-seller-box
  div.seller-check-box-container:first-of-type
  .seller-check-box {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.invite-seller-box
  div.seller-check-box-container:last-of-type
  .seller-check-box {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom: 1px rgba(0, 0, 0, 0.2) solid;
}

.invite-seller-box .seller-check-box .name-container {
  display: flex;
}

.invite-seller-box .seller-check-box .name-container .icon {
  width: 24px;
  height: 24px;
  background-color: var(---a19b9d);
  display: block;
  border-radius: 50%;
}

.invite-seller-box .seller-check-box .name-container .name {
  font-size: 16px;
  color: var(---424143);
  margin-left: 8px;
}

/* Popup Design  */

/* third-step popup form */
[aria-label="create-auction-third-step-buyer-aspect"] .popup-form-container {
  width: 80vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="create-auction-third-step-buyer-aspect"] .popup-form-container {
    width: 60vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="create-auction-third-step-buyer-aspect"] .popup-form-container {
    width: 80vw;
  }
}

[aria-label="create-auction-third-step-buyer-aspect"]
  .image-uploader-container
  .uploader-header
  .upload-btn {
  padding: 0px 14px;
  min-width: 132px;
}

[aria-label="create-auction-third-step-buyer-aspect"]
  .image-uploader-container
  .uploader-header
  .upload-btn.add-link {
  color: #fff;
  background-color: -p-;
  background-color: var(---2170ff-p-);
}

[aria-label="create-auction-third-step-buyer-aspect"]
  .image-uploader-container
  .uploader-header
  .upload-btn
  .MuiButton-endIcon {
  margin-left: 0px !important;
}

[aria-label="create-auction-third-step-buyer-aspect"] .btn-mui {
  font-size: 16px !important;
}

/* third-step popup form */

/* Upload Option List  */
[id="create-auction-third-step-buyer-aspect-upload-file-options-menu"]
  .MuiMenu-list {
  min-width: 227px;
}

[id="create-auction-third-step-buyer-aspect-upload-file-options-menu"]
  .MuiMenu-list
  .MuiMenuItem-root {
  font-size: 16px !important;
  padding: 11px 14px;
  display: block;
}

/* Upload Option List  */

/* Upload Link Popup  */

[aria-label="create-auction-third-step-buyer-aspect-upload-link-file"]
  .popup-form-container {
  width: 30vw;
}

/* for meduim screen  */
@media screen and (max-width: 1200px) {
  [aria-label="create-auction-third-step-buyer-aspect-upload-link-file"]
    .popup-form-container {
    width: 50vw;
  }
}

/* for small screen  */
@media screen and (max-width: 960px) {
  [aria-label="create-auction-third-step-buyer-aspect-upload-link-file"]
    .popup-form-container {
    width: 70vw;
  }
}

/* Upload Link Popup  */

[aria-label="attachment-from-lib-popup-buyer-aspect"] .popup-form-container {
  width: 80vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="attachment-from-lib-popup-buyer-aspect"] .popup-form-container {
    width: 55vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="attachment-from-lib-popup-buyer-aspect"] .popup-form-container {
    width: 80vw;
  }
}

/* Supplier table  */

[aria-label="attachment-from-lib-popup-buyer-aspect"] table tbody,
[aria-label="attachment-from-lib-popup-buyer-aspect"] table thead,
[aria-label="attachment-from-lib-popup-buyer-aspect"] table th,
[aria-label="attachment-from-lib-popup-buyer-aspect"] table tr,
[aria-label="attachment-from-lib-popup-buyer-aspect"] table td {
  display: block;
  max-width: none !important;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"] table tr:after {
  content: " ";
  display: block;
  visibility: hidden;
  clear: both;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"] table tbody {
  height: 41vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #dbdbdb rgba(0, 0, 0, 0.03);
  scrollbar-width: thin;
  margin-top: 14px;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"] table tr:first-of-type {
  margin-bottom: 0px;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"] table tr {
  min-width: 800px;
  margin-bottom: -2px;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"] table td {
  padding-top: 24px !important;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"] table tbody tr:hover {
  background-color: #fff !important;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"] .MuiTableCell-root {
  border-bottom: 1px solid #e0e0e0 !important;
  float: left;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .MuiTableHead-root
  .MuiTableRow-head
  .MuiTableCell-root {
  /* border-top: 1px solid #e0e0e0 !important; */
  min-height: 58px;
  white-space: nowrap;
}

/* devide grid pixels  */
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(1) {
  width: 50%;
  min-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(2) {
  width: 25%;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(3) {
  width: 25%;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: flex-end;
}
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(3)
  .checkbox {
  margin-top: -30px;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(3)
  .checkbox.header {
  margin-top: -9px;
  margin-right: 15px;
}

/* devide grid pixels  */

/* scroll bar design  */

/* scrollbar */
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-webkit-scrollbar,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-moz-scrollbar,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-moz-scrollbar {
  width: 7px;
  height: 7px;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-ms-scrollbar,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-ms-scrollbar {
  width: 7px;
  height: 7px;
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-o-scrollbar,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-o-scrollbar {
  width: 7px;
  height: 7px;
}
/* scrollbar */

/* scrollbar-track */
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-webkit-scrollbar-track,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-moz-scrollbar-track,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-moz-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-ms-scrollbar-track,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-ms-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-o-scrollbar-track,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-o-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}
/* scrollbar-track */

/* scrollbar-thumb */
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-webkit-scrollbar-thumb,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-moz-scrollbar-thumb,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-moz-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-ms-scrollbar-thumb,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-ms-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}

[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container.MuiTableContainer-root::-o-scrollbar-thumb,
[aria-label="attachment-from-lib-popup-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-o-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}
/* scrollbar-thumb */

/* scroll bar design  */

.import-item-btn {
  background-color: #00d4b5 !important;
  text-decoration: none;
  border-color: #00d4b5 !important;
}

.import-item-btn :hover {
  background-color: #00d4b5 !important;
  border-color: #00d4b5 !important;
}
.supplier-import .browse-file-supplier {
  background-color: #2170ff;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 12px 0 !important;
  border-radius: 12px !important;
  cursor: pointer;
}
.browse-file-button {
  width: 25vw;
}

@media screen and (max-width: 1800px) {
  .browse-file-button {
    width: 25vw;
  }
}

.import-item-footer-description {
  text-align: center;
  color: #bbbbbb;
}

.mt-10 {
  padding-top: 14px;
}

.import-download-icn {
  margin-bottom: -5px;
}

hr.solid {
  border-top: 1px solid #bbb;
}

.hr-devider-import-file {
  width: inherit;
  padding: 20px 0px 10px 0px;
}

.tac {
  text-align: center;
}

.devider-center-text {
  color: #bbbbbb;
}

.pt-15 {
  padding-top: 15px;
}

[aria-label="create-auction-fourth-step-buyer-aspect"] .popup-form-container {
  width: 80vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="create-auction-fourth-step-buyer-aspect"] .popup-form-container {
    width: 60vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="create-auction-fourth-step-buyer-aspect"] .popup-form-container {
    width: 80vw;
  }
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .btn-mui.auto-complete-btn {
  width: 100%;
  border: 1px solid #e0e0e0 !important;
  border-radius: 8px !important;
  min-height: 45px;
  padding: 0px 10px !important;
}

@media screen and (max-width: 600px) {
  [aria-label="create-auction-fourth-step-buyer-aspect"]
    .btn-mui.auto-complete-btn {
    margin-left: 0px;
  }
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .btn-mui.auto-complete-btn
  .MuiButton-label {
  font-size: 16px;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .MuiAutocomplete-root
  .MuiOutlinedInput-notchedOutline {
  border-color: #e0e0e0;
}

[aria-label="create-auction-fourth-step-buyer-aspect"] .MuiAutocomplete-root {
  border-color: #e0e0e0;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .MuiAutocomplete-popupIndicatorOpen {
  color: var(---a19b9d);
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .MuiAutocomplete-popupIndicatorOpen
  .MuiSvgIcon-root {
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
}
/* item  table  */

[aria-label="create-auction-fourth-step-buyer-aspect"] table tbody,
[aria-label="create-auction-fourth-step-buyer-aspect"] table thead,
[aria-label="create-auction-fourth-step-buyer-aspect"] table th,
[aria-label="create-auction-fourth-step-buyer-aspect"] table tr,
[aria-label="create-auction-fourth-step-buyer-aspect"] table td {
  display: block;
  max-width: none !important;
}

[aria-label="create-auction-fourth-step-buyer-aspect"] table tr:after {
  content: " ";
  display: block;
  visibility: hidden;
  clear: both;
}

[aria-label="create-auction-fourth-step-buyer-aspect"] table tbody {
  height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 79px;
  scrollbar-color: #dbdbdb rgba(0, 0, 0, 0.03);
  scrollbar-width: thin;
}

[aria-label="create-auction-fourth-step-buyer-aspect"] table tr {
  min-width: 800px;
  /* margin-bottom: -2px; */
}

[aria-label="create-auction-fourth-step-buyer-aspect"] table tr:first-of-type {
  margin-bottom: -2px;
}

[aria-label="create-auction-fourth-step-buyer-aspect"] table td {
  padding-top: 30px !important;
}

[aria-label="create-auction-fourth-step-buyer-aspect"] table tbody tr:hover {
  background-color: #fff !important;
}

[aria-label="create-auction-fourth-step-buyer-aspect"] .MuiTableCell-root {
  border-bottom: 1px solid #e0e0e0 !important;
  float: left;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .MuiTableCell-root:first-of-type {
  border-left: 1px solid #e0e0e0 !important;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .MuiTableCell-root:last-of-type {
  border-right: 1px solid #e0e0e0 !important;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .MuiTableHead-root
  .MuiTableRow-head
  .MuiTableCell-root {
  border-top: 1px solid #e0e0e0 !important;
  line-height: 0.5rem;
  min-height: 42px;
  white-space: nowrap;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .MuiTableHead-root
  .MuiTableRow-head
  .MuiTableCell-root:first-of-type {
  border-left: 1px solid #e0e0e0 !important;
  border-top-left-radius: 8px;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .MuiTableHead-root
  .MuiTableRow-head
  .MuiTableCell-root:last-of-type {
  border-right: 1px solid #e0e0e0 !important;
  border-top-right-radius: 8px;
}

/* devide grid pixels  */
[aria-label="create-auction-fourth-step-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(1) {
  width: 30%;
  min-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(2) {
  width: 20%;
  min-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(3) {
  width: 20%;
  min-width: 160px;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(4) {
  width: 15%;
  min-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(5) {
  width: 15%;
  min-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* devide grid pixels  */

/* form container */
[aria-label="create-auction-fourth-step-buyer-aspect"]
  #dynamic-item-form-container {
  position: absolute;
  background: #ffffff;
  top: 42px;
  left: 1px;
  min-width: 800px;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  margin-left: -1px;
  width: 100%;
  display: flex;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  #dynamic-item-form-container
  .MuiTableCell-root {
  height: 80px;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  #dynamic-item-form-container
  .MuiTableCell-root:nth-of-type(1) {
  border-left: none !important;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  #dynamic-item-form-container
  .MuiTableCell-root:nth-of-type(5) {
  border-right: none !important;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  #dynamic-item-form-container
  .MuiOutlinedInput-notchedOutline {
  border-color: #e0e0e0;
  border-width: 1px;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  #dynamic-item-form-container
  .add-icon {
  width: 24px;
  height: 24px;
  color: -p-;
  color: var(---2170ff-p-);
  margin-top: 12px !important;
  cursor: pointer;
  margin-left: 21px !important;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  #dynamic-item-form-container
  .add-btn {
  background: transparent;
  min-width: 0px !important;
  width: 0px;
  min-height: 0px !important;
  padding: 0px !important;
  margin: 0 !important;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  #dynamic-item-form-container
  .MuiFormControl-root
  .MuiFormHelperText-contained {
  margin-right: 0px;
  margin-top: 0px;
}

/* form container */

/* editable row design  */
[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container.editable-table
  .editable-row
  .editable-field.__active {
  display: block !important;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container.editable-table
  .editable-row
  .editable-field.__active
  .MuiInputBase-root {
  margin-top: -13px;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container.editable-table
  .editable-row
  .editable-field.__active
  .MuiInputBase-root {
  min-width: 0px;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container.editable-table
  .editable-row
  .option-cell.__active {
  justify-content: center;
}
/* editable row design  */

/* scroll bar design  */

/* scrollbar  */

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-webkit-scrollbar,
[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-moz-scrollbar,
[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-moz-scrollbar {
  width: 7px;
  height: 7px;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-ms-scrollbar,
[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-ms-scrollbar {
  width: 7px;
  height: 7px;
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-o-scrollbar,
[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-o-scrollbar {
  width: 7px;
  height: 7px;
}
/* scrollbar  */

/* scrollbar-track */

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-webkit-scrollbar-track,
[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-moz-scrollbar-track,
[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-moz-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-ms-scrollbar-track,
[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-ms-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-o-scrollbar-track,
[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-o-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-moz-scrollbar-track,
[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-moz-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}
/* scrollbar-track */

/* scrollbar-thumb */

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-webkit-scrollbar-thumb,
[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-moz-scrollbar-thumb,
[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-moz-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-ms-scrollbar-thumb,
[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-ms-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}

[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-o-scrollbar-thumb,
[aria-label="create-auction-fourth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-o-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}
/* scrollbar-thumb */

/* scroll bar design  */

/* item table  */

[aria-label="create-auction-fourth-step-buyer-aspect"] .BottomModal .btn-mui {
  font-size: 16px !important;
}

[aria-label="create-auction-fifth-step-buyer-aspect"] .popup-form-container {
  width: 80vw;
}
[aria-label="modal-add-document-from-library"] .popup-form-container {
  width: 50vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="create-auction-fifth-step-buyer-aspect"] .popup-form-container {
    width: 60vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="create-auction-fifth-step-buyer-aspect"] .popup-form-container {
    width: 80vw;
  }
}

/* auto-complete section  */

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .btn-mui.auto-complete-btn {
  width: 100%;
  border: 1px solid #e0e0e0 !important;
  border-radius: 8px !important;
  min-height: 45px;
  padding: 0px 10px !important;
}

@media screen and (max-width: 600px) {
  [aria-label="create-auction-fifth-step-buyer-aspect"]
    .btn-mui.auto-complete-btn {
    margin-left: 0px;
  }
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .btn-mui.auto-complete-btn
  .MuiButton-label {
  font-size: 16px;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiAutocomplete-root
  .MuiOutlinedInput-notchedOutline {
  border-color: #e0e0e0;
}

[aria-label="create-auction-fifth-step-buyer-aspect"] .MuiAutocomplete-root {
  border-color: #e0e0e0;
}

[aria-label="create-auction-fifth-step-buyer-aspect"] .MuiAutocomplete-input {
  padding: 0px !important;
  padding-left: 12px !important;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiAutocomplete-popupIndicatorOpen {
  color: var(---a19b9d);
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiAutocomplete-popupIndicatorOpen
  .MuiSvgIcon-root {
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
}

/* auto-complete section  */

/* Supplier table  */

[aria-label="create-auction-fifth-step-buyer-aspect"] table tbody,
[aria-label="create-auction-fifth-step-buyer-aspect"] table thead,
[aria-label="create-auction-fifth-step-buyer-aspect"] table th,
[aria-label="create-auction-fifth-step-buyer-aspect"] table tr,
[aria-label="create-auction-fifth-step-buyer-aspect"] table td {
  display: block;
  max-width: none !important;
}

[aria-label="create-auction-fifth-step-buyer-aspect"] table tr:after {
  content: " ";
  display: block;
  visibility: hidden;
  clear: both;
}

[aria-label="create-auction-fifth-step-buyer-aspect"] table tbody {
  height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #dbdbdb rgba(0, 0, 0, 0.03);
  scrollbar-width: thin;
}

[aria-label="create-auction-fifth-step-buyer-aspect"] table tr:first-of-type {
  margin-bottom: 0px;
}

[aria-label="create-auction-fifth-step-buyer-aspect"] table tr {
  min-width: 800px;
  margin-bottom: -2px;
}

[aria-label="create-auction-fifth-step-buyer-aspect"] table td {
  padding-top: 30px !important;
}

[aria-label="create-auction-fifth-step-buyer-aspect"] table tbody tr:hover {
  background-color: #fff !important;
}

[aria-label="create-auction-fifth-step-buyer-aspect"] .MuiTableCell-root {
  border-bottom: 1px solid #e0e0e0 !important;
  float: left;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiTableCell-root:first-of-type {
  border-left: 1px solid #e0e0e0 !important;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiTableCell-root:last-of-type {
  border-right: 1px solid #e0e0e0 !important;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiTableHead-root
  .MuiTableRow-head
  .MuiTableCell-root {
  border-top: 1px solid #e0e0e0 !important;
  line-height: 0.5rem;
  min-height: 42px;
  white-space: nowrap;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiTableHead-root
  .MuiTableRow-head
  .MuiTableCell-root:first-of-type {
  border-left: 1px solid #e0e0e0 !important;
  border-top-left-radius: 8px;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiTableHead-root
  .MuiTableRow-head
  .MuiTableCell-root:last-of-type {
  border-right: 1px solid #e0e0e0 !important;
  border-top-right-radius: 8px;
}

/* devide grid pixels  */
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(1) {
  width: 35%;
  min-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(2) {
  width: 41%;
  min-width: 328px;
  overflow: hidden;
  text-overflow: ellipsis;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(3) {
  width: 12%;
  min-width: 96px;
  overflow: hidden;
  text-overflow: ellipsis;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(4) {
  width: 12%;
  min-width: 96px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* devide grid pixels  */

/* scroll bar design  */

/* scrollbar */
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-webkit-scrollbar,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-moz-scrollbar,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-moz-scrollbar {
  width: 7px;
  height: 7px;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-ms-scrollbar,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-ms-scrollbar {
  width: 7px;
  height: 7px;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-o-scrollbar,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-o-scrollbar {
  width: 7px;
  height: 7px;
}
/* scrollbar */

/* scrollbar-track */

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-webkit-scrollbar-track,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-moz-scrollbar-track,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-moz-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-ms-scrollbar-track,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-ms-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-o-scrollbar-track,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-o-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

/* scrollbar-track */

/* scrollbar-thumb */
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-webkit-scrollbar-thumb,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-moz-scrollbar-thumb,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-moz-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-ms-scrollbar-thumb,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-ms-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-o-scrollbar-thumb,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-o-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}
/* scrollbar-thumb */

/* scroll bar design  */

/* Supplier table  */

[aria-label="create-auction-fifth-step-buyer-aspect"] .BottomModal .btn-mui {
  font-size: 16px !important;
}

.support-drawer {
  max-height: 100vh;
  overflow: auto;
}
.support-drawer .support-header-body {
  padding: 30px 32px 10px;
}
.support-drawer .support-body {
  padding: 32px;
}
.support-drawer .MuiTextField-root,
.support-drawer .core-select {
  width: 100% !important;
  margin: 0 !important;
}
.support-drawer .MuiFormLabel-root {
  padding-bottom: 6px;
  color: #424143;
  font-weight: 600;
}

.support-drawer-appbar .collapsed-overlayed-page-contaier {
  z-index: 1200 !important;
}
.support-drawer .common-attachment-btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.dashboard-statistics-container .dashboard-statistics-box {
  padding: 25px;
}

.dashboard-statistics-container .dashboard-statistics-box .icon {
  /* width: 55px;
  height: 55px; */
  width: 55px;
  max-width: 100%;
  height: auto;
}

.dashboard-statistics-container .dashboard-statistics-box .icon.small {
  width: 75px;
  height: 75px;
}

.dashboard-statistics-container .dashboard-statistics-box .details {
  font-weight: 700;
  margin-left: 0.8em;
  text-align: center;
}

.dashboard-statistics-container .dashboard-statistics-box .details .label {
  font-size: 1.2em;
  color: -p-;
  color: var(---2170ff-p-);
}

.dashboard-statistics-container .dashboard-statistics-box .details .label a {
  text-decoration: none;
  color: inherit;
}

.dashboard-statistics-container .dashboard-statistics-box .details .value {
  font-size: 2.2em;
}

.table-container.buyer-aspect .aution-img-container {
  height: 3em;
  border: 2px transparent solid;
  border-radius: 10px;
  margin-right: 2em;
  width: 3em;
  display: block;
}

.table-container.buyer-aspect .aution-img-container img {
  padding: 5px;
  height: 100%;
}

.table-container.buyer-aspect .aution-img-container.blue {
  background-color: -p-;
  background-color: var(---2170ff-p-);
}
.table-container.buyer-aspect .aution-img-container.grey {
  background-color: var(---575ba7);
}
.table-container.buyer-aspect .aution-img-container.green {
  background-color: -s-;
  background-color: var(---00d4b5-s-);
}

.table-container.buyer-aspect .auction-title h3 {
  margin-bottom: 2px;
}

.table-container.buyer-aspect .auction-title p {
  padding-top: 0px !important;
  margin-top: 0px;
  color: var(---a19b9d);
  font-weight: 100;
  font-size: 12px;
}

.table-container.buyer-aspect .auction-datetime {
  white-space: pre-wrap !important;
}

.form2CheckBox label .MuiTypography-root {
  font-weight: 600;
  font-size: 13px;
}

.container .imgPreview.doc_box_auction {
  margin: 0 !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #dbe8ff;
  border: 2px dashed #2170ff;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.container .imgPreview.doc_box_auction .upload-btn {
  background-color: #fff;
  color: #2170ff;
  margin: 20px 0;
  font-size: 13px;
  border-radius: 5em;
  padding: 0.5rem 2rem;
  font-weight: 700;
}

#auction-tab-list .MuiTabs-flexContainer {
  flex-wrap: wrap;
}

[aria-label="create-auction-zero-step-buyer-aspect"]
  .StepControl
  .divider-container,
[aria-label="create-auction-first-step-buyer-aspect"]
  .StepControl
  .divider-container,
[aria-label="create-auction-second-step-buyer-aspect"]
  .StepControl
  .divider-container,
[aria-label="create-auction-third-step-buyer-aspect"]
  .StepControl
  .divider-container,
[aria-label="create-auction-fourth-step-buyer-aspect"]
  .StepControl
  .divider-container,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .StepControl
  .divider-container {
  max-width: 60px;
}

@media screen and (max-width: 960px) {
  [aria-label="create-auction-zero-step-buyer-aspect"]
    .form-stepper-label-container
    .step-text,
  [aria-label="create-auction-first-step-buyer-aspect"]
    .form-stepper-label-container
    .step-text,
  [aria-label="create-auction-second-step-buyer-aspect"]
    .form-stepper-label-container
    .step-text,
  [aria-label="create-auction-third-step-buyer-aspect"]
    .form-stepper-label-container
    .step-text,
  [aria-label="create-auction-fourth-step-buyer-aspect"]
    .form-stepper-label-container
    .step-text,
  [aria-label="create-auction-fifth-step-buyer-aspect"]
    .form-stepper-label-container
    .step-text {
    display: none;
  }
}

/* Popup Design  */

/* first-step popup form */
[aria-label="create-project-first-step-buyer-aspect"] .popup-form-container {
  width: 60vw;
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="create-project-first-step-buyer-aspect"] .popup-form-container {
    width: 80vw;
  }
}

[aria-label="create-project-first-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiButtonBase-root {
  position: absolute;
  left: 9px;
  color: -p-;
  color: var(---2170ff-p-);
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

[aria-label="create-project-first-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiInputBase-root {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 26px;
}

[aria-label="create-project-first-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiInputBase-input {
  margin-left: 10px;
}

[aria-label="create-project-first-step-buyer-aspect"] .btn-mui {
  font-size: 16px !important;
}

[aria-label="create-project-first-step-buyer-aspect"]
  #bottom-button-box-container {
  display: flex;
  justify-content: space-between;
  margin-top: 5em;
}

@media screen and (max-width: 960px) {
  [aria-label="create-project-first-step-buyer-aspect"]
    #bottom-button-box-container {
    display: block;
  }
}

[aria-label="create-project-first-step-buyer-aspect"]
  #bottom-button-box-container
  #right-side {
  min-width: 197px;
}

[aria-label="create-project-first-step-buyer-aspect"]
  #bottom-button-box-container
  #left-side {
  display: flex;
}

@media screen and (max-width: 960px) {
  [aria-label="create-project-first-step-buyer-aspect"]
    #bottom-button-box-container
    #left-side {
    display: block;
  }
}

[aria-label="create-project-first-step-buyer-aspect"]
  #bottom-button-box-container
  #left-side
  > div:first-of-type {
  min-width: 197px;
}

@media screen and (max-width: 960px) {
  [aria-label="create-project-first-step-buyer-aspect"]
    #bottom-button-box-container
    #left-side
    > div:first-of-type {
    margin-top: 10px;
  }
}

[aria-label="create-project-first-step-buyer-aspect"]
  #bottom-button-box-container
  #left-side
  > div:last-of-type {
  min-width: 197px;
  margin-left: 1.25em;
}

@media screen and (max-width: 960px) {
  [aria-label="create-project-first-step-buyer-aspect"]
    #bottom-button-box-container
    #left-side
    > div:last-of-type {
    margin-left: 0;
    margin-top: 10px;
  }
}
/* first-step popup form */

/* Popup Design  */

/* second-step popup form */
[aria-label="create-project-second-step-buyer-aspect"] .popup-form-container {
  width: 60vw;
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="create-project-second-step-buyer-aspect"] .popup-form-container {
    width: 80vw;
  }
}

[aria-label="create-project-second-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiButtonBase-root {
  position: absolute;
  left: 9px;
  color: -p-;
  color: var(---2170ff-p-);
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

[aria-label="create-project-second-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiInputBase-root {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 26px;
}

[aria-label="create-project-second-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiInputBase-input {
  margin-left: 10px;
}

[aria-label="create-project-second-step-buyer-aspect"] .btn-mui {
  font-size: 16px !important;
}

[aria-label="create-project-second-step-buyer-aspect"]
  #bottom-button-box-container {
  display: flex;
  justify-content: space-between;
  margin-top: 5em;
}

@media screen and (max-width: 960px) {
  [aria-label="create-project-second-step-buyer-aspect"]
    #bottom-button-box-container {
    display: block;
  }
}

[aria-label="create-project-second-step-buyer-aspect"]
  #bottom-button-box-container
  #right-side {
  min-width: 197px;
}

[aria-label="create-project-second-step-buyer-aspect"]
  #bottom-button-box-container
  #left-side {
  display: flex;
}

@media screen and (max-width: 960px) {
  [aria-label="create-project-second-step-buyer-aspect"]
    #bottom-button-box-container
    #left-side {
    display: block;
  }
}

[aria-label="create-project-second-step-buyer-aspect"]
  #bottom-button-box-container
  #left-side
  > div:first-of-type {
  min-width: 197px;
}

@media screen and (max-width: 960px) {
  [aria-label="create-project-second-step-buyer-aspect"]
    #bottom-button-box-container
    #left-side
    > div:first-of-type {
    margin-top: 10px;
  }
}

[aria-label="create-project-second-step-buyer-aspect"]
  #bottom-button-box-container
  #left-side
  > div:last-of-type {
  min-width: 197px;
  margin-left: 1.25em;
}

@media screen and (max-width: 960px) {
  [aria-label="create-project-second-step-buyer-aspect"]
    #bottom-button-box-container
    #left-side
    > div:last-of-type {
    margin-left: 0;
    margin-top: 10px;
  }
}
/* second-step popup form */

.projet-card-buyer-aspect {
  height: 24em;
}

.projet-card-buyer-aspect .card-container {
  height: 24em;
  position: relative;
  border: 1px solid transparent;
}

.projet-card-buyer-aspect .card-container.__active {
  border-color: -p-;
  border-color: var(---2170ff-p-);
}

.projet-card-buyer-aspect .card-container .card-image {
  height: 12em;
}

.projet-card-buyer-aspect .card-container .card-image {
  position: absolute;
  top: 0;
  /* object-fit: none; */
}

.projet-card-buyer-aspect .card-container .card-image.__hide {
  opacity: 0.1;
}

.projet-card-buyer-aspect .card-container .option-crud-list {
  position: absolute;
  top: 22px;
  right: 15px;
}

.projet-card-buyer-aspect .card-container .status-crud-list {
  position: absolute;
  top: 15px;
  left: 15px;
}

.projet-card-buyer-aspect .card-container .MuiCardActionArea-root {
  height: 24em;
}

.projet-card-buyer-aspect .card-container .card-content-container {
  /* z-index: 1; */
  position: absolute;
  bottom: 0;
  width: 100%;
}

.projet-card-buyer-aspect .card-container .card-content-container .title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 7px;
}

.projet-card-buyer-aspect .card-container .card-content-container .description {
  font-size: 13px;
}

.projet-card-buyer-aspect .card-container .card-content-container .details {
  margin-top: 10px;
}

.projet-card-buyer-aspect
  .card-container
  .card-content-container
  .details
  .mui-label {
  font-size: 12px !important;
}

.projet-card-buyer-aspect
  .card-container
  .card-content-container
  .details
  .field {
  font-size: 14px;
  font-weight: 600;
}

#project-table-list-buyer-aspect .filter-box-container .filter-box .btn-mui {
  min-height: 45px;
  margin-top: -42px;
  border-radius: 8px !important; 
}

@media screen and (max-width: 899px) {
  #project-table-list-buyer-aspect .filter-box-container .filter-box .btn-mui {
    margin-top: 0px;
  }
}

#project-table-list-buyer-aspect .filter-box-container .filter-box .mui-input {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

#auction-list-filter-box-supplier-aspect .filter-field-container,
#auction-list-filter-box-supplier-aspect
  .filter-box-container
  .filter-field-container {
  align-items: normal;
}

/* .filter-box-container .select-field .MuiButton-root .MuiButton-label {
  white-space: nowrap;
} */
.mandatorySign{
  color: red;
}
.projects-card-container {
  background-color: white;
  border-radius: 12px;
  padding: 24px;
}

.table-container .status.project-status {
  position: absolute;
  top: 10px;
  left: 10px;
}

.table-container .action-list {
  background-color: var(---e2f2ff) !important;
  color: -p- !important;
  color: var(---2170ff-p-) !important;
  border: px solid var(---e2f2ff) !important;
  font-size: 7px !important;
  box-shadow: none !important;
  border-radius: 50% !important;
  padding: 0px 0px 0px 0px !important;
  min-width: 1em !important;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.table-container .action-list .MuiSvgIcon-root {
  width: 30px;
  height: 30px;
  background: var(---f7fafc);
  border-radius: 50%;
  border: 2px var(---f7fafc) solid;
}

.table-container .action-list:hover .MuiSvgIcon-root {
  background: var(---e2f2ff);
  border-color: var(---e2f2ff);
}

.table-container .action-list[aria-controls] .MuiSvgIcon-root {
  border-color: -p-;
  border-color: var(---2170ff-p-);
}

#options-popup-menu .MuiList-root .MuiMenuItem-root {
  font-size: 14px !important;
}

#options-popup-menu .MuiList-root .MuiDivider-root {
  margin: 5px;
}

.project-footer-pagination{
  margin-top: 15px;
}

.pagination-total-container{
  color: var(---a19b9d);
  font-weight: 500;
  font-size: 1em;
}
.f-l{
  float: right;
}
.create-form-container .MuiFormLabel-root {
  font-size: 0.9rem;
  color: var(---a19b9d);
  margin-bottom: 0.5rem;
}

.create-form-container .MuiFormControl-root {
  width: 100% !important;
  margin: 0 !important;
}

.create-form-container .MuiFormControl-root .MuiFormHelperText-contained {
  margin-left: 0px;
  margin-bottom: 7px;
}

.create-form-container .MuiInputBase-root {
  font-weight: 600;
}

/* Popup Design  */

/* first-step popup form */
[aria-label="create-item-first-step-buyer-aspect"] .popup-form-container {
  width: 80vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="create-item-first-step-buyer-aspect"] .popup-form-container {
    width: 60vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="create-item-first-step-buyer-aspect"] .popup-form-container {
    width: 80vw;
  }
}

[aria-label="create-item-first-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiButtonBase-root {
  position: absolute;
  left: 9px;
  color: -p-;
  color: var(---2170ff-p-);
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

[aria-label="create-item-first-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiInputBase-root {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 26px;
}
[aria-label="create-item-first-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiInputBase-input {
  margin-left: 10px;
}

[aria-label="create-item-first-step-buyer-aspect"] .btn-mui {
  font-size: 16px !important;
}

/* bottom buttons  */
[aria-label="create-item-first-step-buyer-aspect"]
  #bottom-button-box-container {
  display: flex;
  justify-content: space-between;
  margin-top: 5em;
}

@media screen and (max-width: 960px) {
  [aria-label="create-item-first-step-buyer-aspect"]
    #bottom-button-box-container {
    display: block;
  }
}

[aria-label="create-item-first-step-buyer-aspect"]
  #bottom-button-box-container
  #left-side {
  min-width: 197px;
}

[aria-label="create-item-first-step-buyer-aspect"]
  #bottom-button-box-container
  #right-side {
  display: flex;
}

@media screen and (max-width: 960px) {
  [aria-label="create-item-first-step-buyer-aspect"]
    #bottom-button-box-container
    #right-side {
    display: block;
  }
}

[aria-label="create-item-first-step-buyer-aspect"]
  #bottom-button-box-container
  #right-side
  > div:first-of-type {
  min-width: 197px;
}

@media screen and (max-width: 960px) {
  [aria-label="create-item-first-step-buyer-aspect"]
    #bottom-button-box-container
    #right-side
    > div:first-of-type {
    margin-top: 10px;
  }
}

[aria-label="create-item-first-step-buyer-aspect"]
  #bottom-button-box-container
  #right-side
  > div:last-of-type {
  min-width: 197px;
  margin-left: 1.25em;
}

@media screen and (max-width: 960px) {
  [aria-label="create-item-first-step-buyer-aspect"]
    #bottom-button-box-container
    #right-side
    > div:last-of-type {
    margin-left: 0;
    margin-top: 10px;
  }
}
/* bottom buttons  */

/* first-step popup form */

.create-form-container .MuiFormLabel-root {
  font-size: 0.9rem;
  color: var(---a19b9d);
  margin-bottom: 0.5rem;
}

.create-form-container .MuiFormControl-root {
  width: 100% !important;
  margin: 0 !important;
}

.create-form-container .MuiFormControl-root .MuiFormHelperText-contained {
  margin-left: 0px;
  margin-bottom: 7px;
}

.create-form-container .MuiInputBase-root {
  font-weight: 600;
}

.create-form-container .MuiFormLabel-root {
  font-size: 0.9rem;
  color: var(---a19b9d);
  margin-bottom: 0.5rem;
}

.create-form-container .MuiFormControl-root {
  width: 100% !important;
  margin: 0 !important;
}

.create-form-container .MuiFormControl-root .MuiFormHelperText-contained {
  margin-left: 0px;
  margin-bottom: 7px;
}

.create-form-container .MuiInputBase-root {
  font-weight: 600;
}

/* Popup Design  */

/* first-step popup form */
[aria-label="create-item-second-step-buyer-aspect"] .popup-form-container {
  width: 80vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="create-item-second-step-buyer-aspect"] .popup-form-container {
    width: 60vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="create-item-second-step-buyer-aspect"] .popup-form-container {
    width: 80vw;
  }
}

[aria-label="create-item-second-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiButtonBase-root {
  position: absolute;
  left: 9px;
  color: -p-;
  color: var(---2170ff-p-);
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

[aria-label="create-item-second-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiInputBase-root {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 26px;
}
[aria-label="create-item-second-step-buyer-aspect"]
  .datetime-picker-cotainer
  .MuiInputBase-input {
  margin-left: 10px;
}

[aria-label="create-item-second-step-buyer-aspect"] .btn-mui {
  font-size: 16px !important;
}

/* bottom buttons  */
[aria-label="create-item-second-step-buyer-aspect"]
  #bottom-button-box-container {
  display: flex;
  justify-content: space-between;
  margin-top: 5em;
}

@media screen and (max-width: 960px) {
  [aria-label="create-item-second-step-buyer-aspect"]
    #bottom-button-box-container {
    display: block;
  }
}

[aria-label="create-item-second-step-buyer-aspect"]
  #bottom-button-box-container
  #left-side {
  min-width: 197px;
}

[aria-label="create-item-second-step-buyer-aspect"]
  #bottom-button-box-container
  #right-side {
  display: flex;
}

@media screen and (max-width: 960px) {
  [aria-label="create-item-second-step-buyer-aspect"]
    #bottom-button-box-container
    #right-side {
    display: block;
  }
}

[aria-label="create-item-second-step-buyer-aspect"]
  #bottom-button-box-container
  #right-side
  > div:first-of-type {
  min-width: 197px;
}

@media screen and (max-width: 960px) {
  [aria-label="create-item-second-step-buyer-aspect"]
    #bottom-button-box-container
    #right-side
    > div:first-of-type {
    margin-top: 10px;
  }
}

[aria-label="create-item-second-step-buyer-aspect"]
  #bottom-button-box-container
  #right-side
  > div:last-of-type {
  min-width: 197px;
  margin-left: 1.25em;
}

@media screen and (max-width: 960px) {
  [aria-label="create-item-second-step-buyer-aspect"]
    #bottom-button-box-container
    #right-side
    > div:last-of-type {
    margin-left: 0;
    margin-top: 10px;
  }
}
/* bottom buttons  */

/* first-step popup form */

/* Upload Link Popup  */

[aria-label="create-item-second-step-buyer-aspect-upload-link-file"]
  .popup-form-container {
  width: 30vw;
}

/* for meduim screen  */
@media screen and (max-width: 1400px) {
  [aria-label="create-item-second-step-buyer-aspect-upload-link-file"]
    .popup-form-container {
    width: 50vw;
  }
}

/* for small screen  */
@media screen and (max-width: 960px) {
  [aria-label="create-item-second-step-buyer-aspect-upload-link-file"]
    .popup-form-container {
    width: 70vw;
  }
}

[aria-label="create-item-second-step-buyer-aspect-upload-link-file"]
  .popup-form-container
  .bottom-button-box-container
  .left-side {
  min-width: 165px;
}

/* Upload Link Popup  */

/* Upload Document Popup  */

[aria-label="create-item-second-step-buyer-aspect-upload-docs-file"]
  .popup-form-container {
  width: 30vw;
}

/* for meduim screen  */
@media screen and (max-width: 1400px) {
  [aria-label="create-item-second-step-buyer-aspect-upload-docs-file"]
    .popup-form-container {
    width: 50vw;
  }
}

/* for small screen  */
@media screen and (max-width: 960px) {
  [aria-label="create-item-second-step-buyer-aspect-upload-docs-file"]
    .popup-form-container {
    width: 70vw;
  }
}

[aria-label="create-item-second-step-buyer-aspect-upload-docs-file"]
  .popup-form-container
  .bottom-button-box-container
  .left-side {
  min-width: 165px;
}

/* Upload Document Popup  */

.table {
  border-radius: 20px;
}

.item-container {
  height: 45px;
  flex: 1 1;
  font-weight: 700;
  color: var(--x424143);
  line-height: 24px;
  white-space: nowrap;
  font-family: "Noto Sans", sans-serif;
  margin-top: 30px;
  color: #424143;
}

.active-btn-item {
  background-color: #def8f3 !important;
  color: #0ad6b8 !important;
  font-size: 10px !important;
  box-shadow: none !important;
  border-radius: 7px !important;
  padding: 2px 2px 2px 5px !important;
  cursor: pointer;
}

.inactive-btn-item {
  background-color: #f8dede !important;
  color: #d60a0a !important;
  font-size: 10px !important;
  box-shadow: none !important;
  border-radius: 7px !important;
  padding: 2px 3px 2px 10px !important;
  cursor: pointer;
}
.active-btn-item :hover {
  border: none;
}

.edit-delete-btn-item {
  background-color: white !important;
  color: #2170ff !important;
  border: 1px solid #2170ff !important;
  font-size: 7px !important;
  box-shadow: none !important;
  border-radius: 100px !important;
  padding: 0px 0px 0px 0px !important;
  cursor: pointer;
  margin-top: 15px !important;
}

.active-btn-popup {
  background-color: #def8f3 !important;
  color: #0ad6b8;
}

.inactive-btn-popup,
.active-btn-popup {
  font-size: 13px !important;
  /* margin-bottom: 5px !important; */
  border: none !important;
  margin: 4px !important;
  cursor: pointer;
  border-radius: 4px;
  line-height: 27px;
  font-weight: 700;
  padding: 0 25px;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  margin: 4px !important;
  border-radius: 4px !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  margin: 4px !important;
}

.inactive-btn-popup {
  background-color: #f1f0f2 !important;
  color: #a19b9d !important;
}

.MuiList-root {
  padding: 7px !important;
}

.icn-active-btn {
  margin-left: -10px !important;
  color: #b5b0b0;
}

.MuiPaper-rounded {
  border-radius: 12px !important;
}

.MuiDialogTitle-root h2 {
  font-weight: 700 !important;
}

.MuiPaper-elevation1 {
  box-shadow: 0px 3px 6px #00000029 !important;
}

.makeStyles-root-64 {
  background-color: white !important;
}

.makeStyles-root-64 svg {
  color: black !important;
}

.MuiMenuItem-root {
  font-size: 13px !important;
}
.MuiSelect-select {
  font-style: normal !important;
}
.table-item-img {
  flex: 1 1;
  width: 48px;
  border-radius: 5px;
  object-fit: cover;
}

/*Claude*/

.MuiInput-underline:before {
  border: none !important;
}

.MuiToolbar-regular {
  height: 80px;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}
.MuiPaper-elevation0 {
  border-radius: 12px;
}
.makeStyles-Button-34 {
  font-size: 16px;
  height: 48px;
}
.MuiButton-contained {
  box-shadow: none !important;
}
/*.makeStyles-Button-34{
    padding: 0.6rem 1.6rem !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    border-radius: 10px !important;
    
}
.MuiBox-root-52{
    padding: 8px 0 8px 8px !important;
}*/

/*.makeStyles-pageHeader-38{
    padding: 4px 0 4px 0px !important;
    z-index: 2;
    width: -webkit-fill-available;
    width: -moz-fill-available;
}*/
.MuiIconButton-colorInherit {
  margin: 0 15px !important;
  height: 40px !important;
  width: 40px !important;
}

.MuiInput-underline:after {
  border-bottom: none !important;
}

.makeStyles-searchInput-9 {
  border: 1px solid #f1f0f2 !important;
  border-radius: 8px !important;
}
.makeStyles-searchInput-9:focus,
.makeStyles-searchInput-9:hover {
  border: 1px solid #000 !important;
}

input {
  border: 1px solid #f1f0f2;
}

.MuiFormControl-root,
.MuiInputBase-root {
  min-height: 48px;
  height: auto;
  /*border: 1px solid #F1F0F2;
    border-radius: 8px;*/
  padding: 7px 10px;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select,
.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  min-height: auto !important;
}

.BottomModal-right button,
.BottomModal-left button {
  display: flex;
  margin: 0 !important;
  height: 48px !important;
  border-radius: 10px !important;
  min-width: 150px;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none !important;
}
.BottomModal-right button {
  margin-left: 20px !important;
}

.BottomModal-right {
  display: flex;
  justify-content: flex-end;
}
.FilterInSelect label {
  line-height: 30px;
  font-size: 14px !important;
  color: #a19b9d;
}

.FilterInSelect input {
  height: 48px;
}

.BlocFieldsInput .FilterInSelect {
  padding-right: 20px !important;
}

/* FilterInSelect{
    width: 25% !important;
} */

.BottomModal {
  padding: 30px 0 10px 0 !important;
}

.BottomModal button {
  border-radius: 10px;
  height: 48px;
  margin: 0;
}

.BottomModal button:nth-child(3) {
  margin-left: 20px !important;
}

.makeStyles-filterDiv-46 {
  width: 100% !important;
  padding: 1rem !important;
  margin: 0.3rem 0 0 0 !important;
}

/*.MuiPaper-root{
    margin: 10px 0 0 0  !important;
    border-radius: 10px !important;
}*/

.inactive-btn-item,
.active-btn-item {
  font-size: 13px !important;
  text-transform: none !important;
  font-weight: 700 !important;
}

.MuiTableRow-root {
  height: 75px;
}

table thead th {
  color: #a19b9d !important;
  font-weight: 400 !important;
}

table tbody tr:hover {
  background-color: #e2f2ff !important;
}

/* Table*/
/* nth-child(1) = the first td in each tr */
td:nth-child(1) {
  width: 7%;
}
.supplier-detail-contact-name {
  width: 30%;
}

/* column 2 */
td:nth-child(2) {
  width: 5%;
}

/* column 3 */
td:nth-child(3) {
  width: 30%;
}
/* column 4 */
td:nth-child(4) {
  width: 10%;
}

/* column 5 */
td:nth-child(5) {
  width: 12%;
}

/* column 6 */
td:nth-child(6) {
  width: 10%;
}

/* column 7 */
td:nth-child(7) {
  width: 10%;
}

/* column 8 */
td:nth-child(8) {
  width: 10%;
  min-width: 60px;
}
.best-bid-col {
  min-width: 200px !important;
}
/* column 8 */
td:nth-child(9) {
  width: 7%;
  /* min-width: 60px !important; */
}
.recent-auction-auction-id {
  width: 12% !important;
}
.recent-auction-auction-name {
  width: 20% !important;
}
.recent-auction-auction-type {
  width: 25% !important;
}
.recent-auction-auction-date {
  width: 15% !important;
}
.auction-action-tab {
  width: 5% !important;
  min-width: 60px !important;
}
.company-user-action-tab {
  width: 5% !important;
  min-width: 60px !important;
}

.css-9tj150-MuiButton-endIcon {
  display: inherit;
  margin-right: -11px !important;
  margin-left: 16px !important;
}

td:nth-child(8) .MuiBox-root {
  min-width: 0 !important;
  /* width: 0 !important; */
  margin: 0;
  padding: 0;
  display: inline;
  align-items: center;
}

.edit-delete-btn-item {
  border: none !important;
}

/* td:nth-child(8) .MuiGrid-root .MuiBox-root.css-0 {
  float: right;
  width: 30px !important;
  margin-right: 20px;
}

td:nth-child(8) .MuiBox-root button {
  line-height: 27px;
  padding: 0 15px !important;
}

td:nth-child(8) .MuiBox-root button svg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transform: rotate(90deg);
  background-color: transparent !important;
}

td:nth-child(8) .MuiBox-root button svg:active {
  border: 1px solid #1976d2;
}

td:nth-child(8) .MuiBox-root button span svg {
  border: none !important;
  transform: none;
  width: 21px;
} */
.edit-delete-btn-item {
  margin-top: 0 !important;
}
/*.css-1q1u3t4-MuiTableRow-root{
    color: inherit;
    display: flex !important;
    vertical-align: middle !important;
    outline: 0;
    justify-content: space-between !important;
}*/
.css-130xbax-MuiTable-root {
  display: inline-table !important;
  border-collapse: unset !important;
}

/*Modal*/

.MuiDialogContent-dividers {
  border: 0 !important;
}

.MuiDialogContent-dividers {
  position: relative;
}

.makeStyles-control-76 {
  position: absolute;
}
.MuiDialogContent-root {
  padding: 30px 0 0 0 !important;
  overflow: hidden;
}
.MuiDialog-paperWidthMd .MuiGrid-spacing-xs-1 > .MuiGrid-item {
  margin-bottom: 10px !important;
}

.MuiDialog-paperWidthMd,
.makeStyles-dialogWrapper-31 {
  padding: 40px !important;
  top: inherit !important;
}

.MuiTypography-h6 {
  font-weight: 700 !important;
}
.ModalHeader {
  padding: 0 !important;
}

.ModalHeader button {
  background: none;
  height: 35px;
  margin-right: -11px !important;
}

.css-w4z10b-MuiStack-root {
  flex-direction: row;
  justify-content: space-between;
}

.ModalHeader button svg {
  color: black !important;
}

.MuiDialog-paperWidthMd .StepControlInput,
.MuiDialog-paperWidthMd .MuiFormControl-root {
  width: 100% !important;
  margin: 0 !important;
  margin-bottom: 10px !important;
}
.ModalHeader h2 {
  color: #1e1d1d;
  font-size: 20px !important;
  font-weight: 700px !important;
}

/*.makeStyles-active-74, .makeStyles-inactive-75{
    width: 36px;
    height: 36px;
    line-height: 35px;
    border-radius: 50%;
    font-size: 14px;
    color: white !important;
    font-weight: 700;
    background-color: #2170FF;
}*/

.makeStyles-secondary-65 {
  background-color: none !important;
}
.makeStyles-root-64 {
  margin: 0 !important;
}

.makeStyles-root-69.MuiFormControl-root {
  width: 100% !important;
}

.MuiBox-root.MuiBox-root-252 {
  display: flex;
}

.MuiPaper-root.MuiBox-root {
  width: 100% !important;
}

.css-1ex1afd-MuiTableCell-root {
  border-bottom: 1px solid rgba(224, 224, 224, 0.4) !important;
  padding: 12px 16px !important;
}

/*Spaces*/
.padding-right-10 {
  padding-right: 10px !important;
}
.padding-right-20 {
  padding-right: 20px !important;
}
.padding-right-30 {
  padding-right: 30px !important;
}
.padding-right-40 {
  padding-right: 40px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}
.margin-right-20 {
  margin-right: 20px !important;
}
.margin-right-30 {
  margin-right: 30px !important;
}
.margin-right-40 {
  margin-right: 40px !important;
}
/*---*/

.padding-left-10 {
  padding-left: 10px !important;
}
.padding-left-20 {
  padding-left: 20px !important;
}
.padding-left-30 {
  padding-left: 30px !important;
}
.padding-left-40 {
  padding-left: 40px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}
.margin-left-20 {
  margin-left: 20px !important;
}
.margin-left-30 {
  margin-left: 30px !important;
}
.margin-left-40 {
  margin-left: 40px !important;
}

.marginleft15 {
  margin-left: 15px !important;
}

.StepControl .gridCol,
.StepControl .gridColdisabled,
.StepControl .gridColactive,
.StepControl .gridColchecked {
  line-height: 26px !important;
  width: 36px !important;
  height: 36px !important;
  font-size: 14px;
  font-weight: 500;
  border-radius: 50% !important;
  color: #fff !important;
  margin: 2px;
}

.StepControlIcon {
  height: 36px;
  width: 36px;
  background-color: #fff !important;
}

.uploadControlIcon {
  height: 40px;
  width: 40px;
  margin: 2px;
}

.attachControlIcon {
  height: 34px;
  margin: 4px 4px 0px 2px;
}
.uploadheader p {
  margin: 2px 0;
}

.uploadheader .customeFileUpload {
  display: none;
}

.StepControl .gridColactive {
  margin: 0;
}
.StepControl .gridColdisabled {
  background-color: #a19b9d !important;
}

.StepControl .gridColchecked {
  background-color: #00d4b5 !important;
}

.StepControl {
  padding: 2rem 0 2rem 0 !important;
  position: relative;
}

.StepControl .wrapper {
  width: 50%;
  margin: auto;
  align-items: center;
}

.StepControl .wrapper .MuiGrid-item {
  padding: 0px;
}

.StepControl svg {
  width: 36px;
  height: 36px;
}

.StepControl .gridCol {
  background: -p-;
  background: var(---2170ff-p-);
  text-align: center;
}

.StepControl .gridCol.__inactive {
  background-color: var(---a19b9d) !important;
}

.StepControl .gridCol.__active {
  background-color: -p- !important;
  background-color: var(---2170ff-p-) !important;
}

.StepControl .gridCol > div {
  margin-top: 5px;
}

/* .StepControl div {
  width: auto !important;
} */

.StepControl .gridColTextActive,
.StepControl .gridColTextInactive {
  font-size: 14px !important;
  line-height: 29px !important;
  width: auto !important;
  margin: 1px 5px !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  font-weight: 700;
}

.StepControl .gridColTextActive {
  color: #000 !important;
}
.StepControl .gridColTextInactive {
  color: #a19b9d !important;
}

.filterBox {
  position: absolute;
  right: 0;
  z-index: 999;
  left: 0;
}

.MuiOutlinedInput-root {
  border-radius: 8px !important;
}

.FilterControl button {
  height: 48px;
  margin: 8px 0 8px 16px;
  border-radius: 10px;
}

/* .FilterControl button span:nth-child(2){
    display: none !important;
} */

.FilterBoxView {
  z-index: 99;
}

.blockInFilter {
  /* margin: 12px 0 0 0; */
  width: 100%;
  padding: 20px 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.BlocFieldsButtun,
.BlocFieldsInput {
  display: flex;
  align-items: flex-end;
}

.BlocFieldsButtun button {
  width: 176px;
  margin: 0 !important;
}

.BlocFieldsButtun .FilterInSelect:nth-child(1) {
  padding-right: 20px !important;
}

.BlocFieldsButtun {
  margin-left: 0 !important;
}
/* .blockInFilter .MuiOutlinedInput-root, .blockInFilter button{
    width: 180px;
    border-radius: 8px;
} */

.blockInFilter .MuiInputBase-root {
  width: 100%;
}
.blockInFilter button {
  height: 48px;
}

.blockInFilter div button:nth-child(1) {
  border: 1px solid #dddbdb !important;
}

.blockInFilter div button:nth-child(1) {
  border: 1px solid #dddbdb !important;
}
.ContainerAttachment hr {
  border-color: #e0e0e0 !important;
}
.AttachedFiles {
  padding: 11px 20px 10px 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin: 10px 10px 3px 0;
  width: 220px;
}

.blockInFilter button {
  font-size: 16px;
  height: 48px;
  margin: 8px;
}
.CloseControlIcon {
  height: 18px;
}

.blockInFilter .MuiOutlinedInput-root,
.blockInFilter button {
  font-family: "Noto Sans" !important;
  font-size: 14px !important;
  border-radius: 8px;
}

.BlueButtun,
.WhiteButton,
.WhiteButton,
.WhiteButtonFilter,
.GreyButton,
.GreenButton {
  height: 48px;
  border-radius: 8px;
  padding: 0 5px !important;
  min-width: 175px !important;
  width: 100% !important;
}

.BlueButtun {
  background-color: #2170ff;
  color: #fff !important;
}

.WhiteButton {
  background-color: #fff;
  color: #2170ff !important;
}
.WhiteButtonFilter {
  background-color: #fff;
  border: 1px solid #dddbdb;
}

.GreyButton {
  background-color: #f1f0f2;
  color: #424143 !important;
}

.GreenButton {
  background-color: #e2f2ff;
  color: #2170ff !important;
}

.container .imgPreview {
  margin: 0 !important;
  width: 100%;
}

.container .imgPreview.attach_box {
  margin: 0 !important;
  width: 150px;
  height: 141px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #e2f2ff;
  border-radius: 8px;
  margin: 10px 0 0 10px;
  color: #000000;
}

.container .imgPreview.doc_box {
  margin: 0 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #dbe8ff;
  border: 2px dashed #2170ff;
  border-radius: 8px;
  margin-bottom: 1rem;
  color: #000000;
}

.container .imgPreview.doc_box .upload-btn {
  background-color: #fff;
  color: #2170ff;
  margin: 20px 0;
  font-size: 13px;
  border-radius: 5em;
  padding: 0.5rem 2rem;
  font-weight: 700;
}

.imgPreview input {
  display: none;
}

.DividerContainer {
  min-width: 700px;
}
.DividerContainer label {
  margin-left: -8px;
}

.imgPreview svg {
  width: 29px;
  height: 29px;
  margin-top: 8px;
}

.imgPreview label {
  width: 150px !important;
  height: 150px !important;
}

.ContainerAttachment {
  margin: 32px 0 10px 0;
}
.ContainerAttachment span {
  line-height: 34px;
}

.ContainerAttachment hr {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.SearchInputList {
  padding: 0 2px 0 18px !important;
  border: 1px solid #f1f0f2 !important;
  border-radius: 8px !important;
  background: no-repeat;
  background-size: 18px;
  background-position: 10px center;
  width: 278px;
}

.SearchInputList:hover,
.SearchInputList:focus,
.SearchInputList:active {
  border: 1px solid -p- !important;
  border: 1px solid var(---2170ff-p-) !important;
}

.SearchInputList svg {
  display: none;
}

.library-doc {
  cursor: pointer;
  text-decoration: underline;
}

.item-list-details-info-container *,
#item-list-info-details-status,
#item-list-info-details-option-list,
#item-list-info-details-attach-option-list,
#item-slider-details-buyer-aspect-notification-box,
[aria-label="item-list-info-details-attach-docs-popup"],
[aria-label="item-list-info-details-attach-link-popup"] {
  z-index: 1600 !important;
}

.item-list-details-info-container .image-container {
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
}

.item-list-details-info-container .image-container .empty-bck {
  content: "there is no image";
  position: absolute;
  top: 3vh;
  left: 1em;
  color: #b1b0b0;
  font-size: 18px;
  z-index: 0;
  text-decoration: underline;
}

.item-list-details-info-container .table-container.MuiBox-root {
  margin-right: 0px;
}

.item-list-details-info-container .image-container .image {
  display: block;
  width: 100%;
  object-fit: contain;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 38vh;
  background: #ffffff;
  z-index: 2;
}

.item-list-details-info-container .image-container .middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  bottom: 24px;
  -webkit-transform: translateX(1vw);
          transform: translateX(1vw);
  text-align: center;
}

.item-list-details-info-container .image-container:hover .middle {
  opacity: 1;
}

.item-list-details-info-container .image-container .middle .hover-box {
  background-color: #24242487;
  padding: 16px 16px;
  display: flex;
  color: #fff;
  border-radius: 32px;
  align-items: center;
}

.item-list-details-info-container .image-container .middle .hover-box .btn-mui {
  padding: 0 12px !important;
  border-radius: 26px !important;
  white-space: break-spaces;
  font-size: 13px !important;
}

.item-list-details-info-container .table-container tbody td,
.item-list-details-info-container .table-container tbody th {
  font-size: 13px;
}

.item-list-details-info-container
  .table-container.MuiTableContainer-root::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.item-list-details-info-container
  .table-container.MuiTableContainer-root::-moz-scrollbar {
  height: 8px;
  width: 8px;
}

.item-list-details-info-container
  .table-container.MuiTableContainer-root::-ms-scrollbar {
  height: 8px;
  width: 8px;
}

.item-list-details-info-container
  .table-container.MuiTableContainer-root::-o-scrollbar {
  height: 8px;
  width: 8px;
}

.item-list-details-info-container .MuiFormControl-root,
.MuiInputBase-root {
  height: 44px;
}

.item-list-details-info-container .btn-mui-table-option.MuiButton-root {
  background: #fff;
  color: -p-;
  color: var(---2170ff-p-);
  height: 44px;
}

.item-list-details-container .scroll-page {
  overflow-y: scroll;
  height: 100vh;
}

.item-list-details-container .scroll-page {
  scrollbar-color: #dbdbdb rgba(0, 0, 0, 0.03);
  scrollbar-width: thin;
}

.item-list-details-container .scroll-page::-webkit-scrollbar {
  height: 1px;
  width: 1px;
}

.item-list-details-container .scroll-page::-moz-scrollbar {
  height: 1px;
  width: 1px;
}

.item-list-details-container .scroll-page::-ms-scrollbar {
  height: 1px;
  width: 1px;
}

.item-list-details-container .scroll-page::-o-scrollbar {
  height: 1px;
  width: 1px;
}

.item-edit-board-details-container *,
#menu-category {
  z-index: 1600 !important;
  font-size: 13px !important;
}
.item-edit-board-details-container *,
#menu-group {
  z-index: 1600 !important;
  font-size: 13px !important;
}

.item-edit-board-details-container .edit-btn {
  height: 26px;
  width: 52px;
  border-radius: 8px;
  font-size: 16px;
  background-color: #fff !important;
  border-color: -p- !important;
  border-color: var(---2170ff-p-) !important;
  border-width: 1px !important;
  color: -p- !important;
  color: var(---2170ff-p-) !important;
}

.item-edit-board-details-container .edit-btn:hover {
  background-color: -p- !important;
  background-color: var(---2170ff-p-) !important;
  color: #fff !important;
}

.item-edit-board-details-container .title-header {
  font-size: 14px !important;
  margin-top: 0px;
}

.item-edit-board-details-container .tag {
  border-radius: 18px;
  margin-left: 5px;
  margin-top: 5px;
}

.item-edit-board-details-container .tag.badge {
  padding: 0.2em 0.5em;
  display: flex;
  align-items: center;
}

.item-edit-board-details-container .mui-input {
  height: 43px !important;
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  background-color: #fff;
}

.item-edit-board-details-container .mui-select .MuiInputBase-root {
  height: 43px;
  background-color: #fff;
}

.item-edit-board-details-container .mui-select.MuiFormControl-root {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.item-edit-board-details-container .mui-select .MuiSelect-iconOutlined {
  margin-top: 3px;
  font-size: 18px !important;
}

.details-banner {
  background-color: rgb(255, 255, 255);
  display: block;
  margin-top: 1rem;
  margin-left: -2em;
  margin-right: -1.7em;
  border: none;
  background: transparent
    linear-gradient(90deg, #ffffff 0%, #d5e4ffe7 49%, #2170ff82 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
  position: relative;
  height: 14em;
}

.details-banner .banner-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.2;
}

.details-banner .banner-container {
  margin: 1em 2em;
  z-index: 2;
  position: relative;
}

.details-banner .project-title {
  margin-bottom: 0em;
}

.details-banner .project-title__smal {
  margin-bottom: 1em;
  font-size: 0.9em;
  margin-top: 0.5em;
}

.details-banner .project-title__smal span:nth-of-type(1) {
  color: -p-;
  color: var(---2170ff-p-);
}

.details-banner .project-title__smal span:nth-of-type(2) {
  color: var(---a19b9d);
}

.details-banner .banner-container .go-back-button {
  font-size: 16px;
  font-weight: 700;
  color: -p-;
  color: var(---2170ff-p-);
  background-color: #fff;
  border-color: #fff;
  white-space: break-spaces;
  height: 45px;
  border-radius: 12px;
  padding: 0px 12px;
}

.details-banner .banner-container .go-back-button svg {
  font-size: 1.1em;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
  margin-right: 0.4em;
  vertical-align: middle;
}

.details-banner .banner-container .go-back-button .live-icon {
  color: -p-;
  color: var(---2170ff-p-);
  width: 24px;
  height: 24px;
  vertical-align: bottom;
}

.details-banner .banner-container .go-back-button.__edit {
  border: 1px solid -p-;
  border: 1px solid var(---2170ff-p-);
  font-size: 0.9em;
  padding: 4px 10px;
  border-radius: 8px;
  text-transform: capitalize;
}

.details-banner .banner-container .go-back-button.__save {
  border: 1px solid -p-;
  border: 1px solid var(---2170ff-p-);
  font-size: 0.9em;
  color: #fff;
  padding: 4px 10px;
  border-radius: 8px;
  text-transform: capitalize;
  background-color: -p-;
  background-color: var(---2170ff-p-);
}

.details-banner .banner-container .editable-board-container {
  margin-top: 1em;
  position: relative;
  overflow: hidden;
  padding: 1.2em;
  /* min-height: 10em; */
}

.details-banner .banner-container .editable-board-container .image {
  width: 100%;
  max-height: 100px;
  border-radius: 8px;
  background: #f2f2f270;
}

.details-banner .banner-container .editable-board-container .img-back-grd {
  background-image: linear-gradient(
    to top left,
    transparent 50%,
    -p- 50%
  );
  background-image: linear-gradient(
    to top left,
    transparent 50%,
    var(---2170ff-p-) 50%
  );
  position: absolute;
  width: 6em;
  height: 157%;
  top: 0;
  left: 0;
  z-index: 0;
}

.details-banner .banner-container .editable-board-container .lable {
  font-size: 0.8em;
  color: var(---a19b9d);
  padding: 2px 0px;
}

.details-banner .banner-container .editable-board-container .field {
  margin-bottom: 1em;
  font-weight: 600;
  border: none;
  border-bottom: 1px transparent solid;
  width: 100%;
  font-family: "Noto Sans", sans-serif;
  resize: none;
  outline: none;
}

.details-banner .banner-container .editable-board-container .status {
  padding: 0px 10px !important;
  margin-right: 4px;
  font-size: 1em !important;
}

.details-banner .banner-container .editable-board-container .edit-project {
  border: 1px solid -p-;
  border: 1px solid var(---2170ff-p-);
  font-size: 0.7em;
  padding: 4px;
  border-radius: 8px;
  text-transform: capitalize;
}

.details-banner .banner-container .editable-board-container .field.__edit {
  font-weight: 600;
  border: none;
  border-bottom: 2px solid #0e86f5;
  background-color: #badeff;
}

.details-banner .banner-container .editable-board-container select:disabled {
  color: #000;
  opacity: 1;
  -webkit-appearance: none;
          appearance: none;
}

.details-banner .banner-container .editable-board-container select option {
  background-color: #fff;
}

.details-banner
  .banner-container
  .editable-board-container
  .field.__edit:focus-visible {
  outline: none;
}

.show__less__text {
  display: inline;
}

.show-wrap-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  max-width: 300px;
  word-break: break-word;
}
.show-wrap-table-text {
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.show-wrap-text.mw550 {
  max-width: 550px !important;
}
.show-wrap-text.mw600 {
  max-width: 600px !important;
}
.show-wrap-text.mw200 {
  max-width: 200px !important;
}
.show-wrap-text.eight-line {
  -webkit-line-clamp: 8 !important;
}
.show-wrap-text.two-line {
  -webkit-line-clamp: 2 !important;
}
.show-wrap-text.one-line {
  -webkit-line-clamp: 1 !important;
}
.show__complete__text {
  display: none;
}

.show__more__link {
  color: -p-;
  color: var(---2170ff-p-);
  padding: 3px;
  cursor: pointer;
}


/* normal  */
.mui-input {
  height: 45px !important;
}

.mui-input .MuiInputBase-root {
  height: 45px !important;
}

.mui-input textarea:first-of-type {
  max-height: 45px !important;
}
/* normal  */

/* 5em height  */
.mui-input.fix-h-5em {
  min-height: 5rem !important;
}

.mui-input.fix-h-5em .MuiInputBase-root {
  height: 5em !important;
}

.mui-input.fix-h-5em textarea:first-of-type {
  max-height: 3em !important;
  height: 3em !important;
}
/* 5em height  */

/* 8em height  */
.mui-input.fix-h-8em {
  min-height: 8rem !important;
}

.mui-input.fix-h-8em .MuiInputBase-root {
  height: 8em !important;
}

.mui-input.fix-h-8em textarea:first-of-type {
  max-height: 6em !important;
  height: 6em !important;
}
/* 8em height  */

/* 12em height  */
.mui-input.fix-h-12em {
  min-height: 12em !important;
}

.mui-input.fix-h-12em .MuiInputBase-root {
  height: 12em !important;
}

.mui-input.fix-h-12em textarea:first-of-type {
  max-height: 8em !important;
  height: 8em !important;
}
/* 12em height  */

/* 20em height  */
.mui-input.fix-h-20em {
  min-height: 20em !important;
}

.mui-input.fix-h-20em .MuiInputBase-root {
  height: 20em !important;
}

.mui-input.fix-h-20em textarea:first-of-type {
  max-height: calc(100% - 10px) !important;
  height: calc(100% - 10px) !important;
}
/* 20em height  */

.mui-input .MuiOutlinedInput-notchedOutline,
.mui-input .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: var(---e0e0e0);
}

.MuiOutlinedInput-root.Mui-disabled
  .MuiOutlinedInput-notchedOutline
  .mui-input
  .MuiFormHelperText-contained {
  margin-left: 0px;
}

/* date time picker design  */

.mui-input.mui-date-picker-reverse .MuiIconButton-root {
  padding: 0 !important;
}

.mui-input.mui-date-picker-reverse .MuiButtonBase-root {
  position: absolute;
  left: 10px;
  color: -p-;
  color: var(---2170ff-p-);
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.mui-input.mui-date-picker-reverse .MuiInputBase-root {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 26px;
}

.mui-input .MuiInputBase-root.Mui-disabled {
  cursor: no-drop;
  color: rgba(0, 0, 0, 0.87);
  background: var(---f1f0f2);
}

/* date time picker design  */

.mui-label {
  font-size: 14px !important;
  color: var(---a19b9d) !important;
  margin-bottom: 4px;
}

.mui-input.helper-text-nowrap .MuiFormHelperText-contained {
  white-space: nowrap;
}

.mui-input .MuiFormHelperText-contained {
  margin-left: 0px;
}
.mui-select .MuiFormHelperText-contained {
  margin-left: 0px;
}

#item-table-list-buyer-aspect .filter-box-container .filter-box .btn-mui {
  min-height: 45px;
  margin-top: -50px;
  border-radius: 8px !important;
}

@media screen and (max-width: 960px) {
  #item-table-list-buyer-aspect .filter-box-container .filter-box .btn-mui {
    margin-top: 0px;
  }
}

.profile-title {
  margin-top: 0;
  font-weight: 700 !important;
  margin-bottom: 10px;
  border-bottom: 1px solid #e2e2e2;
  font-size: 18px;
  padding-bottom: 8px;
}

.BlocTabContainer {
  display: flex;
}

/* Tab */
.BlocTabMenuContainer {
  padding: 20px;
}

.MuiTabs-indicator {
  display: none !important;
}

.BlocTabMenuContainer button {
  padding: 0 !important;
  width: 100%;
  min-height: auto !important;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: flex-start;
}

.MuiOutlinedInput-root {
  border-radius: 8px !important;
}

.BlocTabMenuContainer button:nth-last-child() {
  border: 0 !important;
}

.BlocTabMenuContainer button.Mui-selected {
  background-color: #2170ff;
  color: rgb(255, 255, 255);
  z-index: 999;
  border-radius: 12px !important;
}

.BlocTabMenuContainer button svg,
.BlocTabMenuContainer button img {
  margin: 18px 20px !important;
  width: 24px;
  height: 24px;
}

.TabMenu:hover {
  background-color: #e2f2ff;
  border-radius: 0 !important;
  z-index: 0 !important;
}

.TabMenu {
  border-bottom: 1px solid #e0e0e0 !important;

  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  text-transform: none !important;
  font-weight: 700 !important;
}

.TabMenu.Mui-selected:hover {
  background-color: none !important;
  border: none;
}

.containerSettings {
  padding: 65px 0 0 0;
  max-width: 1120px;
  margin: auto;
}

.leftContent,
.RightContent {
  display: flex;
  flex-direction: column;
}

.leftContent {
  justify-items: center;
  justify-content: center;
  padding-right: 35px;
}

.RightContent {
  display: flex;
  flex-direction: column;
}

.RightContent .MuiFormControl-root,
.BlocPreferences .MuiFormControl-root,
.BlocPrefeBlocPasswordrences .MuiFormControl-root {
  width: 100% !important;
}

.RightContent .MuiGrid-root,
.BlocPreferences .MuiGrid-root,
.BlocPassword .MuiGrid-root {
  display: flex !important;
  flex-direction: row !important;
  margin-bottom: 15px;
  border-radius: 8px !important;
}

.RightContent .MuiGrid-root button,
.BlocPassword button {
  height: 48px !important;
  margin: 8px !important;
  background-color: #e2f2ff !important;
  color: #2170ff !important;
  border-radius: 12px !important;
  font-weight: bold;
  font-size: 12px !important;
}

.BlocInfos .BottomModal-left button {
  background-color: #f1f0f2 !important;
}

.BlocInfos .BottomModal-left button span:nth-child(1) {
  color: rgb(75, 74, 74) !important;
}

.BlocTabContainer .BottomModal {
  padding-top: 50px !important;
}

.BlocPreferences,
.BlocPassword {
  width: auto;
  align-items: center;
}

table tfoot td {
  border-bottom: none !important;
}

/* table 4 columns T4*/

.T4columns table {
  width: auto;
}

.T4columns table td:nth-child(1) {
  width: 35%;
}

/* column 2 */
.T4columns table td:nth-child(2) {
  width: 35%;
}

/* column 3 */
.T4columns table td:nth-child(3) {
  width: 25%;
}
/* column 4 */
.T4columns table td:nth-child(4) {
  width: 10%;
}

/* table 3 columns T3*/

.T3columns table {
  width: 100%;
}

.T3columns table td:nth-child(1) {
  width: 55%;
}

/* column 2 */
.T3columns table td:nth-child(2) {
  width: 35%;
}

/* column 3 */
.T3columns table td:nth-child(3) {
  width: 10%;
}

.TextAfterImage {
  margin-left: 6px;
  position: relative;
  top: -6px;
  font-weight: 700;
}

.SearchBox button {
  margin: 0 !important;
  text-transform: none;
  font-weight: 600;
}

.SearchBox button svg {
  margin-right: 10px !important;
}

.SearchBox button span {
  margin-left: 11px !important;
}

.SearchBox {
  margin: 20px 0;
  align-items: center !important;
}

.SettingsModal .MuiBox-root .BottomModal-right {
  margin-left: 0 !important;
  justify-content: space-between;
}

.SettingsModal .MuiGrid-item {
  padding: 0 !important;
}

.SettingsModal .MuiBox-root .BottomModal-right button {
  margin: 0 !important;
}

.SettingsModal .MuiBox-root .MuiFormControl-root {
  margin: 0 !important;
  width: 100% !important;
}

.SettingsModal .MuiGrid-container {
  margin: 30px 0 5px;
  width: 100% !important;
}

.error-msg {
  color: red;
  font-size: 11px;
  padding-left: 5px;
}

.summary-word-count {
  font-size: 12px;
  color: #bbb;
}

.settings-profile-section-contaier .mui-label {
  margin-bottom: -2px;
}

.settings-profile-section-contaier .MuiFormControl-root {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.settings-profile-section-contaier
  .mui-input.clean-bck-input
  .MuiOutlinedInput-notchedOutline,
.settings-profile-section-contaier
  .mui-input.clean-bck-input
  .MuiInputBase-root.Mui-disabled
  .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

.settings-profile-section-contaier
  .mui-input.clean-bck-input
  .MuiInputBase-root.Mui-disabled {
  background: transparent;
  font-size: 27px;
  font-weight: 600;
}

.settings-profile-section-contaier
  .mui-input.clean-bck-input
  textarea:first-of-type {
  height: 45px;
}
.settings-profile-section-contaier .mui-input textarea:first-of-type,
.settings-profile-section-contaier
  .mui-select
  .MuiSelect-outlined.MuiSelect-outlined {
  font-weight: 600;
}

.settings-profile-section-contaier .change-password-btn {
  width: 120px;
  height: 40px;
  background: var(---e2f2ff);
  border-radius: 12px;
  color: #1976d2;
  font-weight: 600;
}

.settings-profile-section-contaier
  .change-password-btn.MuiButton-containedPrimary:hover {
  background-color: var(---e2f2ff);
}

/* <> preferences */
.settings-profile-section-contaier .preferences-container .row-label {
  max-width: 45%;
  flex-basis: 45%;
}

@media screen and (max-width: 600px) {
  .settings-profile-section-contaier .preferences-container .row-label {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.settings-profile-section-contaier .preferences-container .row-field {
  max-width: 50%;
  flex-basis: 50%;
}

@media screen and (max-width: 600px) {
  .settings-profile-section-contaier .preferences-container .row-field {
    max-width: 100%;
    flex-basis: 100%;
  }
}
/* </> preferences */

/* <> password  */
.settings-profile-section-contaier .password-container .row-label {
  max-width: 45%;
  flex-basis: 45%;
}

@media screen and (max-width: 600px) {
  .settings-profile-section-contaier .password-container .row-label {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.settings-profile-section-contaier .password-container .row-field {
  max-width: 50%;
  flex-basis: 50%;
}

@media screen and (max-width: 600px) {
  .settings-profile-section-contaier .password-container .row-field {
    max-width: 100%;
    flex-basis: 100%;
  }
}
/* </> password */

/* ---bkp-xs: 0px;
---bkp-sm: 600px;
---bkp-md: 960px;
---bkp-lg: 1280px;
---bkp-xl: 1536px; */

.settings-company-section-contaier .mui-label {
  margin-bottom: -2px;
}

.settings-company-section-contaier .MuiFormControl-root {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.settings-company-section-contaier
  .mui-input.clean-bck-input
  .MuiOutlinedInput-notchedOutline,
.settings-company-section-contaier
  .mui-input.clean-bck-input
  .MuiInputBase-root.Mui-disabled
  .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

.settings-company-section-contaier
  .mui-input.clean-bck-input
  .MuiInputBase-root.Mui-disabled {
  background: transparent;
  font-size: 27px;
  font-weight: 600;
}

.settings-company-section-contaier
  .mui-input.clean-bck-input
  textarea:first-of-type {
  height: 45px;
}
.settings-company-section-contaier .mui-input textarea:first-of-type,
.settings-company-section-contaier
  .mui-select
  .MuiSelect-outlined.MuiSelect-outlined {
  font-weight: 600;
}

.settings-company-section-contaier .change-password-btn {
  width: 120px;
  height: 40px;
  background: var(---e2f2ff);
  border-radius: 12px;
  color: #1976d2;
  font-weight: 600;
}

.settings-company-section-contaier
  .change-password-btn.MuiButton-containedPrimary:hover {
  background-color: var(---e2f2ff);
}

.settings-company-section-contaier .act-container {
  display: flex;
  flex-wrap: wrap;
}

.settings-company-section-contaier .act-container .activity {
  display: flex;
  align-items: center;
  color: rgb(75, 74, 74) !important;
  font-weight: 600;
  border-radius: 18px;
  font-size: 14px;
  padding: 4px 8px;
  margin: 5px 5px 5px 0px;
}

.settings-company-section-contaier .act-container .activity svg {
  width: 16px;
  height: 16px;
  margin: 0px 4px;
  cursor: pointer;
}

[aria-label="company-settings-attachments-option-list"] {
  width: 200px;
}

[aria-label="add-edit-item-cat-settings-modal"] .popup-form-container {
  width: 40vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="add-edit-item-cat-settings-modal"] .popup-form-container {
    width: 25vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 600px) {
  [aria-label="add-edit-item-cat-settings-modal"] .popup-form-container {
    width: 70vw;
  }
}

[aria-label="add-edit-supplier-cat-settings-modal"] .popup-form-container {
  width: 40vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="add-edit-supplier-cat-settings-modal"] .popup-form-container {
    width: 25vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 600px) {
  [aria-label="add-edit-supplier-cat-settings-modal"] .popup-form-container {
    width: 70vw;
  }
}

[aria-label="add-edit-item-cost-center-settings-modal"] .popup-form-container {
  width: 40vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="add-edit-item-cost-center-settings-modal"]
    .popup-form-container {
    width: 25vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 600px) {
  [aria-label="add-edit-item-cost-center-settings-modal"]
    .popup-form-container {
    width: 70vw;
  }
}

[aria-label="add-edit-item-business-unit-settings-modal"]
  .popup-form-container {
  width: 40vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="add-edit-item-business-unit-settings-modal"]
    .popup-form-container {
    width: 25vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 600px) {
  [aria-label="add-edit-item-business-unit-settings-modal"]
    .popup-form-container {
    width: 70vw;
  }
}

[aria-label="add-edit-tag-settings-modal"] .popup-form-container {
  width: 40vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="add-edit-tag-settings-modal"] .popup-form-container {
    width: 25vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 600px) {
  [aria-label="add-edit-tag-settings-modal"] .popup-form-container {
    width: 70vw;
  }
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .popup-form-container {
  width: 80vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="attachment-from-lib-company-settings-buyer-aspect"]
    .popup-form-container {
    width: 55vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="attachment-from-lib-company-settings-buyer-aspect"]
    .popup-form-container {
    width: 80vw;
  }
}

/* Supplier table  */

[aria-label="attachment-from-lib-company-settings-buyer-aspect"] table tbody,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"] table thead,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"] table th,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"] table tr,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"] table td {
  display: block;
  max-width: none !important;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  table
  tr:after {
  content: " ";
  display: block;
  visibility: hidden;
  clear: both;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"] table tbody {
  height: 41vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #dbdbdb rgba(0, 0, 0, 0.03);
  scrollbar-width: thin;
  margin-top: 14px;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  table
  tr:first-of-type {
  margin-bottom: 0px;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"] table tr {
  min-width: 800px;
  margin-bottom: -2px;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"] table td {
  padding-top: 24px !important;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  table
  tbody
  tr:hover {
  background-color: #fff !important;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .MuiTableCell-root {
  border-bottom: 1px solid #e0e0e0 !important;
  float: left;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .MuiTableHead-root
  .MuiTableRow-head
  .MuiTableCell-root {
  /* border-top: 1px solid #e0e0e0 !important; */
  min-height: 58px;
  white-space: nowrap;
}

/* devide grid pixels  */
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(1) {
  width: 50%;
  min-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(2) {
  width: 25%;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(3) {
  width: 25%;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: flex-end;
}
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(3)
  .checkbox {
  margin-top: -30px;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(3)
  .checkbox.header {
  margin-top: -9px;
  margin-right: 15px;
}

/* devide grid pixels  */

/* scroll bar design  */

/* scrollbar */
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-webkit-scrollbar,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-moz-scrollbar,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-moz-scrollbar {
  width: 7px;
  height: 7px;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-ms-scrollbar,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-ms-scrollbar {
  width: 7px;
  height: 7px;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-o-scrollbar,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-o-scrollbar {
  width: 7px;
  height: 7px;
}
/* scrollbar */

/* scrollbar-track */
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-webkit-scrollbar-track,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-moz-scrollbar-track,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-moz-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-ms-scrollbar-track,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-ms-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-o-scrollbar-track,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-o-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}
/* scrollbar-track */

/* scrollbar-thumb */
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-webkit-scrollbar-thumb,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-moz-scrollbar-thumb,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-moz-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-ms-scrollbar-thumb,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-ms-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-o-scrollbar-thumb,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-o-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}
/* scrollbar-thumb */

/* scroll bar design  */

/* :checked  */
/* [aria-label="attachment-from-lib-company-settings-buyer-aspect"] */
/* :checked */

.page-chart-container__hide.MuiPaper-rounded {
  box-shadow: none !important ;
  background-color: transparent;
  border: none !important;
}

.page-chart-container__view.MuiPaper-rounded {
  box-shadow: inherit;
  background-color: #fff;
  border: inherit;
}

/* <> tab labels  */
.settings-index-section-contaier .settings-index-section-tab-labels {
  max-width: 23%;
  flex-basis: 23%;
}

@media screen and (max-width: 1280px) {
  .settings-index-section-contaier .settings-index-section-tab-labels {
    max-width: 32%;
    flex-basis: 32%;
  }
}

@media screen and (max-width: 960px) {
  .settings-index-section-contaier .settings-index-section-tab-labels {
    max-width: 75%;
    flex-basis: 75%;
  }
}

@media screen and (max-width: 600px) {
  .settings-index-section-contaier .settings-index-section-tab-labels {
    max-width: 90%;
    flex-basis: 90%;
  }
}

[aria-label="settings-index-section-tab-labels-block"] {
  align-items: center;
}
/* </> tab labels  */

/* <> tab Content  */
.settings-index-section-contaier .settings-index-section-tab-content {
  max-width: 75%;
  flex-basis: 75%;
}

@media screen and (max-width: 1280px) {
  .settings-index-section-contaier .settings-index-section-tab-content {
    max-width: 65%;
    flex-basis: 65%;
  }
}

@media screen and (max-width: 960px) {
  .settings-index-section-contaier .settings-index-section-tab-content {
    max-width: 90%;
    flex-basis: 90%;
  }
}
/* </> tab Content  */

/* className={mdScreen ? "w90-percent-box mb-4" : "w23-percent-box"} */
/* 
.settings-profile-section-contaier .preferences-container .row-label {
  max-width: 45%;
  flex-basis: 45%;
} */

/* 
   MuiTab-textColorPrimary Mui-selected  
     MuiTab-textColorPrimary TabMenu css-aym9vq-MuiButtonBase-root-MuiTab-root" */

.profile-title {
  margin-top: 0;
  font-weight: 700 !important;
  margin-bottom: 10px;
  border-bottom: 1px solid #e2e2e2;
  font-size: 18px;
  padding-bottom: 8px;
}

.BlocTabContainer {
  display: flex;
}

/* Tab */
.BlocTabMenuContainer {
  padding: 20px;
}

.MuiTabs-indicator {
  display: none !important;
}

.BlocTabMenuContainer button {
  padding: 0 !important;
  width: 100%;
  min-height: auto !important;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: flex-start;
}

.MuiOutlinedInput-root {
  border-radius: 8px !important;
}

.BlocTabMenuContainer button:nth-last-child() {
  border: 0 !important;
}

.BlocTabMenuContainer button.Mui-selected {
  background-color: #2170ff;
  color: rgb(255, 255, 255);
  z-index: 999;
  border-radius: 12px !important;
}

.BlocTabMenuContainer button svg,
.BlocTabMenuContainer button img {
  margin: 18px 20px !important;
  width: 24px;
  height: 24px;
}

.TabMenu:hover {
  background-color: #e2f2ff;
  border-radius: 0 !important;
  z-index: 0 !important;
}

.TabMenu {
  border-bottom: 1px solid #e0e0e0 !important;

  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  text-transform: none !important;
  font-weight: 700 !important;
}

.TabMenu.Mui-selected:hover {
  background-color: none !important;
  border: none;
}

.containerSettings {
  padding: 65px 0 0 0;
  max-width: 1120px;
  margin: auto;
}

.leftContent,
.RightContent {
  display: flex;
  flex-direction: column;
}

.leftContent {
  justify-items: center;
  justify-content: center;
  padding-right: 35px;
}

.RightContent {
  display: flex;
  flex-direction: column;
}

.RightContent .MuiFormControl-root,
.BlocPreferences .MuiFormControl-root,
.BlocPrefeBlocPasswordrences .MuiFormControl-root {
  width: 100% !important;
}

.RightContent .MuiGrid-root,
.BlocPreferences .MuiGrid-root,
.BlocPassword .MuiGrid-root {
  display: flex !important;
  flex-direction: row !important;
  margin-bottom: 15px;
  border-radius: 8px !important;
}

.RightContent .MuiGrid-root button,
.BlocPassword button {
  height: 48px !important;
  margin: 8px !important;
  background-color: #e2f2ff !important;
  color: #2170ff !important;
  border-radius: 12px !important;
  font-weight: bold;
  font-size: 12px !important;
}

.BlocInfos .BottomModal-left button {
  background-color: #f1f0f2 !important;
}

.BlocInfos .BottomModal-left button span:nth-child(1) {
  color: rgb(75, 74, 74) !important;
}

.BlocTabContainer .BottomModal {
  padding-top: 50px !important;
}

.BlocPreferences,
.BlocPassword {
  width: auto;
  align-items: center;
}

table tfoot td {
  border-bottom: none !important;
}

/* table 4 columns T4*/

.T4columns table {
  width: auto;
}

.T4columns table td:nth-child(1) {
  width: 35%;
}

/* column 2 */
.T4columns table td:nth-child(2) {
  width: 35%;
}

/* column 3 */
.T4columns table td:nth-child(3) {
  width: 25%;
}
/* column 4 */
.T4columns table td:nth-child(4) {
  width: 10%;
}

/* table 3 columns T3*/

.T3columns table {
  width: 100%;
}

.T3columns table td:nth-child(1) {
  width: 55%;
}

/* column 2 */
.T3columns table td:nth-child(2) {
  width: 35%;
}

/* column 3 */
.T3columns table td:nth-child(3) {
  width: 10%;
}

.TextAfterImage {
  margin-left: 6px;
  position: relative;
  top: -6px;
  font-weight: 700;
}

.SearchBox button {
  margin: 0 !important;
  text-transform: none;
  font-weight: 600;
}

.SearchBox button svg {
  margin-right: 10px !important;
}

.SearchBox button span {
  margin-left: 11px !important;
}

.SearchBox {
  margin: 20px 0;
  align-items: center !important;
}

.SettingsModal .MuiBox-root .BottomModal-right {
  margin-left: 0 !important;
  justify-content: space-between;
}

.SettingsModal .MuiGrid-item {
  padding: 0 !important;
}

.SettingsModal .MuiBox-root .BottomModal-right button {
  margin: 0 !important;
}

.SettingsModal .MuiBox-root .MuiFormControl-root {
  margin: 0 !important;
  width: 100% !important;
}

.SettingsModal .MuiGrid-container {
  margin: 30px 0 5px;
  width: 100% !important;
}

.error-msg {
  color: red;
  font-size: 11px;
  padding-left: 5px;
}

.summary-word-count {
  font-size: 12px;
  color: #bbb;
}
.react-tel-input {
  border-radius: 8px !important;
  height: 45px !important;
}
.react-tel-input > input {
  width: 100% !important;
  height: 45px !important;
}
.react-tel-input .flag-dropdown {
  border-radius: 8px 0 0 8px;
}

.settings-profile-section-contaier .mui-label {
  margin-bottom: -2px;
}

.settings-profile-section-contaier .MuiFormControl-root {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.settings-profile-section-contaier
  .mui-input.clean-bck-input
  .MuiOutlinedInput-notchedOutline,
.settings-profile-section-contaier
  .mui-input.clean-bck-input
  .MuiInputBase-root.Mui-disabled
  .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

.settings-profile-section-contaier
  .mui-input.clean-bck-input
  .MuiInputBase-root.Mui-disabled {
  background: transparent;
  font-size: 27px;
  font-weight: 600;
}

.settings-profile-section-contaier
  .mui-input.clean-bck-input
  textarea:first-of-type {
  height: 45px;
}
.settings-profile-section-contaier .mui-input textarea:first-of-type,
.settings-profile-section-contaier
  .mui-select
  .MuiSelect-outlined.MuiSelect-outlined {
  font-weight: 600;
}

.settings-profile-section-contaier .change-password-btn {
  width: 120px;
  height: 40px;
  background: var(---e2f2ff);
  border-radius: 12px;
  color: #1976d2;
  font-weight: 600;
}

.settings-profile-section-contaier
  .change-password-btn.MuiButton-containedPrimary:hover {
  background-color: var(---e2f2ff);
}

/* <> preferences */
.settings-profile-section-contaier .preferences-container .row-label {
  max-width: 45%;
  flex-basis: 45%;
}

@media screen and (max-width: 600px) {
  .settings-profile-section-contaier .preferences-container .row-label {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.settings-profile-section-contaier .preferences-container .row-field {
  max-width: 50%;
  flex-basis: 50%;
}

@media screen and (max-width: 600px) {
  .settings-profile-section-contaier .preferences-container .row-field {
    max-width: 100%;
    flex-basis: 100%;
  }
}
/* </> preferences */

/* <> password  */
.settings-profile-section-contaier .password-container .row-label {
  max-width: 45%;
  flex-basis: 45%;
}

@media screen and (max-width: 600px) {
  .settings-profile-section-contaier .password-container .row-label {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.settings-profile-section-contaier .password-container .row-field {
  max-width: 50%;
  flex-basis: 50%;
}

@media screen and (max-width: 600px) {
  .settings-profile-section-contaier .password-container .row-field {
    max-width: 100%;
    flex-basis: 100%;
  }
}
/* </> password */

/* ---bkp-xs: 0px;
---bkp-sm: 600px;
---bkp-md: 960px;
---bkp-lg: 1280px;
---bkp-xl: 1536px; */

.settings-company-section-contaier .mui-label {
  margin-bottom: -2px;
}

.settings-company-section-contaier .MuiFormControl-root {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.settings-company-section-contaier
  .mui-input.clean-bck-input
  .MuiOutlinedInput-notchedOutline,
.settings-company-section-contaier
  .mui-input.clean-bck-input
  .MuiInputBase-root.Mui-disabled
  .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

.settings-company-section-contaier
  .mui-input.clean-bck-input
  .MuiInputBase-root.Mui-disabled {
  background: transparent;
  font-size: 27px;
  font-weight: 600;
}

.settings-company-section-contaier
  .mui-input.clean-bck-input
  textarea:first-of-type {
  height: 45px;
}
.settings-company-section-contaier .mui-input textarea:first-of-type,
.settings-company-section-contaier
  .mui-select
  .MuiSelect-outlined.MuiSelect-outlined {
  font-weight: 600;
}

.settings-company-section-contaier .change-password-btn {
  width: 120px;
  height: 40px;
  background: var(---e2f2ff);
  border-radius: 12px;
  color: #1976d2;
  font-weight: 600;
}

.settings-company-section-contaier
  .change-password-btn.MuiButton-containedPrimary:hover {
  background-color: var(---e2f2ff);
}

.settings-company-section-contaier .act-container {
  display: flex;
  flex-wrap: wrap;
}

.settings-company-section-contaier .act-container .activity {
  display: flex;
  align-items: center;
  color: rgb(75, 74, 74) !important;
  font-weight: 600;
  border-radius: 18px;
  font-size: 14px;
  padding: 4px 8px;
  margin: 5px 5px 5px 0px;
}

.settings-company-section-contaier .act-container .activity svg {
  width: 16px;
  height: 16px;
  margin: 0px 4px;
  cursor: pointer;
}

[aria-label="company-settings-attachments-option-list"] {
  width: 200px;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .popup-form-container {
  width: 80vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="attachment-from-lib-company-settings-buyer-aspect"]
    .popup-form-container {
    width: 55vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="attachment-from-lib-company-settings-buyer-aspect"]
    .popup-form-container {
    width: 80vw;
  }
}

/* Supplier table  */

[aria-label="attachment-from-lib-company-settings-buyer-aspect"] table tbody,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"] table thead,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"] table th,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"] table tr,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"] table td {
  display: block;
  max-width: none !important;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  table
  tr:after {
  content: " ";
  display: block;
  visibility: hidden;
  clear: both;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"] table tbody {
  height: 41vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #dbdbdb rgba(0, 0, 0, 0.03);
  scrollbar-width: thin;
  margin-top: 14px;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  table
  tr:first-of-type {
  margin-bottom: 0px;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"] table tr {
  min-width: 800px;
  margin-bottom: -2px;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"] table td {
  padding-top: 24px !important;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  table
  tbody
  tr:hover {
  background-color: #fff !important;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .MuiTableCell-root {
  border-bottom: 1px solid #e0e0e0 !important;
  float: left;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .MuiTableHead-root
  .MuiTableRow-head
  .MuiTableCell-root {
  /* border-top: 1px solid #e0e0e0 !important; */
  min-height: 58px;
  white-space: nowrap;
}

/* devide grid pixels  */
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(1) {
  width: 50%;
  min-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(2) {
  width: 25%;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(3) {
  width: 25%;
  min-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: flex-end;
}
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(3)
  .checkbox {
  margin-top: -30px;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(3)
  .checkbox.header {
  margin-top: -9px;
  margin-right: 15px;
}

/* devide grid pixels  */

/* scroll bar design  */

/* scrollbar */
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-webkit-scrollbar,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-moz-scrollbar,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-moz-scrollbar {
  width: 7px;
  height: 7px;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-ms-scrollbar,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-ms-scrollbar {
  width: 7px;
  height: 7px;
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-o-scrollbar,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-o-scrollbar {
  width: 7px;
  height: 7px;
}
/* scrollbar */

/* scrollbar-track */
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-webkit-scrollbar-track,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-moz-scrollbar-track,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-moz-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-ms-scrollbar-track,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-ms-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-o-scrollbar-track,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-o-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}
/* scrollbar-track */

/* scrollbar-thumb */
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-webkit-scrollbar-thumb,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-moz-scrollbar-thumb,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-moz-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-ms-scrollbar-thumb,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-ms-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}

[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container.MuiTableContainer-root::-o-scrollbar-thumb,
[aria-label="attachment-from-lib-company-settings-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-o-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}
/* scrollbar-thumb */

/* scroll bar design  */

/* :checked  */
/* [aria-label="attachment-from-lib-company-settings-buyer-aspect"] */
/* :checked */

.page-chart-container__hide.MuiPaper-rounded {
  box-shadow: none !important ;
  background-color: transparent;
  border: none !important;
}

.page-chart-container__view.MuiPaper-rounded {
  box-shadow: inherit;
  background-color: #fff;
  border: inherit;
}

/* <> tab labels  */
.settings-index-section-contaier .settings-index-section-tab-labels {
  max-width: 23%;
  flex-basis: 23%;
}

@media screen and (max-width: 1280px) {
  .settings-index-section-contaier .settings-index-section-tab-labels {
    max-width: 32%;
    flex-basis: 32%;
  }
}

@media screen and (max-width: 960px) {
  .settings-index-section-contaier .settings-index-section-tab-labels {
    max-width: 75%;
    flex-basis: 75%;
  }
}

@media screen and (max-width: 600px) {
  .settings-index-section-contaier .settings-index-section-tab-labels {
    max-width: 90%;
    flex-basis: 90%;
  }
}

[aria-label="settings-index-section-tab-labels-block"] {
  align-items: center;
}
/* </> tab labels  */

/* <> tab Content  */
.settings-index-section-contaier .settings-index-section-tab-content {
  max-width: 75%;
  flex-basis: 75%;
}

@media screen and (max-width: 1280px) {
  .settings-index-section-contaier .settings-index-section-tab-content {
    max-width: 65%;
    flex-basis: 65%;
  }
}

@media screen and (max-width: 960px) {
  .settings-index-section-contaier .settings-index-section-tab-content {
    max-width: 90%;
    flex-basis: 90%;
  }
}
/* </> tab Content  */

/* className={mdScreen ? "w90-percent-box mb-4" : "w23-percent-box"} */
/* 
.settings-profile-section-contaier .preferences-container .row-label {
  max-width: 45%;
  flex-basis: 45%;
} */

/* 
   MuiTab-textColorPrimary Mui-selected  
     MuiTab-textColorPrimary TabMenu css-aym9vq-MuiButtonBase-root-MuiTab-root" */

.supplier-list-details-info-container *,
#supplier-list-info-details-status,
#supplier-list-info-details-option-list,
#supplier-list-info-details-attach-option-list,
[aria-label="supplier-list-info-details-contact-popup"],
[aria-label="supplier-list-info-details-attach-link-popup"] {
  z-index: 1600 !important;
}

.supplier-list-details-info-container .contact {
  margin-bottom: 10px;
}

.supplier-list-details-info-container .contact .icon {
  color: #80808085;
  font-size: 18px;
}

.supplier-list-details-info-container .contact .link {
  color: var(---1e1d1d);
  text-decoration: none;
  margin-left: 8px;
  word-break: break-word;
  padding-right: 15px;
}

.supplier-list-details-info-container .contact .link:hover {
  color: -p-;
  color: var(---2170ff-p-);
}

.supplier-list-details-info-container .MuiFormControl-root,
.MuiInputBase-root {
  height: 44px;
}

.supplier-list-details-info-container .btn-mui-table-option.MuiButton-root {
  background: #fff;
  color: -p-;
  color: var(---2170ff-p-);
  height: 44px;
}

.table-container tbody td[aria-label="contact-name-col"],
.table-container tbody th[aria-label="contact-name-col"] {
  font-weight: 600;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.supplier-edit-board-details-container *,
#menu-category {
  z-index: 1600 !important;
  font-size: 13px !important;
}

.supplier-edit-board-details-container .edit-btn {
  height: 26px;
  width: 52px;
  border-radius: 8px;
  font-size: 16px;
  background-color: #fff !important;
  border-color: -p- !important;
  border-color: var(---2170ff-p-) !important;
  border-width: 1px !important;
  color: -p- !important;
  color: var(---2170ff-p-) !important;
}

.supplier-edit-board-details-container .edit-btn:hover {
  background-color: -p- !important;
  background-color: var(---2170ff-p-) !important;
  color: #fff !important;
}

.supplier-edit-board-details-container .title-header {
  font-size: 14px !important;
  margin-top: 0px;
}

.supplier-edit-board-details-container .scorecard-container {
  position: relative;
  display: inline-flex;
}

.supplier-edit-board-details-container
  .scorecard-container
  .MuiCircularProgress-root {
  width: 100px !important;
  height: 100px !important;
}

.supplier-edit-board-details-container
  .scorecard-container
  .MuiCircularProgress-colorPrimary {
  color: -p-;
  color: var(---2170ff-p-);
}

.supplier-edit-board-details-container
  .scorecard-container
  .MuiCircularProgress-colorInherit {
  color: var(---ecdfe4);
  position: absolute;
  z-index: 1599 !important;
}

.supplier-edit-board-details-container
  .scorecard-container
  .MuiCircularProgress-circle,
.supplier-edit-board-details-container
  .scorecard-container
  .MuiCircularProgress-circleDeterminate {
  stroke-width: 1.6 !important;
}

.supplier-edit-board-details-container .scorecard-container .score-progress {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.supplier-edit-board-details-container
  .scorecard-container
  .score-progress
  .score-progress-number {
  font-size: 28px !important;
}

.supplier-edit-board-details-container .tag {
  border-radius: 18px;
  margin-left: 5px;
  margin-top: 5px;
}

.supplier-edit-board-details-container .tag.badge {
  padding: 0.2em 0.5em;
  display: flex;
  align-items: center;
}

.supplier-edit-board-details-container .mui-input {
  height: 43px !important;
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  background-color: #fff;
}

.supplier-edit-board-details-container .mui-select .MuiInputBase-root {
  height: 43px;
  background-color: #fff;
}

.supplier-edit-board-details-container .mui-select.MuiFormControl-root {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.supplier-edit-board-details-container .mui-select .MuiSelect-iconOutlined {
  margin-top: 3px;
  font-size: 18px !important;
}

.supplier-list-details-container .MuiFormLabel-root,
.font-13 {
  font-size: 13px !important;
}

.supplier-list-details-container .scroll-page {
  overflow-y: scroll;
  height: 100vh;
}

.supplier-list-details-container .scroll-page {
  scrollbar-color: #dbdbdb rgba(0, 0, 0, 0.03);
  scrollbar-width: thin;
}

.supplier-list-details-container .scroll-page::-webkit-scrollbar {
  height: 1px;
  width: 1px;
}

.supplier-list-details-container .scroll-page::-moz-scrollbar {
  height: 1px;
  width: 1px;
}

.supplier-list-details-container .scroll-page::-ms-scrollbar {
  height: 1px;
  width: 1px;
}

.supplier-list-details-container .scroll-page::-o-scrollbar {
  height: 1px;
  width: 1px;
}

#supplier-table-list-buyer-aspect .filter-box-container .filter-box .btn-mui {
  min-height: 45px;
  margin-top: -50px;
  border-radius: 8px !important;
}

@media screen and (max-width: 960px) {
  #supplier-table-list-buyer-aspect .filter-box-container .filter-box .btn-mui {
    margin-top: 0px;
  }
}

/* Popup Design  */
[aria-label="create-supplier-popup-buyer-aspect"] .popup-form-container {
  width: 40vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="create-supplier-popup-buyer-aspect"] .popup-form-container {
    width: 40vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="create-supplier-popup-buyer-aspect"] .popup-form-container {
    width: 50vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 600px) {
  [aria-label="create-supplier-popup-buyer-aspect"] .popup-form-container {
    width: 70vw;
  }
}

[aria-label="create-supplier-popup-buyer-aspect"] .btn-mui {
  font-size: 16px !important;
}

/* bottom buttons  */

[aria-label="create-supplier-popup-buyer-aspect"]
  .popup-form-container
  .bottom-button-box-container {
  margin-top: 3em;
}

[aria-label="create-supplier-popup-buyer-aspect"]
  .popup-form-container
  .bottom-button-box-container
  .left-side {
  min-width: 165px;
}
/* bottom buttons  */

/* first-step popup form */

.invite-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0 40px 0;
}
.common-tab {
  font-size: 16px;
  background-color: #fff;
  color: #000;
  padding: 0px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  text-transform: capitalize;
  padding: 12px 50px;
  font-weight: 600;
  border-bottom: 1px solid #2170ff;
  cursor: pointer;
}
.activeTab {
  background-color: #2170ff;
  color: #fff;
}


.table {
  border-radius: 20px;
}

.item-container {
  height: 45px;
  flex: 1 1;
  font-weight: 700;
  color: var(--x424143);
  line-height: 24px;
  white-space: nowrap;
  font-family: "Noto Sans", sans-serif;
  margin-top: 30px;
  color: #424143;
}

.active-btn-item {
  background-color: #def8f3 !important;
  color: #0ad6b8 !important;
  font-size: 10px !important;
  box-shadow: none !important;
  border-radius: 7px !important;
  padding: 2px 2px 2px 5px !important;
  cursor: pointer;
}
.inactive-btn-item {
  background-color: #f8dede !important;
  color: #d60a0a !important;
  font-size: 10px !important;
  box-shadow: none !important;
  border-radius: 7px !important;
  padding: 2px 3px 2px 7px !important;
  cursor: pointer;
}
.active-btn-item :hover {
  border: none;
}
/* .edit-delete-btn-item{
      background-color: white !important;
      color : #2170FF !important;
      border : 1px solid #2170FF !important;
      font-size: 7px !important;
      box-shadow: none !important;
      border-radius: 100px !important;
      padding: 0px 0px 0px 0px !important;
      cursor: pointer;
      margin-top: 15px !important;
  } */

.active-btn-popup {
  background-color: #def8f3 !important;
  color: #0ad6b8;
}

.inactive-btn-popup,
.active-btn-popup {
  font-size: 13px !important;
  /* margin-bottom: 5px !important; */
  border: none !important;
  margin: 4px !important;
  cursor: pointer;
  border-radius: 4px;
  line-height: 27px;
  font-weight: 700;
  padding: 0 25px;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  margin: 4px !important;
  border-radius: 4px !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  margin: 4px !important;
}

.inactive-btn-popup {
  background-color: #f1f0f2 !important;
  color: #a19b9d !important;
}

.MuiList-root {
  padding: 7px !important;
}

.icn-active-btn {
  margin-left: -20px !important;
  color: #b5b0b0;
}

.MuiPaper-rounded {
  border-radius: 12px !important;
}

.MuiDialogTitle-root h2 {
  font-weight: 700 !important;
}

.MuiPaper-elevation1 {
  box-shadow: 0px 3px 6px #00000029 !important;
}

.makeStyles-root-64 {
  background-color: white !important;
}

.makeStyles-root-64 svg {
  color: black !important;
}

.MuiMenuItem-root {
  font-size: 13px !important;
}
.MuiSelect-select {
  font-style: normal !important;
}
.table-item-img {
  flex: 1 1;
  width: 48px;
  border-radius: 5px;
  object-fit: cover;
}

.craete-edit-form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 30%;
  background-color: #fff;
  padding: 3em;
}

/* is admin switch toggle  */
/* .craete-edit-form-container #is-admin-switch {
  width: 38px;
  height: 22px;
  margin-left: 1.5rem;
}

.craete-edit-form-container #is-admin-switch + span {
  color: var(---a19b9d);
}

.craete-edit-form-container #is-admin-switch.Mui-checked .MuiSwitch-track {
  background: var(---2170ff-p-);
}

.craete-edit-form-container #is-admin-switch .MuiSwitch-track {
  background-color: var(---a19b9d);
}

.craete-edit-form-container #is-admin-switch.Mui-checked .MuiSwitch-thumb {
  left: 18px;
  top: 3px;
}

.craete-edit-form-container #is-admin-switch .MuiSwitch-thumb {
  width: 16px;
  height: 16px;
  top: 3px;
  left: 3px;
} */
/* is admin switch toggle  */

.table {
  border-radius: 20px;
}

.item-container {
  height: 45px;
  flex: 1 1;
  font-weight: 700;
  color: var(--x424143);
  line-height: 24px;
  white-space: nowrap;
  font-family: "Noto Sans", sans-serif;
  margin-top: 30px;
  color: #424143;
}

.active-btn-item {
  background-color: #def8f3 !important;
  color: #0ad6b8 !important;
  font-size: 10px !important;
  box-shadow: none !important;
  border-radius: 7px !important;
  padding: 2px 2px 2px 5px !important;
  cursor: pointer;
}
.inactive-btn-item {
  background-color: #f8dede !important;
  color: #d60a0a !important;
  font-size: 10px !important;
  box-shadow: none !important;
  border-radius: 7px !important;
  padding: 2px 3px 2px 7px !important;
  cursor: pointer;
}
.active-btn-item :hover {
  border: none;
}
/* .edit-delete-btn-item{
      background-color: white !important;
      color : #2170FF !important;
      border : 1px solid #2170FF !important;
      font-size: 7px !important;
      box-shadow: none !important;
      border-radius: 100px !important;
      padding: 0px 0px 0px 0px !important;
      cursor: pointer;
      margin-top: 15px !important;
  } */

.active-btn-popup {
  background-color: #def8f3 !important;
  color: #0ad6b8;
}

.inactive-btn-popup,
.active-btn-popup {
  font-size: 13px !important;
  /* margin-bottom: 5px !important; */
  border: none !important;
  margin: 4px !important;
  cursor: pointer;
  border-radius: 4px;
  line-height: 27px;
  font-weight: 700;
  padding: 0 25px;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  margin: 4px !important;
  border-radius: 4px !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  margin: 4px !important;
}

.inactive-btn-popup {
  background-color: #f1f0f2 !important;
  color: #a19b9d !important;
}

.MuiList-root {
  padding: 7px !important;
}

.icn-active-btn {
  margin-left: -20px !important;
  color: #b5b0b0;
}

.MuiPaper-rounded {
  border-radius: 12px !important;
}

.MuiDialogTitle-root h2 {
  font-weight: 700 !important;
}

.MuiPaper-elevation1 {
  box-shadow: 0px 3px 6px #00000029 !important;
}

.makeStyles-root-64 {
  background-color: white !important;
}

.makeStyles-root-64 svg {
  color: black !important;
}

.MuiMenuItem-root {
  font-size: 13px !important;
}
.MuiSelect-select {
  font-style: normal !important;
}
.table-item-img {
  flex: 1 1;
  width: 48px;
  border-radius: 5px;
  object-fit: cover;
}

.craete-edit-form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 30%;
  background-color: #fff;
  padding: 3em;
}

/* is admin switch toggle  */
/* .craete-edit-form-container #is-admin-switch {
  width: 38px;
  height: 22px;
  margin-left: 1.5rem;
}

.craete-edit-form-container #is-admin-switch + span {
  color: var(---a19b9d);
}

.craete-edit-form-container #is-admin-switch.Mui-checked .MuiSwitch-track {
  background: var(---2170ff-p-);
}

.craete-edit-form-container #is-admin-switch .MuiSwitch-track {
  background-color: var(---a19b9d);
}

.craete-edit-form-container #is-admin-switch.Mui-checked .MuiSwitch-thumb {
  left: 18px;
  top: 3px;
}

.craete-edit-form-container #is-admin-switch .MuiSwitch-thumb {
  width: 16px;
  height: 16px;
  top: 3px;
  left: 3px;
} */
/* is admin switch toggle  */

.auth-box-container .success-page-logo {
  text-align: -webkit-center;
  text-align: center;
  margin-bottom: 6em;
}

.auth-box-container .success-page-logo img {
  width: 60%;
}

@media screen and (min-width: 1475px) {
  .auth-box-container .success-page-logo {
    width: 175px;
    height: 30px;
  }
}

@media screen and (min-width: 960px) {
  .auth-box-container .success-page-logo {
    width: 150px;
    height: 30px;
  }

  .auth-box-container .success-page-logo {
    margin-bottom: 5em;
  }

  .auth-box-container .form-box-container {
    background-color: #fff;
    padding: 33px 16px;
    border-radius: 30px;
    margin-top: 25px;
  }

  .MuiTypography-subtitle2 {
    font-size: 0.75rem !important;
    font-family: Noto Sans, sans-serif;
    font-weight: 500;
    line-height: 1.57;
  }

  .auth-box-container .text-logo-box-container-success {
    position: relative;
    /* display: list-item !important; */
    text-align: -webkit-center;
  }
}

.auth-box-container .text-logo-box-container-success {
  position: relative;
  display: none;
  text-align: -webkit-center;
  text-align: center;
}

@media screen and (min-width: 1280px) {
  .auth-box-container .text-logo-box-container-success {
    display: block;
  }

  .auth-box-container .success-page-logo {
    width: 150px;
    height: 30px;
  }
}

.auth-box-container .text-logo-box-success {
  /* background: #94c8f157; */
  padding: 15px 0px;
  border-radius: 100px 0px;
  /* width: 100px; */
  text-transform: lowercase;
}

.auth-box-container .text-logo-box-success span {
  font-family: "Raleway", sans-serif !important;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 3px;
  color: #fff;
  opacity: 1;
  text-transform: lowercase;
}

@media screen and (min-width: 1475px) {
  .auth-box-container .text-logo-box-success {
    /* width: 120px; */
    height: 52px !important;
  }
}

.form-box-container span {
  font-weight: 700;
  /* font-size: 20px; */
}

.email-content-text-email {
  text-align: -webkit-center;
}

.text-contact-support {
  margin-top: 17px;
  font-size: 10px;
  color: #bbbbbb;
}

.contact-support-color {
  color: #72a3fe;
}

.footer-alredy-account-text {
  margin-top: 3em;
}

#auction-list-filter-box-supplier-aspect .filter-field-container,
#auction-list-filter-box-supplier-aspect
  .filter-box-container
  .filter-field-container {
  align-items: normal;
}

.table-container.buyer-aspect .aution-img-container {
  height: 3em;
  border: 2px transparent solid;
  border-radius: 10px;
  margin-right: 2em;
  width: 3em;
  display: block;
}

.table-container.buyer-aspect .aution-img-container img {
  padding: 5px;
  height: 100%;
}

.table-container.buyer-aspect .aution-img-container.blue {
  background-color: -p-;
  background-color: var(---2170ff-p-);
}
.table-container.buyer-aspect .aution-img-container.grey {
  background-color: var(---575ba7);
}
.table-container.buyer-aspect .aution-img-container.green {
  background-color: -s-;
  background-color: var(---00d4b5-s-);
}

.table-container.buyer-aspect .auction-title h3 {
  margin-bottom: 2px;
}

.table-container.buyer-aspect .auction-title p {
  padding-top: 0px !important;
  margin-top: 0px;
  color: var(---a19b9d);
  font-weight: 100;
  font-size: 12px;
}

.table-container.buyer-aspect .auction-datetime {
  white-space: pre-wrap !important;
}

.form2CheckBox label .MuiTypography-root {
  font-weight: 600;
  font-size: 13px;
}

.container .imgPreview.doc_box_auction {
  margin: 0 !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #dbe8ff;
  border: 2px dashed #2170ff;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.container .imgPreview.doc_box_auction .upload-btn {
  background-color: #fff;
  color: #2170ff;
  margin: 20px 0;
  font-size: 13px;
  border-radius: 5em;
  padding: 0.5rem 2rem;
  font-weight: 700;
}

#auction-tab-list .MuiTabs-flexContainer {
  flex-wrap: wrap;
}

[aria-label="create-auction-zero-step-buyer-aspect"]
  .StepControl
  .divider-container,
[aria-label="create-auction-first-step-buyer-aspect"]
  .StepControl
  .divider-container,
[aria-label="create-auction-second-step-buyer-aspect"]
  .StepControl
  .divider-container,
[aria-label="create-auction-third-step-buyer-aspect"]
  .StepControl
  .divider-container,
[aria-label="create-auction-fourth-step-buyer-aspect"]
  .StepControl
  .divider-container,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .StepControl
  .divider-container {
  max-width: 60px;
}

@media screen and (max-width: 960px) {
  [aria-label="create-auction-zero-step-buyer-aspect"]
    .form-stepper-label-container
    .step-text,
  [aria-label="create-auction-first-step-buyer-aspect"]
    .form-stepper-label-container
    .step-text,
  [aria-label="create-auction-second-step-buyer-aspect"]
    .form-stepper-label-container
    .step-text,
  [aria-label="create-auction-third-step-buyer-aspect"]
    .form-stepper-label-container
    .step-text,
  [aria-label="create-auction-fourth-step-buyer-aspect"]
    .form-stepper-label-container
    .step-text,
  [aria-label="create-auction-fifth-step-buyer-aspect"]
    .form-stepper-label-container
    .step-text {
    display: none;
  }
}

.auction-duplicate-success-icon {
  width: 60px !important;
  height: 60px !important;
}

.filter-table-header {
  margin-top: 2em;
  align-items: center;
  padding: 15px 0px;
}

.filter-table-header .title {
  font-size: 2em;
  font-weight: 600;
}

.filter-table-header .buttons-box {
  text-align: end;
}

/* small screen  */
@media screen and (max-width: 600px) {
  .filter-table-header .buttons-box {
    text-align: center;
  }
  .filter-table-header .title {
    text-align: center;
  }
}

.filter-box-container {
  position: relative;
}

.filter-box-container .filter-box {
  position: absolute;
  /* margin-top: 1em; */
  z-index: 2;
  border-radius: 15px !important;
  width: 100%;
  top: 0;
  right: 0;
  padding: 1.5em 2em;
  box-shadow: 0px 1px 4px 2px #dddbdb !important;
}

.filter-box-container .filter-action-container,
.filter-box-container .filter-field-container {
  display: flex;
  align-items: flex-end;
}

.filter-box-container .filter-action-container button {
  margin: 0 !important;
  white-space: nowrap;
  padding: 0.9em 2em;
  min-width: 14em;
  text-transform: capitalize;
  border-radius: 8px;
  min-height: 48px;
}

.filter-box-container .filter-action-container .select-field:nth-child(1) {
  padding-right: 20px !important;
}

.filter-box-container .filter-action-container {
  margin-left: 0 !important;
}

.filter-box-container .select-field label {
  line-height: 30px;
  font-size: 14px !important;
  color: #a19b9d;
}

.filter-box-container .select-field input {
  height: 48px;
  font-weight: 700;
}

.filter-box-container .filter-field-container .select-field {
  /* padding-right: 20px !important; */
  flex-grow: 1;
}

.filter-box-container
  .filter-field-container
  .select-field
  .MuiOutlinedInput-root {
  width: 100%;
}

/* zero-step popup form */
[aria-label="create-auction-zero-step-buyer-aspect"] .popup-form-container {
  width: 40vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="create-auction-zero-step-buyer-aspect"] .popup-form-container {
    width: 30vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="create-auction-zero-step-buyer-aspect"] .popup-form-container {
    width: 50vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 600px) {
  [aria-label="create-auction-zero-step-buyer-aspect"] .popup-form-container {
    width: 70vw;
  }
}
/* zero-step popup form */

/* list-all-auction-table-supplier-aspect */

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .mui-select
  .MuiInputBase-root {
  font-weight: 600;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .mui-select
  .MuiSelect-icon {
  color: rgb(33 33 33);
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .aution-img-container {
  height: 3em;
  border: 2px transparent solid;
  border-radius: 10px;
  margin-right: 1em;
  width: 3em;
  display: block;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .aution-img-container.disabled {
  opacity: 0;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .aution-img-container
  img {
  padding: 5px;
  height: 100%;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .aution-img-container.blue {
  background-color: -p-;
  background-color: var(---2170ff-p-);
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .aution-img-container.grey {
  background-color: var(---575ba7);
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .aution-img-container.green {
  background-color: -s-;
  background-color: var(---00d4b5-s-);
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .auction-title
  h3 {
  margin-bottom: 2px;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .auction-title
  p {
  padding-top: 0px !important;
  margin-top: 0px;
  color: #ada7a9;
  font-weight: 100;
  font-size: 12px;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .auction-datetime {
  white-space: pre-wrap !important;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container .status {
  border-radius: 6px !important;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container .status {
  border-radius: 6px !important;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .badge.badge-win.status {
  background: #a5eda7 0% 0% no-repeat padding-box;
  border-color: #a5eda7;
  color: #509148;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .badge.badge-success.status {
  /* background: #a5eda7 0% 0% no-repeat padding-box;
    border-color: #a5eda7; */
  color: #307e73;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .badge.badge-secondary.status {
  background: #a0b2c4 0% 0% no-repeat padding-box;
  border-color: #a0b2c4;
  color: #375572;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .badge.badge-secondary.status {
  background: #a0b2c4 0% 0% no-repeat padding-box;
  border-color: #a0b2c4;
  color: #375572;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .badge.badge-warning-outline.status {
  background: #ffd3b2 0% 0% no-repeat padding-box;
  border-color: #ffd3b2;
  color: #8c6140;
}

.details-banner.supplier-aspect .project-title .sm-icon {
  width: 36px;
  height: 36px;
  margin-right: 0.5em;
  background-color: -p-;
  background-color: var(---2170ff-p-);
  border-radius: 8px;
}
.details-banner.rfx-seller-banner {
  height: 11em !important;
}

.details-banner.supplier-aspect .project-title .sm-icon.cp-icon {
  color: #fff;
  -webkit-transform: rotatey(180deg);
          transform: rotatey(180deg);
  padding: 7px;
  opacity: 0.66;
}

.details-banner.supplier-aspect .banner-container .project-title .status {
  /* width: inherit; */
  height: 1.6em;
  vertical-align: super;
  font-size: 16px !important;
  font-weight: 300 !important;
  padding: 4px 10px !important;
  border-radius: 6px !important;
}

.badge.badge-danger {
  background: var(---fd5656) 0% 0% no-repeat padding-box;
  border-color: var(---fd5656);
  color: #fff;
}

.details-banner.supplier-aspect .datetime-picker-cotainer {
  position: relative;
}

.details-banner.supplier-aspect .datetime-picker-cotainer .MuiFormControl-root {
  width: 15em;
  margin: 0px;
}

.details-banner.supplier-aspect .datetime-picker-cotainer .MuiButtonBase-root {
  position: absolute;
  left: 0;
  padding: 0;
  color: -p-;
  color: var(---2170ff-p-);
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.details-banner.supplier-aspect .datetime-picker-cotainer .MuiInputBase-root {
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 32px;
}

.details-banner.supplier-aspect
  .datetime-picker-cotainer
  .MuiInputBase-root
  input {
  margin-left: 1em;
  border-bottom: 2px solid transparent;
  padding: 1px 6px;
}

.details-banner.supplier-aspect
  .datetime-picker-cotainer.__view
  .MuiInputBase-root
  input {
  border-bottom-color: transparent;
  background-color: transparent;
}

.details-banner.supplier-aspect
  .datetime-picker-cotainer.__edit
  .MuiInputBase-root
  input {
  border-bottom-color: -p-;
  border-bottom-color: var(---2170ff-p-);
  background-color: #badeff;
}

.details-banner.supplier-aspect
  .datetime-picker-cotainer
  .MuiInputBase-root
  .MuiOutlinedInput-notchedOutline {
  border: none;
  margin: 0;
  padding: 0;
}

.details-banner.supplier-aspect
  .banner-container
  .editable-board-container
  .image {
  width: 93px;
}

.details-banner.supplier-aspect .project-title__smal span:nth-of-type(2) {
  color: -p-;
  color: var(---2170ff-p-);
}

.details-banner.supplier-aspect .btn-danger {
  background-color: var(---dc3545) !important;
  border-color: var(---dc3545) !important;
  font-size: 16px !important;
  letter-spacing: 0.02em;
}

.details-banner.supplier-aspect .btn-success {
  background-color: var(---13c2c2) !important;
  border-color: var(---13c2c2) !important;
  font-size: 16px !important;
  letter-spacing: 0.02em;
  margin-right: 20px;
}

.supp-auction-details-general-info-container .file-name-container {
  display: flex;
  overflow: hidden;
}

.supp-auction-details-general-info-container .file-name-container .icon {
  width: 20px;
  height: 20px;
  color: var(---a19b9d);
  vertical-align: middle;
}

.supp-auction-details-general-info-container .file-name-container .name {
  margin-left: 15px;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}

.read-more-paragraph {
    overflow: hidden;
    position: relative;
  }
  
  .paragraph-text {
    max-height: 360px;
    overflow: hidden;
    transition: max-height 0.3s ease;
    margin-bottom: 8px;
  }
  
  .paragraph-text.expanded {
    max-height: none;
  }
  
  .read-more-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    color: #2170FF;
    font-weight: 600;
  }
.supp-auction-details-items-list-container .header-title {
  font-size: 1.3em;
  font-weight: 700;
}

.supp-auction-details-items-list-container .header-button {
  padding: 0px;
  font-weight: 700 !important;
  color: -p-;
  color: var(---2170ff-p-);
}

.supp-auction-details-items-list-container .header-button .btn {
  float: right;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.1em;
}

.table-item-img {
  height: 48px;
}

.supp-auction-details-items-list-container .btn-primary {
  color: #fff !important;
  background-color: -p- !important;
  background-color: var(---2170ff-p-) !important;
  border-color: -p- !important;
  border-color: var(---2170ff-p-) !important;
}

[aria-label="auction-details-items-popup"] .popup-form-container {
  width: 40vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="auction-details-items-popup"] .popup-form-container {
    width: 45vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="auction-details-items-popup"] .popup-form-container {
    width: 50vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 960px) {
  [aria-label="auction-details-items-popup"] .popup-form-container {
    width: 70vw;
  }
}

.supp-auction-details-bid-list-container .header-title {
  font-size: 1.3em;
  font-weight: 700;
}

.supp-auction-details-bid-list-container .options-contaier {
  text-align: end;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.supp-auction-details-bid-list-container .table-container.mybid-table {
  opacity: 0;
  height: 0;
}

.supp-auction-details-bid-list-container .table-container.allbid-table {
  opacity: 0;
  height: 0;
}

.supp-auction-details-bid-list-container .table-container.__active {
  opacity: 1;
  height: 100%;
  max-height: 50vh;
  -webkit-animation: 1s opacity-animate;
          animation: 1s opacity-animate;
}

.supp-auction-details-bid-list-container
  .options-contaier
  .MuiFormControlLabel-root {
  margin-right: 0px;
}

.supp-auction-details-bid-list-container .options-contaier span.my-bid {
  font-weight: 600;
  margin-right: 10px;
}

.supp-auction-details-bid-list-container .options-contaier span.all-bid {
  font-weight: 600;
  margin-right: 15px;
}

.supp-auction-details-bid-list-container .header-button {
  padding: 0px;
  font-weight: 700 !important;
  color: -p-;
  color: var(---2170ff-p-);
}

.supp-auction-details-bid-list-container .header-button .btn {
  cursor: pointer;
  font-weight: 600;
  font-size: 1.1em;
}

.table-item-img {
  height: 48px;
}

.supp-auction-details-bid-list-container .btn-primary {
  color: #fff !important;
  background-color: -p- !important;
  background-color: var(---2170ff-p-) !important;
  border-color: -p- !important;
  border-color: var(---2170ff-p-) !important;
}

.supp-auction-details-bid-list-container
  .switchable-toggle.Mui-checked
  .MuiSwitch-track {
  background: -s-;
  background: var(---00d4b5-s-);
}

.supp-auction-details-bid-list-container .switchable-toggle .MuiSwitch-track {
  background: -p-;
  background: var(---2170ff-p-);
}

[aria-label="auction-details-bid-popup"] .popup-form-container {
  width: 40vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="auction-details-bid-popup"] .popup-form-container {
    width: 45vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="auction-details-bid-popup"] .popup-form-container {
    width: 50vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 960px) {
  [aria-label="auction-details-bid-popup"] .popup-form-container {
    width: 70vw;
  }
}

.supp-auction-details-live-bid-container .number-bis span:first-child {
  color: var(---a19b9d);
  margin-right: 10px;
}

.supp-auction-details-live-bid-container .number-bis span:last-child {
  color: var(---13c2c2);
  font-weight: 600;
}

.supp-auction-details-live-bid-container .reserve-price-container {
  cursor: pointer;
}

.supp-auction-details-live-bid-container .reserve-price-container .price {
  font-size: 50px;
  font-weight: 600;
  position: relative;
}

.supp-auction-details-live-bid-container .reserve-price-container .price.hazel {
  color: var(---ffc107);
}

.supp-auction-details-live-bid-container .reserve-price-container .price.pass {
  color: #000;
}

.supp-auction-details-live-bid-container
  .reserve-price-container
  .price
  .sm-sup-rank {
  font-size: 20px;
  padding-top: -50 !important;
  position: absolute;
  font-weight: 700;
  top: 6px;
}

.supp-auction-details-live-bid-container
  .reserve-price-container
  .price.cancel {
  color: var(---dc3545);
}

.supp-auction-details-live-bid-container .reserve-price-container .label {
  font-size: 13px;
}

.supp-auction-details-live-bid-container .reserve-price-container .label.hazel {
  color: var(---ffc107);
}

.supp-auction-details-live-bid-container .reserve-price-container .label.pass {
  color: var(---009b73);
}

.supp-auction-details-live-bid-container .minimum-step {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.supp-auction-details-live-bid-container .minimum-step div:first-child {
  color: var(---a19b9d);
  margin-right: 20px;
  font-size: 22px;
  /* font-weight: 600; */
}

.supp-auction-details-live-bid-container .minimum-step div:last-child {
  font-size: 22px;
  font-weight: 600;
}

.supp-auction-details-live-bid-container
  .place-bid-container
  .decrease
  .MuiButton-startIcon,
.supp-auction-details-live-bid-container
  .place-bid-container
  .increase
  .MuiButton-startIcon {
  margin-left: 0px;
  margin-right: 0px;
}

.supp-auction-details-live-bid-container
  .place-bid-container
  .btn-mui.increase {
  padding: 0.4rem 1.5rem !important;
}

.supp-auction-details-live-bid-container
  .place-bid-container
  .btn-mui.decrease {
  padding: 0.4rem 1.5rem !important;
}

.supp-auction-details-live-bid-container
  .place-bid-container
  .btn-mui.btn-primary,
.supp-auction-details-live-bid-container
  .place-bid-container
  .btn-mui.btn-primary,
.supp-auction-details-live-bid-container
  .place-bid-container
  .btn-mui.btn-primary {
  color: #fff !important;
  background-color: -p- !important;
  background-color: var(---2170ff-p-) !important;
  border-color: -p- !important;
  border-color: var(---2170ff-p-) !important;
}

.supp-auction-details-live-bid-container
  .place-bid-container
  .btn-mui.place-btn
  .MuiButton-label {
  font-size: 16px;
}
.supp-auction-details-live-bid-container
  .place-bid-container
  .btn-mui.place-btn {
  margin-top: 12px !important;
}

.supp-auction-details-live-bid-container
  .place-bid-container
  .decrease.MuiButton-root {
  min-width: 100%;
}

.supp-auction-details-live-bid-container
  .place-bid-container
  .increase.MuiButton-root {
  min-width: 100%;
  margin-left: 0px;
}

.supp-auction-details-live-bid-container
  .place-bid-container
  textarea[name="price"] {
  font-weight: 700;
}

/* //////////////////////////////// */
.supp-auction-details-live-bid-container .item-stc-container {
  text-align: -webkit-center;
  text-align: -moz-center;
  text-align: -ms-center;
  text-align: -o-center;
}

.supp-auction-details-live-bid-container .icon-container {
  margin: auto 0px;
}

.supp-auction-details-live-bid-container .icon {
  color: -p-;
  color: var(---2170ff-p-);
  width: 50px;
  height: 50px;
  vertical-align: bottom;
}

.supp-auction-details-live-bid-container .content-container .text {
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
}

.supp-auction-details-live-bid-container .content-container.timer .text {
  font-size: 34px;
  color: #fff;
  margin-top: 0px;
}

.supp-auction-details-live-bid-container .content-container .mui-label {
  white-space: nowrap;
}

.supp-auction-details-live-bid-container .content-container.timer .mui-label {
  line-height: 13px;
  color: #fff !important;
}

.supp-auction-details-live-bid-container .content-container .hint-icon {
  width: 16px;
  height: 16px;
  color: -p-;
  color: var(---2170ff-p-);
  margin-left: 4px;
}

.supp-auction-details-container [aria-label="general-info"] > div {
  height: 100%;
}
@media (min-width: 1536px) {
  .supp-auction-details-container [aria-label="general-info"] {
    max-width: 22%;
    flex-basis: 22%;
  }

  .supp-auction-details-container [aria-label="item-bid-list"] {
    max-width: 50%;
    flex-basis: 50%;
  }

  .supp-auction-details-container [aria-label="live-bid"] {
    max-width: 28%;
    flex-basis: 28%;
  }
}

@media (max-width: 1535px) {
  .supp-auction-details-container [aria-label="general-info"] {
    order: 0;
  }

  .supp-auction-details-container [aria-label="item-bid-list"] {
    order: 2;
  }

  .supp-auction-details-container [aria-label="live-bid"] {
    order: 1;
  }
}

.details-banner.buyer-aspect .project-title {
  display: flex;
  align-items: center;
}

.details-banner.buyer-aspect .project-title .sm-icon {
  width: 36px;
  height: 36px;
  margin-right: 0.5em;
  background-color: -p-;
  background-color: var(---2170ff-p-);
  border-radius: 8px;
}

.details-banner.buyer-aspect .project-title .main-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.details-banner.buyer-aspect .project-title .badge.badge-secondary {
  background: #cfcdd0;
  border-color: var(---f1f0f2);
  color: #958f91;
}

.details-banner.buyer-aspect .project-title .sm-icon.cp-icon {
  color: #fff;
  -webkit-transform: rotatey(180deg);
          transform: rotatey(180deg);
  padding: 7px;
  opacity: 0.66;
}

.details-banner.buyer-aspect .banner-container .project-title .status {
  width: 6.5em;
  height: 1.6em;
  font-size: 16px !important;
  font-weight: 300 !important;
  min-height: 36px;
}

.details-banner.buyer-aspect .banner-container .project-title .table-container {
  margin-top: 0px;
}

.details-banner.buyer-aspect .datetime-picker-cotainer {
  position: relative;
}

.details-banner.buyer-aspect .datetime-picker-cotainer .MuiFormControl-root {
  width: 15em;
  margin: 0px;
}

.details-banner.buyer-aspect .datetime-picker-cotainer .MuiButtonBase-root {
  position: absolute;
  left: 0;
  padding: 0;
  color: -p-;
  color: var(---2170ff-p-);
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.details-banner.buyer-aspect .datetime-picker-cotainer .MuiInputBase-root {
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 32px;
}

.details-banner.buyer-aspect
  .datetime-picker-cotainer
  .MuiInputBase-root
  input {
  margin-left: 1em;
  border-bottom: 2px solid transparent;
  padding: 1px 6px;
}
.details-banner.buyer-aspect
  .datetime-picker-cotainer.project-date
  .MuiInputBase-root
  input {
  width: 90px;
}

.details-banner.buyer-aspect
  .datetime-picker-cotainer.__view
  .MuiInputBase-root
  input {
  border-bottom-color: transparent;
  background-color: transparent;
}

.details-banner.buyer-aspect
  .datetime-picker-cotainer.__edit
  .MuiInputBase-root
  input {
  border-bottom-color: -p-;
  border-bottom-color: var(---2170ff-p-);
  background-color: #badeff;
}

.details-banner.buyer-aspect
  .datetime-picker-cotainer
  .MuiInputBase-root
  .MuiOutlinedInput-notchedOutline {
  border: none;
  margin: 0;
  padding: 0;
}

.details-banner.buyer-aspect
  .banner-container
  .editable-board-container
  .image {
  width: 93px;
}

.details-form-container .title-container {
  display: flex;
  justify-content: space-between;
}

.details-form-container .title-container .edit-btn {
  height: 30px;
  width: 52px;
  height: 30px;
  border-radius: 8px;
  font-size: 16px;
}

.body-container .MuiFormControl-root {
  width: 100%;
}

.details-form-container .disclose-option {
  display: inline-flex;
  padding: 14px !important;
  margin-top: 0.5em;
  border-radius: 8px;
  border: 1px rgba(0, 0, 0, 0.2) solid;
  width: 100%;
}

.details-form-container .disclose-option .icon {
  height: 16px;
  width: 16px;
  margin: auto 10px;
  color: #1e65e6;
}

.details-form-container .awarding-check-alert {
  background-color: var(---e2f2ff);
  padding: 20px;
  margin-top: 1em;
  border-radius: 12px;
}

.details-form-container .awarding-check-alert .text {
  font-size: 14px;
  margin-top: 8px;
  color: var(---707070);
}

.details-form-container .awarding-check-alert .label {
  font-weight: 600;
}

.details-form-container .awarding-check-alert .MuiCheckbox-root {
  padding-top: 0;
}

.details-form-container .animate-max-height.__feed {
  max-height: 60em;
}

/* small screen  */
@media (min-width: 600px) {
  .mui-tooltip .MuiTooltip-tooltipPlacementTop {
    margin: 5px 0;
  }
}

#statistics-box-first-container {
  padding: 1.5em 1em;
  min-height: 9.5em;
}

#statistics-box-first-container .label {
  color: var(---a19b9d);
  font-size: 14px;
}

#statistics-box-first-container .line.MuiGrid-item {
  padding-bottom: 0px;
}

#statistics-box-first-container .icon {
  width: 18px;
  height: 18px;
  color: var(---424143);
  vertical-align: text-bottom;
}

#statistics-box-first-container .field {
  font-weight: 600;
  border: none;
  border-bottom: 1px transparent solid;
  width: 100%;
  font-family: "Noto Sans", sans-serif;
  resize: none;
  outline: none;
  font-size: 14px !important;
}

#statistics-box-first-container .field.__edit {
  font-weight: 600;
  border: none;
  border-bottom: 2px solid #0e86f5;
  background-color: #badeff;
}

#statistics-box-first-container .go-back-button {
  font-size: 16px;
  font-weight: 700;
  color: -p-;
  color: var(---2170ff-p-);
  background-color: #fff;
  border-color: #fff;
  white-space: break-spaces;
  height: 45px;
  border-radius: 12px;
  padding: 0px 12px;
}

#statistics-box-first-container .go-back-button.__edit {
  border: 1px solid -p-;
  border: 1px solid var(---2170ff-p-);
  font-size: 0.9em;
  border-radius: 8px;
  text-transform: capitalize;
}

#statistics-box-first-container .go-back-button.__save {
  border: 1px solid -p-;
  border: 1px solid var(---2170ff-p-);
  font-size: 0.9em;
  color: #fff;
  padding: 4px 10px;
  border-radius: 8px;
  text-transform: capitalize;
  background-color: -p-;
  background-color: var(---2170ff-p-);
}

#statistics-box-first-container .field.__edit:focus-visible {
  outline: none;
}

#statistics-box-second-container {
  padding: 1em;
  background-color: -p-;
  background-color: var(---2170ff-p-);
  color: #fff;
  min-height: 9.5em;
}

#statistics-box-second-container .icon {
  width: 50px;
  height: 50px;
  margin: auto;
  display: block;
}

#statistics-box-third-container {
  padding: 1em;
  padding-left: calc(1em + 8px);
  min-height: 9.5em;
}

#statistics-box-third-container .label {
  color: var(---a19b9d);
  font-size: 18px;
  font-weight: 600;
}

#statistics-box-third-container .number {
  color: var(---424143);
  font-size: 31px;
  font-weight: 600;
}

#statistics-box-third-container .time {
  color: var(---a19b9d);
  font-size: 15px;
}

.table-container .file-name-container {
  display: flex;
  overflow: hidden;
}

.table-container .file-name-container .icon {
  color: var(---a19b9d);
}

.table-container .file-name-container .name {
  margin-left: 10px;
  color: -p-;
  color: var(---2170ff-p-);
  padding-top: 3px;
  font-size: 16px;
}

/* attach document modal */
.popup-form-container {
  width: 40vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  .popup-form-container {
    width: 30vw;
  }
}

.table-record-count-libarary{
  color: var(---a19b9d);
    font-weight: 500;
    font-size: 1em;
    padding-top: 11px;
}

.image-uploader-container {
  margin: 0 !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #dbe8ff;
  border: 2px dashed #2170ff;
  border-radius: 8px;
  margin-bottom: 1rem;
  color: #000000;
  padding: 1.5em;
}

.image-uploader-container .uploader-header p {
  margin: 2px 0;
}

.image-uploader-container .uploader-header .upload-btn {
  background-color: #fff;
  color: -p-;
  color: var(---2170ff-p-);
  margin: 20px 0;
  font-size: 16px;
  border-radius: 5em;
  padding: 0.5rem 2rem;
  font-weight: 700;
  height: 40px;
  text-transform: capitalize;
}

.image-uploader-container .uploader-header .upload-icon {
  margin-top: 1em;
  height: 43px;
  width: 46px;
}

.image-uploader-container .uploader-header .upload-title {
  font-size: 20px;
  color: var(---1e1d1d);
  font-weight: 600;
}

.image-uploader-container .uploader-header .upload-description {
  font-size: 14px;
  color: var(---a19b9d);
  margin-top: 8px;
}

.image-uploader-container input[type="file"] {
  display: none;
}

.image-uploader-container .label {
  display: none;
}

.uploaded-files {
  padding: 11px 20px 10px 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin: 10px 0px 3px 0;
}


.uploaded-files .icon {
  height: 37px;
  width: 37px;
}

.uploaded-files .details {
  overflow: hidden;
}

.uploaded-files .details .name {
  font-size: 16px;
  color: var(---1e1d1d);
  font-weight: 600;
}

.uploaded-files .details .size {
  font-size: 14px;
  color: var(---a19b9d);
}

.auction-comment-container {
}

.new-comment-container {
}
.auction-comment-container .new-comment-img-container {
  margin-right: 2em;
}

.auction-comment-container img {
  width: 2.7em;
  border-radius: 50%;
}

.auction-comment-container .title-divider {
  color: #f2f2f2;
  font-size: 1em;
  opacity: 0.4;
  height: 2px;
}

.auction-comment-container .new-comment-form-container .comment-text-box {
  height: 5em;
  width: calc(100% - 2px) !important;
}

.auction-comment-container
  .new-comment-form-container
  .comment-text-box.repaly {
  height: 8em;
  width: calc(100% - 2px) !important;
}

.auction-comment-container
  .new-comment-form-container
  .comment-text-box
  .MuiInputBase-root {
  height: 5em;
}

.auction-comment-container
  .new-comment-form-container
  .comment-text-box.repaly
  .MuiInputBase-root {
  height: 8em;
}

.auction-comment-container
  .new-comment-form-container
  .comment-text-box
  textarea:first-of-type {
  max-height: 3em;
}

.auction-comment-container
  .new-comment-form-container
  .comment-text-box.repaly
  textarea:first-of-type {
  max-height: 6em;
}

.auction-comment-container .new-comment-text {
  font-weight: 600;
  border: 2px #f2f2f2 solid;
  width: -webkit-fill-available;
  width: -moz-fill-available;
  width: -ms-fill-available;
  width: -o-fill-available;
  font-family: "Noto Sans", sans-serif;
  resize: none;
  outline: none;
  border-radius: 15px;
  overflow: hidden;
  padding: 1em;
  font-size: 1em;
  margin-right: -3em;
}

.auction-comment-container .new-comment-text:visited,
.auction-comment-container .new-comment-text:focus-visible,
.auction-comment-container .new-comment-text:focus {
  border-color: -p-;
  border-color: var(---2170ff-p-);
}

.auction-comment-container .comment-container {
  display: flex;
}

.auction-comment-container .comment-container img {
  margin-right: 0.6em;
}

.auction-comment-container .comment-container .comment-container-title {
  font-size: 16px;
  font-weight: 600;
  color: -p-;
  color: var(---2170ff-p-);
  text-transform: capitalize;
}

.auction-comment-container .comment-container .comment-container-time {
  display: block;
  font-size: 0.7em;
  font-weight: 500;
  color: var(---a19b9d);
  line-height: 2em;
}

.auction-comment-container .replay-container {
  margin-left: 3.6em;
}

.auction-comment-container .replay-container .replay-container-label {
  color: -p-;
  color: var(---2170ff-p-);
  font-size: 0.8em;
  font-weight: 600;
  margin-bottom: 2em;
}

.auction-comment-container .action-btn {
  padding: 0.6em 2em;
  min-width: 10em;
  text-transform: capitalize;
  border-radius: 12px;
}

.auction-comment-container .action-btn.cancel {
  margin-right: 0.5em;
  background-color: var(---e2feff);
  color: #1976d2;
  font-weight: 600;
}

.table-container {
  margin-top: 1em;
}

.table-container.MuiPaper-rounded {
  border-radius: 0px !important;
}

.table-container.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.table-container tbody td,
.table-container tbody th {
  white-space: nowrap;
  font-weight: 600;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-container tbody td .icon,
.table-container tbody th .icon {
  font-size: 1.5em;
  margin-bottom: -3px;
  color: var(---e0a800);
  margin-left: 0.2em;
}

.table-container tbody td .action-btn {
  background-color: var(---e2f2ff) !important;
  color: -p- !important;
  color: var(---2170ff-p-) !important;
  border: px solid var(---e2f2ff) !important;
  font-size: 7px !important;
  box-shadow: none !important;
  border-radius: 50% !important;
  padding: 0px 0px 0px 0px !important;
  min-width: 1em !important;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.table-container tbody td .action-btn .MuiSvgIcon-root {
  width: 30px;
  height: 30px;
  background: var(---f7fafc);
  border-radius: 50%;
  border: 2px var(---f7fafc) solid;
}

.table-container tbody td .action-btn:hover .MuiSvgIcon-root {
  background: var(---e2f2ff);
  border-color: var(---e2f2ff);
}

.table-container tbody td .action-btn[aria-controls] .MuiSvgIcon-root {
  border-color: -p-;
  border-color: var(---2170ff-p-);
}

/* scroll bar design  */
.table-container.MuiTableContainer-root {
  scrollbar-color: #dbdbdb rgba(0, 0, 0, 0.03);
  scrollbar-width: thin;
}

/* scrollbar */
.table-container.MuiTableContainer-root::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

.table-container.MuiTableContainer-root::-moz-scrollbar {
  height: 8px;
  width: 8px;
}

.table-container.MuiTableContainer-root::-ms-scrollbar {
  height: 8px;
  width: 8px;
}

.table-container.MuiTableContainer-root::-o-scrollbar {
  height: 8px;
  width: 8px;
}
/* scrollbar */

/* scrollbar-track */
.table-container.MuiTableContainer-root::-webkit-scrollbar-track {
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}

.table-container.MuiTableContainer-root::-moz-scrollbar-track {
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}

.table-container.MuiTableContainer-root::-ms-scrollbar-track {
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}

.table-container.MuiTableContainer-root::-o-scrollbar-track {
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}
/* scrollbar-track */

/* scrollbar-thumb */
.table-container.MuiTableContainer-root::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.table-container.MuiTableContainer-root::-moz-scrollbar-thumb {
  border-radius: 10px;
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.table-container.MuiTableContainer-root::-ms-scrollbar-thumb {
  border-radius: 10px;
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.table-container.MuiTableContainer-root::-o-scrollbar-thumb {
  border-radius: 10px;
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}
/* scrollbar-thumb */

/* scroll bar design  */

.select-colored-option {
  border: none !important;
  margin: 0.3em 0;
  border-radius: 8px;
  letter-spacing: 1px;
  cursor: pointer;
}

.select-colored-option {
  border: none !important;
  margin: 0.3em 0;
  border-radius: 8px;
  letter-spacing: 1px;
  cursor: pointer;
  text-transform: capitalize !important;
}

.table-container tbody td .badge.select-colored-btn:hover {
  background-color: inherit !important;
}

.table-container tbody td .badge.select-colored-btn {
  line-height: 1em;
  border-radius: 10px;
  text-transform: capitalize;
}

.table-container tbody td .badge.select-colored-btn > span {
  margin-left: -3px !important;
}

.table-container .status {
  text-transform: capitalize !important;
  font-weight: 600 !important;
  font-size: 1em !important;
  border-radius: 10px !important;
  letter-spacing: 1px !important;
  height: 30px;
}

.table-container .status .MuiButton-endIcon {
  margin-left: 0px !important;
}

.table-container .status.badge-success.MuiButton-root:hover {
  background: var(---def8f3) 0% 0% no-repeat padding-box !important;
}

.table-container .status.badge-warning.MuiButton-root:hover {
  background: var(---ffc107) 0% 0% no-repeat padding-box !important;
}

.table-container .status.badge-secondary.MuiButton-root:hover {
  background: var(---f1f0f2) 0% 0% no-repeat padding-box !important;
}

.table-container .status.badge-purple.MuiButton-root:hover {
  background: var(---dee2ff) 0% 0% no-repeat padding-box !important;
}

.table-container .status.badge-danger.MuiButton-root:hover {
  background: var(---c82333) 0% 0% no-repeat padding-box !important;
}

.table-container .status.badge-danger-outline.MuiButton-root:hover {
  background: var(---fbeaec) 0% 0% no-repeat padding-box !important;
}

.table-container .status.badge-warning-outline.MuiButton-root:hover {
  background: var(---fcf6e5) 0% 0% no-repeat padding-box !important;
}
.table-container .status.badge-primary-outline.MuiButton-root:hover {
  background: var(---e2f2ff) 0% 0% no-repeat padding-box !important;
}

.table-container .status.badge-primary.MuiButton-root:hover {
  background: -p- 0% 0% no-repeat padding-box !important;
  background: var(---2170ff-p-) 0% 0% no-repeat padding-box !important;
}

.table-container .pagination-box-container {
  flex-grow: 1;
  padding-top: 1.3em;
  padding-bottom: 0.9em;
}

.table-container .pagination-box-container .pagination-slider-container {
  margin: auto;
}

.table-container .pagination-box-container .pagination-slider-container ul {
  justify-content: end;
  /* margin-right: 3em; */
}

.table-container .pagination-box-container .pagination-slider-container ul li {
  margin-right: 5px;
}

.table-container
  .pagination-box-container
  .pagination-slider-container
  .MuiButtonBase-root {
  width: 35px;
  height: 35px;
  border-radius: 8px;
  background-color: var(---e2f2ff);
  color: -p-;
  color: var(---2170ff-p-);
}

.table-container
  .pagination-box-container
  .pagination-slider-container
  .MuiButtonBase-root.Mui-selected {
  background-color: -p-;
  background-color: var(---2170ff-p-);
  color: #fff;
}

.table-container
  .pagination-box-container
  .pagination-slider-container
  .MuiPaginationItem-previousNext {
  background-color: #fff;
}

.table-container .pagination-box-container .pagination-total-container h3 {
  color: var(---a19b9d);
  font-weight: 500;
  font-size: 1em;
}

.table-header-container .sm-right-box {
  text-align: end;
}

@media screen and (max-width: 960px) {
  .table-header-container .sm-left-box {
    text-align: center;
  }

  .table-header-container .sm-right-box {
    text-align: center;
  }
}

.table-container .no-record-msg {
  color: -p-;
  color: var(---2170ff-p-);
  text-align: center;
  width: 100%;
  margin: 20px 0px;
  font-size: 16px;
}

/* editable row  */
.table-container.editable-table .editable-row .option-cell {
  display: none;
  align-items: center;
}

.table-container.editable-table .editable-row .option-cell.__active {
  display: flex;
}

.table-container.editable-table .editable-row .option-cell .icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.table-container.editable-table .editable-row .option-cell .icon.__edit {
  color: var(---424143);
  margin-right: 14px;
}

.table-container.editable-table .editable-row .option-cell .icon.__check {
  color: -p-;
  color: var(---2170ff-p-);
  margin-right: 14px;
}

.table-container.editable-table .editable-row .option-cell .icon.__close {
  color: #f00;
}

.table-container.editable-table .editable-row.__select {
  background-color: var(---e2f2ff);
}

.table-container.editable-table .editable-row .editable-field {
  display: none !important;
}

.table-container.editable-table .editable-row .editable-field.__active {
  display: flex !important;
}
.table-container.editable-table .editable-row .editable-field.text-show {
  display: none !important;
}

.table-container.editable-table
  .editable-row
  .editable-field.__active
  .MuiInputBase-root {
  min-width: 100px;
}
/* editable row  */


/* .live-graph-container .live-graph-range-bar-container .MuiSlider-rail {
  height: 16px;
  border-radius: 0px;
  background-color: var(---e0e0e0);
}

.live-graph-container .live-graph-range-bar-container .MuiSlider-track {
  height: 16px;
  border-radius: 0px;
  background-color: var(---2170ff-p-);
}

.live-graph-container .live-graph-range-bar-container .MuiSlider-thumb {
  border-radius: 5px;
  background-color: #fff;
  height: 24px;
} */

.live-graph-container
  .live-graph-range-bar-container
  .MuiSlider-thumb
  .bot-bar {
  height: 12px;
  width: 2px;
  background-color: #9e9e9e;
}

.live-graph-container
  .live-graph-range-bar-container
  .MuiSlider-thumb
  .bot-bar[aria-label="first"] {
  margin-right: 2px;
}

.live-graph-container
  .live-graph-range-bar-container
  .MuiSlider-thumb
  .bot-bar[aria-label="second"] {
  margin-left: 2px;
}

.live-graph-container .live-graph-container #bottom-container .MuiList-root {
  display: flex;
  margin: auto;
  flex-direction: row;
}

@media screen and (max-width: 960px) {
  .live-graph-container .live-graph-container #bottom-container .MuiList-root {
    flex-wrap: wrap;
  }
}

.live-graph-container #bottom-container .MuiListItemText-root {
  white-space: nowrap;
}

/* 
.live-graph-container [aria-label="bot-vertex"] {
  stroke-width: 2px;
  fill: #fff;
} */

.live-statistics-container {
  background-color: #fff;
  padding: 24px;
}

.live-statistics-container > div {
  /* justify-content: space-between; */
  justify-content: space-between;
}

.live-statistics-container .MuiGrid-container {
  align-items: center;
  justify-content: unset !important;
}

.live-statistics-container .mui-card.MuiGrid-item {
  padding: 24px;
}

.live-statistics-container .mui-card {
  border: 1px solid var(---e0e0e0);
  /* max-width: 238px; */
  text-align: -webkit-auto;
  text-align: -moz-auto;
  text-align: -ms-auto;
  text-align: -o-auto;
}

@media screen and (max-width: 1200px) {
  .live-statistics-container .mui-card {
    max-width: 270px;
  }
}

.live-statistics-container .mui-card.best-bid {
  /* max-width: 260px; */
  padding-left: 16px;
}
.live-statistics-container .item-stc-container {
  text-align: -webkit-center;
  text-align: -moz-center;
  text-align: -ms-center;
  text-align: -o-center;
}

.live-statistics-container .icon-container {
  margin: auto 0px;
}

.live-statistics-container .icon {
  color: -p-;
  color: var(---2170ff-p-);
  width: 50px;
  height: 50px;
  vertical-align: bottom;
}

.live-statistics-container .content-container .text {
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
}

.live-statistics-container .content-container.supplier-name .text {
  overflow: overlay;
}

.live-statistics-container
  .content-container.supplier-name
  .text::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.live-statistics-container
  .content-container.supplier-name
  .text::-webkit-scrollbar-track {
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  background: linear-gradient(
    0deg,
    rgb(239 239 239) 0%,
    rgb(255, 255, 255) 100%
  );
  border-radius: 10px;
}

.live-statistics-container
  .content-container.supplier-name
  .text::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.live-statistics-container .content-container.timer .text {
  font-size: 34px;
  color: #fff;
  margin-top: 0px;
}

.live-statistics-container .content-container .mui-label {
  white-space: nowrap;
}

.live-statistics-container .content-container.timer .mui-label {
  line-height: 13px;
  color: #fff !important;
}

.live-statistics-container .content-container .hint-icon {
  width: 16px;
  height: 16px;
  color: -p-;
  color: var(---2170ff-p-);
  margin-left: 4px;
}

.mui-tooltip.small-box .MuiTooltip-tooltip {
  padding: 6px 6px 6px 6px;
}

.app-main-section-container.live-auction {
  margin-left: 0px !important;
}

.details-banner
  .banner-container
  .project-details-buyer-aspect.editable-board-container
  .container
  .imgPreview.attach_box {
  width: 100%;
  height: 100px;
}

.details-banner
  .banner-container
  .project-details-buyer-aspect.editable-board-container
  .imgPreview
  svg {
  width: 29px;
  height: 29px;
  margin-top: 0px;
  margin-bottom: -5px;
}

.btn-white.filter-btn {
  border-color: var(---f1f0f2) !important;
}

.btn-white.active.filter-btn {
  border-color: -p- !important;
  border-color: var(---2170ff-p-) !important;
}

.filter-box-container .filter-box.__inner {
  width: calc(100% + 48px);
  right: -24px;
}

#user-role-container .ratio-btn .MuiFormControlLabel-root {
  background-color: var(---e2f2ff);
  border-radius: 12px;
  margin: 5px 0px;
  padding: 10px;
}

.BottomModal-right .MuiGrid-item {
  text-align: -webkit-center;
  text-align: -moz-center;
  text-align: -ms-center;
  text-align: -o-center;
}

#user-role-container .MuiRadio-colorSecondary.Mui-checked {
  color: -p-;
  color: var(---2170ff-p-);
}

#user-role-container .MuiRadio-root {
  color: -p-;
  color: var(---2170ff-p-);
}

[aria-label="project-details-user-popup"] .popup-form-container {
  width: 40vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="project-details-user-popup"] .popup-form-container {
    width: 30vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="project-details-user-popup"] .popup-form-container {
    width: 50vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 600px) {
  [aria-label="project-details-user-popup"] .popup-form-container {
    width: 70vw;
  }
}

.imge-user-list {
  width: 26px;
  border-radius: 50%;
  margin-right: 12px;
}

.letter-user-list {
  padding: 6px;
  background-color: var(---def8f3);
  border-radius: 50%;
  margin-right: 15px;
  color: -p-;
  color: var(---2170ff-p-);
  font-weight: 600;
  font-size: 12px;
}

/* .MuiPopover-paper {
  width: 100%;
  max-width: 416px !important;
  max-height: 200px !important;
} */
.user-dropdown-data {
  max-width: 416px !important;
  display: flex;
  align-items: center;
}
.user-dropdown-data .user-title {
  white-space: pre;
  overflow: hidden;
  word-break: break-all;
  max-width: 370px !important;
  text-overflow: ellipsis;
}
.inner-li {
  display: block !important;
  width: 100% !important;
  padding: 4px 6px !important;
}

.prodject-comment-container {
}

.new-comment-container {
}
.prodject-comment-container .new-comment-img-container {
  margin-right: 2em;
}

.prodject-comment-container img {
  width: 2.7em;
  border-radius: 50%;
}

/* 
.prodject-comment-container .new-comment-form-container .MuiFormControl-root {
  width: calc(100% - 2px) !important;
} */

.prodject-comment-container .new-comment-form-container .comment-text-box {
  height: 5em;
  width: calc(100% - 2px) !important;
}

.prodject-comment-container
  .new-comment-form-container
  .comment-text-box.repaly {
  height: 8em;
  width: calc(100% - 2px) !important;
}

.prodject-comment-container
  .new-comment-form-container
  .comment-text-box
  .MuiInputBase-root {
  height: 5em;
}

.prodject-comment-container
  .new-comment-form-container
  .comment-text-box.repaly
  .MuiInputBase-root {
  height: 8em;
}

.prodject-comment-container
  .new-comment-form-container
  .comment-text-box
  textarea:first-of-type {
  max-height: 3em;
}

.prodject-comment-container
  .new-comment-form-container
  .comment-text-box.repaly
  textarea:first-of-type {
  max-height: 6em;
}

.prodject-comment-container .new-comment-text {
  font-weight: 600;
  border: 2px #f2f2f2 solid;
  width: -webkit-fill-available;
  width: -moz-fill-available;
  width: -ms-fill-available;
  width: -o-fill-available;
  font-family: "Noto Sans", sans-serif;
  resize: none;
  outline: none;
  border-radius: 15px;
  overflow: hidden;
  padding: 1em;
  font-size: 1em;
  margin-right: -3em;
}

.prodject-comment-container .new-comment-text:visited,
.prodject-comment-container .new-comment-text:focus-visible,
.prodject-comment-container .new-comment-text:focus {
  border-color: -p-;
  border-color: var(---2170ff-p-);
}

.prodject-comment-container .comment-container {
  display: flex;
  margin-top: 1em;
}

.prodject-comment-container .comment-container img {
  margin-right: 0.6em;
}

.prodject-comment-container .comment-container .comment-container-title {
  font-size: 0.8em;
  font-weight: 600;
  color: -p-;
  color: var(---2170ff-p-);
  text-transform: capitalize;
}

.prodject-comment-container .comment-container .comment-container-time {
  display: block;
  font-size: 0.7em;
  font-weight: 500;
  color: var(---a19b9d);
  line-height: 2em;
}

.prodject-comment-container .replay-container {
  margin-left: 3.6em;
  margin-top: 2em;
}

.prodject-comment-container .replay-container .replay-container-label {
  color: -p-;
  color: var(---2170ff-p-);
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: 1em;
}

.prodject-comment-container .action-btn {
  padding: 0.6em 2em;
  min-width: 10em;
  text-transform: capitalize;
  border-radius: 12px;
}

.prodject-comment-container .action-btn.cancel {
  margin-right: 0.5em;
  background-color: var(---e2feff);
  color: #1976d2;
  font-weight: 600;
}

.budget-statistics-card .MuiCardHeader-root {
  border-radius: 12px;
  box-shadow: 0px 0px 1px 2px #eee;
  padding-bottom: 3em;
}

.budget-statistics-card .MuiCardHeader-action {
  margin-top: 0px;
  margin-right: 0px;
}

/* MuiIconButton-label */
.budget-statistics-card .chart-icon-label,
.budget-statistics-card .chart-icon-label svg {
  width: 2em;
  height: 2em;
  padding: 1.5em !important;
  border-radius: 15px;
}

.buget-chart-container .budget-statistics-card.first .chart-icon-label {
  background-color: var(---e2f2ff);
}

.buget-chart-container .budget-statistics-card.second .chart-icon-label {
  background-color: var(---def8f3);
}

.buget-chart-container .budget-statistics-card.third .chart-icon-label {
  background-color: var(---ffe8ea);
}

.buget-chart-container .budget-statistics-card #bottom-container .MuiList-root {
  flex-wrap: wrap;
}

.project-buget-container > .MuiBox-root {
  padding: 0px !important;
}

.page-chart-container__hide.MuiPaper-rounded {
  box-shadow: none !important ;
  background-color: transparent;
  border: none !important;
}

.page-chart-container__show.MuiPaper-rounded {
  box-shadow: inherit;
  background-color: #fff;
  border: inherit;
}

.tab-chart-container__hide > .MuiBox-root {
  padding: 0px !important;
}

.tab-chart-container__show > .MuiBox-root {
  padding: inherit;
}

.buget-chart-container .buget-chart-title {
  margin-left: 0.5em;
  font-weight: 700;
}

.buget-chart-container .buget-chart-title span:nth-of-type(1) {
  color: var(---a19b9d);
  display: block;
  font-size: 0.9em;
}

.buget-chart-container .buget-chart-title span:nth-of-type(2) {
  font-size: 1.2em;
}

/* 
.buget-chart-container [arialabel="chart-number-1"] g:nth-of-type(1) path {
  fill: #00ad84;
}

.buget-chart-container [arialabel="chart-number-1"] g:nth-of-type(2) path {
  fill: #00d4b5;
}

.buget-chart-container [arialabel="chart-number-2"] g:nth-of-type(1) path {
  fill: #00ad84;
}

.buget-chart-container [arialabel="chart-number-2"] g:nth-of-type(2) path {
  fill: #00d4b5;
}

.buget-chart-container [arialabel="chart-number-3"] g:nth-of-type(1) path {
  fill: #00ad84;
}

.buget-chart-container [arialabel="chart-number-3"] g:nth-of-type(2) path {
  fill: #00d4b5;
} */

.buget-chart-container .budget-statistics-card #bottom-container .MuiList-root {
  display: flex;
  margin: auto;
  flex-direction: row;
}

.buget-chart-container .budget-statistics-card .MuiListItemText-root {
  white-space: nowrap;
}

.prodject-comment-container .action-btn {
  padding: 0.6em 2em;
  min-width: 10em;
  text-transform: capitalize;
  border-radius: 12px;
}

.prodject-comment-container .action-btn.add {
  background-color: -p-;
  background-color: var(---2170ff-p-);
}

.prodject-comment-container .action-btn.cancel {
  margin-right: 0.5em;
  background-color: #e2f2ff;
  color: #1976d2;
  font-weight: 600;
}

/* #center-container {
  max-height: 25em;
} */

.budget-form-container .action-btn {
  padding: 0.6em 2em !important;
  min-width: 10em !important;
  text-transform: capitalize !important;
  border-radius: 12px !important;
}

.budget-form-container .action-btn.cancel {
  margin-right: 0.5em !important;
  background-color: var(---e2f2ff) !important;
  color: #1976d2 !important;
  font-weight: 600 !important;
}

.budget-form-container .MuiFormHelperText-root.Mui-error {
  margin-left: 0px;
}

#project-tab-list .tab-header-container {
  flex-wrap: wrap;
}
/* 
#project-tab-list .tab-header-container {
  margin-top: 2em;
}

@media screen and (max-width: 1279px) {
  #project-tab-list .tab-header-container {
    margin-top: 3em;
  }
} */
#project-tab-list .MuiTabs-flexContainer {
  flex-wrap: wrap;
}

#project-tab-list .comment-number {
  margin-left: 6px;
  padding: 6px;
  border-radius: 100%;
  font-size: 14px;
  width: 2em;
  height: 2em;
  background-color: -p-;
  background-color: var(---2170ff-p-);
  color: #fff;
}

#project-tab-list .MuiTab-root.Mui-selected .comment-number {
  background-color: #fff;
  color: -p-;
  color: var(---2170ff-p-);
}

/* .tab-header-container */

#buyer-side-project-attachment-dropdown-list .MuiPaper-root {
  width: 200px;
}
.project-attachment-tab {
  background-color: #fff !important;
  border-radius: 12px !important;
}

#error-box-wrapper {
  position: relative;
  height: auto;
  background-color: -p-;
  background-color: var(---83c9f4-p-);
  min-height: 100vh;
}

#error-box-wrapper .box {
  max-width: 70%;
  min-height: auto;
  margin: 0 auto;
  padding: 1em 1em;
  text-align: center;
  background: url("https://www.dropbox.com/s/xq0841cp3icnuqd/flame.png?raw=1")
      no-repeat top 10em center/128px 128px,
    transparent
      url("https://www.dropbox.com/s/w7qqrcvhlx3pwnf/desktop-pc.png?raw=1")
      no-repeat top 13em center/128px 128px;
}

#error-box-wrapper h1,
#error-box-wrapper p:not(:last-of-type) {
  /* text-shadow: 0 0 6px var(---2170ff-p-); */
}

#error-box-wrapper h1 {
  margin: 0 0 1rem 0;
  font-size: 8em;
}

#error-box-wrapper p {
  margin-bottom: 0.5em;
  font-size: 3em;
}

#error-box-wrapper p:first-of-type {
  margin-top: 4em;
}

#error-box-wrapper p > a {
  /* border-bottom: 1px dashed var(---2170ff-p-); */
  font-style: italic;
  text-decoration: none;
  color: -p-;
  color: var(---2170ff-p-);
}

#error-box-wrapper p > a:hover {
  /* text-shadow: 0 0 6px var(---2170ff-p-); */
}

#error-box-wrapper p img {
  vertical-align: bottom;
}

.table-style {
  background: #ffffff;
  cursor: pointer;
}

@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 1650px !important;
  }
}

table thead tr {
  height: 44px !important;
}

.MuiOutlinedInput-root {
  border-radius: 8px !important;
}

table th,
table thead th {
  text-align: left;
  padding-bottom: 10 !important;
}

.table-style:hover {
  background-color: #e2f2ff;
}

table td {
  display: flex;
  height: 75px;
  align-items: center;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.heading {
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
}

.searchInputWrapper {
  position: relative;
}

.searchInput {
  width: 18rem;
  color: #a19b9d;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid #f1f0f2;
  outline: none;
  background: none;
  /* transition: transform 0.1s ease-in-out; */
}

::-webkit-input-placeholder {
  color: #a1a1a1;
}

::placeholder {
  color: #a1a1a1;
}

/* hide the placeholder text on focus */

:focus::-webkit-input-placeholder {
  text-indent: -999px;
}

:focus::placeholder {
  text-indent: -999px;
}

.searchInput:focus {
  outline: none;
  /* transform: scale(1.1); */
  /* transition: all 0.1s ease-in-out; */
}

.searchInputIcon {
  position: absolute;
  right: 0.8rem;
  top: 0.5rem;
  color: #a1a1a1;
  /* transition: all 0.1s ease-in-out; */
}

.container:focus-within > .searchInputWrapper > .searchInputIcon {
  right: 0.2rem;
}

/* Select Styles */

.MuiBox-root {
  border-radius: 12px !important;
}

.MuiDrawer-root {
  background: rgb(51, 54, 204);
  background: linear-gradient(
    0deg,
    rgba(51, 54, 204, 1) 0%,
    rgba(33, 112, 255, 1) 100%
  );
}

.app-main-section-container {
  flex-grow: 1;
  min-height: 100vh;
  overflow: overlay;
  background: #f2f2f2;
  margin-left: 96px;
}
.MuiList-root .MuiMenuItem-root {
  display: block !important;
  padding: 6px 16px !important;
  margin: 0 !important;
}
.textfield-error-style {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  color: #f44336;
}
.textfield-error-style.contract-module {
  margin-left: 10px;
}

/*  max height animation */

.animate-max-height.__hide {
  transition: max-height 1s;
  background: #e5feff;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  -webkit-animation: 7s overflow-initial-animate;
          animation: 7s overflow-initial-animate;
}

.animate-max-height.__feed {
  max-height: 100%;
  opacity: 1;
  overflow: initial;
  transition: max-height 1s;
  -webkit-animation: 5s opacity-animate;
          animation: 5s opacity-animate;
}

/*  height animation */

.animate-height.__hide {
  transition: height 1s;
  background: #e5feff;
  height: 0;
  opacity: 0;
}

.animate-height.__feed {
  height: 100%;
  opacity: 1;
  transition: height 1s;
  -webkit-animation: 1s opacity-animate;
          animation: 1s opacity-animate;
}

/*  opacity animation */

.animate-opacity.__hide {
  transition: opacity 1s;
  opacity: 0;
}

.animate-opacity.__feed {
  opacity: 1;
  transition: opacity 1s;
  -webkit-animation: 1s opacity-animate;
          animation: 1s opacity-animate;
}

/*  max width animation */

.animate-max-width.__hide {
  transition: max-width 1s;
  max-width: 0;
}

.animate-max-width.__feed {
  max-width: 100%;
  transition: max-width 1s;
}

@-webkit-keyframes overflow-initial-animate {
  from {
    overflow: initial;
  }
}

@keyframes overflow-initial-animate {
  from {
    overflow: initial;
  }
}

@-webkit-keyframes opacity-animate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes opacity-animate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.mui-card {
  padding: 2em;
  border-radius: 12px;
  background-color: #fff;
  position: relative;
}

.mui-card-primary {
  background-color: -p-;
  background-color: var(---2170ff-p-);
}
.mui-chat-card {
  padding: 12px 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px #0000001f;
}

/* width  */

.w100-percent-box {
  max-width: 100%;
  flex-basis: 100%;
}

.w90-percent-box {
  max-width: 90%;
  flex-basis: 90%;
}

.w84-percent-box {
  max-width: 89%;
  flex-basis: 89%;
}

.w88-percent-box {
  max-width: 88%;
  flex-basis: 88%;
}

.w87-percent-box {
  max-width: 87%;
  flex-basis: 87%;
}

.w86-percent-box {
  max-width: 86%;
  flex-basis: 86%;
}

.w85-percent-box {
  max-width: 85%;
  flex-basis: 85%;
}

.w84-percent-box {
  max-width: 84%;
  flex-basis: 84%;
}

.w83-percent-box {
  max-width: 83%;
  flex-basis: 83%;
}

.w82-percent-box {
  max-width: 82%;
  flex-basis: 82%;
}

.w81-percent-box {
  max-width: 81%;
  flex-basis: 81%;
}

.w80-percent-box {
  max-width: 80%;
  flex-basis: 80%;
}

.w79-percent-box {
  max-width: 79%;
  flex-basis: 79%;
}

.w78-percent-box {
  max-width: 78%;
  flex-basis: 78%;
}

.w77-percent-box {
  max-width: 77%;
  flex-basis: 77%;
}

.w76-percent-box {
  max-width: 76%;
  flex-basis: 76%;
}

.w75-percent-box {
  max-width: 75%;
  flex-basis: 75%;
}

.w74-percent-box {
  max-width: 74%;
  flex-basis: 74%;
}

.w73-percent-box {
  max-width: 73%;
  flex-basis: 73%;
}

.w72-percent-box {
  max-width: 72%;
  flex-basis: 72%;
}

.w71-percent-box {
  max-width: 71%;
  flex-basis: 71%;
}

.w70-percent-box {
  max-width: 70%;
  flex-basis: 70%;
}

.w65-percent-box {
  max-width: 65%;
  flex-basis: 65%;
}

.w63-percent-box {
  max-width: 63%;
  flex-basis: 63%;
}

.w60-percent-box {
  max-width: 60%;
  flex-basis: 60%;
}

.w50-percent-box {
  max-width: 50%;
  flex-basis: 50%;
}

.w45-percent-box {
  max-width: 45%;
  flex-basis: 45%;
}

.w40-percent-box {
  max-width: 40%;
  flex-basis: 40%;
}

.w37-percent-box {
  max-width: 37%;
  flex-basis: 37%;
}

.w36-percent-box {
  max-width: 36%;
  flex-basis: 36%;
}

.w35-percent-box {
  max-width: 35%;
  flex-basis: 35%;
}

.w34-percent-box {
  max-width: 33%;
  flex-basis: 33%;
}

.w33-percent-box {
  max-width: 33%;
  flex-basis: 33%;
}

.w32-percent-box {
  max-width: 32%;
  flex-basis: 32%;
}

.w31-percent-box {
  max-width: 31%;
  flex-basis: 31%;
}

.w30-percent-box {
  max-width: 30%;
  flex-basis: 30%;
}

.w29-percent-box {
  max-width: 29%;
  flex-basis: 29%;
}

.w28-percent-box {
  max-width: 28%;
  flex-basis: 28%;
}

.w27-percent-box {
  max-width: 27%;
  flex-basis: 27%;
}

.w26-percent-box {
  max-width: 26%;
  flex-basis: 26%;
}

.w25-percent-box {
  max-width: 25%;
  flex-basis: 25%;
}

.w24-percent-box {
  max-width: 24%;
  flex-basis: 24%;
}

.w23-percent-box {
  max-width: 23%;
  flex-basis: 23%;
}

.w22-percent-box {
  max-width: 22%;
  flex-basis: 22%;
}

.w21-percent-box {
  max-width: 21%;
  flex-basis: 21%;
}
.w20-percent-box {
  max-width: 20%;
  flex-basis: 20%;
}

.w19-percent-box {
  max-width: 19%;
  flex-basis: 19%;
}

.w18-percent-box {
  max-width: 18%;
  flex-basis: 18%;
}

.w17-percent-box {
  max-width: 17%;
  flex-basis: 17%;
}

.w16-percent-box {
  max-width: 16%;
  flex-basis: 16%;
}

.w15-percent-box {
  max-width: 15%;
  flex-basis: 15%;
}

.w14-percent-box {
  max-width: 14%;
  flex-basis: 14%;
}

.w13-percent-box {
  max-width: 13%;
  flex-basis: 13%;
}

.w12p5-percent-box {
  max-width: 12.5%;
  flex-basis: 12.5%;
}

.w12-percent-box {
  max-width: 12%;
  flex-basis: 12%;
}

.w11-percent-box {
  max-width: 11%;
  flex-basis: 11%;
}

.w10-percent-box {
  max-width: 10%;
  flex-basis: 10%;
}

/* width  */

/* scroll bar  */

.scroll-page {
  overflow-y: scroll;
  height: 100vh;
}

.scroll-page {
  scrollbar-color: #dbdbdb rgba(0, 0, 0, 0.03);
  scrollbar-width: thin;
}

/* scrollbar */
.scroll-page::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}

.scroll-page::-moz-scrollbar {
  height: 12px;
  width: 12px;
}

.scroll-page::-ms-scrollbar {
  height: 12px;
  width: 12px;
}

.scroll-page::-o-scrollbar {
  height: 12px;
  width: 12px;
}
/* scrollbar */

/* scrollbar-track */
.scroll-page::-webkit-scrollbar-track {
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}

.scroll-page::-moz-scrollbar-track {
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}

.scroll-page::-ms-scrollbar-track {
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}

.scroll-page::-o-scrollbar-track {
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}
/* scrollbar-track */

/* scrollbar-thumb */
.scroll-page::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.scroll-page::-moz-scrollbar-thumb {
  border-radius: 10px;
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.scroll-page::-ms-scrollbar-thumb {
  border-radius: 10px;
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.scroll-page::-o-scrollbar-thumb {
  border-radius: 10px;
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}
/* scrollbar-thumb */

/* scroll bar  */

.checkbox {
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  cursor: pointer;
}

.checkbox-primary-outline [aria-label="outer-box"],
.checkbox-primary [aria-label="outer-box"] {
  color: -p-;
  color: var(---2170ff-p-);
}

.checkbox [aria-label="check-mark"] {
  display: none;
  position: absolute;
  left: 3px;
  top: 3px;
  width: 18px;
  height: 17px;
}

.checkbox-primary-outline [aria-label="check-mark"] {
  color: -p-;
  color: var(---2170ff-p-);
}

.checkbox-primary [aria-label="check-mark"] {
  color: #ffffff;
  background-color: -p-;
  background-color: var(---2170ff-p-);
}

.checkbox.checked [aria-label="check-mark"] {
  display: block;
}

.app-bar-container .app-toolbar-container {
  padding-right: 3em;
}

.app-bar-container .app-toolbar-container .SearchInputList {
  border: none !important;
  margin-left: 5%;
}

.app-bar-container .app-toolbar-container .SearchInputList input {
  margin-left: 0.7em;
}

.app-bar-container .app-toolbar-container .app-toolbar-list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: space-around;
  align-items: flex-end;
}

.app-bar-container .app-toolbar-container .app-toolbar-list .popup-container {
  position: absolute;
  right: -1.7em;
  box-shadow: 0px 3px 6px 3px #00000029 !important;
  top: 1.4em;
  border-radius: 8px !important;
  /* max-height: 40vh;
  overflow-y: overlay;
  min-height: 10rem; */
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .popup-container
  .arrow-icon::before {
  position: absolute;
  right: 15px;
  top: -17px;
  content: "";
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 16px solid #00000021;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .popup-container
  .arrow-icon::after {
  position: absolute;
  right: 14px;
  top: -14px;
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #fff;
  z-index: 10;
}

.app-bar-container .app-toolbar-container .app-toolbar-list .MuiMenuItem-root {
  padding: 17px 25px !important;
  font-size: 1rem !important;
  justify-content: center !important;
  position: relative;
  font-weight: 600;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .MuiMenuItem-root
  a {
  color: -p-;
  color: var(---2170ff-p-);
  text-decoration: none;
}

.app-bar-container .app-toolbar-container .app-toolbar-list > div {
  margin-right: 1em;
}

.app-bar-container .app-toolbar-container .app-toolbar-list > div:last-of-type {
  margin-right: 0em;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .notification-list-container,
.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .auction-list-container,
.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-list-container {
  position: relative;
  cursor: pointer;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .auction-list-container
  .MuiAvatar-root:hover {
  background-color: var(---e2f2ff);
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .notification-list-container
  .MuiAvatar-root:hover {
  background-color: var(---def8f3);
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .notification-list-container
  .MuiAvatar-img {
  width: 1em !important;
  height: 1.1em !important;
}

.app-bar-container .app-toolbar-container .app-toolbar-list .badge {
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  top: -4px;
  z-index: 1;
  text-align: center;
  padding: 1px 0px;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .badge.badge-success {
  background-color: -s-;
  background-color: var(---00d4b5-s-);
  border-color: -s-;
  border-color: var(---00d4b5-s-);
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .badge.badge-primary {
  background-color: -p-;
  background-color: var(---2170ff-p-);
  border-color: -p-;
  border-color: var(---2170ff-p-);
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .list-item-image-container {
  background-color: -p-;
  background-color: var(---83c9f4-p-);
  height: 60px;
  border-radius: 8px;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .list-item-image-container
  img {
  height: 100%;
  width: 60px;
  padding: 8px;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .list-item-details
  .text {
  white-space: break-spaces;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .list-item-details
  .sub-text {
  color: var(---afafaf);
  font-size: 0.7em;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .auction-list-container
  ul,
.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .notification-list-container
  ul {
  min-width: 30em;
}

.app-bar-container .app-toolbar-container .app-toolbar-list ul {
  padding: 0 !important;
  max-height: 40vh;
  min-height: 9em;
  overflow-y: auto;
  scrollbar-color: #dbdbdb rgba(0, 0, 0, 0.03);
  scrollbar-width: thin;
}

/* scroll bar design  */

/* scrollbar */

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  ul::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.app-bar-container .app-toolbar-container .app-toolbar-list ul::-moz-scrollbar {
  width: 12px;
  height: 12px;
}

.app-bar-container .app-toolbar-container .app-toolbar-list ul::-ms-scrollbar {
  width: 12px;
  height: 12px;
}

.app-bar-container .app-toolbar-container .app-toolbar-list ul::-o-scrollbar {
  width: 12px;
  height: 12px;
}
/* scrollbar */

/* scrollbar-track */

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  ul::-webkit-scrollbar-track {
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  background: linear-gradient(
    0deg,
    rgb(239 239 239) 0%,
    rgb(255, 255, 255) 100%
  );
  border-radius: 10px;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  ul::-moz-scrollbar-track {
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  background: linear-gradient(
    0deg,
    rgb(239 239 239) 0%,
    rgb(255, 255, 255) 100%
  );
  border-radius: 10px;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  ul::-ms-scrollbar-track {
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  background: linear-gradient(
    0deg,
    rgb(239 239 239) 0%,
    rgb(255, 255, 255) 100%
  );
  border-radius: 10px;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  ul::-o-scrollbar-track {
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  background: linear-gradient(
    0deg,
    rgb(239 239 239) 0%,
    rgb(255, 255, 255) 100%
  );
  border-radius: 10px;
}

/* scrollbar-track */

/* scrollbar-thumb */

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  ul::-moz-scrollbar-thumb {
  border-radius: 10px;
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  ul::-ms-scrollbar-thumb {
  border-radius: 10px;
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  ul::-o-scrollbar-thumb {
  border-radius: 10px;
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

/* scrollbar-thumb */

/* scroll bar design  */

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-list-container
  .MuiAvatar-root {
  width: 44px;
  height: 44px;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-list-container
  .MuiAvatar-circular.__active {
  border: 2px -p- solid;
  border: 2px var(---2170ff-p-) solid;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-list-container
  .MuiAvatar-circular.__nonactive {
  border: 2px transparent solid;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-list-container
  ul {
  /* min-width: 22em; */
  padding: 0 !important;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-list-container
  .profile-label {
  opacity: 1 !important;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-label
  .text {
  white-space: nowrap;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-label
  .list-item-image-container {
  background-color: transparent;
  border-radius: 0;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-label
  .list-item-image-container
  img {
  padding: 0;
  border-radius: 50%;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-label
  .sub-text {
  font-size: 0.8em;
  font-weight: 500;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-list-container
  .MuiMenuItem-root {
  justify-content: left !important;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-list-container
  .MuiMenuItem-root:nth-child(1) {
  padding-bottom: 22px !important;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-list-container
  .MuiMenuItem-root:nth-child(3) {
  padding-bottom: 10px !important;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-list-container
  .MuiMenuItem-root:nth-child(4) {
  padding-top: 10px !important;
  padding-bottom: 1.8em !important;
}
.support-list-container .support-option-icon img {
  width: 32px !important;
  height: auto !important;
}

.popup-form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 50vw;
  background-color: #fff;
  padding: 3em;
  flex-grow: 1;
  max-height: 90vh;
  overflow: auto;
  scrollbar-color: #dbdbdb rgba(0, 0, 0, 0.03);
  scrollbar-width: thin;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  .popup-form-container {
    width: 30vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 960px) {
  .popup-form-container {
    width: 70vw;
  }
}

.popup-form-container .title {
  margin-bottom: 1.4em;
  font-size: 1.3rem;
  font-weight: 600;
}

.popup-form-container .MuiFormLabel-root {
  font-size: 0.875rem;
  color: var(---a19b9d);
  margin-bottom: 0.25rem;
}

.popup-form-container .MuiFormControl-root {
  width: 100% !important;
  margin: 0 !important;
}

.popup-form-container .MuiInputBase-root {
  font-weight: 600;
}

/* scroll bar design  */

/* scrollbar */
.popup-form-container::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.popup-form-container::-moz-scrollbar {
  width: 7px;
  height: 7px;
}

.popup-form-container::-ms-scrollbar {
  width: 7px;
  height: 7px;
}

.popup-form-container::-o-scrollbar {
  width: 7px;
  height: 7px;
}
/* scrollbar */

/* scrollbar-track */
.popup-form-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}

.popup-form-container::-moz-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}

.popup-form-container::-ms-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}

.popup-form-container::-o-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}
/* scrollbar-track */

/* scrollbar-thumb */
.popup-form-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -moz-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.popup-form-container::-moz-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -moz-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.popup-form-container::-ms-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -moz-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.popup-form-container::-o-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -moz-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}
/* scrollbar-thumb */
/* scroll bar design  */

/* bottom box (left-rigt)  */

.popup-form-container .bottom-button-box-container {
  display: flex;
  justify-content: space-between;
  margin-top: 5em;
}

@media screen and (max-width: 960px) {
  .popup-form-container .bottom-button-box-container {
    display: block;
  }
}

.popup-form-container .bottom-button-box-container .left-side {
  min-width: 197px;
}

.popup-form-container .bottom-button-box-container .right-side {
  display: flex;
}

@media screen and (max-width: 960px) {
  .popup-form-container .bottom-button-box-container .right-side {
    display: block;
  }
}

.popup-form-container
  .bottom-button-box-container
  .right-side
  > div:first-of-type {
  min-width: 197px;
}

@media screen and (max-width: 960px) {
  .popup-form-container
    .bottom-button-box-container
    .right-side
    > div:first-of-type {
    margin-top: 10px;
  }
}

.popup-form-container
  .bottom-button-box-container
  .right-side
  > div:last-of-type {
  min-width: 197px;
  margin-left: 1.25em;
}

@media screen and (max-width: 960px) {
  .popup-form-container
    .bottom-button-box-container
    .right-side
    > div:last-of-type {
    margin-left: 0;
    margin-top: 10px;
  }
}

/* bottom box (left-rigt)  */

[araia-label="table-option-list-of-menu-item"] .MuiList-root .MuiMenuItem-root {
  display: block;
}

.mui-tooltip .MuiTooltip-tooltip {
  font-size: 14px;
  padding: 16px 16px 24px 16px;
  border-radius: 12px;
}

.mui-tooltip-primary .MuiTooltip-tooltip {
  background-color: var(---e2f2ff);
  color: var(---707070);
}

.mui-tooltip-primary .MuiTooltip-arrow {
  color: var(---e2f2ff);
}

.mui-tooltip.MuiTooltip-popperArrow[x-placement*="top-start"]
  .MuiTooltip-arrow {
  left: 6px !important;
}

 
.select-box {
  padding: 0 2px 0 18px !important;
  border: 1px solid #f1f0f2 !important;
  border-radius: 8px !important;
}

.select-box.search-box {
  background: no-repeat;
  background-size: 18px;
  background-position: 10px center;
  width: 300px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAYAAACoYAD2AAAABHNCSVQICAgIfAhkiAAAA8tJREFUWEfVmEtW2zAUhu+VybiZdFyYdVZYAbCCwgaIffI4p7OwgtIVlFnPyeM4YQOkKyCMOiSsoOm0o3RMrNsrpzaxLUUSbXlkBPaV9Pm+pF8Inr/4S7wtask+STgChDoQ1BFxV01DRDN+tuBnCxQwkXfBdfQhmnsuUTFH1wnGg0GDwboZkOs4Bc7A541Wa+w6pmxnhYz7/QMkESPC9kMXybxMSKdRuz31nWcj5Kg3VHCh76Sb7IlgFHaakc+cWsg4juu4TC4R8EA7GcE1w08SkDNYbs2zvIt78S7gsh6A2GWYkPPznW48AU1pKziOomjhAluBTAHvkit97tFYLoMz12JIi2wrOQPARhlG5SrVgkMX0ArkqD+4qniQ4FaCCKNONHP58rKN8rDAZMKwb9bfKY+G7dahbc4CpDYHObSyJo5cvnjTYipC4k5OOAX2C6AOOZpDqioWIK4KCzFgo9PU56Xt8w3vx73htAwqQR5uqvockr34vdBmVIhr4uBvPVgJvfLoMuG0uQ+9ys+w09ozfXcKedEbhoQQrxtJEnsPzUGbk1c5Km/W7ZAgOuk0R7qxKeSoN7gpVjONG+3WP+2P5cXH/QED3Vf9Jm/iqk3I7wUvLsWOa5uxec303mddrIT6PxSLCZSLSB1I8oZvCjmy21X/ep9NxPE/PWk3zx/qIZ9xF/1hlwA+34+hr5xmR+U5sNwSbO3AB8JmW2l7hihiufXIR8jHDL5c5abi4XAP2eP3v0a7aT2+2Tzk895l/ZcCOZivd/+nDDfLjlvehlMpUmj0L6JwXkQLespmXt6Ojc3cZ3vyqVqbrc+6abspb0+soB/9gGEqGsX3co5qOm+y2ptTjc+UjorOFt58l0mFnuSj4ZqON7SetfPE6k+dfHAVSq6Ayo6L5SfH7/UqjKvNzXZeKGyB5YOomsBXI5uA/wixb8z1NrdRGzLa87+yT+uEUqqRIYgeKifUQQJBXuquajbJhkq413OGpadSdJXbB3VFQon45HpqV20GA/kxu6rhqOQhLmx7G/RNXt3lEJk0cmaX3pRxeiGIWQL4K/Ow8lgA9IpA7nIkQ+0tSBrialJYhZgpj3Q56lMkelsaI+G0rE5Tjxk8aj07plVP4tx0+eQMrXQ8ym52CaCT0SZQK2QGkU4K0PWGZThe5FynqV1BnSHzwuJiCAJ5wBeiR0BY5+f1HJyB+P8Fx23BIZ0kiZjaiswF1BvSObwehiZQiXQctVqTZwGpvkcHqrZmvhXeeTaQelD6wTp8+1lBZqASKL2cIAHhswr3phT+DZyvRkh/H+TSAAAAAElFTkSuQmCC);
}

.select-box-primary:hover,
.select-box-primary:focus,
.select-box-primary:active {
  border: 1px solid -p- !important;
  border: 1px solid var(---2170ff-p-) !important;
}

.select-box-primary svg {
  display: none;
}

.mui-select .MuiInputBase-root {
  height: 45px;
}

.mui-select .MuiSelect-select:focus {
  background-color: transparent !important;
}

.mui-select .MuiOutlinedInput-notchedOutline {
  border-color: var(---e0e0e0);
}

.mui-select.outline .MuiOutlinedInput-notchedOutline {
  border: none;
}

.mui-select-placeholder {
  color: #acacac;
  padding: 4px 14px;
}

.side-bar-container {
  overflow: hidden !important;
  z-index: 1450;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
}

.side-bar-container.MuiDrawer-root .MuiDrawer-paper {
  scrollbar-width: thin;
  scrollbar-color: #206cf9 rgba(0, 0, 0, 0.03);
}
.side-bar-container.MuiDrawer-root .MuiDrawer-paper::-webkit-scrollbar {
  width: 0px;
  height: 0;
  background-color: #206cf9;
}

.side-bar-container.MuiDrawer-root .MuiDrawer-paper::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #206cf9;
}

.side-bar-container .logo-container {
  display: block;
  min-height: 5em;
  margin-top: 1em;
}

.side-bar-container .logo-text {
  display: flex;
  flex-direction: column;
  margin-top: 0.5em;
}

.side-bar-container .logo-text div:nth-of-type(1) {
  color: #fff;
  font-size: 0.7em;
  padding-bottom: 1px;
}

.side-bar-container .logo-text img {
  width: 10em;
}

.side-bar-container .logo-image img {
  width: 3em;
  margin-left: 2em;
  margin-right: 1.8em;
}

.side-bar-container .logo-image img.toggle-width-show {
  width: 4em !important;
}

.side-bar-container .logo-image img.toggle-width-hide {
  width: 3em !important;
}

.side-bar-container .MuiListItemText-root p {
  font-weight: 600;
  font-size: 1.1em;
  vertical-align: middle;
  margin-bottom: 0;
}

.side-bar-container .MuiListItemIcon-root {
  height: 3em;
  border: 2px transparent solid;
  border-radius: 10px;
  margin-right: 2em;
  padding: 0 !important;
  min-width: 3em !important;
  max-width: 3em !important;
}

.side-bar-container .MuiListItemIcon-root[arialable="selected"] {
  border-color: #fff;
}

.side-bar-container .MuiListItemIcon-root .MuiSvgIcon-root {
  margin: auto;
}

.side-bar-container .MuiListItem-gutters {
  padding-left: 1.8em;
}

.side-bar-container .footer {
  padding-top: 20px;
  border-top: 1px rgba(0, 0, 0, 0.12) solid;
  margin-bottom: 0.2em;
}

.side-bar-container .side-bar-toggle-switch span {
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  cursor: pointer;
  opacity: 1;
}

.side-bar-container .side-bar-toggle-switch.animation-toggle-show {
  width: 40px !important;
  height: 20px !important;
}

.side-bar-container .side-bar-toggle-switch span .MuiSwitch-track {
  background: transparent;
  border-radius: 10px;
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  border: 1px #fff solid;
}

.side-bar-container .side-bar-toggle-switch span .MuiSwitch-thumb {
  display: block;
  width: 14px;
  height: 14px;
  top: 3px;
  left: 3px;
  border-radius: 16px;
  background-color: #fff;
  position: relative;
  transition: all 500ms ease;
}

.side-bar-container
  .side-bar-toggle-switch
  span:focus-visible
  .MuiSwitch-thumb {
  background-color: #aab4be;
  box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
}

.side-bar-container .side-bar-toggle-switch span.Mui-checked .MuiSwitch-thumb {
  left: 22px;
  top: 3px;
  background-color: #fff;
}

.side-bar-container .side-bar-toggle-switch span input {
  cursor: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
}

.side-bar-container .side-bar-toggle-btn {
  display: block;
  min-width: 180%;
  text-align: center;
}

.side-bar-container .side-bar-toggle-btn .MuiToggleButtonGroup-root {
  min-width: 100%;
}

.side-bar-container .side-bar-toggle-btn .MuiToggleButton-root.Mui-selected {
  color: -p- !important;
  color: var(---2170ff-p-) !important;
  background-color: #fff !important;
}

.side-bar-container .side-bar-toggle-btn .MuiToggleButton-root {
  flex-grow: 1;
  color: #fff;
  background-color: transparent;
  border: 1px #fff solid;
  font-weight: 700;
  padding: 0.5em;
  border-radius: 8px;
  text-transform: capitalize;
}

.side-bar-container
  .side-bar-toggle-btn
  .MuiToggleButtonGroup-groupedHorizontal:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.side-bar-container
  .side-bar-toggle-btn
  .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.app-bar-container {
  border-bottom: 1px solid #afafaf6e;
}

.app-bar-container.MuiAppBar-colorPrimary {
  background-color: #fff;
}

.app-bar-container.MuiAppBar-positionFixed {
  z-index: 1000;
}

/* scroll bar  */

/* scrollbar */

.MuiDrawer-paper::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.MuiDrawer-paper::-moz-scrollbar {
  width: 12px;
  height: 12px;
}

.MuiDrawer-paper::-ms-scrollbar {
  width: 12px;
  height: 12px;
}

.MuiDrawer-paper::-o-scrollbar {
  width: 12px;
  height: 12px;
}

/* scrollbar */

/* scrollbar-track */
.MuiDrawer-paper::-webkit-scrollbar-track {
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  background: linear-gradient(
    0deg,
    rgba(51, 54, 204, 1) 0%,
    rgba(33, 112, 255, 1) 100%
  );
  border-radius: 10px;
}

.MuiDrawer-paper::-moz-scrollbar-track {
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  background: linear-gradient(
    0deg,
    rgba(51, 54, 204, 1) 0%,
    rgba(33, 112, 255, 1) 100%
  );
  border-radius: 10px;
}

.MuiDrawer-paper::-ms-scrollbar-track {
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  background: linear-gradient(
    0deg,
    rgba(51, 54, 204, 1) 0%,
    rgba(33, 112, 255, 1) 100%
  );
  border-radius: 10px;
}

.MuiDrawer-paper::-o-scrollbar-track {
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  background: linear-gradient(
    0deg,
    rgba(51, 54, 204, 1) 0%,
    rgba(33, 112, 255, 1) 100%
  );
  border-radius: 10px;
}

/* scrollbar-track */

/* scrollbar-thumb */
.MuiDrawer-paper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  background: linear-gradient(
    0deg,
    rgba(51, 54, 204, 1) 0%,
    rgba(33, 112, 255, 1) 100%
  );
}

.MuiDrawer-paper::-moz-scrollbar-thumb {
  border-radius: 10px;
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  background: linear-gradient(
    0deg,
    rgba(51, 54, 204, 1) 0%,
    rgba(33, 112, 255, 1) 100%
  );
}

.MuiDrawer-paper::-ms-scrollbar-thumb {
  border-radius: 10px;
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  background: linear-gradient(
    0deg,
    rgba(51, 54, 204, 1) 0%,
    rgba(33, 112, 255, 1) 100%
  );
}

.MuiDrawer-paper::-o-scrollbar-thumb {
  border-radius: 10px;
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.26);
  background: linear-gradient(
    0deg,
    rgba(51, 54, 204, 1) 0%,
    rgba(33, 112, 255, 1) 100%
  );
}
/* scrollbar-thumb */

/* scroll bar  */

/* signature block   */

.side-bar-container .signature {
  color: #fff;
  flex-direction: column;
  margin-bottom: 1em;
}

.side-bar-container .signature img {
  margin-bottom: -2px;
}

.side-bar-container .signature img.__opened {
  width: 6em;
  margin-left: 0.3em;
}

.side-bar-container .signature img.__closed {
  width: 4.5em;
}

.side-bar-container .signature span:nth-of-type(1) {
  font-size: 0.7em;
  text-transform: uppercase;
}

.side-bar-container .signature span:nth-of-type(1).__opened {
  display: inline;
}

.side-bar-container .signature span:nth-of-type(1).__closed {
  display: block;
  margin-top: 2em;
}

/* signature block   */

.side-bar-container .btn-success {
  background-color: -s-;
  background-color: var(---00d4b5-s-);
  border-radius: 12px;
  border-color: -s-;
  border-color: var(---00d4b5-s-);
  width: 16em;
  /* margin-top: 1em; */
  margin-bottom: 1em;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  margin-left: 1.5em;
}

.side-bar-container .btn-success svg {
  vertical-align: middle;
  margin-right: 0.5em;
}

.side-bar-container .btn-success span {
  vertical-align: bottom;
  font-size: 1em;
  letter-spacing: 1px;
  font-weight: 600;
}

/* animation  */

.animation-toggle-show {
  width: 100% !important;
  height: 100% !important;
  opacity: 1 !important;
  margin-left: 0px;
  transition: all 0.5s ease;
}

.animation-toggle-hide {
  width: 0% !important;
  height: 0% !important;
  opacity: 0 !important;
  margin-left: -2em;
  transition: all 0.5s ease;
}

.add-auction-animation-show {
  width: 16em !important;
  transition: all 0.4s ease !important;
}

.add-auction-animation-show span {
  opacity: 1 !important;
}

.add-auction-animation-hide {
  width: 3em !important;
  transition: all 0.4s ease !important;
}

.add-auction-animation-hide span {
  opacity: 0 !important;
}

/* animation  */

.switchable-toggle {
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 38px;
  height: 22px;
  cursor: pointer;
  opacity: 1;
  margin: 10px;
}

/* refere to label  */
.switchable-toggle + span {
  color: var(---a19b9d);
}

.switchable-toggle.Mui-checked + span {
  color: -p-;
  color: var(---2170ff-p-);
}

.switchable-toggle .MuiSwitch-track {
  background: var(---a19b9d);
  border-radius: 10px;
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
}

.switchable-toggle.Mui-checked .MuiSwitch-track {
  background: -p-;
  background: var(---2170ff-p-);
}

.switchable-toggle .MuiSwitch-thumb {
  display: block;
  width: 16px;
  height: 16px;
  top: 3px;
  left: 3px;
  border-radius: 16px;
  background-color: #fff;
  position: relative;
  transition: all 300ms ease;
}

.switchable-toggle.Mui-checked .MuiSwitch-thumb {
  left: 18px;
  top: 3px;
}

.switchable-toggle input {
  cursor: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
}

.tab-header-container {
  margin-top: 12em;
  margin-bottom: 2em;
  justify-content: space-between;
}

.tab-header {
  background-color: rgb(255, 255, 255);
  border-radius: 25px !important;
}

.tab-header .MuiTab-root {
  font-weight: 600;
  color: #000;
  text-transform: capitalize;
  padding: 0px 15px;
  min-height: 2.7em;
}

.tab-header .MuiTab-root.Mui-selected {
  color: #fff;
  background-color: -p-;
  background-color: var(---2170ff-p-);
  padding: 0px;
  margin: 7px;
  border-radius: 25px;
  text-transform: capitalize;
}

.tab-body-container.__clear-page.MuiPaper-rounded {
  box-shadow: none !important ;
  background-color: transparent;
  border: none !important;
}

.tab-body-container.__clear-page > .MuiBox-root {
  padding: 0px !important;
}

.tab-body-container.__clear-page [labelAria="no-padding"] > .MuiBox-root {
  padding: 0px !important;
}

.form-stepper-label-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-stepper-label-container .stepitem {
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-wrap: wrap;
  justify-content: center;
}
.form-stepper-label-container .step-num {
  width: 36px;
  height: 36px;
  background-color: var(---a19b9d);
  border-radius: 50% !important;
  color: #fff;
  display: flex;
}

.form-stepper-label-container .step-num.__active {
  background-color: -p-;
  background-color: var(---2170ff-p-);
}

.form-stepper-label-container .step-num div {
  margin: auto;
}

.form-stepper-label-container .step-text {
  font-size: 14px;
  margin-left: 8px;
  color: var(---a19b9d);
  font-weight: 600;
  letter-spacing: 0.02rem;
}

.form-stepper-label-container .step-text.__active {
  color: #231f20;
}

.form-stepper-label-container .divider-container {
  flex-grow: 1;
  margin-left: 10px;
  margin-right: 10px;
}

.form-stepper-label-container .divider-container .line {
  min-width: 15px;
  width: 100%;
  height: 2px;
  background-color: #d7d5d6;
}

@media screen and (max-width: 600px) {
  .form-stepper-label-container .step-text {
    display: none;
  }
}

.collapsed-overlayed-page-contaier {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1500;
  background-color: #000000a8;
  width: 0vw;
  min-height: 100vh;
  transition: width 0.5s;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}

.collapsed-overlayed-page-contaier.active {
  width: 100vw;
  overflow: visible;
}

.collapsed-overlayed-page-contaier .collapsed-overlayed-page-body {
  width: 0vw;
  overflow: hidden;
  transition: width 0.5s;
}

.collapsed-overlayed-page-contaier .collapsed-overlayed-page-body.active {
  width: inherit;
  overflow: visible;
}

.collapsed-overlayed-page-contaier .close-icon {
  color: white;
  font-size: 26px;
  margin: 16px;
  cursor: pointer;
}

.shimmer-thumbnail.h24 {
  height: 24px !important;
}

.custom-table {
  width: 100%;
}

.custom-table .shimmer-table-row {
  padding: 18px 0;
  margin-bottom: 0px;
  box-shadow: none;
  border-radius: 5px !important;
  min-height: 48px;
}

.custom-table .shimmer-table-row > .shimmer {
  margin: 0 20px;
}

@media (max-width: 700px) {
  .custom-table .shimmer-table-row > .shimmer {
    margin: 0 10px;
  }
}

.custom-table .shimmer-table-row.shimmer-table-row--header {
  background: #f2f4f6;
  min-height: 57px;
  padding: 22px 0;
}

.custom-table .shimmer-table-row.shimmer-table-row--header .shimmer-table-col {
  background: rgba(0, 0, 0, 0.15) !important;
}

.dynamicLink-table {
  width: 100%;
}

.dynamicLink-table .shimmer-table-row {
  padding: 18px 0;
  margin-bottom: 0px;
  box-shadow: none;
  border-radius: 5px !important;
  min-height: 48px;
}

.dynamicLink-table .shimmer-table-row > .shimmer {
  margin: 0 20px;
}

@media (max-width: 700px) {
  .dynamicLink-table .shimmer-table-row > .shimmer {
    margin: 0 10px;
  }
}

.dynamicLink-table .shimmer-table-row.shimmer-table-row--header {
  background: #fff;
  min-height: 48px;
  padding: 18px 0;
}

.dynamicLink-table
  .shimmer-table-row.shimmer-table-row--header
  .shimmer-table-col {
  background: rgba(0, 0, 0, 0.1) !important;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

@keyframes placeholderShimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.cu-shimmer {
  background-color: #f2f4f6 !important;
  background-image: linear-gradient(
    to right,
    #f6f6f6 8%,
    #f0f0f0 18%,
    #f6f6f6 33%
  ) !important;
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  position: relative;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
          animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.cu-shimmer.br20 {
  border-radius: 20px;
}

.cu-shimmer.br10 {
  border-radius: 10px;
}

.cu-shimmer.h20 {
  height: 20px;
}

.cu-shimmer.mh400 {
  min-height: 400px;
}
.cu-shimmer.mh170 {
  min-height: 170px;
}

@media (max-width: 767px) {
  .cu-shimmer.mh400 {
    min-height: 300px;
  }
}

.cu-shimmer-white {
  background-color: #f2f4f6 !important;
  background-image: linear-gradient(
    to right,
    #fff 8%,
    #f0f0f0 18%,
    #fff 33%
  ) !important;
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  position: relative;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
          animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.shimmer-thumbnail.h150 {
  height: 150px !important;
}

.shimmer-thumbnail.h120 {
  height: 120px !important;
}

.shimmer-thumbnail.h150 {
  height: 150px !important;
}

.shimmer-thumbnail.h100 {
  height: 100px !important;
}

.shimmer-thumbnail.h40 {
  height: 40px !important;
}

.shimmer-thumbnail.h100p {
  height: 100px !important;
}

.shimmer-thumbnail.br20 {
  border-radius: 20px;
}

.shimmer-thumbnail.mb20 {
  margin-bottom: 20px;
}
.cu-shimmer.mb5 {
  margin-bottom: 5px;
}
.cu-shimmer.mb10 {
  margin-bottom: 10px;
}

.cu-shimmer.h15 {
  height: 15px !important;
}
.cu-shimmer.h50 {
  height: 50px !important;
}
.cu-shimmer.w50 {
  width: 50px !important;
}
.cu-shimmer.w75 {
  width: 75% !important;
}
.cu-shimmer.w100 {
  width: 100px !important;
}
.cu-shimmer.w160 {
  width: 160px !important;
}
.cu-shimmer.fixWidth260 {
  width: 260px !important;
}
.cu-shimmer.w260 {
  max-width: 260px !important;
}
.cu-shimmer.w360 {
  max-width: 360px !important;
}
.border-round {
  border-radius: 50%;
}
.thumbnail-center {
  margin: 0 auto;
}
.item-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contract-row .contract-supplier-name {
  width: 10% !important;
}

.rfx-checkbox {
  border: none !important;
}
.rfx-question-step .question-box {
  /* padding: 11px 20px 10px 10px; */
  border: 1px solid #e0e0e0;
  border-radius: 12px !important;
}
.rfx-question-step .question-box .question-title {
  padding: 11px 20px 10px 10px;
  border-bottom: 1px solid #e0e0e0;
}
.rfx-question-step .question-box .answer-area {
  padding: 10px;
}
.rfx-question-step .lower-box {
  padding: 10px;
}
.rfx-question-step .common-rfx-icon,
.edit-question-answer .common-rfx-icon {
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
}
.rfx-question-step .add-rfx-icon,
.edit-question-answer .add-rfx-icon {
  background-color: #83c9f4;
  color: #2170ff;
}
.rfx-question-step .remove-rfx-icon,
.edit-question-answer .remove-rfx-icon {
  background-color: #f3949e;
  color: #c82333;
}
.edit-question-answer .add-rfx-icon,
.edit-question-answer .remove-rfx-icon {
  margin-top: -10px;
}
.rfx-general-info .inner-info-first {
  min-height: 6.5em !important;
}
.rfx-general-info .question-num-text {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 5px;
}
.rfx-general-info .question-num {
  font-size: 31px;
  line-height: 42px;
}
.rfx-general-info .time-num {
  font-size: 24px;
  line-height: 35px;
}
.rfx-cover-image {
  width: 29px;
  height: 38px;
}
.icon-rfx-general {
  width: 52px;
  height: auto;
  display: block;
  margin: auto;
}
.question-tab .question-detail {
  display: flex;
  justify-content: space-between;
}
.question-tab .action-btn,
.question-seller-tab .action-btn {
  background-color: var(---e2f2ff) !important;
  color: -p- !important;
  color: var(---2170ff-p-) !important;
  border: px solid var(---e2f2ff) !important;
  font-size: 7px !important;
  box-shadow: none !important;
  border-radius: 50% !important;
  padding: 0px 0px 0px 0px !important;
  min-width: 1em !important;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.mui-custom-drawer {
  background: unset !important;
}
.rfx-question-badge {
  border: 1px solid #2170ff;
  background-color: #e2f2ff;
  color: #2170ff;
  padding: 6px 22px;
  border-radius: 16px;
}
.each-option-card .inner-option-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 100%;
  padding: 12px 6px;
  word-break: break-all;
  max-height: 150px;
  overflow-y: auto;
}
.question-tab .quetion-action {
  margin-top: -6px;
  justify-content: end;
}

@media screen and (max-width: 900px) {
  .question-tab .question-order {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 900px) {
  .question-tab .quetion-action {
    margin-top: 15px;
  }
}
.edit-question-drawer .MuiPaper-root {
  width: calc(100% - 130px);
  max-width: 640px;
}
.question-tab .inner-edit-question {
  padding: 32px;
}
.question-tab .edit-title,
.messages-tab .common-title {
  color: #2170ff;
  font-size: 24px;
  line-height: 33px;
  font-weight: 700;
}
.question-tab .edit-title {
  padding: 30px 32px 10px;
}
.messages-tab .chat-head-body {
  padding: 30px 32px 10px;
}
.question-tab .edit-question-answer {
  padding: 32px;
}
.messages-tab .messages-list {
  padding: 0 32px;
}
.question-tab .divider-title,
.messages-tab .divider-title {
  width: 100%;
  height: 5px;
  border-top: 1px solid #eeeeee;
}
.edit-question-textarea .MuiFormControl-root {
  margin: 0 !important;
  width: 100% !important;
}
.common-chat .buyer-chat {
  /* width: calc(100% - 49px); */
  width: 70%;
}
.common-chat .buyer-chat .chat-time {
  color: #888888;
  font-size: 14px;
  line-height: 19px;
}
.common-chat .buyer-chat-profile {
  margin-right: 17px;
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.common-chat .seller-chat-profile {
  margin-left: 17px;
  order: 1;
}
.chat-box {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.chat-box .chat-header {
  flex: 0 1;
}

.chat-box .chat-body {
  flex: 1% 1;
  overflow-y: auto;
}
.chat-box .chat-footer {
  grid-gap: 10px;
  gap: 10px;
  flex: 0 1;
  padding: 32px;
}
.chat-box .chat-input {
  min-height: 48px;
}
.chat-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.chat-footer .attachment-icon {
  margin-right: 5px;
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
  color: #afafaf;
}
.chat-footer .icon-chat-attach-list {
  margin-left: -7px;
}
.chat-footer .document-list {
  max-height: 255px;
  overflow-y: auto;
}
.rfx-step-2-checkbox {
  margin-top: -2px;
}
.uploaded-files.chat-file {
  width: 40%;
  cursor: pointer;
}
.question-seller-tab .question-tab-title {
  color: #2170ff;
  font-weight: 700;
  margin-right: 8px;
  font-size: 22px;
  line-height: 43px;
  margin-top: -4px;
}
.question-seller-tab .btn-reply {
  padding: 0 25px !important;
  min-height: 27px !important;
}
.question-seller-tab .btn-replied {
  background: var(---def8f3) 0% 0% no-repeat padding-box !important;
  border-color: var(---def8f3) !important;
  color: -s- !important;
  color: var(---00d4b5-s-) !important;
}
.question-seller-tab .inner-option-card {
  border: 0 !important;
  padding: 0 !important;
}
.item-seller-table .item-add-action {
  height: 30px;
  border-radius: 15px;
}
.item-seller-table .item-edit-action {
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid -p-;
  border: 1px solid var(---2170ff-p-);
  color: -p-;
  color: var(---2170ff-p-);
}
.item-seller-table .item-edit-action:hover {
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid -p-;
  border: 1px solid var(---2170ff-p-);
  color: -p-;
  color: var(---2170ff-p-);
}
.rfx-seller-bid-card .add-lumpsum-btn {
  background-color: #e2f2ff;
  color: #2170ff;
}
.rfx-seller-bid-card .add-lumpsum-btn:hover {
  background-color: #e2f2ff;
  color: #2170ff;
}

.rfx-seller-bid-card .attach-name-display {
  height: 45px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  width: 100%;
  padding: 8px 12px;
}

.rfx-seller-bid-card .add-bid-attach {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid;
  height: 45px !important;
  min-height: 45px !important;
}

.rfx-seller-bid-card .bid-doc-display,
.rfx-compare-tab .bid-doc-display {
  /* width: 48%; */
  border-radius: 16px;
  background-color: #e2f2ff;
  padding: 4px 12px;
  color: #2170ff;
  justify-content: space-between;
  align-items: center;
}
.rfx-compare-tab .compare-doc {
  width: 130px !important;
  /* width: 42% !important; */
}
.rfx-seller-bid-card .bid-doc-display .delete-icon-body {
  background-color: #fff;
  border-radius: 50%;
  padding: 4px;
}
.rfx-seller-bid-card .bid-doc-display .delete-icon-body .delete-icon {
  color: #c82333;
}
.rfx-seller-bid-card .bid-doc-display .attach-icon,
.rfx-compare-tab .bid-doc-display .attach-icon {
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
}
.rfx-seller-bid-card .price-body {
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.rfx-seller-bid-card .bid-edit-container {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  border-radius: 50%;
  padding: 4px;
}
.rfx-seller-bid-card .bid-number {
  padding: 0 40px 0 0;
}
.rfx-seller-bid-card .rfx-bid-body {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  max-width: 100%;
  margin: 0 auto;
}
.rfx-seller-bid-card .bid-edit-container.border-primary {
  border: 1px solid #2170ff;
}
.rfx-seller-bid-card .bid-edit-container.border-warning {
  border: 1px solid #ffc107;
}

.rfx-seller-bid-card .btn-mui.btn-primary,
.rfx-seller-bid-card .btn-mui.btn-primary,
.rfx-seller-bid-card .btn-mui.btn-primary {
  color: #fff;
  background-color: -p-;
  background-color: var(---2170ff-p-);
  border-color: -p-;
  border-color: var(---2170ff-p-);
}
.rfx-supplier .file-name {
  color: #2170ff !important;
}
.details-banner.rfx-compare-banner {
  height: 9em !important;
}
/* .rfx-compare-tab tr:hover,
.rfx-compare-price-tab tr:hover {
  background-color: inherit !important;
} */
.rfx-compare-tab tr:nth-child(odd):hover,
.rfx-compare-price-tab tr:nth-child(even):hover,
.rfx-compare-price-tab th:hover,
.rfx-lumpsum-tab th:hover {
  background-color: #fff !important;
}
.rfx-compare-tab tr:nth-child(even):hover,
.rfx-compare-price-tab tr:nth-child(odd):hover {
  background-color: #f0f5ff !important;
}
.rfx-compare-price-tab tr:first-child:hover,
.rfx-lumpsum-tab tr:hover {
  background-color: #fff !important;
}
.rfx-compare-tab th,
.rfx-compare-price-tab th,
.rfx-lumpsum-tab th {
  color: #1e1d1d !important;
  font-weight: 600 !important;
}
.rfx-compare-tab th:first-child,
.rfx-compare-tab td:first-child {
  border-right: 1px solid rgba(224, 224, 224, 1) !important;
}
.rfx-compare-tab td {
  color: #a19b9d !important;
  font-weight: 400 !important;
}
.rfx-compare-tab .inner-td {
  padding: 8px 16px 8px 0;
  border-right: 1px solid rgba(224, 224, 224, 1);
}
.rfx-compare-tab .inner-th {
  padding: 8px 15px 8px 0px;
  border-right: 1px solid rgba(224, 224, 224, 1);
}
.rfx-compare-tab .inner-th:last-child,
.rfx-compare-tab .inner-td:last-child {
  border: none !important;
}
@media screen and (min-width: 1536px) {
  .rfx-invite-supplier {
    width: 40vw !important;
  }
}
@media screen and (max-width: 1148px) {
  .rfx-invite-supplier .rfx-supplier-detail {
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
  .rfx-invite-supplier .dot {
    display: none;
  }
  .rfx-invite-supplier .supplier-name {
    padding-top: 10px;
  }
}

.rfx-invite-supplier .dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #a19b9d;
}
.rfx-invite-supplier .supplier-data {
  padding: 16px 0;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.rfx-compare-tab .close-icon-body,
.rfx-compare-price-tab .close-icon-body,
.rfx-lumpsum-tab .close-icon-body {
  width: 24px;
  height: 24px;
  background-color: #fcdee1;
  border-radius: 4px;
}
.rfx-compare-tab .close-icon-body .close-icon,
.rfx-compare-price-tab .close-icon-body .close-icon,
.rfx-lumpsum-tab .close-icon-body .close-icon {
  color: #c82333;
  width: 16px;
  height: 16px;
}
.rfx-compare-tab .hr-line,
.rfx-compare-price-tab .hr-line,
.rfx-lumpsum-tab .hr-line {
  width: 1px;
  height: auto;
  background-color: rgba(224, 224, 224, 1);
  margin: 0 6px;
}
.rfx-compare-tab .response-value {
  width: calc(100% - 140px);
}

.rfx-compare-price-tab th,
.rfx-lumpsum-tab th {
  border: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.rfx-compare-price-tab .sub-header,
.rfx-lumpsum-tab .sub-header {
  background-color: #f0f5ff;
  color: #2170ff;
  height: 40px !important;
}
.rfx-compare-price-tab th,
.rfx-lumpsum-tab th,
.rfx-compare-tab th {
  /* width: auto !important; */
  width: 350px !important;
  max-width: 350px !important;
  min-width: 350px !important;
}
.rfx-compare-tab td,
.rfx-compare-price-tab td,
.rfx-lumpsum-tab td {
  width: auto !important;
  max-width: 150px !important;
  min-width: 150px !important;
}
.rfx-compare-tab td:first-child {
  max-width: 300px !important;
  min-width: 300px !important;
}
.rfx-compare-price-tab th:nth-child(1),
.rfx-compare-price-tab td:nth-child(2) {
  border-right: 1px solid rgba(224, 224, 224, 1);
}
.rfx-compare-price-tab td:nth-child(2n),
.rfx-compare-price-tab th,
.rfx-compare-tab td,
.rfx-compare-tab th,
.rfx-lumpsum-tab td,
.rfx-lumpsum-tab th {
  position: relative;
}
.rfx-compare-price-tab th::before,
.rfx-compare-price-tab td:nth-child(2n)::before,
.rfx-compare-tab th::before,
.rfx-compare-tab td::before,
.rfx-lumpsum-tab th::before,
.rfx-lumpsum-tab td::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 80%;
  top: 10%;
  right: 0px;
  background-color: rgba(224, 224, 224, 1);
}
.rfx-compare-price-tab td:nth-child(2)::before,
.rfx-compare-price-tab td:last-child::before,
.rfx-compare-price-tab th:nth-child(1)::before,
.rfx-compare-price-tab th:last-child::before,
.rfx-compare-tab th:first-child::before,
.rfx-compare-tab td:first-child::before,
.rfx-compare-tab th:last-child::before,
.rfx-compare-tab td:last-child::before,
.rfx-lumpsum-tab th:last-child::before,
.rfx-lumpsum-tab td:last-child::before {
  display: none !important;
}
@media screen and (max-width: 730px) {
  .rfx-tab-header {
    padding-top: 60px !important;
    padding-bottom: 30px !important;
    grid-gap: 20px;
    gap: 20px;
  }
}
.rfx-submit-bid-modal {
  max-width: 540px !important;
}
.rfx-submit-bid-modal .submit-bid-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-right: 60px;
  padding-left: 60px;
}
@media screen and (max-width: 830px) {
  .rfx-submit-bid-modal .submit-bid-body {
    padding-left: 0;
    padding-right: 0;
  }
}
.rfx-submit-bid-modal .rfx-submit-icon {
  width: 60px !important;
  height: 60px !important;
}
.rfx-submit-bid-modal .submit-main-text {
  font-size: 20px;
}

.rfx-submit-bid-modal .submit-cancel-btn {
  background-color: #e2f2ff;
  color: #2170ff;
}
.rfx-submit-bid-modal .submit-cancel-btn:hover {
  background-color: #e2f2ff;
  color: #2170ff;
}
.rfx-tab-list .message-tab-count {
  background-color: #e2f2ff;
  color: #2170ff;
  border-radius: 50%;
  padding: 4px;
}
.rfx-tab {
  display: inline-flex;
  align-items: center;
}
.rfx-tab .message-count {
  border-radius: 50%;
  background-color: #2170ff;
  color: #fff;
  margin-left: 8px !important;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Mui-selected .rfx-tab .message-count {
  background-color: #fff;
  color: #2170ff;
}
.rfx-tab .tab-title {
  font-weight: 600 !important;
  font-size: 14px !important;
}
.messages-tab .collapsed-overlayed-page-contaier {
  z-index: 1050 !important;
}
.rfx-seller-detail-card .file-name-container {
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}
.rfx-seller-table .rfx-seller-status {
  max-width: 300px !important;
}
.rfx-read-more-modal {
  outline: none;
}
.rfx-read-more-modal .read-question {
  font-size: 18px;
  line-height: 1.1;
}
@media screen and (max-width: 1414px) {
  .rfx-tab-body .cancel-rfx {
    margin-top: 16px !important;
  }
}

