.mui-card {
  padding: 2em;
  border-radius: 12px;
  background-color: #fff;
  position: relative;
}

.mui-card-primary {
  background-color: var(---2170ff-p-);
}
.mui-chat-card {
  padding: 12px 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px #0000001f;
}

/* width  */

.w100-percent-box {
  max-width: 100%;
  flex-basis: 100%;
}

.w90-percent-box {
  max-width: 90%;
  flex-basis: 90%;
}

.w84-percent-box {
  max-width: 89%;
  flex-basis: 89%;
}

.w88-percent-box {
  max-width: 88%;
  flex-basis: 88%;
}

.w87-percent-box {
  max-width: 87%;
  flex-basis: 87%;
}

.w86-percent-box {
  max-width: 86%;
  flex-basis: 86%;
}

.w85-percent-box {
  max-width: 85%;
  flex-basis: 85%;
}

.w84-percent-box {
  max-width: 84%;
  flex-basis: 84%;
}

.w83-percent-box {
  max-width: 83%;
  flex-basis: 83%;
}

.w82-percent-box {
  max-width: 82%;
  flex-basis: 82%;
}

.w81-percent-box {
  max-width: 81%;
  flex-basis: 81%;
}

.w80-percent-box {
  max-width: 80%;
  flex-basis: 80%;
}

.w79-percent-box {
  max-width: 79%;
  flex-basis: 79%;
}

.w78-percent-box {
  max-width: 78%;
  flex-basis: 78%;
}

.w77-percent-box {
  max-width: 77%;
  flex-basis: 77%;
}

.w76-percent-box {
  max-width: 76%;
  flex-basis: 76%;
}

.w75-percent-box {
  max-width: 75%;
  flex-basis: 75%;
}

.w74-percent-box {
  max-width: 74%;
  flex-basis: 74%;
}

.w73-percent-box {
  max-width: 73%;
  flex-basis: 73%;
}

.w72-percent-box {
  max-width: 72%;
  flex-basis: 72%;
}

.w71-percent-box {
  max-width: 71%;
  flex-basis: 71%;
}

.w70-percent-box {
  max-width: 70%;
  flex-basis: 70%;
}

.w65-percent-box {
  max-width: 65%;
  flex-basis: 65%;
}

.w63-percent-box {
  max-width: 63%;
  flex-basis: 63%;
}

.w60-percent-box {
  max-width: 60%;
  flex-basis: 60%;
}

.w50-percent-box {
  max-width: 50%;
  flex-basis: 50%;
}

.w45-percent-box {
  max-width: 45%;
  flex-basis: 45%;
}

.w40-percent-box {
  max-width: 40%;
  flex-basis: 40%;
}

.w37-percent-box {
  max-width: 37%;
  flex-basis: 37%;
}

.w36-percent-box {
  max-width: 36%;
  flex-basis: 36%;
}

.w35-percent-box {
  max-width: 35%;
  flex-basis: 35%;
}

.w34-percent-box {
  max-width: 33%;
  flex-basis: 33%;
}

.w33-percent-box {
  max-width: 33%;
  flex-basis: 33%;
}

.w32-percent-box {
  max-width: 32%;
  flex-basis: 32%;
}

.w31-percent-box {
  max-width: 31%;
  flex-basis: 31%;
}

.w30-percent-box {
  max-width: 30%;
  flex-basis: 30%;
}

.w29-percent-box {
  max-width: 29%;
  flex-basis: 29%;
}

.w28-percent-box {
  max-width: 28%;
  flex-basis: 28%;
}

.w27-percent-box {
  max-width: 27%;
  flex-basis: 27%;
}

.w26-percent-box {
  max-width: 26%;
  flex-basis: 26%;
}

.w25-percent-box {
  max-width: 25%;
  flex-basis: 25%;
}

.w24-percent-box {
  max-width: 24%;
  flex-basis: 24%;
}

.w23-percent-box {
  max-width: 23%;
  flex-basis: 23%;
}

.w22-percent-box {
  max-width: 22%;
  flex-basis: 22%;
}

.w21-percent-box {
  max-width: 21%;
  flex-basis: 21%;
}
.w20-percent-box {
  max-width: 20%;
  flex-basis: 20%;
}

.w19-percent-box {
  max-width: 19%;
  flex-basis: 19%;
}

.w18-percent-box {
  max-width: 18%;
  flex-basis: 18%;
}

.w17-percent-box {
  max-width: 17%;
  flex-basis: 17%;
}

.w16-percent-box {
  max-width: 16%;
  flex-basis: 16%;
}

.w15-percent-box {
  max-width: 15%;
  flex-basis: 15%;
}

.w14-percent-box {
  max-width: 14%;
  flex-basis: 14%;
}

.w13-percent-box {
  max-width: 13%;
  flex-basis: 13%;
}

.w12p5-percent-box {
  max-width: 12.5%;
  flex-basis: 12.5%;
}

.w12-percent-box {
  max-width: 12%;
  flex-basis: 12%;
}

.w11-percent-box {
  max-width: 11%;
  flex-basis: 11%;
}

.w10-percent-box {
  max-width: 10%;
  flex-basis: 10%;
}

/* width  */

/* scroll bar  */

.scroll-page {
  overflow-y: scroll;
  height: 100vh;
}

.scroll-page {
  scrollbar-color: #dbdbdb rgba(0, 0, 0, 0.03);
  scrollbar-width: thin;
}

/* scrollbar */
.scroll-page::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}

.scroll-page::-moz-scrollbar {
  height: 12px;
  width: 12px;
}

.scroll-page::-ms-scrollbar {
  height: 12px;
  width: 12px;
}

.scroll-page::-o-scrollbar {
  height: 12px;
  width: 12px;
}
/* scrollbar */

/* scrollbar-track */
.scroll-page::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}

.scroll-page::-moz-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}

.scroll-page::-ms-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}

.scroll-page::-o-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  border-radius: 10px;
}
/* scrollbar-track */

/* scrollbar-thumb */
.scroll-page::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -moz-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.scroll-page::-moz-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -moz-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.scroll-page::-ms-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -moz-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.scroll-page::-o-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -moz-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}
/* scrollbar-thumb */

/* scroll bar  */
