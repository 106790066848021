/*  max height animation */

.animate-max-height.__hide {
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
  background: #e5feff;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  animation: 7s overflow-initial-animate;
}

.animate-max-height.__feed {
  max-height: 100%;
  opacity: 1;
  overflow: initial;
  -webkit-transition: max-height 1s;
  -moz-transition: max-height 1s;
  -ms-transition: max-height 1s;
  -o-transition: max-height 1s;
  transition: max-height 1s;
  animation: 5s opacity-animate;
}

/*  height animation */

.animate-height.__hide {
  -webkit-transition: height 1s;
  -moz-transition: height 1s;
  -ms-transition: height 1s;
  -o-transition: height 1s;
  transition: height 1s;
  background: #e5feff;
  height: 0;
  opacity: 0;
}

.animate-height.__feed {
  height: 100%;
  opacity: 1;
  -webkit-transition: height 1s;
  -moz-transition: height 1s;
  -ms-transition: height 1s;
  -o-transition: height 1s;
  transition: height 1s;
  animation: 1s opacity-animate;
}

/*  opacity animation */

.animate-opacity.__hide {
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -ms-transition: opacity 1s;
  -o-transition: opacity 1s;
  transition: opacity 1s;
  opacity: 0;
}

.animate-opacity.__feed {
  opacity: 1;
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -ms-transition: opacity 1s;
  -o-transition: opacity 1s;
  transition: opacity 1s;
  animation: 1s opacity-animate;
}

/*  max width animation */

.animate-max-width.__hide {
  -webkit-transition: max-width 1s;
  -moz-transition: max-width 1s;
  -ms-transition: max-width 1s;
  -o-transition: max-width 1s;
  transition: max-width 1s;
  max-width: 0;
}

.animate-max-width.__feed {
  max-width: 100%;
  -webkit-transition: max-width 1s;
  -moz-transition: max-width 1s;
  -ms-transition: max-width 1s;
  -o-transition: max-width 1s;
  transition: max-width 1s;
}

@keyframes overflow-initial-animate {
  from {
    overflow: initial;
  }
}

@keyframes opacity-animate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
