.btn-white.filter-btn {
  border-color: var(---f1f0f2) !important;
}

.btn-white.active.filter-btn {
  border-color: var(---2170ff-p-) !important;
}

.filter-box-container .filter-box.__inner {
  width: calc(100% + 48px);
  right: -24px;
}
