.supplier-edit-board-details-container *,
#menu-category {
  z-index: 1600 !important;
  font-size: 13px !important;
}

.supplier-edit-board-details-container .edit-btn {
  height: 26px;
  width: 52px;
  border-radius: 8px;
  font-size: 16px;
  background-color: #fff !important;
  border-color: var(---2170ff-p-) !important;
  border-width: 1px !important;
  color: var(---2170ff-p-) !important;
}

.supplier-edit-board-details-container .edit-btn:hover {
  background-color: var(---2170ff-p-) !important;
  color: #fff !important;
}

.supplier-edit-board-details-container .title-header {
  font-size: 14px !important;
  margin-top: 0px;
}

.supplier-edit-board-details-container .scorecard-container {
  position: relative;
  display: inline-flex;
}

.supplier-edit-board-details-container
  .scorecard-container
  .MuiCircularProgress-root {
  width: 100px !important;
  height: 100px !important;
}

.supplier-edit-board-details-container
  .scorecard-container
  .MuiCircularProgress-colorPrimary {
  color: var(---2170ff-p-);
}

.supplier-edit-board-details-container
  .scorecard-container
  .MuiCircularProgress-colorInherit {
  color: var(---ecdfe4);
  position: absolute;
  z-index: 1599 !important;
}

.supplier-edit-board-details-container
  .scorecard-container
  .MuiCircularProgress-circle,
.supplier-edit-board-details-container
  .scorecard-container
  .MuiCircularProgress-circleDeterminate {
  stroke-width: 1.6 !important;
}

.supplier-edit-board-details-container .scorecard-container .score-progress {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.supplier-edit-board-details-container
  .scorecard-container
  .score-progress
  .score-progress-number {
  font-size: 28px !important;
}

.supplier-edit-board-details-container .tag {
  border-radius: 18px;
  margin-left: 5px;
  margin-top: 5px;
}

.supplier-edit-board-details-container .tag.badge {
  padding: 0.2em 0.5em;
  display: flex;
  align-items: center;
}

.supplier-edit-board-details-container .mui-input {
  height: 43px !important;
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  background-color: #fff;
}

.supplier-edit-board-details-container .mui-select .MuiInputBase-root {
  height: 43px;
  background-color: #fff;
}

.supplier-edit-board-details-container .mui-select.MuiFormControl-root {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.supplier-edit-board-details-container .mui-select .MuiSelect-iconOutlined {
  margin-top: 3px;
  font-size: 18px !important;
}
