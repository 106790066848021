:root {
  ---2170ff-p-: #2170ff;
  ---2a5cec: #2a5cec;
  ---424143: #424143;
  ---1e1d1d: #1e1d1d;
  ---707070: #707070;
  ---a19b9d: #a19b9d;
  ---ecdfe4: #ecdfe4;
  ---e0e0e0: #e0e0e0;
  ---afafaf: #afafaf;
  ---e2feff: #e2feff;
  ---13c2c2: #13c2c2;
  ---009b73: #009b73;
  ---00d4b5-s-: #00d4b5;
  ---def8f3: #def8f3;
  ---83c9f4-p-: #83c9f4;
  ---e2f2ff: #e2f2ff;
  ---dee2ff: #dee2ff;
  ---e0a800: #e0a800;
  ---f1f0f2: #f1f0f2;
  ---f7fafc: #f7fafc;
  ---c82333: #c82333;
  ---dc3545: #dc3545;
  ---fd5656: #fd5656;
  ---ffe8ea: #ffe8ea;
  ---fbeaec: #fbeaec;
  ---ffc107: #ffc107;
  ---fcf6e5: #fcf6e5;
  ---575ba7: #575ba7;
  /* break points  */
  ---bkp-xs: 0px;
  ---bkp-sm: 600px;
  ---bkp-md: 960px;
  ---bkp-lg: 1280px;
  ---bkp-xl: 1536px;
}

* {
  margin: 0;
  /* font-family: "Noto Sans Avestan", sans-serif; */
  font-family: "Noto Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.toggle-display-hide {
  display: none !important;
}

.toggle-display-show {
  display: block !important;
}

.toggle-width-show {
  width: 100% !important;
  transition: width 0.5s ease;
}

.toggle-width-hide {
  width: 0 !important;
  transition: width 0.5s ease;
}

.toggle-height-show {
  height: 100% !important;
  transition: height 0.5s ease;
}

.toggle-height-hide {
  height: 0 !important;
  transition: height 0.5s ease;
}

.toggle-opacity-show {
  opacity: 1 !important;
  transition: opacity 0.5s ease;
}

.toggle-opacity-hide {
  opacity: 0 !important;
  transition: opacity 0.5s ease;
}

.flexible-container {
  margin-left: 6em !important;
}

@media screen {
  @media screen and (min-width: 1400px) {
    .flexible-container {
      margin-left: 0;
    }
  }
}

/* fix width and height  */
.fix-w-12em {
  min-width: 12.5rem !important;
}

fix-w-8em {
  min-width: 8rem !important;
}

.fix-w-6em {
  min-width: 6rem !important;
}

@media screen and (max-width: 960px) {
  .fix-w-12em {
    min-width: 6.5rem !important;
  }

  .fix-w-8em {
    min-width: 4.5rem !important;
  }

  .fix-w-6em {
    min-width: 3.5rem !important;
  }
}

/* mui link (anchor) */
.mui-link {
  text-decoration: none;
  color: var(---2170ff-p-);
}

.mui-link:link {
  color: var(---2170ff-p-);
}

/* visited link */
.mui-link:visited {
  color: var(---575ba7);
}

/* mouse over link */
.mui-link:hover {
  color: var(---575ba7);
}

/* selected link */
.mui-link:active {
  color: var(---575ba7);
}
/* mui link (anchor) */

/* bold  */
.bold-line-600 {
  font-size: 1em !important;
  font-weight: 600 !important;
}

.b-600 {
  font-weight: 600 !important;
}
/* bold  */

/* color  */
.brc-f1f0f2 {
  border-color: var(---f1f0f2) !important;
}

.clr-2170ff {
  color: var(---2170ff-p-) !important;
}
.clr-ff0000 {
  color: #f00 !important;
}

.clr-ffffff {
  color: #fff !important;
}
/* color  */

/* display  */
.d-flex {
  display: flex !important;
}

.d-block {
  display: block !important;
}

.d-inline {
  display: inline !important;
}

.d-none {
  display: none !important;
}
/* display  */

/* position  */
.position-rel {
  position: relative !important;
}

.position-abs {
  position: absolute !important;
}

/* fonts  */

.fz-16 {
  font-size: 16px !important;
}

.fz-18 {
  font-size: 18px !important;
}

.fz-30 {
  font-size: 30px !important;
}

.fz-32 {
  font-size: 32px !important;
}

.fz-34 {
  font-size: 34px !important;
}
/* font size  */

/* width  */
.w-100 {
  width: 100% !important;
}
.w-50 {
  width: 50% !important;
}
.w-auto {
  width: auto !important;
}
/* width  */

/* margin  */
.m-0 {
  margin: 0px !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.25rem !important;
}

.m-5 {
  margin: 1.75rem !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.25rem !important;
}

.mt-5 {
  margin-top: 1.75rem !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.25rem !important;
}

.mb-5 {
  margin-bottom: 1.75rem !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.25rem !important;
}

.mr-5 {
  margin-right: 1.75rem !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.25rem !important;
}

.ml-5 {
  margin-left: 1.75rem !important;
}
/* margin  */

/* padding  */
.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.25rem !important;
}

.p-5 {
  padding: 1.75rem !important;
}

.p-0 {
  padding: 0px !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.25rem !important;
}

.pt-5 {
  padding-top: 1.75rem !important;
}
.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.25rem !important;
}

.pb-5 {
  padding-bottom: 1.75rem !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.px-5 {
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
}
.py-1 {
  padding: 0.25rem !important;
}

.py-2 {
  padding: 0.5rem !important;
}

.py-3 {
  padding: 1rem !important;
}

.py-4 {
  padding: 1.25rem !important;
}

.py-5 {
  padding: 1.75rem !important;
}
.pt-0 {
  padding-top: 0px !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.25rem !important;
}

.pt-5 {
  padding-top: 1.75rem !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.25rem !important;
}

.pb-5 {
  padding-bottom: 1.75rem !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-4 {
  padding-right: 1.25rem !important;
}

.pr-5 {
  padding-right: 1.75rem !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.25rem !important;
}

.pl-5 {
  padding-left: 1.75rem !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.25rem !important;
}

.mt-5 {
  margin-top: 1.75rem !important;
}
.ml-auto {
  margin-left: auto !important;
}
.float-right {
  float: right;
}
/* padding  */

/* text alignment  */
.text-end {
  text-align: end !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}
/* text alignment  */

.text-transform-cap {
  text-transform: capitalize !important;
}

/* item alignment  */
.items-center {
  align-items: center;
}
/* item alignment */

/* cursor shape  */

.cursor-p {
  cursor: pointer;
}
/* cursor shape */

/* overflow: hidden; */
.overflow-h {
  overflow: hidden;
}
/* overflow: hidden; */

.bck-transparent {
  background-color: transparent !important;
}
.MuiFormLabel-root {
  line-height: 1.4 !important;
}

.lineBreak {
  word-break: break-all;
}
.Toastify__close-button {
  align-self: center !important;
  width: 14px;
  height: 16px;
  padding: 0 10px 0 0 !important;
}
.Toastify__toast--success {
  box-shadow: 0 6px 15px -7px #edf7ed;
  background-color: #edf7ed !important;
}

.Toastify__toast--success .Toastify__toast-body div {
  color: #1e4620;
}
.Toastify__toast--succcess .Toastify__close-button {
  background-color: #edf7ed !important;
  padding: 0 10px 0 0;
}
.Toastify__close-button svg {
  fill: #1e4620;
}
.Toastify__toast--error {
  box-shadow: 0 6px 15px -7px #fdecea;
  background-color: #fdecea !important;
}

.Toastify__toast--error .Toastify__toast-body div {
  color: #611a15;
}
.Toastify__toast--error .Toastify__close-button {
  background-color: #fdecea !important;
}
.Toastify__toast--error .Toastify__close-button svg {
  fill: #611a15;
}
.lineheight28 {
  line-height: 28px;
}
.breakLine {
  white-space: break-spaces;
}
.simple-word-break {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table-word-break {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  word-break: break-word;
}
.minW175 {
  width: 175px !important;
  max-width: 175px;
}
.minW350 {
  min-width: 350px;
}
.mw100 {
  max-width: 100px;
}
.mw200 {
  max-width: 200px;
}
.minW200 {
  min-width: 200px !important;
}
.max-width100 {
  max-width: 100%;
}
.global-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.global-flex-center.item-start {
  justify-content: start !important;
}
.supplier-import .import-done-icon {
  width: 60px;
  height: 60px;
}
.cusrsor-pointer {
  cursor: pointer;
}
.text-warning {
  color: #ffc107 !important;
}
.g-10 {
  gap: 10px;
}
.error-msg {
  color: #e64646 !important;
  margin-bottom: 8px;
  font-size: 12px !important;
  line-height: 1.1;
}

/* COLOR-CLASS */
.text-black {
  color: #1e1d1d;
}
.text-gray {
  color: #a19b9d;
}
.text-primary {
  color: #1e65e6;
}
.text-dark {
  color: #424143;
}

/* FONT-WEIGHT */
.text-bold {
  font-weight: 700;
}

.item-disabeled {
  cursor: not-allowed !important;
  filter: grayscale(1) !important;
}
.badge.badge-primary.button-disabeled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  cursor: not-allowed;
}
.supp-auction-details-live-bid-container
  .place-bid-container
  .btn-mui.btn-primary.button-disabeled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  cursor: not-allowed;
}
.button-disabeled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  cursor: not-allowed;
}
.h-30 {
  height: 30px;
}
.d-flex {
  display: flex;
}
.align-item-center {
  align-items: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-end {
  justify-content: end;
}
.common-slider {
  background-color: #fff;
  min-height: 100vh;
}
.common-slider.MuiBox-root {
  border-radius: 0 !important;
}
.common-textarea-input .MuiFormControl-root,
.common-textarea-input .MuiInputBase-root {
  min-height: 44px;
  height: 100%;
  padding: 0;
}
.question-seller-tab .common-textarea-input .MuiFormControl-root,
.question-seller-tab .common-textarea-input .MuiInputBase-root {
  width: 100%;
}

.common-textarea-input .MuiInputBase-root textarea {
  padding: 0 20px !important;
  min-height: 28px;
  height: 100%;
  line-height: 1.6;
}
.font1622 {
  font-size: 16px;
  line-height: 22px;
}

.common-light-button {
  background-color: #e2f2ff;
  color: #2170ff;
}
.common-border-button {
  background-color: #ffffff;
  color: #2170ff;
  border: 1px solid #2170ff;
}
.text-white {
  color: #ffffff;
}

.bg-grey {
  background-color: #f8fcff;
}
.bg-primary {
  background-color: #2170ff;
}
.side-bar-container .MuiListItemIcon-root img {
  object-fit: contain !important;
  max-width: 38px;
}
.d-block {
  display: block;
}
.common-attachment-btn {
  text-transform: capitalize;
  border: 1px #2170ff solid;
  background-color: #2170ff;
  color: #fff;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 0.6em 1.4em;
  border-radius: 10px;
  margin-left: 12px;
  height: 48px;
  cursor: pointer;
}
.common-attachment-btn.outline {
  color: #2170ff !important;
  background-color: #fff !important;
}

.button-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.bg-skyBlue {
  background-color: #f0f5ff !important;
}

.select-label-margin {
  margin-top: -9px !important;
}

.common-title {
  color: #2170ff;
  font-size: 24px;
  line-height: 33px;
  font-weight: 700;
}
.divider-title {
  width: 100%;
  height: 5px;
  border-top: 1px solid #eeeeee;
}
.MuiSelect-select:focus {
  background-color: transparent !important;
}
.link-outline-none {
  text-decoration: none !important;
  color: #000000de !important;
}
