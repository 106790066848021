/* Popup Design  */

/* third-step popup form */
[aria-label="create-auction-third-step-buyer-aspect"] .popup-form-container {
  width: 80vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="create-auction-third-step-buyer-aspect"] .popup-form-container {
    width: 60vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="create-auction-third-step-buyer-aspect"] .popup-form-container {
    width: 80vw;
  }
}

[aria-label="create-auction-third-step-buyer-aspect"]
  .image-uploader-container
  .uploader-header
  .upload-btn {
  padding: 0px 14px;
  min-width: 132px;
}

[aria-label="create-auction-third-step-buyer-aspect"]
  .image-uploader-container
  .uploader-header
  .upload-btn.add-link {
  color: #fff;
  background-color: var(---2170ff-p-);
}

[aria-label="create-auction-third-step-buyer-aspect"]
  .image-uploader-container
  .uploader-header
  .upload-btn
  .MuiButton-endIcon {
  margin-left: 0px !important;
}

[aria-label="create-auction-third-step-buyer-aspect"] .btn-mui {
  font-size: 16px !important;
}

/* third-step popup form */

/* Upload Option List  */
[id="create-auction-third-step-buyer-aspect-upload-file-options-menu"]
  .MuiMenu-list {
  min-width: 227px;
}

[id="create-auction-third-step-buyer-aspect-upload-file-options-menu"]
  .MuiMenu-list
  .MuiMenuItem-root {
  font-size: 16px !important;
  padding: 11px 14px;
  display: block;
}

/* Upload Option List  */

/* Upload Link Popup  */

[aria-label="create-auction-third-step-buyer-aspect-upload-link-file"]
  .popup-form-container {
  width: 30vw;
}

/* for meduim screen  */
@media screen and (max-width: 1200px) {
  [aria-label="create-auction-third-step-buyer-aspect-upload-link-file"]
    .popup-form-container {
    width: 50vw;
  }
}

/* for small screen  */
@media screen and (max-width: 960px) {
  [aria-label="create-auction-third-step-buyer-aspect-upload-link-file"]
    .popup-form-container {
    width: 70vw;
  }
}

/* Upload Link Popup  */
