.auth-box-container.reset-forgot
  .form-box-container
  .form-banner-container
  .main-title {
  text-align: center;
}

.auth-box-container.reset-forgot
  .form-box-container
  .form-banner-container
  .sub-title {
  color: var(---a19b9d);
  border-left: none;
  text-transform: none;
  font-weight: 500;
}

@media screen and (min-width: 1071px) and (max-width: 1279px) {
  .auth-box-container.reset-forgot .face-logo {
    width: 400px;
    height: 380px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1070px) {
  .auth-box-container.reset-forgot .face-logo {
    width: 360px;
    height: 380px;
  }
}
