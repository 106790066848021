.settings-profile-section-contaier .mui-label {
  margin-bottom: -2px;
}

.settings-profile-section-contaier .MuiFormControl-root {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.settings-profile-section-contaier
  .mui-input.clean-bck-input
  .MuiOutlinedInput-notchedOutline,
.settings-profile-section-contaier
  .mui-input.clean-bck-input
  .MuiInputBase-root.Mui-disabled
  .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

.settings-profile-section-contaier
  .mui-input.clean-bck-input
  .MuiInputBase-root.Mui-disabled {
  background: transparent;
  font-size: 27px;
  font-weight: 600;
}

.settings-profile-section-contaier
  .mui-input.clean-bck-input
  textarea:first-of-type {
  height: 45px;
}
.settings-profile-section-contaier .mui-input textarea:first-of-type,
.settings-profile-section-contaier
  .mui-select
  .MuiSelect-outlined.MuiSelect-outlined {
  font-weight: 600;
}

.settings-profile-section-contaier .change-password-btn {
  width: 120px;
  height: 40px;
  background: var(---e2f2ff);
  border-radius: 12px;
  color: #1976d2;
  font-weight: 600;
}

.settings-profile-section-contaier
  .change-password-btn.MuiButton-containedPrimary:hover {
  background-color: var(---e2f2ff);
}

/* <> preferences */
.settings-profile-section-contaier .preferences-container .row-label {
  max-width: 45%;
  flex-basis: 45%;
}

@media screen and (max-width: 600px) {
  .settings-profile-section-contaier .preferences-container .row-label {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.settings-profile-section-contaier .preferences-container .row-field {
  max-width: 50%;
  flex-basis: 50%;
}

@media screen and (max-width: 600px) {
  .settings-profile-section-contaier .preferences-container .row-field {
    max-width: 100%;
    flex-basis: 100%;
  }
}
/* </> preferences */

/* <> password  */
.settings-profile-section-contaier .password-container .row-label {
  max-width: 45%;
  flex-basis: 45%;
}

@media screen and (max-width: 600px) {
  .settings-profile-section-contaier .password-container .row-label {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.settings-profile-section-contaier .password-container .row-field {
  max-width: 50%;
  flex-basis: 50%;
}

@media screen and (max-width: 600px) {
  .settings-profile-section-contaier .password-container .row-field {
    max-width: 100%;
    flex-basis: 100%;
  }
}
/* </> password */

/* ---bkp-xs: 0px;
---bkp-sm: 600px;
---bkp-md: 960px;
---bkp-lg: 1280px;
---bkp-xl: 1536px; */
