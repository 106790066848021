.support-drawer {
  max-height: 100vh;
  overflow: auto;
}
.support-drawer .support-header-body {
  padding: 30px 32px 10px;
}
.support-drawer .support-body {
  padding: 32px;
}
.support-drawer .MuiTextField-root,
.support-drawer .core-select {
  width: 100% !important;
  margin: 0 !important;
}
.support-drawer .MuiFormLabel-root {
  padding-bottom: 6px;
  color: #424143;
  font-weight: 600;
}

.support-drawer-appbar .collapsed-overlayed-page-contaier {
  z-index: 1200 !important;
}
.support-drawer .common-attachment-btn {
  width: fit-content;
}
