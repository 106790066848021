#error-box-wrapper {
  position: relative;
  height: auto;
  background-color: var(---83c9f4-p-);
  min-height: 100vh;
}

#error-box-wrapper .box {
  max-width: 70%;
  min-height: auto;
  margin: 0 auto;
  padding: 1em 1em;
  text-align: center;
  background: url("https://www.dropbox.com/s/xq0841cp3icnuqd/flame.png?raw=1")
      no-repeat top 10em center/128px 128px,
    transparent
      url("https://www.dropbox.com/s/w7qqrcvhlx3pwnf/desktop-pc.png?raw=1")
      no-repeat top 13em center/128px 128px;
}

#error-box-wrapper h1,
#error-box-wrapper p:not(:last-of-type) {
  /* text-shadow: 0 0 6px var(---2170ff-p-); */
}

#error-box-wrapper h1 {
  margin: 0 0 1rem 0;
  font-size: 8em;
}

#error-box-wrapper p {
  margin-bottom: 0.5em;
  font-size: 3em;
}

#error-box-wrapper p:first-of-type {
  margin-top: 4em;
}

#error-box-wrapper p > a {
  /* border-bottom: 1px dashed var(---2170ff-p-); */
  font-style: italic;
  text-decoration: none;
  color: var(---2170ff-p-);
}

#error-box-wrapper p > a:hover {
  /* text-shadow: 0 0 6px var(---2170ff-p-); */
}

#error-box-wrapper p img {
  vertical-align: bottom;
}
