@import url("https://fonts.googleapis.com/css?family=Noto+Sans:400,700,500,600|Roboto:400|Noto+Sans+Tamil:400");
.table-style {
  background: #ffffff;
  cursor: pointer;
}

@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 1650px !important;
  }
}

table thead tr {
  height: 44px !important;
}

.MuiOutlinedInput-root {
  border-radius: 8px !important;
}

table th,
table thead th {
  text-align: left;
  padding-bottom: 10 !important;
}

.table-style:hover {
  background-color: #e2f2ff;
}

table td {
  display: flex;
  height: 75px;
  align-items: center;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.heading {
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
}

.searchInputWrapper {
  position: relative;
}

.searchInput {
  width: 18rem;
  color: #a19b9d;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid #f1f0f2;
  outline: none;
  background: none;
  /* transition: transform 0.1s ease-in-out; */
}

::placeholder {
  color: #a1a1a1;
}

/* hide the placeholder text on focus */

:focus::placeholder {
  text-indent: -999px;
}

.searchInput:focus {
  outline: none;
  /* transform: scale(1.1); */
  /* transition: all 0.1s ease-in-out; */
}

.searchInputIcon {
  position: absolute;
  right: 0.8rem;
  top: 0.5rem;
  color: #a1a1a1;
  /* transition: all 0.1s ease-in-out; */
}

.container:focus-within > .searchInputWrapper > .searchInputIcon {
  right: 0.2rem;
}

/* Select Styles */

.MuiBox-root {
  border-radius: 12px !important;
}

.MuiDrawer-root {
  background: rgb(51, 54, 204);
  background: linear-gradient(
    0deg,
    rgba(51, 54, 204, 1) 0%,
    rgba(33, 112, 255, 1) 100%
  );
}

.app-main-section-container {
  flex-grow: 1;
  min-height: 100vh;
  overflow: overlay;
  background: #f2f2f2;
  margin-left: 96px;
}
.MuiList-root .MuiMenuItem-root {
  display: block !important;
  padding: 6px 16px !important;
  margin: 0 !important;
}
.textfield-error-style {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  color: #f44336;
}
.textfield-error-style.contract-module {
  margin-left: 10px;
}
