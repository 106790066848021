.app-bar-container .app-toolbar-container {
  padding-right: 3em;
}

.app-bar-container .app-toolbar-container .SearchInputList {
  border: none !important;
  margin-left: 5%;
}

.app-bar-container .app-toolbar-container .SearchInputList input {
  margin-left: 0.7em;
}

.app-bar-container .app-toolbar-container .app-toolbar-list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: space-around;
  align-items: flex-end;
}

.app-bar-container .app-toolbar-container .app-toolbar-list .popup-container {
  position: absolute;
  right: -1.7em;
  box-shadow: 0px 3px 6px 3px #00000029 !important;
  top: 1.4em;
  border-radius: 8px !important;
  /* max-height: 40vh;
  overflow-y: overlay;
  min-height: 10rem; */
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .popup-container
  .arrow-icon::before {
  position: absolute;
  right: 15px;
  top: -17px;
  content: "";
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 16px solid #00000021;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .popup-container
  .arrow-icon::after {
  position: absolute;
  right: 14px;
  top: -14px;
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #fff;
  z-index: 10;
}

.app-bar-container .app-toolbar-container .app-toolbar-list .MuiMenuItem-root {
  padding: 17px 25px !important;
  font-size: 1rem !important;
  justify-content: center !important;
  position: relative;
  font-weight: 600;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .MuiMenuItem-root
  a {
  color: var(---2170ff-p-);
  text-decoration: none;
}

.app-bar-container .app-toolbar-container .app-toolbar-list > div {
  margin-right: 1em;
}

.app-bar-container .app-toolbar-container .app-toolbar-list > div:last-of-type {
  margin-right: 0em;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .notification-list-container,
.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .auction-list-container,
.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-list-container {
  position: relative;
  cursor: pointer;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .auction-list-container
  .MuiAvatar-root:hover {
  background-color: var(---e2f2ff);
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .notification-list-container
  .MuiAvatar-root:hover {
  background-color: var(---def8f3);
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .notification-list-container
  .MuiAvatar-img {
  width: 1em !important;
  height: 1.1em !important;
}

.app-bar-container .app-toolbar-container .app-toolbar-list .badge {
  color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  top: -4px;
  z-index: 1;
  text-align: center;
  padding: 1px 0px;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .badge.badge-success {
  background-color: var(---00d4b5-s-);
  border-color: var(---00d4b5-s-);
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .badge.badge-primary {
  background-color: var(---2170ff-p-);
  border-color: var(---2170ff-p-);
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .list-item-image-container {
  background-color: var(---83c9f4-p-);
  height: 60px;
  border-radius: 8px;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .list-item-image-container
  img {
  height: 100%;
  width: 60px;
  padding: 8px;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .list-item-details
  .text {
  white-space: break-spaces;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .list-item-details
  .sub-text {
  color: var(---afafaf);
  font-size: 0.7em;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .auction-list-container
  ul,
.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .notification-list-container
  ul {
  min-width: 30em;
}

.app-bar-container .app-toolbar-container .app-toolbar-list ul {
  padding: 0 !important;
  max-height: 40vh;
  min-height: 9em;
  overflow-y: auto;
  scrollbar-color: #dbdbdb rgba(0, 0, 0, 0.03);
  scrollbar-width: thin;
}

/* scroll bar design  */

/* scrollbar */

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  ul::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.app-bar-container .app-toolbar-container .app-toolbar-list ul::-moz-scrollbar {
  width: 12px;
  height: 12px;
}

.app-bar-container .app-toolbar-container .app-toolbar-list ul::-ms-scrollbar {
  width: 12px;
  height: 12px;
}

.app-bar-container .app-toolbar-container .app-toolbar-list ul::-o-scrollbar {
  width: 12px;
  height: 12px;
}
/* scrollbar */

/* scrollbar-track */

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  background: linear-gradient(
    0deg,
    rgb(239 239 239) 0%,
    rgb(255, 255, 255) 100%
  );
  border-radius: 10px;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  ul::-moz-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  background: linear-gradient(
    0deg,
    rgb(239 239 239) 0%,
    rgb(255, 255, 255) 100%
  );
  border-radius: 10px;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  ul::-ms-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  background: linear-gradient(
    0deg,
    rgb(239 239 239) 0%,
    rgb(255, 255, 255) 100%
  );
  border-radius: 10px;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  ul::-o-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -ms-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  -o-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.103);
  background: linear-gradient(
    0deg,
    rgb(239 239 239) 0%,
    rgb(255, 255, 255) 100%
  );
  border-radius: 10px;
}

/* scrollbar-track */

/* scrollbar-thumb */

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -moz-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  ul::-moz-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -moz-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  ul::-ms-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -moz-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -ms-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  ul::-o-scrollbar-thumb {
  border-radius: 10px;
  -o-box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  box-shadow: inset 0 0 6px rgb(0 0 0 / 16%);
  background: linear-gradient(
    0deg,
    rgb(241 241 241) 0%,
    rgb(255, 255, 255) 100%
  );
}

/* scrollbar-thumb */

/* scroll bar design  */

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-list-container
  .MuiAvatar-root {
  width: 44px;
  height: 44px;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-list-container
  .MuiAvatar-circular.__active {
  border: 2px var(---2170ff-p-) solid;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-list-container
  .MuiAvatar-circular.__nonactive {
  border: 2px transparent solid;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-list-container
  ul {
  /* min-width: 22em; */
  padding: 0 !important;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-list-container
  .profile-label {
  opacity: 1 !important;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-label
  .text {
  white-space: nowrap;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-label
  .list-item-image-container {
  background-color: transparent;
  border-radius: 0;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-label
  .list-item-image-container
  img {
  padding: 0;
  border-radius: 50%;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-label
  .sub-text {
  font-size: 0.8em;
  font-weight: 500;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-list-container
  .MuiMenuItem-root {
  justify-content: left !important;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-list-container
  .MuiMenuItem-root:nth-child(1) {
  padding-bottom: 22px !important;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-list-container
  .MuiMenuItem-root:nth-child(3) {
  padding-bottom: 10px !important;
}

.app-bar-container
  .app-toolbar-container
  .app-toolbar-list
  .profile-list-container
  .MuiMenuItem-root:nth-child(4) {
  padding-top: 10px !important;
  padding-bottom: 1.8em !important;
}
.support-list-container .support-option-icon img {
  width: 32px !important;
  height: auto !important;
}
