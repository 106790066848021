[aria-label="create-auction-fifth-step-buyer-aspect"] .popup-form-container {
  width: 80vw;
}
[aria-label="modal-add-document-from-library"] .popup-form-container {
  width: 50vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="create-auction-fifth-step-buyer-aspect"] .popup-form-container {
    width: 60vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="create-auction-fifth-step-buyer-aspect"] .popup-form-container {
    width: 80vw;
  }
}

/* auto-complete section  */

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .btn-mui.auto-complete-btn {
  width: 100%;
  border: 1px solid #e0e0e0 !important;
  border-radius: 8px !important;
  min-height: 45px;
  padding: 0px 10px !important;
}

@media screen and (max-width: 600px) {
  [aria-label="create-auction-fifth-step-buyer-aspect"]
    .btn-mui.auto-complete-btn {
    margin-left: 0px;
  }
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .btn-mui.auto-complete-btn
  .MuiButton-label {
  font-size: 16px;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiAutocomplete-root
  .MuiOutlinedInput-notchedOutline {
  border-color: #e0e0e0;
}

[aria-label="create-auction-fifth-step-buyer-aspect"] .MuiAutocomplete-root {
  border-color: #e0e0e0;
}

[aria-label="create-auction-fifth-step-buyer-aspect"] .MuiAutocomplete-input {
  padding: 0px !important;
  padding-left: 12px !important;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiAutocomplete-popupIndicatorOpen {
  color: var(---a19b9d);
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiAutocomplete-popupIndicatorOpen
  .MuiSvgIcon-root {
  transform: rotateZ(180deg);
}

/* auto-complete section  */

/* Supplier table  */

[aria-label="create-auction-fifth-step-buyer-aspect"] table tbody,
[aria-label="create-auction-fifth-step-buyer-aspect"] table thead,
[aria-label="create-auction-fifth-step-buyer-aspect"] table th,
[aria-label="create-auction-fifth-step-buyer-aspect"] table tr,
[aria-label="create-auction-fifth-step-buyer-aspect"] table td {
  display: block;
  max-width: none !important;
}

[aria-label="create-auction-fifth-step-buyer-aspect"] table tr:after {
  content: " ";
  display: block;
  visibility: hidden;
  clear: both;
}

[aria-label="create-auction-fifth-step-buyer-aspect"] table tbody {
  height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #dbdbdb rgba(0, 0, 0, 0.03);
  scrollbar-width: thin;
}

[aria-label="create-auction-fifth-step-buyer-aspect"] table tr:first-of-type {
  margin-bottom: 0px;
}

[aria-label="create-auction-fifth-step-buyer-aspect"] table tr {
  min-width: 800px;
  margin-bottom: -2px;
}

[aria-label="create-auction-fifth-step-buyer-aspect"] table td {
  padding-top: 30px !important;
}

[aria-label="create-auction-fifth-step-buyer-aspect"] table tbody tr:hover {
  background-color: #fff !important;
}

[aria-label="create-auction-fifth-step-buyer-aspect"] .MuiTableCell-root {
  border-bottom: 1px solid #e0e0e0 !important;
  float: left;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiTableCell-root:first-of-type {
  border-left: 1px solid #e0e0e0 !important;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiTableCell-root:last-of-type {
  border-right: 1px solid #e0e0e0 !important;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiTableHead-root
  .MuiTableRow-head
  .MuiTableCell-root {
  border-top: 1px solid #e0e0e0 !important;
  line-height: 0.5rem;
  min-height: 42px;
  white-space: nowrap;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiTableHead-root
  .MuiTableRow-head
  .MuiTableCell-root:first-of-type {
  border-left: 1px solid #e0e0e0 !important;
  border-top-left-radius: 8px;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiTableHead-root
  .MuiTableRow-head
  .MuiTableCell-root:last-of-type {
  border-right: 1px solid #e0e0e0 !important;
  border-top-right-radius: 8px;
}

/* devide grid pixels  */
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(1) {
  width: 35%;
  min-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(2) {
  width: 41%;
  min-width: 328px;
  overflow: hidden;
  text-overflow: ellipsis;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(3) {
  width: 12%;
  min-width: 96px;
  overflow: hidden;
  text-overflow: ellipsis;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .MuiTableCell-root:nth-of-type(4) {
  width: 12%;
  min-width: 96px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* devide grid pixels  */

/* scroll bar design  */

/* scrollbar */
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-webkit-scrollbar,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-moz-scrollbar,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-moz-scrollbar {
  width: 7px;
  height: 7px;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-ms-scrollbar,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-ms-scrollbar {
  width: 7px;
  height: 7px;
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-o-scrollbar,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-o-scrollbar {
  width: 7px;
  height: 7px;
}
/* scrollbar */

/* scrollbar-track */

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-webkit-scrollbar-track,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-moz-scrollbar-track,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-moz-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-ms-scrollbar-track,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-ms-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-o-scrollbar-track,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-o-scrollbar-track {
  border-radius: 10px;
  background: var(---f1f0f2);
}

/* scrollbar-track */

/* scrollbar-thumb */
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-webkit-scrollbar-thumb,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-moz-scrollbar-thumb,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-moz-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-ms-scrollbar-thumb,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-ms-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}

[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container.MuiTableContainer-root::-o-scrollbar-thumb,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .table-container
  .MuiTableBody-root::-o-scrollbar-thumb {
  border-radius: 10px;
  background: var(---a19b9d);
}
/* scrollbar-thumb */

/* scroll bar design  */

/* Supplier table  */

[aria-label="create-auction-fifth-step-buyer-aspect"] .BottomModal .btn-mui {
  font-size: 16px !important;
}
