.supplier-list-details-info-container *,
#supplier-list-info-details-status,
#supplier-list-info-details-option-list,
#supplier-list-info-details-attach-option-list,
[aria-label="supplier-list-info-details-contact-popup"],
[aria-label="supplier-list-info-details-attach-link-popup"] {
  z-index: 1600 !important;
}

.supplier-list-details-info-container .contact {
  margin-bottom: 10px;
}

.supplier-list-details-info-container .contact .icon {
  color: #80808085;
  font-size: 18px;
}

.supplier-list-details-info-container .contact .link {
  color: var(---1e1d1d);
  text-decoration: none;
  margin-left: 8px;
  word-break: break-word;
  padding-right: 15px;
}

.supplier-list-details-info-container .contact .link:hover {
  color: var(---2170ff-p-);
}

.supplier-list-details-info-container .MuiFormControl-root,
.MuiInputBase-root {
  height: 44px;
}

.supplier-list-details-info-container .btn-mui-table-option.MuiButton-root {
  background: #fff;
  color: var(---2170ff-p-);
  height: 44px;
}

.table-container tbody td[aria-label="contact-name-col"],
.table-container tbody th[aria-label="contact-name-col"] {
  font-weight: 600;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}
