/* .filter-box-container .select-field .MuiButton-root .MuiButton-label {
  white-space: nowrap;
} */
.mandatorySign{
  color: red;
}
.projects-card-container {
  background-color: white;
  border-radius: 12px;
  padding: 24px;
}

.table-container .status.project-status {
  position: absolute;
  top: 10px;
  left: 10px;
}

.table-container .action-list {
  background-color: var(---e2f2ff) !important;
  color: var(---2170ff-p-) !important;
  border: px solid var(---e2f2ff) !important;
  font-size: 7px !important;
  box-shadow: none !important;
  border-radius: 50% !important;
  padding: 0px 0px 0px 0px !important;
  min-width: 1em !important;
  transform: rotate(90deg);
}

.table-container .action-list .MuiSvgIcon-root {
  width: 30px;
  height: 30px;
  background: var(---f7fafc);
  border-radius: 50%;
  border: 2px var(---f7fafc) solid;
}

.table-container .action-list:hover .MuiSvgIcon-root {
  background: var(---e2f2ff);
  border-color: var(---e2f2ff);
}

.table-container .action-list[aria-controls] .MuiSvgIcon-root {
  border-color: var(---2170ff-p-);
}

#options-popup-menu .MuiList-root .MuiMenuItem-root {
  font-size: 14px !important;
}

#options-popup-menu .MuiList-root .MuiDivider-root {
  margin: 5px;
}

.project-footer-pagination{
  margin-top: 15px;
}

.pagination-total-container{
  color: var(---a19b9d);
  font-weight: 500;
  font-size: 1em;
}
.f-l{
  float: right;
}