.table-container .file-name-container {
  display: flex;
  overflow: hidden;
}

.table-container .file-name-container .icon {
  color: var(---a19b9d);
}

.table-container .file-name-container .name {
  margin-left: 10px;
  color: var(---2170ff-p-);
  padding-top: 3px;
  font-size: 16px;
}

/* attach document modal */
.popup-form-container {
  width: 40vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  .popup-form-container {
    width: 30vw;
  }
}

.table-record-count-libarary{
  color: var(---a19b9d);
    font-weight: 500;
    font-size: 1em;
    padding-top: 11px;
}
