.settings-company-section-contaier .mui-label {
  margin-bottom: -2px;
}

.settings-company-section-contaier .MuiFormControl-root {
  width: 100% !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.settings-company-section-contaier
  .mui-input.clean-bck-input
  .MuiOutlinedInput-notchedOutline,
.settings-company-section-contaier
  .mui-input.clean-bck-input
  .MuiInputBase-root.Mui-disabled
  .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

.settings-company-section-contaier
  .mui-input.clean-bck-input
  .MuiInputBase-root.Mui-disabled {
  background: transparent;
  font-size: 27px;
  font-weight: 600;
}

.settings-company-section-contaier
  .mui-input.clean-bck-input
  textarea:first-of-type {
  height: 45px;
}
.settings-company-section-contaier .mui-input textarea:first-of-type,
.settings-company-section-contaier
  .mui-select
  .MuiSelect-outlined.MuiSelect-outlined {
  font-weight: 600;
}

.settings-company-section-contaier .change-password-btn {
  width: 120px;
  height: 40px;
  background: var(---e2f2ff);
  border-radius: 12px;
  color: #1976d2;
  font-weight: 600;
}

.settings-company-section-contaier
  .change-password-btn.MuiButton-containedPrimary:hover {
  background-color: var(---e2f2ff);
}

.settings-company-section-contaier .act-container {
  display: flex;
  flex-wrap: wrap;
}

.settings-company-section-contaier .act-container .activity {
  display: flex;
  align-items: center;
  color: rgb(75, 74, 74) !important;
  font-weight: 600;
  border-radius: 18px;
  font-size: 14px;
  padding: 4px 8px;
  margin: 5px 5px 5px 0px;
}

.settings-company-section-contaier .act-container .activity svg {
  width: 16px;
  height: 16px;
  margin: 0px 4px;
  cursor: pointer;
}

[aria-label="company-settings-attachments-option-list"] {
  width: 200px;
}
