/* Popup Design  */
[aria-label="create-supplier-popup-buyer-aspect"] .popup-form-container {
  width: 40vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="create-supplier-popup-buyer-aspect"] .popup-form-container {
    width: 40vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="create-supplier-popup-buyer-aspect"] .popup-form-container {
    width: 50vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 600px) {
  [aria-label="create-supplier-popup-buyer-aspect"] .popup-form-container {
    width: 70vw;
  }
}

[aria-label="create-supplier-popup-buyer-aspect"] .btn-mui {
  font-size: 16px !important;
}

/* bottom buttons  */

[aria-label="create-supplier-popup-buyer-aspect"]
  .popup-form-container
  .bottom-button-box-container {
  margin-top: 3em;
}

[aria-label="create-supplier-popup-buyer-aspect"]
  .popup-form-container
  .bottom-button-box-container
  .left-side {
  min-width: 165px;
}
/* bottom buttons  */

/* first-step popup form */
