.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:focus,
.btn:hover {
  text-decoration: none;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

/* Primary  */
.btn-primary {
  color: #fff;
  background-color: var(---2170ff-p-);
  border-color: var(---2170ff-p-);
}

.btn-primary:hover {
  /* need to be more bold  */
  background-color: var(---2170ff-p-);
  /* need to be more bold  */
  border-color: var(---2170ff-p-);
}

.btn-outline-primary {
  color: var(---2170ff-p-);
  background-color: transparent;
  background-image: none;
  border-color: var(---2170ff-p-);
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 50%);
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: var(---2170ff-p-);
  border-color: var(---2170ff-p-);
}

.btn-primary-lite {
  color: var(---2170ff-p-) !important;
  background-color: var(---e2f2ff) !important;
  border: 2px solid var(---e2f2ff) !important;
}

/* success  */
.btn-success {
  color: #fff;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}

.btn-light-blue {
  background-color: var(---e2feff) !important;
  border-color: var(---e2feff) !important;
  color: #1976d2 !important;
}

/* white  */
.btn-white {
  color: var(---2170ff-p-) !important;
  background-color: #fff !important;
  border: 2px solid #fff !important;
}

.btn-white.active {
  border-color: var(---2170ff-p-) !important;
}

.btn-danger {
  color: #fff !important;
  background-color: var(---c82333) !important;
  border: 2px solid var(---c82333) !important;
}

.btn.draft {
  color: var(---424143);
  background-color: var(---f1f0f2);
  border-color: var(---f1f0f2);
}

/* .btn-primary:hover {
  background-color: var(---2170ff-p-);
  border-color: var(---2170ff-p-);
} */

/* MUI Button Custome Style  */
.btn-mui {
  padding: 0.4rem 2rem !important;
  border-radius: 12px !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  min-height: 48px;

  text-transform: capitalize;
}

.setting-button {
  color: #fff !important;
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
  padding: 0.6em 1.4em !important;
  border-radius: 10px !important;
  margin-left: 12px !important;
  height: 48px !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
}

.btn-mui-table-option.MuiButton-root {
  text-transform: capitalize;
  border: 1px #2170ff solid;
  padding: 0.6em 1.4em;
  border-radius: 10px;
  margin-left: 12px;
  height: 48px;
}

.btn-mui-table-option.outline.MuiButton-root {
  text-transform: capitalize;
  border: none;
  padding: 0px;
  margin-left: 12px;
  color: var(---424143);
  background-color: transparent;
  margin-right: 40px;
  font-weight: 600;
}

.btn-mui-table-option.outline .MuiButton-endIcon {
  margin-left: 5px !important;
}

/* MUI Button Custome Style  */
