.collapsed-overlayed-page-contaier {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1500;
  background-color: #000000a8;
  width: 0vw;
  min-height: 100vh;
  transition: width 0.5s;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}

.collapsed-overlayed-page-contaier.active {
  width: 100vw;
  overflow: visible;
}

.collapsed-overlayed-page-contaier .collapsed-overlayed-page-body {
  width: 0vw;
  overflow: hidden;
  transition: width 0.5s;
}

.collapsed-overlayed-page-contaier .collapsed-overlayed-page-body.active {
  width: inherit;
  overflow: visible;
}

.collapsed-overlayed-page-contaier .close-icon {
  color: white;
  font-size: 26px;
  margin: 16px;
  cursor: pointer;
}
