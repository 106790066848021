.mui-tooltip .MuiTooltip-tooltip {
  font-size: 14px;
  padding: 16px 16px 24px 16px;
  border-radius: 12px;
}

.mui-tooltip-primary .MuiTooltip-tooltip {
  background-color: var(---e2f2ff);
  color: var(---707070);
}

.mui-tooltip-primary .MuiTooltip-arrow {
  color: var(---e2f2ff);
}

.mui-tooltip.MuiTooltip-popperArrow[x-placement*="top-start"]
  .MuiTooltip-arrow {
  left: 6px !important;
}
