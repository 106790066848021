.table-container.buyer-aspect .aution-img-container {
  height: 3em;
  border: 2px transparent solid;
  border-radius: 10px;
  margin-right: 2em;
  width: 3em;
  display: block;
}

.table-container.buyer-aspect .aution-img-container img {
  padding: 5px;
  height: 100%;
}

.table-container.buyer-aspect .aution-img-container.blue {
  background-color: var(---2170ff-p-);
}
.table-container.buyer-aspect .aution-img-container.grey {
  background-color: var(---575ba7);
}
.table-container.buyer-aspect .aution-img-container.green {
  background-color: var(---00d4b5-s-);
}

.table-container.buyer-aspect .auction-title h3 {
  margin-bottom: 2px;
}

.table-container.buyer-aspect .auction-title p {
  padding-top: 0px !important;
  margin-top: 0px;
  color: var(---a19b9d);
  font-weight: 100;
  font-size: 12px;
}

.table-container.buyer-aspect .auction-datetime {
  white-space: pre-wrap !important;
}

.form2CheckBox label .MuiTypography-root {
  font-weight: 600;
  font-size: 13px;
}

.container .imgPreview.doc_box_auction {
  margin: 0 !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #dbe8ff;
  border: 2px dashed #2170ff;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.container .imgPreview.doc_box_auction .upload-btn {
  background-color: #fff;
  color: #2170ff;
  margin: 20px 0;
  font-size: 13px;
  border-radius: 5em;
  padding: 0.5rem 2rem;
  font-weight: 700;
}

#auction-tab-list .MuiTabs-flexContainer {
  flex-wrap: wrap;
}

[aria-label="create-auction-zero-step-buyer-aspect"]
  .StepControl
  .divider-container,
[aria-label="create-auction-first-step-buyer-aspect"]
  .StepControl
  .divider-container,
[aria-label="create-auction-second-step-buyer-aspect"]
  .StepControl
  .divider-container,
[aria-label="create-auction-third-step-buyer-aspect"]
  .StepControl
  .divider-container,
[aria-label="create-auction-fourth-step-buyer-aspect"]
  .StepControl
  .divider-container,
[aria-label="create-auction-fifth-step-buyer-aspect"]
  .StepControl
  .divider-container {
  max-width: 60px;
}

@media screen and (max-width: 960px) {
  [aria-label="create-auction-zero-step-buyer-aspect"]
    .form-stepper-label-container
    .step-text,
  [aria-label="create-auction-first-step-buyer-aspect"]
    .form-stepper-label-container
    .step-text,
  [aria-label="create-auction-second-step-buyer-aspect"]
    .form-stepper-label-container
    .step-text,
  [aria-label="create-auction-third-step-buyer-aspect"]
    .form-stepper-label-container
    .step-text,
  [aria-label="create-auction-fourth-step-buyer-aspect"]
    .form-stepper-label-container
    .step-text,
  [aria-label="create-auction-fifth-step-buyer-aspect"]
    .form-stepper-label-container
    .step-text {
    display: none;
  }
}

.auction-duplicate-success-icon {
  width: 60px !important;
  height: 60px !important;
}
