.profile-title {
  margin-top: 0;
  font-weight: 700 !important;
  margin-bottom: 10px;
  border-bottom: 1px solid #e2e2e2;
  font-size: 18px;
  padding-bottom: 8px;
}

.BlocTabContainer {
  display: flex;
}

/* Tab */
.BlocTabMenuContainer {
  padding: 20px;
}

.MuiTabs-indicator {
  display: none !important;
}

.BlocTabMenuContainer button {
  padding: 0 !important;
  width: 100%;
  min-height: auto !important;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: flex-start;
}

.MuiOutlinedInput-root {
  border-radius: 8px !important;
}

.BlocTabMenuContainer button:nth-last-child() {
  border: 0 !important;
}

.BlocTabMenuContainer button.Mui-selected {
  background-color: #2170ff;
  color: rgb(255, 255, 255);
  z-index: 999;
  border-radius: 12px !important;
}

.BlocTabMenuContainer button svg,
.BlocTabMenuContainer button img {
  margin: 18px 20px !important;
  width: 24px;
  height: 24px;
}

.TabMenu:hover {
  background-color: #e2f2ff;
  border-radius: 0 !important;
  z-index: 0 !important;
}

.TabMenu {
  border-bottom: 1px solid #e0e0e0 !important;

  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  text-transform: none !important;
  font-weight: 700 !important;
}

.TabMenu.Mui-selected:hover {
  background-color: none !important;
  border: none;
}

.containerSettings {
  padding: 65px 0 0 0;
  max-width: 1120px;
  margin: auto;
}

.leftContent,
.RightContent {
  display: flex;
  flex-direction: column;
}

.leftContent {
  justify-items: center;
  justify-content: center;
  padding-right: 35px;
}

.RightContent {
  display: flex;
  flex-direction: column;
}

.RightContent .MuiFormControl-root,
.BlocPreferences .MuiFormControl-root,
.BlocPrefeBlocPasswordrences .MuiFormControl-root {
  width: 100% !important;
}

.RightContent .MuiGrid-root,
.BlocPreferences .MuiGrid-root,
.BlocPassword .MuiGrid-root {
  display: flex !important;
  flex-direction: row !important;
  margin-bottom: 15px;
  border-radius: 8px !important;
}

.RightContent .MuiGrid-root button,
.BlocPassword button {
  height: 48px !important;
  margin: 8px !important;
  background-color: #e2f2ff !important;
  color: #2170ff !important;
  border-radius: 12px !important;
  font-weight: bold;
  font-size: 12px !important;
}

.BlocInfos .BottomModal-left button {
  background-color: #f1f0f2 !important;
}

.BlocInfos .BottomModal-left button span:nth-child(1) {
  color: rgb(75, 74, 74) !important;
}

.BlocTabContainer .BottomModal {
  padding-top: 50px !important;
}

.BlocPreferences,
.BlocPassword {
  width: auto;
  align-items: center;
}

table tfoot td {
  border-bottom: none !important;
}

/* table 4 columns T4*/

.T4columns table {
  width: auto;
}

.T4columns table td:nth-child(1) {
  width: 35%;
}

/* column 2 */
.T4columns table td:nth-child(2) {
  width: 35%;
}

/* column 3 */
.T4columns table td:nth-child(3) {
  width: 25%;
}
/* column 4 */
.T4columns table td:nth-child(4) {
  width: 10%;
}

/* table 3 columns T3*/

.T3columns table {
  width: 100%;
}

.T3columns table td:nth-child(1) {
  width: 55%;
}

/* column 2 */
.T3columns table td:nth-child(2) {
  width: 35%;
}

/* column 3 */
.T3columns table td:nth-child(3) {
  width: 10%;
}

.TextAfterImage {
  margin-left: 6px;
  position: relative;
  top: -6px;
  font-weight: 700;
}

.SearchBox button {
  margin: 0 !important;
  text-transform: none;
  font-weight: 600;
}

.SearchBox button svg {
  margin-right: 10px !important;
}

.SearchBox button span {
  margin-left: 11px !important;
}

.SearchBox {
  margin: 20px 0;
  align-items: center !important;
}

.SettingsModal .MuiBox-root .BottomModal-right {
  margin-left: 0 !important;
  justify-content: space-between;
}

.SettingsModal .MuiGrid-item {
  padding: 0 !important;
}

.SettingsModal .MuiBox-root .BottomModal-right button {
  margin: 0 !important;
}

.SettingsModal .MuiBox-root .MuiFormControl-root {
  margin: 0 !important;
  width: 100% !important;
}

.SettingsModal .MuiGrid-container {
  margin: 30px 0 5px;
  width: 100% !important;
}

.error-msg {
  color: red;
  font-size: 11px;
  padding-left: 5px;
}

.summary-word-count {
  font-size: 12px;
  color: #bbb;
}
.react-tel-input {
  border-radius: 8px !important;
  height: 45px !important;
}
.react-tel-input > input {
  width: 100% !important;
  height: 45px !important;
}
.react-tel-input .flag-dropdown {
  border-radius: 8px 0 0 8px;
}
