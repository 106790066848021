/* @import url("https://fonts.googleapis.com/css2?family=Raleway:wght@1,600&display=swap"); */

.auth-box-container {
  background: transparent
    linear-gradient(180deg, var(---2170ff-p-) 0%, #3336cc 100%) 0% 0% no-repeat
    padding-box;
  display: flex;
  min-height: 100vh;
  align-items: center;
}

.auth-box-container .text-logo-box-container {
  position: relative;
  display: none;
  text-align: -webkit-center;
  text-align: center;
}

@media screen and (min-width: 1280px) {
  .auth-box-container .text-logo-box-container {
    display: block;
    text-align: center;
  }
}

.auth-box-container .text-logo-box {
  /* background: #94c8f157; */
  padding: 33px 0px;
  border-radius: 100px 0px;
  /* width: 330px; */
  text-transform: lowercase;
}

.auth-box-container .text-logo-box span {
  font-family: "Raleway", sans-serif !important;
  font-weight: 700;
  font-size: 72px;
  letter-spacing: 4px;
  color: #fff;
  opacity: 1;
  text-transform: lowercase;
  padding-left: 12px;
}

@media screen and (min-width: 1475px) {
  .auth-box-container .text-logo-box {
    /* width: 410px; */
    height: 165px;
  }
}

.auth-box-container .text-logo-img {
  position: absolute;
  top: 71px;
  transform: translate(-50%, -25%);
  height: 40px;
}

@media screen and (min-width: 1475px) {
  .auth-box-container .text-logo-img {
    transform: translate(-50%, 0%);
    height: 42px;
  }
}

.auth-box-container .face-logo {
  text-align: center;
  margin-bottom: 5em;
}

.auth-box-container .face-logo img {
  /* width: 480px;
  height: 380px;
  text-align: -webkit-right; */
  width: 60%;
}

@media screen and (min-width: 1475px) {
  .auth-box-container .face-logo {
    /* width: 525px; */
    height: 380px;
  }
}

.auth-box-container .form-box-container {
  background-color: #fff;
  padding: 50px 70px;
  border-radius: 30px;
  margin-top: 25px;
}

.auth-box-container .form-box-container .footer {
  font-size: 12px;
}

@media screen and (min-width: 1475px) {
  .auth-box-container .form-box-container .footer {
    font-size: 16px;
  }
}

.auth-box-container .form-box-container .form-banner-container .main-title {
  text-align: center;
  font-size: 22px;
  color: var(---424143);
  font-weight: 600;
}

@media screen and (min-width: 1475px) {
  .auth-box-container .form-box-container .form-banner-container .main-title {
    font-size: 32px;
  }
}

.auth-box-container .form-box-container .form-banner-container .sub-title {
  font-size: 14px;
  color: var(---424143);
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  border-left: 1px solid var(---424143);
  padding: 14px 0px;
}
.auth-box-container
  .form-box-container
  .form-banner-container
  .singup-sub-title {
  font-size: 14px;
  color: var(---2170ff-p-);
  /* text-transform: uppercase; */
  text-align: center;

  padding: 14px 0px 0px;
}

@media screen and (min-width: 1475px) {
  .auth-box-container
    .form-box-container
    .form-banner-container
    .singup-sub-title {
    font-size: 16px;
  }
}
@media screen and (min-width: 1475px) {
  .auth-box-container .form-box-container .form-banner-container .sub-title {
    font-size: 16px;
  }
}

.auth-box-container a {
  text-decoration: none;
  color: var(---2170ff-p-);
}

.auth-box-container .form-box-container form {
  margin-top: 2em;
}

@media screen and (min-width: 1475px) {
  .auth-box-container .form-box-container form {
    margin-top: 4em;
  }
}

.auth-box-container
  .form-box-container
  .mui-input
  .MuiOutlinedInput-notchedOutline {
  border-radius: 30px;
}

.auth-box-container .form-box-container .mui-input .MuiSvgIcon-root {
  width: 16px;
  height: 16px;
  margin: 0px 14px 0px 12px;
}

.auth-box-container .form-box-container .remember-me-box-container {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: var(---a19b9d);
}

.auth-box-container .form-box-container .mui-input {
  min-height: 55px !important;
}
.auth-box-container .form-box-container .mui-select {
  min-height: 55px !important;
}

.auth-box-container .form-box-container .mui-input .MuiInputBase-root {
  height: 55px !important;
}

.auth-box-container .form-box-container .mui-input textarea:first-of-type {
  max-height: 20px !important;
  height: 20px !important;
}

.auth-box-container .form-box-container .mui-select {
  width: 100%;
}

.auth-box-container .form-box-container .mui-select .MuiInputBase-root {
  border-radius: 30px !important;
  height: 55px !important;
}

.auth-box-container .form-box-container .error-msg {
  color: red;
  font-size: 11px;
  padding-left: 5px;
}

.auth-box-container .form-box-container .submit-btn {
  background-color: var(---2170ff-p-);
  border-radius: 30px;
  color: #fff;
  height: 55px !important;
}

.auth-box-container .form-box-container .submit-btn.MuiButton-root {
  text-transform: none;
  font-size: 19px;
}

.auth-box-container .form-box-container .checkbox-container {
  min-height: 45px;
  display: flex;
  align-items: center;
  padding: 8px;
}

.auth-box-container .form-box-container .checkbox-container .MuiCheckbox-root {
  margin-left: 12px;
  color: var(---2170ff-p-);
}

.auth-box-container
  .form-box-container
  .checkbox-container
  .MuiTypography-root {
  color: var(---424143);
  font-weight: 600;
  /* white-space: nowrap; */
}

.auth-box-container .form-box-container .checkbox-container .hint-icon {
  width: 16px;
  height: 16px;
  color: var(---2170ff-p-);
  margin-left: 10px;
  display: block;
}

.auth-box-container
  .form-box-container
  .checkbox-container
  .MuiFormControl-root {
  height: 45px;
}

.auth-box-container
  .form-box-container
  .checkbox-container
  .MuiFormControlLabel-root {
  min-height: 45px;
}

.auth-box-container .form-box-container .MuiGrid-container .user-tab {
  margin: 14px 0;
  justify-content: center;
}
.auth-box-container .form-box-container .MuiGrid-container .inner-all-tab {
  border-bottom: 1px solid var(---2170ff-p-);
}
.signup-tab-body > div {
  padding: 0 !important;
}
.signup-tab-body > div {
  padding: 0 !important;
}
.sub-text-none {
  opacity: 0;
  pointer-events: none;
  user-select: none;
}
