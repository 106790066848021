/* zero-step popup form */
[aria-label="create-auction-zero-step-buyer-aspect"] .popup-form-container {
  width: 40vw;
}

/* for xtra large screen  */
@media screen and (min-width: 1536px) {
  [aria-label="create-auction-zero-step-buyer-aspect"] .popup-form-container {
    width: 30vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 1200px) {
  [aria-label="create-auction-zero-step-buyer-aspect"] .popup-form-container {
    width: 50vw;
  }
}

/* for xtra large screen  */
@media screen and (max-width: 600px) {
  [aria-label="create-auction-zero-step-buyer-aspect"] .popup-form-container {
    width: 70vw;
  }
}
/* zero-step popup form */
