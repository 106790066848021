.badge {
  border-radius: 3px;
  font-size: 0.9em;
  padding: 0.5em 0.7em;
}

.badge.badge-info {
  background: var(---83c9f4-p-) 0% 0% no-repeat padding-box;
  border-color: var(---83c9f4-p-);
  color: #fff;
}

.badge.badge-success {
  background: var(---def8f3) 0% 0% no-repeat padding-box !important;
  border-color: var(---def8f3) !important;
  color: var(---00d4b5-s-) !important;
}

.badge.badge-secondary {
  background: var(---f1f0f2) 0% 0% no-repeat padding-box !important;
  border-color: var(---f1f0f2) !important;
  color: var(---a19b9d) !important;
}

.badge.badge-primary {
  background: var(---2170ff-p-) 0% 0% no-repeat padding-box !important;
  border-color: var(---2170ff-p-) !important;
  color: #fff !important;
}

.badge.badge-primary-outline {
  background: var(---e2f2ff) 0% 0% no-repeat padding-box !important;
  border-color: var(---e2f2ff) !important;
  color: var(---2170ff-p-) !important;
}

.badge.badge-warning {
  background: var(---ffc107) 0% 0% no-repeat padding-box;
  border-color: var(---ffc107);
  color: #fff;
}

.badge.badge-warning-outline {
  background: var(---fcf6e5) 0% 0% no-repeat padding-box;
  border-color: var(---ffc107);
  color: var(---ffc107);
}

.badge.badge-purple {
  background: var(---dee2ff) 0% 0% no-repeat padding-box !important;
  border-color: var(---dee2ff) !important;
  color: var(---575ba7) !important;
}

.badge.badge-danger {
  background: var(---c82333) 0% 0% no-repeat padding-box;
  border-color: var(---c82333);
  color: #fff;
}

.badge.badge-danger-outline {
  background: var(---fbeaec) 0% 0% no-repeat padding-box;
  border-color: var(---fbeaec);
  color: var(---c82333);
}
