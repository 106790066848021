/* list-all-auction-table-supplier-aspect */

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .mui-select
  .MuiInputBase-root {
  font-weight: 600;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .mui-select
  .MuiSelect-icon {
  color: rgb(33 33 33);
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .aution-img-container {
  height: 3em;
  border: 2px transparent solid;
  border-radius: 10px;
  margin-right: 1em;
  width: 3em;
  display: block;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .aution-img-container.disabled {
  opacity: 0;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .aution-img-container
  img {
  padding: 5px;
  height: 100%;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .aution-img-container.blue {
  background-color: var(---2170ff-p-);
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .aution-img-container.grey {
  background-color: var(---575ba7);
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .aution-img-container.green {
  background-color: var(---00d4b5-s-);
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .auction-title
  h3 {
  margin-bottom: 2px;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .auction-title
  p {
  padding-top: 0px !important;
  margin-top: 0px;
  color: #ada7a9;
  font-weight: 100;
  font-size: 12px;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .auction-datetime {
  white-space: pre-wrap !important;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container .status {
  border-radius: 6px !important;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container .status {
  border-radius: 6px !important;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .badge.badge-win.status {
  background: #a5eda7 0% 0% no-repeat padding-box;
  border-color: #a5eda7;
  color: #509148;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .badge.badge-success.status {
  /* background: #a5eda7 0% 0% no-repeat padding-box;
    border-color: #a5eda7; */
  color: #307e73;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .badge.badge-secondary.status {
  background: #a0b2c4 0% 0% no-repeat padding-box;
  border-color: #a0b2c4;
  color: #375572;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .badge.badge-secondary.status {
  background: #a0b2c4 0% 0% no-repeat padding-box;
  border-color: #a0b2c4;
  color: #375572;
}

[aria-label="list-all-auction-table-supplier-aspect"].table-container
  .badge.badge-warning-outline.status {
  background: #ffd3b2 0% 0% no-repeat padding-box;
  border-color: #ffd3b2;
  color: #8c6140;
}
